import React from "react";
import { Breadcrumb, Card, Divider, Table, Modal } from "antd";
import {
  InputNumber,
  Radio,
  Rate,
  Slider,
  Switch,
  message,
  Upload,
} from "antd";
import {
  AutoComplete,
  Button,
  Popconfirm,
  Cascader,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Tooltip,
  formLayout,
} from "antd";
import { Tabs } from "antd";
import IntlMessages from "util/IntlMessages";
import { MenuService, OptionService } from "../../services";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";

import { Collapse } from "antd";
const { Panel } = Collapse;
function callback(key) {
  console.log(key);
}

// search-script
const Search = Input.Search;
const { confirm } = Modal;
// search-script-end

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const columns = [
  {
    title: "Option Name",
    dataIndex: "optionName",
    key: "optionName",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
];

// PrimarySize-table-data
const columnsPrimarySize = [
  {
    title: <IntlMessages id="menuOptionTemplate.optionItems" />,
    dataIndex: "option",
    key: "option",
  },
  {
    title: <IntlMessages id="menuOptionTemplate.price" />,
    dataIndex: "price",
    key: "price",
  },
];

//   upload-script start
const props = {
  name: "file",
  action: "//jsonplaceholder.typicode.com/posts/",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
//   upload-script end

class optionTemplates extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value }, this.jsonObject);
  };

  //For Testing Purpose
  jsonObject = () => {
    //     let values=this.props.form.getFieldsValue();
    // console.log(this.state.selectSecondayOption);
    //     let secondryTemplateItems = [];
    //     let secondaryCheckbox = []
    //     for (const key in values) {
    //       let soId=key.split('-')[0];
    //       if (typeof(values[key]) == "boolean" && values[key]==true) {
    //         secondaryCheckbox.push(soId)
    //       }
    //     }
    //     for (const key in values) {
    //       let ids = key.split('-');
    //       let value=values[key];
    //       let defaultSecondaryOptionItemId=ids[0];
    //       let defaultPrimaryOptionItemId= ids[1];
    //       let defaultSecondaryOptionId= ids[2];
    //       if(typeof(value)!="boolean" && this.state.selectSecondayOption.includes(defaultSecondaryOptionId) && secondaryCheckbox.includes(defaultSecondaryOptionItemId) && this.isSelectedPrimaryOptionItemId(defaultPrimaryOptionItemId)){
    //               secondryTemplateItems.push({
    //                 DefaultPrimaryOptionItemId: defaultPrimaryOptionItemId,
    //                 DefaultSecondaryOptionId: defaultSecondaryOptionId,
    //                 DefaultSecondaryOptionItemId: defaultSecondaryOptionItemId,
    //                 Price: (value == undefined || value=="" || value == "undefined")?undefined:value,
    //                 UseDefault: (value == undefined || value=="" || value == "undefined") ? true : this.state.defaultPrice[defaultSecondaryOptionId],
    //                 IsAvailable: true
    //               });
    //         }
    //     }
    //     this.setState({jsonObject:"form : "+JSON.stringify(secondryTemplateItems)});
  };

  constructor() {
    super();
    this.state = {
      valuesPrice: {},
      defaultValuePrice: {},
      template: null,
      templateId: "",
      templateName: "",
      secondaryOptionsIds: {},

      defaultPrice: [],
      secondaryOptions: [],
      selectSecondayOptionList: [],
      primaryOptions: [],
      selectedPrimaryId: "",
      selectedPrimaryName: "",
      selectedPrimaryOption: [],
      saveTemplateLoading: false,

      selectSecondayOption: [],

      primaryCheckbox: false,
      secondaryCheckbox: false,

      primaryOptionList: [],

      formLayout: "horizontal",
      visible: false,
      addItem: false,
      addOption: false,

      jsonObject: "",
    };
  }

  checkDefaultPrice = (e, index) => {
    let defaultPrice = this.state.defaultPrice;
    defaultPrice[index] = e.target.checked;
    this.setState(
      {
        defaultPrice: defaultPrice,
      },
      this.jsonObject
    );
  };

  async componentDidMount() {
    await this.init();
  }

  async init() {
    const allOptions = await MenuService.getAllOption();
    this.setState(
      {
        secondaryOptions: this.getSelectiveOptionList(
          allOptions.filter(
            (o) => o.IsPrimary == false && o.IsAvailable == true
          )
        ),
        primaryOptions: this.getSelectiveOptionList(
          allOptions.filter((o) => o.IsPrimary == true && o.IsAvailable == true)
        ),
      },
      this.jsonObject
    );

    const template =
      this.props.location.template && this.props.location.template.data;
    if (template) {
      let defaultPrice = {};
      let valuesPrice = {};
      let defaultValuePrices = {};
      let secondaryOptionsIdsValues = {};

      const getAllOptionTempleteInGroup =
        await MenuService.getAllOptionTempleteInGroup();
      getAllOptionTempleteInGroup.forEach((ot) => {
        if (template.Id == ot.Id) {
          ot.SecondaryCombinationOptions.forEach((sco) => {
            sco.SecondryOptions.forEach((so) => {
              defaultPrice[so.DefaultSecondaryOptionId] = so.UseDefault;
              var key =
                so.DefaultSecondaryOptionItemId +
                "-" +
                so.DefaultPrimaryOptionItemId +
                "-" +
                so.DefaultSecondaryOptionId;
              valuesPrice[key] = so.Price;
              secondaryOptionsIdsValues[key] = so.Id;
              so.DefaultSecondaryOption.OptionItems.forEach((oi) => {
                if (so.DefaultSecondaryOptionItemId == oi.Id) {
                  defaultValuePrices[key] = oi.Price;
                }
              });
            });
          });
        }
      });

      let secondaryCheckbox = [];
      template.SecondryOptions.forEach((x) => {
        if (!secondaryCheckbox.includes(x.DefaultSecondaryOptionId)) {
          secondaryCheckbox.push(x.DefaultSecondaryOptionId);
        }
      });

      this.setState(
        {
          template: template,
          templateId: template.Id,
          templateName: template.Name,
          primaryCheckbox: template.PrimaryOptionId,
          secondaryCheckbox: secondaryCheckbox,
          defaultPrice: defaultPrice,
          valuesPrice: valuesPrice,
          defaultValuePrice: defaultValuePrices,
          secondaryOptionsIds: secondaryOptionsIdsValues,
        },
        () => {
          this.selectPrimaryOption();
          this.selectSecondayOption();
          this.jsonObject();
        }
      );
    }

    console.log(this.state);
  }

  getSelectiveOptionList(optionList) {
    return optionList.map((o) => {
      return {
        name: o.Name,
        id: o.Id,
        primary: o.IsPrimary,
        optionItems: o.OptionItems,
      };
    });
  }

  getSelectivePrimaryOptionItemsList(optionItems) {
    return optionItems.map((oi) => {
      return { key: oi.Id, option: oi.Name, price: oi.Price };
    });
  }
  getSelectiveSecondaryOptionList(secondaryOptions) {
    return secondaryOptions.map((so) => {
      return {
        selectedSecondaryId: so.id,
        selectedSecondaryName: so.name,
        selectedSecondaryOption: so.optionItems,
      };
    });
  }

  selectSecondayOption = (checkedValues) => {
    const checkedValuesList = checkedValues || this.state.secondaryCheckbox;
    var secondaryOptions = this.state.secondaryOptions.filter((x) =>
      checkedValuesList.includes(x.id)
    );
    this.setState(
      {
        selectSecondayOptionList:
          this.getSelectiveSecondaryOptionList(secondaryOptions),
        selectSecondayOption: checkedValuesList, //checkedValues
      },
      this.jsonObject
    );
  };

  selectPrimaryOption = (e) => {
    const id = (e && e.target.value) || this.state.primaryCheckbox;

    this.state.primaryOptions.forEach((po) => {
      if (po.id == id) {
        this.setState(
          {
            selectedPrimaryId: po.id,
            selectedPrimaryName: po.name,
            selectedPrimaryOption: po.optionItems,
            primaryOptionList: this.getSelectivePrimaryOptionItemsList(
              po.optionItems
            ),
          },
          this.jsonObject
        );
      }
    });
  };

  setTemplateName = (e) => {
    this.setState(
      {
        templateName: e.target.value,
      },
      this.jsonObject
    );
  };

  isSelectedPrimaryOptionItemId = (id) => {
    let result = false;
    this.state.selectedPrimaryOption.forEach((x) => {
      if (x.Id == id) {
        result = true;
      }
    });
    return result;
  };

  submit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        if (this.state.templateName == "") {
          message.error("Please add template name..!");
          return;
        } else if (!this.state.selectedPrimaryId) {
          message.error("Please select primary option..!");
          return;
        } else if (this.state.selectSecondayOptionList.length == 0) {
          message.error("Please select secondary option..!");
          return;
        }

        this.setState({ saveTemplateLoading: true }, this.jsonObject);

        let secondryTemplateItems = [];
        let secondaryCheckbox = [];
        for (const key in values) {
          if (typeof values[key] == "boolean" && values[key] == true) {
            secondaryCheckbox.push(key.split("-")[0]);
          }
        }

        for (const key in values) {
          let secondaryoptionId = this.state.secondaryOptionsIds[key];
          let ids = key.split("-");
          let value = values[key];
          let defaultSecondaryOptionItemId = ids[0];
          let defaultPrimaryOptionItemId = ids[1];
          let defaultSecondaryOptionId = ids[2];

          if (
            typeof value != "boolean" &&
            this.state.selectSecondayOption.includes(
              defaultSecondaryOptionId
            ) &&
            secondaryCheckbox.includes(defaultSecondaryOptionItemId) &&
            this.isSelectedPrimaryOptionItemId(defaultPrimaryOptionItemId)
          ) {
            secondryTemplateItems.push({
              Id: secondaryoptionId,
              DefaultPrimaryOptionItemId: defaultPrimaryOptionItemId,
              DefaultSecondaryOptionId: defaultSecondaryOptionId,
              DefaultSecondaryOptionItemId: defaultSecondaryOptionItemId,
              Price:
                value == undefined || value == "" || value == "undefined"
                  ? undefined
                  : value,
              UseDefault:
                value == undefined || value == "" || value == "undefined"
                  ? true
                  : this.state.defaultPrice[defaultSecondaryOptionId],
              IsAvailable: true,
            });
          }
        }

        const template = {
          OptionTemplateId: this.state.templateId,
          Name: this.state.templateName,
          PrimaryOptionId: this.state.selectedPrimaryId,
          SecondryTemplateItems: secondryTemplateItems,
        };

        const response = await OptionService.addOptionTemplate(template);
        if (response && response.Success) {
          message.success("Template saved");
          this.setState(
            {
              templateId: response.Data.Id,
            },
            this.jsonObject
          );
        } else {
          message.error(response.APIMessage);
        }
        this.setState({ saveTemplateLoading: false }, this.jsonObject);
      }
    });
  };

  saveOptionTemplate = (e) => {
    let _this = this;
    confirm({
      title: "Do you really want to change this?",
      icon: <ExclamationCircleOutlined />,
      content: "Click!",
      onOk() {
        console.log("OK");
        _this.submit();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // Modal-scripts end
  render() {
    // form-scripts start
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 16 },
          }
        : null;
    const buttonItemLayout =
      formLayout === "horizontal"
        ? {
            wrapperCol: { xs: 24, sm: { span: 14, offset: 6 } },
          }
        : null;
    // form-scripts end
    // tab-script-start
    const TabPane = Tabs.TabPane;
    const Option = Select.Option;
    // tab-script-end
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list option-list Option-Template Option-Template-ui">
        <div className="payout-report-box option-add-box">
          {/*  {this.state.jsonObject} */}

          <Card className="gx-card breadcrumb-box" title="">
            <div>
              <div className="title-sub">
                <h2>
                  <IntlMessages id="optionTemplates.allOptions" />
                </h2>
                <hr className="hr-line"></hr>
              </div>

              <div class="ant-row Platform-Charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <div className="detail-res-lft">
                    <div class="ant-row charge-form op-cnter">
                      <h2 style={{ paddingTop: "20px", color: "#8bc53f" }}>
                        <IntlMessages id="optionTemplates.primary" /> :{" "}
                      </h2>
                      <Radio.Group
                        onChange={this.selectPrimaryOption}
                        value={this.state.selectedPrimaryId}
                      >
                        {this.state.primaryOptions.map((v, i) => (
                          <Radio key={i} value={v.id}>
                            {v.name}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </div>
                  </div>
                </div>
              </div>

              <div class="ant-row Platform-Charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <div className="detail-res-lft">
                    <div class="ant-row charge-form op-cnter">
                      {/* <div class="ant-col ant-col-xs-24 ant-col-md-4 gx-mb-3"> */}
                      <h2 style={{ paddingTop: "20px", color: "#8bc53f" }}>
                        <IntlMessages id="optionTemplates.secondary" /> :{" "}
                      </h2>

                      <Checkbox.Group
                        onChange={this.selectSecondayOption}
                        value={this.state.selectSecondayOption}
                      >
                        {this.state.secondaryOptions.map((v, i) => (
                          <Checkbox key={i} value={v.id}>
                            {v.name}
                          </Checkbox>
                        ))}
                      </Checkbox.Group>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="title-sub gx-mt-3">
                <h2>
                  <IntlMessages id="optionTemplates.optionTemplate" />
                </h2>
                <hr className="hr-line"></hr>
              </div>
              <div class="ant-row Platform-Charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <div className="detail-res-lft">
                    <div class="ant-row charge-form op-cnter">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>
                          <IntlMessages id="optionTemplates.templateName" /> :
                        </label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-6 gx-mb-3">
                        <IntlMessages id="optionTemplates.templateNameHere">
                          {(text) => (
                            <Input
                              value={this.state.templateName}
                              onChange={(e) => this.setTemplateName(e)}
                              placeholder={text}
                            />
                          )}
                        </IntlMessages>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.selectedPrimaryName ? (
                <div className="title-sub">
                  <h2>
                    <IntlMessages id="optionTemplates.primaryOption" />
                  </h2>
                  <hr className="hr-line"></hr>
                </div>
              ) : null}
              {this.state.selectedPrimaryName ? (
                <section className="gx-w-100">
                  <Table
                    title={() => (
                      <h2 style={{ color: "#8bc53f" }}>
                        {this.state.selectedPrimaryName}
                      </h2>
                    )}
                    bordered
                    className="gx-table-responsive MenuOpt-tabile"
                    columns={columnsPrimarySize}
                    pagination={false}
                    dataSource={this.state.primaryOptionList}
                  />
                </section>
              ) : null}
              {this.state.selectSecondayOptionList.length ? (
                <div className="title-sub gx-mt-4">
                  <h2>
                    <IntlMessages id="optionTemplates.selectedOptions" />
                  </h2>
                  <hr className="hr-line"></hr>
                </div>
              ) : null}
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log(e);
                }}
              >
                <Collapse defaultActiveKey={["0"]} onChange={callback}>
                  {this.state.selectSecondayOptionList.map(
                    (item, indexMain) => {
                      var alreadyAddedSecondaryId =
                        this.state.template?.SecondryOptions?.some(
                          (so) =>
                            so.DefaultSecondaryOptionId ==
                            item.selectedSecondaryId
                        );
                      return (
                        <Panel
                          header={item.selectedSecondaryName}
                          key={indexMain}
                        >
                          <div className="purchases-tabs-box ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-4 ant-col-md-4 ant-col-lg-4 ant-col-xl-4">
                            <section>
                              <div className="title-sub gx-mt-3">
                                <h2>{item.selectedSecondaryName}</h2>
                                <hr className="hr-line"></hr>
                              </div>

                              <div class="ant-col ant-col-xs-24 ant-col-md-24 gx-mb-3 toping-checkbox">
                                {item.selectedSecondaryOption.map(
                                  (list, index) => {
                                    var selectedIds =
                                      this.state.template?.SecondryOptions?.map(
                                        (x) => x.DefaultSecondaryOptionItemId
                                      ) ?? null;
                                    var propVal = !alreadyAddedSecondaryId
                                      ? true
                                      : selectedIds?.includes(list.Id) ?? true;

                                    return (
                                      <FormItem key={index}>
                                        {getFieldDecorator(
                                          list.Id +
                                            "-" +
                                            item.selectedSecondaryId,
                                          {
                                            valuePropName: "checked",
                                            initialValue: propVal,
                                          }
                                        )(
                                          <Checkbox key={index}>
                                            {list.Name}
                                          </Checkbox>
                                        )}
                                      </FormItem>
                                    );
                                  }
                                )}
                              </div>
                            </section>
                          </div>

                          <div className="purchases-tabs-box ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-20 ant-col-md-20 ant-col-lg-20 ant-col-xl-20">
                            <Tabs tabPosition="top">
                              {this.state.selectedPrimaryOption.map(
                                (primItem, index) => {
                                  return (
                                    <TabPane
                                      disabled={false}
                                      tab={primItem.Name}
                                      key={index}
                                    >
                                      <section>
                                        <p className="gx-pl-3">
                                          <IntlMessages id="optionTemplates.optionPriceBased" />
                                        </p>
                                        <Checkbox
                                          checked={
                                            this.state.defaultPrice[
                                              item.selectedSecondaryId
                                            ]
                                          }
                                          onChange={(e) =>
                                            this.checkDefaultPrice(
                                              e,
                                              item.selectedSecondaryId
                                            )
                                          }
                                          className="gx-pl-3 gx-mb-3"
                                        >
                                          <IntlMessages id="optionTemplates.defaultPriceBased" />
                                        </Checkbox>

                                        <div class="ant-col ant-col-xs-24 ant-col-sm-6 gx-mb-3 tabs-input-field">
                                          {item.selectedSecondaryOption.map(
                                            (secItem, i) => {
                                              let key =
                                                secItem.Id +
                                                "-" +
                                                primItem.Id +
                                                "-" +
                                                item.selectedSecondaryId;
                                              let price =
                                                this.state.valuesPrice[key];
                                              let iniVal =
                                                price == 0 || price == undefined
                                                  ? this.state
                                                      .defaultValuePrice[key]
                                                  : price;
                                              iniVal =
                                                iniVal == undefined
                                                  ? ""
                                                  : iniVal + "";
                                              return (
                                                <FormItem
                                                  key={i}
                                                  {...formItemLayout}
                                                >
                                                  {getFieldDecorator(key, {
                                                    initialValue: iniVal,
                                                  })(
                                                    <Input
                                                      disabled={
                                                        this.state.defaultPrice[
                                                          item
                                                            .selectedSecondaryId
                                                        ]
                                                      }
                                                    />
                                                  )}
                                                </FormItem>
                                              );
                                            }
                                          )}
                                        </div>
                                      </section>
                                    </TabPane>
                                  );
                                }
                              )}
                            </Tabs>
                          </div>
                        </Panel>
                      );
                    }
                  )}
                </Collapse>
              </Form>
            </div>
            <Button
              loading={this.state.saveTemplateLoading}
              onClick={this.saveOptionTemplate}
              type="primary"
              class="ant-btn color-white ant-btn-primary"
              style={{ marginTop: "10px", float: "right" }}
            >
              <i class="icon icon-add"></i>
              {this.props.location.template || this.state.templateId ? (
                <IntlMessages id="optionTemplates.update" />
              ) : (
                <IntlMessages id="optionTemplates.add" />
              )}
            </Button>
          </Card>
        </div>
      </div>
    );
  }
}

const WrappedOptionTemplates = Form.create()(optionTemplates);
export default WrappedOptionTemplates;
