import React from "react";
import { Breadcrumb, Card, Collapse, Divider, Table, Modal, Spin } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import {
  InputNumber,
  Radio,
  Rate,
  Slider,
  Switch,
  message,
  Upload,
} from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Tooltip,
  formLayout,
} from "antd";
import IntlMessages from "util/IntlMessages";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import "../pushMessage/custom.css";
import { PushMessageService } from "../../services";
import { RestaurantService } from "../../services";
import appState from "../../state/AppStateContainer";
const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

class SendPushMessage extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      pushMessage: {},
      restaurants: [],
      selectedRestaurants: [],
      AudienceSelected: 2,
      loading: false,
      formLayout: "horizontal",
      spinLoading: true,
      restaurantId: "",
    };
  }

  async componentDidMount() {
    let rId = appState.loadString("rId");
    if (this.props.tempUser && rId) await this.loadRestaurants(rId);
    else await this.loadRestaurants();
  }

  loadRestaurants = async (restaurantId = null) => {
    try {
      var response = await RestaurantService.getActiveRestaurantNameList();
      if (response && response.Success) {
        this.setState({ restaurants: response.Data });

        if (restaurantId) {
          let selectedId = response.Data?.find((a) => a.Id == restaurantId).Id;
          this.setState({
            selectedRestaurants: [selectedId],
            AudienceSelected: 1,
            restaurantId: restaurantId,
          });
          this.props.form.setFieldsValue({ Restaurants: [selectedId] });
        } else this.setState({ selectedRestaurants: ["0"] });
        this.setState({ spinLoading: false });
      } else {
        message.error(response.APIMessage);
      }
    } catch {
      message.error("Something going wrong!");
    }
    this.setState({ loading: false });
  };

  onSelectChange = (selectedRestaurants) => {
    console.log("selectedRestaurants changed: ", selectedRestaurants);
    this.setState({ selectedRestaurants });
  };

  onHandleChange = (e, opt) => {
    let res = e;
    if (res.length >= 1) {
      res = res.filter((e) => {
        return e !== "0";
      });
      this.setState({ selectedRestaurants: res });
      return;
    }
  };

  onAudienceChange = (e) => {
    this.setState({ AudienceSelected: e.target.value });
  };
  sendPushMessage = async () => {
    this.props.form.validateFields(
      ["Title", "Message", "Audience", "Restaurants"],
      async (err, values) => {
        if (!err) {
          if (typeof values.Restaurants == "undefined") {
            values.Restaurants = [];
          }
          values.Restaurants = values.Restaurants.filter((e) => e !== "0");
          const form = values;
          this.setState({ loading: true });
          form.RestaurantId = this.props.tempUser
            ? this.state.restaurantId
            : null;
          const response = await PushMessageService.send(form);
          if (response && response.Success) {
            message.success("Sending Message!");
            this.props.history.push("/push-messages");
          } else {
            message.error(response.APIMessage);
          }
        }
        this.setState({ loading: false });
      }
    );
  };

  render() {
    const { formLayout } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list option-list">
        <div className="payout-report-box option-add-box">
          <Card className="gx-card breadcrumb-box" title="">
            <Spin spinning={this.state.spinLoading}>
              <div>
                <div className="title-sub">
                  <h2>
                    <IntlMessages
                      id="pushMessage.send"
                      defaultMessage="Send Push Message"
                    />
                  </h2>
                  <hr className="hr-line"></hr>
                </div>
                <div class="ant-row Platform-Charges">
                  <Form
                    onSubmit={(e) => e.preventDefault()}
                    layout={formLayout}
                  >
                    <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                      <div className="detail-res-lft">
                        <div class="ant-row charge-form op-cnter new-opp-sa">
                          <div class="ant-col" style={{ marginLeft: "50px" }}>
                            <FormItem
                              label={
                                <IntlMessages
                                  id="pushMessage.title"
                                  defaultMessage="Title"
                                />
                              }
                            >
                              {getFieldDecorator("Title", {
                                rules: [
                                  {
                                    required: true,
                                    message: "Required!",
                                  },
                                ],
                              })(<Input placeholder="Message Title" />)}
                            </FormItem>
                          </div>
                          <div class="ant-col" style={{ marginLeft: "50px" }}>
                            <FormItem
                              label={
                                <IntlMessages
                                  id="pushMessage.message"
                                  defaultMessage="Message"
                                />
                              }
                            >
                              {getFieldDecorator("Message", {
                                rules: [
                                  {
                                    required: true,
                                    message: "Required!",
                                  },
                                  {
                                    max: 200,
                                    message:
                                      "Message should be less than 200 character",
                                  },
                                ],
                              })(
                                <TextArea
                                  placeholder="Type message"
                                  autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                              )}
                            </FormItem>
                          </div>
                        </div>

                        <div class="ant-row charge-form op-cnter new-opp-sa">
                          <div class="ant-col" style={{ marginLeft: "50px" }}>
                            <FormItem
                              label={
                                <IntlMessages
                                  id="pushMessage.audience"
                                  defaultMessage="Audience"
                                />
                              }
                            >
                              {getFieldDecorator("Audience", {
                                rules: [
                                  {
                                    required: true,
                                    message: "Required!",
                                  },
                                ],
                                initialValue: this.props.tempUser ? 1 : 2,
                              })(
                                <Radio.Group onChange={this.onAudienceChange}>
                                  {this.props.tempUser ? (
                                    <Radio value={1}>Customers</Radio>
                                  ) : (
                                    <>
                                      <Radio value={1}>Customers</Radio>
                                      <Radio value={2}>Riders</Radio>
                                    </>
                                  )}
                                </Radio.Group>
                              )}
                            </FormItem>
                          </div>
                          {this.state.AudienceSelected == 1 && (
                            <div
                              class="ant-col-8"
                              style={{ marginLeft: "50px" }}
                            >
                              <FormItem
                                label={
                                  <IntlMessages
                                    id="pushMessage.restaurants"
                                    defaultMessage="Restaurants"
                                  />
                                }
                              >
                                {getFieldDecorator(
                                  "Restaurants",
                                  {}
                                )(
                                  <Select
                                    className="c-select"
                                    mode="multiple"
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder="(Default is All)"
                                    onChange={this.onHandleChange}
                                    disabled={
                                      this.props.tempUser ? true : false
                                    }
                                  >
                                    {/* <Option key={0} value='0'>All</Option> */}
                                    {this.state.restaurants.map((v) => {
                                      return (
                                        <Option
                                          Key={v.Id}
                                          value={v.Id}
                                          label={v.Name}
                                        >
                                          {v.Name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                )}
                              </FormItem>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div style={{ float: "right", marginTop: "10px" }}>
                <Button
                  type="default"
                  onClick={() => {
                    this.props.history.push("/push-messages");
                  }}
                >
                  <IntlMessages
                    id="pushMessage.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button
                  loading={this.state.loading}
                  onClick={this.sendPushMessage}
                  htmlType="submit"
                  type="primary"
                  class="ant-btn color-white ant-btn-primary"
                >
                  <i class="icon icon-add"></i>
                  <IntlMessages
                    id="pushMessage.send"
                    defaultMessage="Send Message"
                  />
                </Button>
              </div>
            </Spin>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tempUser: state.auth.tempUser,
});
const WrappedSendPushMessage = Form.create()(
  connect(mapStateToProps)(SendPushMessage)
);
export default WrappedSendPushMessage;
