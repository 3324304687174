import axios from "axios";
import BaseService from "./baseService";

class OptionServiceController extends BaseService {

    async getAllOptionsList(body) {
        const response = await axios.post("/restaurant/getalloptionswithfilters", body);
        return response.data.Data;
    }

    async getAllOptionTemplatesList(body) {
        const response = await axios.post("/restaurant/getAllOptionTemplateswithfilters", body);
        return response.data.Data;
    }


    async addOptionsForSingleSelect(optionData) {
        const response = await axios.post("/restaurant/addupdatesingleselectoption", optionData);
        return response.data;
    }

    async addOptionsForMultiSelect(optionData) {
        const response = await axios.post("/restaurant/addupdatemultiselectoption", optionData);
        return response.data;
    }

    async addOptionDetails(optionDetailsData) {
        const response = await axios.post("/restaurant/addupdateoptionitem", optionDetailsData);
        return response.data;
    }

    async addOptionTemplate(template) {
        const response = await axios.post("/restaurant/addupdateoptiontemplate", template);
        return response.data;
    }

    async removePrimaryOption(templateInfo) {
        const response = await axios.post("/restaurant/deleteoptioncombinations", templateInfo);
        return response.data;
    }

    async deleteOptionTemplate(templateInfo) {
        const response = await axios.post("/restaurant/deleteoptiontemplate", templateInfo);
        return response.data;
    }

    async deleteOption(optionInfo) {
        const response = await axios.post("/restaurant/deleteoption", optionInfo);
        return response.data;
    }

    async updateOptionTemplateStatus(templateInfo) {
        const response = await axios.post("/restaurant/updateoptiontemplatestatus", templateInfo);
        return response.data;
    }

    async updateOptionStatus(optionInfo) {
        const response = await axios.post("/restaurant/updateoptionstatusforra", optionInfo);
        return response.data;
    }

}

export const OptionService = new OptionServiceController();