import axios from "axios";
import BaseService from "./baseService";
import appState from "../state/AppStateContainer";
//import { post, IHttpResponse } from "../utility/bt-http";

class AuthService extends BaseService {
  async loginRestaurantAdmin(email, password, token) {
    const response = await axios.post("/auth/login-ra", {
      email: email,
      password: password,
      token: token,
    });
    return response;
  }

  async loginSuperAdmin(email, password, token) {
    const response = await axios.post("/auth/login-sa", {
      email: email,
      password: password,
      token: token,
    });
    return response;
  }

  async loginAs(restaurantId) {
    const response = await axios.post("/auth/loginAs", {
      RestaurantId: restaurantId,
    });
    return response;
  }

  async loginAsBranch(restaurantId) {
    const response = await axios.post("/auth/login-as-branch", {
      RestaurantId: restaurantId,
    });
    return response;
  }

  async loginAsUndo(saId) {
    const response = await axios.post("/auth/loginAsUndo", {
      SAId: saId,
    });
    return response;
  }

  async createToken() {
    const response = await axios.post("/auth/createsession", {
      Platform: "web",
      CultureCode: "en",
      DeviceToken: "",
      Module: 5, //MerchantWebApp
    });
    return response;
  }

  async devicePairing(code, id, confirm = false, confirmCreateDevice = false) {
    const response = await axios.post("/auth/Pairdevice", {
      PairingCode: code,
      RestaurantId: id,
      ConfirmPair: confirm,
      ConfirmCreateDevice: confirmCreateDevice,
    });
    return response;
  }

  async deviceUnpairing(PairingCode, RestaurantId) {
    const response = await axios.post("/auth/unpairdevice", {
      PairingCode,
      RestaurantId,
    });
    return response;
  }

  async isDevicePaired(id) {
    const response = await axios.post("/auth/haspaireddevice", {
      RestaurantId: id,
    });
    return response;
  }

  async restaurantDevice(id) {
    const response = await axios.post("/auth/restaurant-paired-device", {
      RestaurantId: id,
    });
    return response.data;
  }

  async changePassword(data) {
    const response = await axios.post("/auth/changerestaurantpassword", data);
    return response.data;
  }

  async changeForgotPassword(data) {
    const response = await axios.post("/auth/resetpasword", data);
    return response.data;
  }

  async verifyOtpPasswordRestaurant(data) {
    const response = await axios.post(
      "/auth/verifyRestaurantPasswordOtp",
      data
    );
    return response.data;
  }

  async verifyAdministrativeAccess(data) {
    const response = await axios.post("/auth/verify-whitelist-access", data);
    return response.data;
  }
}

export const AuthSvc = new AuthService();
