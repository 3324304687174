import React from "react";
import {
  Card,
  Table,
  Spin,
  message,
  Button,
  Checkbox,
  Typography,
  Tooltip,
  Popover,
  Input,
  Tag,
  Select,
  Badge,
  Modal,
  Form,
} from "antd";
import { RestaurantService, AuthSvc, DeviceService } from "../../services";
import { AppSettings } from "../../constants/AppSettings";
import { Link, useHistory } from "react-router-dom";
import SwitchUser from "../../containers/SwitchUser";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";
import MapModal from "./mapModal";
import moment from "moment";
import { connect } from "react-redux";

import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import { Line } from "react-simple-maps";
import InfiniteScrollTable from "./../../components/table/InfiniteScrollTable";

const { Text } = Typography;
const Search = Input.Search;
const Option = Select.Option;

const columns = [
  {
    title: <IntlMessages id="restaurant.companyNo" />,
    dataIndex: "companyNumber",
    key: "companyNumber",
  },
  {
    title: <IntlMessages id="restaurant.restaurantName" />,
    dataIndex: "restaurantName",
    key: "restaurantName",
  },
  {
    title: <IntlMessages id="restaurant.device" defaultMessage="Device" />,
    dataIndex: "device",
    key: "device",
    width: 250,
  },
  {
    title: <IntlMessages id="restaurant.email" />,
    dataIndex: "email",
    key: "email",
  },
  {
    title: <IntlMessages id="restaurant.phone" />,
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: (
      <IntlMessages
        id="restaurant.thirdPartyAppId"
        defaultMessage="Third Party App ID"
      />
    ),
    dataIndex: "thirdPartyAppId",
    key: "thirdPartyAppId",
    width: 250,
  },
  {
    title: <IntlMessages id="restaurant.rating" />,
    dataIndex: "rating",
    key: "rating",
  },
  {
    title: <IntlMessages id="restaurant.status" />,
    dataIndex: "status",
    key: "status",
  },
  {
    title: <IntlMessages id="restaurant.action" />,
    dataIndex: "action",
    key: "action",
  },
];

class SaRestaurantList extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      filter: {
        SearchQuery: "",
        PageNo: 1,
        PageSize: 15,
        RatingSortOrder: "",
        FilterServiceType: 0,
      },
      restaurants: [],
      delivery: false,
      takeaway: false,
      dineIn: false,
      loading: false,
      hasMore: true,
      loadingAutoUpdate: false,
      formLayout: "horizontal",
      showMapModal: false,
      mapModalDeviceLocation: { Latitude: 0, Longitude: 0 },
      restaurantLogId: "",
      showLogModal: false,
      logLoading: false,
    };
  }

  async componentDidMount() {
    let searchQuery = new URLSearchParams(this.props.location.search).get(
      "searchQuery"
    );
    if (searchQuery != null) {
      this.setState(
        { filter: { ...this.state.filter, SearchQuery: searchQuery } },
        this.handleFilter
      );
    } else {
      this.handleFilter();
    }

    this.startStatusUpdatePolling();
  }

  startStatusUpdatePolling = async () => {
    setInterval(
      this.updateDevicesStatus,
      AppSettings.RefreshDeviceStatusAfterSeconds * 1000
    );
  };

  updateDevicesStatus = async () => {
    if (this.state.loadingAutoUpdate == false) {
      this.setState({ loadingAutoUpdate: true });
      try {
        var request = {
          RestaurantsIds: this.state.restaurants.map((x) => x.Id),
        };

        var response = await RestaurantService.getUpdatedDevicesStatus(request);
        if (response && response.Success) {
          if (!this.state.loading) {
            this.setDevicesStatus(response.Data);
          }
        } else {
          message.error(response.APIMessage);
        }
      } catch (ex) {
        message.error(ex.message);
      } finally {
        this.setState({ loadingAutoUpdate: false });
      }
    }
  };
  setDevicesStatus = (statuses) => {
    var restaurants = this.state.restaurants.map((r) => {
      var updatedStatuses = statuses.filter((x) => x.Id == r.Id);
      if (updatedStatuses.length > 0) {
        var updatedStatuse = updatedStatuses[0];
        r.Device = updatedStatuse.Device;
        r.CloseTill = updatedStatuse.CloseTill;
        r.LastOnlineHeartbeat = updatedStatuse.LastOnlineHeartbeat;
        r.CloseTillDateTime = updatedStatuse.CloseTillDateTime;
        r.LastOnlineHeartbeatDateTime =
          updatedStatuse.LastOnlineHeartbeatDateTime;
        return r;
      } else {
        return r;
      }
    });
    this.setState({ restaurants });
  };

  refreshList = async () => {
    this.setState({
      filter: { ...this.state.filter, PageNo: 1 },
      restaurants: [],
    });
    await this.handleFilter(1);
  };

  handleFilter = async (pageNo) => {
    this.setState({ loading: true });
    try {
      let filterServiceType =
        (this.state.delivery ? 1 : 0) +
        (this.state.takeaway ? 2 : 0) +
        (this.state.dineIn ? 8 : 0);
      let request = {
        ...this.state.filter,
        FilterServiceType: filterServiceType,
      };
      if (pageNo) request.PageNo = pageNo;
      const response = await RestaurantService.getRestaurantsList(request);
      if (response && response.Success) {
        let hasMore = response.Data.length > 0;
        if (!hasMore) message.warning("End!");

        response.Data = response.Data.map((r) => {
          r.FormattedTimings = r.FormattedTimings.map((t) => {
            try {
              t.From = moment(t.UtcFrom);
              t.To = moment(t.UtcTo);
            } catch (ex) {}
            return t;
          });
          return r;
        });

        let merge = [...this.state.restaurants, ...response.Data];
        this.setState({ restaurants: merge, hasMore: hasMore });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
    this.setState({ loading: false });
  };

  fetchMoreData = async (e) => {
    if (!this.state.loading) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            PageNo: this.state.filter.PageNo + 1,
          },
          loading: true,
        },
        this.handleFilter
      );
    }
  };

  searchHandler = async (searchInput) => {
    this.props.history.push("/restaurants?searchQuery=" + searchInput);
    this.setState(
      {
        filter: { ...this.state.filter, PageNo: 1, SearchQuery: searchInput },
        restaurants: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  searchReset = (searchInput) => {
    if (searchInput.target.value == null || searchInput.target.value == "") {
      this.props.history.push("/restaurants");
      this.setState(
        {
          filter: { ...this.state.filter, PageNo: 1, SearchQuery: "" },
          restaurants: [],
          loading: true,
        },
        this.handleFilter
      );
    } else {
      this.setState({
        filter: { ...this.state.filter, SearchQuery: searchInput.target.value },
      });
    }
  };

  showMapModal = (location) => {
    this.setState({
      mapModalDeviceLocation: location,
      showMapModal: true,
    });
  };

  handleFilterServiceTypeChange = (key, value) => {
    this.setState({ [key]: value }, this.refreshList);
  };

  handleSorting = (value) => {
    this.setState(
      { filter: { ...this.state.filter, RatingSortOrder: value } },
      this.refreshList
    );
  };

  loginAs = async (id) => {
    try {
      const response = await AuthSvc.loginAs(id);

      console.log(response);
      if (response && response.data.Success) {
        message.success("success");
      } else {
        message.error(response.data.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
  };
  editCreateRestaurant = (id = null) => {
    if (id == null) {
      this.props.history.push("/restaurants/create");
    } else {
      this.props.history.push("/restaurants/update/" + id);
    }
  };

  deviceOptions = (r) => {
    // older version of localNowTime is
    // var localNowTime = moment()
    //   .toISOString(true)
    //   .split("T")[1];

    var localNowTime = moment()
      .utcOffset(0, true)
      .toISOString(false)
      .split("T")[1];

    var unixLocalDate = moment(`1970-01-01T${localNowTime}`);
    var weekday = moment().utc().day();
    var operationalTiming = r.FormattedTimings.filter(
      (x) =>
        x.From <= unixLocalDate &&
        x.To >= unixLocalDate &&
        x.DayOfWeek == weekday
    );
    var closeTill = moment.unix(r.CloseTill - moment().utcOffset() * 60);
    // if(r.Name=='Mujahid Restaurant'){
    //   console.log(r.Name,{closeTill,isCloseTill : closeTill>moment(),operationalTiming,unixLocalDate});
    // }

    var lastHearbeat = moment(r.LastOnlineHeartbeatDateTime);
    var isNoOperationalTimings = operationalTiming.length == 0;
    var isCloseTill = closeTill > moment();
    var isNoOnline =
      lastHearbeat <
      moment().subtract(
        AppSettings.RestaurantOnlineOfflineMarginSeconds,
        "seconds"
      );
    let version = r.Device?.AppVersion;
    let pairingCode = r.Device?.PairingCode ?? "Device Not Connected";
    let status = r.Device?.IsPaired == true;
    let location = r.Device?.DeviceLocation;
    let versionJhx = version && <small>v{version}</small>;
    var lastHeartbeatTime = `${lastHearbeat.toLocaleString()} (${lastHearbeat.fromNow()})`;
    var restaurantOpenStatus = "Open Now";

    if (isNoOperationalTimings) {
      restaurantOpenStatus = "Closed Now (no timings)";
      status = false;
    } else if (isCloseTill) {
      restaurantOpenStatus = `Close till : ${closeTill.toLocaleString()} (${closeTill.fromNow()})`;
      status = false;
    } else if (isNoOnline) {
      restaurantOpenStatus = `Offline`;
      status = false;
    }

    let locationJhx = location && (
      <Link onClick={() => this.showMapModal(location)}>
        <i className="icon icon-geo-location"></i> Locate Device
      </Link>
    );
    var deviceDetailsContent = (
      <>
        <table>
          <tbody>
            <tr>
              <td>Pairing Code</td>
              <td>
                <small style={{ padding: 10 }}>
                  <b>
                    <Text copyable>{pairingCode}</Text>
                  </b>
                </small>
              </td>
            </tr>
            <tr>
              <td>OA App Version</td>
              <td>
                <small style={{ padding: 10 }}>
                  <b>{version}</b>
                </small>
              </td>
            </tr>
            <tr>
              <td>Last Online Heartbeat</td>
              <td>
                <small style={{ padding: 10 }}>
                  <b>{lastHeartbeatTime}</b>
                </small>
              </td>
            </tr>
            <tr>
              <td>Restaurant Open Status</td>
              <td>
                <small style={{ padding: 10 }}>
                  <b>{restaurantOpenStatus}</b>
                </small>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
    var devicePopover = (
      <>
        <Popover title="Device Details" content={deviceDetailsContent}>
          {" "}
          {pairingCode}
        </Popover>{" "}
        {versionJhx}
      </>
    );

    return (
      <>
        <Badge
          status={status ? "success" : "default"}
          text={r.Device?.IsPaired ? devicePopover : pairingCode}
        />
        <br />
        {locationJhx}
      </>
    );
  };

  handleLogSubmit = async () => {
    this.setState({
      logLoading: true,
    });

    this.props.form.validateFields(["Email"], async (err, values) => {
      if (!err) {
        try {
          let requestData = {
            Email: values.Email,
            RestaurantId: this.state.restaurantLogId,
          };

          if (
            !this.checkIfNullOrEmpty(requestData.Email) &&
            !this.checkIfNullOrEmpty(requestData.RestaurantId)
          ) {
            const response = await DeviceService.RequestLog(requestData);
            if (response && response.Success) {
              message.success(response.APIMessage);
              this.setState({ showLogModal: false, restaurantLogId: "" });
            } else {
              message.error(response.APIMessage);
            }
          }
        } catch {
          message.error("Unexpected error occured while saving.");
        }
      }
      this.setState({
        logLoading: false,
      });
    });
  };

  checkIfNullOrEmpty(value) {
    if (value === undefined || value === null || value === "") return true;

    return false;
  }

  handleLogModalOpen = (rId) => {
    this.setState({
      showLogModal: true,
      restaurantLogId: rId,
    });
  };

  handleLogCancel = () => {
    this.setState({ showLogModal: false });
  };

  render() {
    const rows = this.state.restaurants.map((r) => {
      return {
        companyNumber: r.CompanyNumber,
        restaurantName: r.Name,
        device: this.deviceOptions(r),
        thirdPartyAppId: r.ThirdPartyAppId,
        email: r.Email,
        phone: r.PhoneNumber,
        rating: Math.round(r.Rating * 10) / 10,
        status:
          r.Status == 1 ? (
            <span style={{ color: "green" }}>
              <IntlMessages id="restaurant.enabled" />
            </span>
          ) : (
            <IntlMessages id="restaurant.disabled" />
          ),
        action: (
          <div>
            {" "}
            <a
              className="ant-btn ant-btn-sm ant-btn-primary"
              onClick={() => this.editCreateRestaurant(r.Id)}
            >
              <IntlMessages id="restaurant.edit" />{" "}
            </a>{" "}
            <SwitchUser rid={r.Id} />
            <a
              className="ant-btn ant-btn-sm ant-btn-primary"
              onClick={() => this.handleLogModalOpen(r.Id)}
            >
              Request Logs
            </a>
          </div>
        ),
      };
    });
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list">
        <MapModal
          showMarker={true}
          google={this.props.google}
          height="300px"
          zoom={15}
          center={this.state.mapModalDeviceLocation}
          onClose={() => this.setState({ showMapModal: false })}
          visible={this.state.showMapModal}
        />

        <Modal
          visible={this.state.showLogModal}
          title="Log Request"
          onOk={this.handleLogSubmit}
          onCancel={this.handleLogCancel}
          footer={[
            <Button
              key="back"
              onClick={this.handleLogCancel}
              disabled={this.state.logLoading}
            >
              Back
            </Button>,
            <Button
              key="submit"
              loading={this.state.logLoading}
              onClick={this.handleLogSubmit}
            >
              Submit
            </Button>,
          ]}
        >
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }}>
            <Form.Item label="Receiving E-mail">
              {getFieldDecorator("Email", {
                rules: [
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Form>
        </Modal>
        <div className="payout-report-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div>
              <div className="ant-row">
                <div className="search-box ant-col-md-12">
                  <IntlMessages id="categoryList.search">
                    {(text) => (
                      <Search
                        placeholder={text}
                        onSearch={(value) => this.searchHandler(value)}
                        onChange={(value) => this.searchReset(value)}
                        style={{ width: 200 }}
                      />
                    )}
                  </IntlMessages>
                  <div className="check-box-fill">
                    <ul>
                      <Spin spinning={this.state.loading} size="small">
                        <li>
                          <Checkbox
                            onChange={(e) =>
                              this.handleFilterServiceTypeChange(
                                "delivery",
                                !this.state.delivery
                              )
                            }
                          >
                            <IntlMessages id="restaurant.delivery" />
                          </Checkbox>
                        </li>
                        <li>
                          <Checkbox
                            onChange={(e) =>
                              this.handleFilterServiceTypeChange(
                                "takeaway",
                                !this.state.takeaway
                              )
                            }
                          >
                            <IntlMessages id="restaurant.takeaway" />
                          </Checkbox>
                        </li>
                        <li>
                          <Checkbox
                            onChange={(e) =>
                              this.handleFilterServiceTypeChange(
                                "dineIn",
                                !this.state.dineIn
                              )
                            }
                          >
                            <IntlMessages id="config.dineIn" />
                          </Checkbox>
                        </li>
                      </Spin>
                    </ul>
                  </div>
                </div>
                <div className="ant-col-md-12">
                  <div className="orders-item top-section filter-box ">
                    <div className="drop-filter-cover">
                      <i class="fa fa-filter filter-icon"></i>
                      <Select
                        className="select-box"
                        defaultValue={<IntlMessages id="restaurant.rating" />}
                        onChange={(value) => this.handleSorting(value)}
                      >
                        <Option value={"ascending"}>
                          <IntlMessages id="restaurant.lowToHigh" />
                        </Option>
                        <Option value={"descending"}>
                          <IntlMessages id="restaurant.highToLow" />
                        </Option>
                      </Select>
                    </div>

                    <Button
                      type="primary"
                      className="color-white"
                      onClick={(e) => {
                        this.editCreateRestaurant();
                      }}
                    >
                      <i class="icon icon-add"></i>
                      <IntlMessages id="restaurant.add" />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="ant-row">
                <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
                  <InfiniteScrollTable
                    columns={columns}
                    dataSource={rows}
                    loading={this.state.loading}
                    hasMore={this.state.hasMore}
                    height={"calc(100vh - 205px)"}
                    loadMore={this.fetchMoreData}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  whitelisting: state.settings.whitelisting,
});
const WrappedSaRestaurantList = injectIntl(Form.create()(SaRestaurantList));
export default connect(mapStateToProps)(WrappedSaRestaurantList);
