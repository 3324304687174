import React from "react";
import {connect} from 'react-redux'
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import {
  Form,
  Input,
  Modal,
  Card,
  Alert,
  message,
  Drawer,
  Select,
  Popconfirm,
  Button,
  DatePicker,
  Row,
  Col,
} from "antd";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";
import { ScheduleService } from "../../../../services";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";

const FormItem = Form.Item;
const Option = Select.Option;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { confirm } = Modal;

const localizer = momentLocalizer(moment);

class RestaurantCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurantId: this.props.rid,
      schedules: [],
      schedule: {},
      showDrawer: false,
      loading: false,
      removeLoading: false,
    };
  }
  componentDidMount = async () => {
    await this.loadSchedules(this.props.rid);
  };

  loadSchedules = async (restaurantId) => {
    try {
      const response = await ScheduleService.fetchRestaurantCloseSchedules({
        RestaurantId: restaurantId,
      });
      if (response && response.Success) {
        var index = 0;
        var schedules = response.Data.map((x) => {
          return {
            id: index++,
            objectId: x.Id,
            start: new Date(x.Start),
            end: new Date(x.End),
            title: x.Title,
          };
        });
        this.setState({ schedules: schedules });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
  };

  removeSchedule = async () => {
    try {
      this.setState({ removeLoading: true });
      let event = this.state.schedule;
      var schedule = {
        Id: event.objectId,
      };
      const response = await ScheduleService.removeRestaurantCloseSchedule(
        schedule
      );
      if (response && response.Success) {
        message.success("Successfully Removed!");
        await this.loadSchedules(this.state.restaurantId);
        this.onClose();
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    } finally {
      this.setState({ removeLoading: false });
    }
  };

  saveSchedule = async () => {
    this.props.form.validateFields(
      ["Title", "Start", "End"],
      async (err, values) => {
        if (!err) {
          try {
            this.setState({ loading: true });
            let event = this.state.schedule;
            var schedule = {
              Start: values.Start,
              End: values.End,
              Title: values.Title,
              Id: event?.objectId,
              RestaurantId: this.state.restaurantId,
            };
            const response = await ScheduleService.saveRestaurantCloseSchedule(
              schedule
            );
            if (response && response.Success) {
              message.success("Successfully Saved!");
              await this.loadSchedules(this.state.restaurantId);
              this.onClose();
            } else {
              message.error(response.APIMessage);
            }
          } catch (ex) {
            message.error(ex.message);
          } finally {
            this.setState({ loading: false });
          }
        }
        this.setState({ loading: false });
      }
    );
  };

  onSelectEvent = (event) => {
    this.setState({
      schedule: event,
      showDrawer: true,
    });
    this.props.form.setFieldsValue({
      Start: moment(event.start),
      End: moment(event.end),
      Title: event.title,
    });
  };
  onClose = () => {
    this.setState({
      showDrawer: false,
      schedule: null,
    });
    this.props.form.setFieldsValue({
      Start: null,
      End: null,
      Title: null,
    });
  };

  ///
  disabledStartDate = (start) => {
    const { end } = this.state.schedule || {};
console.log(this.props.whitelisting.AdminShortTimeFormat)
    if(start.valueOf()<moment(moment().format(this.props.whitelisting.AdminDateFormat.toUpperCase())).valueOf()){
      return true;
    }

    if (!start || !end) {
      return false;
    }
    return start.valueOf() > end.valueOf();
  };

  disabledEndDate = (end) => {
    const { start } = this.state.schedule || {};
    if(end.valueOf()<moment(moment().format(this.props.whitelisting.AdminDateFormat.toUpperCase())).valueOf()){
      return true;
    }
    if (!end || !start) {
      return false;
    }
    return end.valueOf() <= start.valueOf();
  };

  range=(start, end)=> {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  disabledStartTime = (current) => {
    if(current && current < moment().endOf('day')){
      var validation = {
        disabledHours: () => this.range(0, moment().hour())
      };
      if(current.hour()==moment().hour()){
        validation.disabledMinutes = () => this.range(0, moment().minute())
      }
      return validation;
      
    }else{
      return {};
    }
  };

  onDateChange = (field, value) => {
    var schedule = this.state.schedule || {};
    schedule[field] = value;
    this.setState({ schedule });
  };

  onStartChange = (value) => {
    this.onDateChange("start", value);
  };

  onEndChange = (value) => {
    this.onDateChange("end", value);
  };

  showNewSchedule=()=>{
    var start=moment();
    var end= moment().endOf('day');
    this.props.form.setFieldsValue({
      Start: start,
      End: end
    },this.setState({ schedule: null, showDrawer: true }));
    this.onStartChange(start);
    this.onEndChange(end);
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    return (
      <>
        <Drawer
          title="Schedule"
          placement="right"
          closable={true}
          onClose={() => this.onClose()}
          visible={this.state.showDrawer}
        >
          <Form onSubmit={(e) => e.preventDefault()} className="scheduleForm">
            <div className="row" style={{ marginTop: 15 }}>
              <div className="col-md">
                <FormItem label="Title">
                  {getFieldDecorator("Title", {
                    rules: [
                      {
                        required: true,
                        message: "Required!",
                      }
                    ],
                  })(
                    <TextArea
                      placeholder="Type message"
                      value={this.state.schedule?.title}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                  )}
                </FormItem>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <FormItem label="Start">
                  {getFieldDecorator("Start", {
                    rules: [
                      {
                        required: true,
                        message: "Required!",
                      },
                    ],
                  })(
                    <DatePicker
                      allowClear
                      showTime={{
                        format: this.props.whitelisting.AdminShortTimeFormat,
                      }}
                      format={this.props.whitelisting.AdminDateTimeFormat}
                      disabledDate={this.disabledStartDate}
                      disabledTime={(current)=>this.disabledStartTime(current,'start')}
                      onChange={this.onStartChange}
                    />
                  )}
                </FormItem>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <FormItem label="End">
                  {getFieldDecorator("End", {
                    rules: [
                      {
                        required: true,
                        message: "Required!",
                      },
                    ],
                  })(
                    <DatePicker
                      allowClear
                      showTime={{
                        format: this.props.whitelisting.AdminShortTimeFormat,
                      }}
                      format={this.props.whitelisting.AdminDateTimeFormat}
                      disabledDate={this.disabledEndDate}
                      disabledTime={(current)=>this.disabledStartTime(current,'end')}
                      onChange={this.onEndChange}
                    />
                  )}
                </FormItem>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <Popconfirm
                  disabled={
                    !this.state.schedule?.objectId || this.state.loading
                  }
                  title="Are you sure delete this?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => this.removeSchedule()}
                >
                  <Button
                    disabled={
                      !this.state.schedule?.objectId || this.state.loading
                    }
                    loading={this.state.removeLoading}
                    type="default"
                  >
                    Delete
                  </Button>
                </Popconfirm>

                <Popconfirm
                  disabled={this.state.removeLoading}
                  title="Are you sure save this?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => this.saveSchedule()}
                >
                  <Button
                    loading={this.state.loading}
                    disabled={this.state.removeLoading}
                    type="primary"
                    className="ant-btn ant-btn-primary"
                  >
                    Save
                  </Button>
                </Popconfirm>
              </div>
            </div>
          </Form>
        </Drawer>
        <div style={{ marginBottom: "10px" }}>
          <Button
            onClick={() => this.showNewSchedule()}
            htmlType="submit"
            type="primary"
            class="ant-btn color-white ant-btn-primary"
          >
            <i class="icon icon-add"></i>
            <IntlMessages id="config.save" defaultMessage="New Schedules" />
          </Button>
        </div>
        <div>
          <Calendar
            events={this.state.schedules}
            localizer={localizer}
            defaultView={Views.MONTH}
            onSelectEvent={(event) => this.onSelectEvent(event)}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  whitelisting: state.settings.whitelisting
});
const WrappedRestaurantCalendar = injectIntl(Form.create()(RestaurantCalendar));
export default connect(mapStateToProps)(WrappedRestaurantCalendar);
