import axios from "axios";
import BaseService from "./baseService";

class SEOServiceController extends BaseService {
  async PostData(data) {
    const response = await axios.post("/seo/post", data);
    return response.data;
  }
}

export const SEOService = new SEOServiceController();
