export function initStickyTableHead() {
  try {
    var table = document.getElementsByClassName("infinite-container");
    var header = document.getElementsByClassName("ant-table-thead");
    if (table.length && header.length) {
      table[0].addEventListener("scroll", () => {
        header[0].setAttribute(
          "style",
          `top:${table[0].scrollTop}px !important`
        );
      });
    }
  } catch (ex) {
    console.error(ex);
  }
}

export function removeStickyTableHead() {
  try {
    var table = document.getElementsByClassName("infinite-container");
    if (table.length) {
      table[0].removeEventListener("scroll", () => {});
    }
  } catch (ex) {
    console.error(ex);
  }
}
