import axios from "axios";
import BaseService from "./baseService";

class CityServiceController extends BaseService {
  async fetch(body) {
    const response = await axios.post("/city/fetch", body);
    return response.data;
  }
  async cityNameList() {
    const response = await axios.post("/city/cities-name-list");
    return response.data;
  }
  async getSEODetails(data) {
    const response = await axios.post("/city/get-seo-details", data);
    return response.data;
  }
}

export const CityService = new CityServiceController();
