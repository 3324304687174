import axios from "axios";
import BaseService from "./baseService";

class CustomerServiceController extends BaseService {

    async fetch(body) {
        const response = await axios.post("/user/fetch", body);
        return response.data;
    }
    async fetchByRestaurant(body) {
        const response = await axios.post("/user/fetch-ra", body);
        return response.data;
    }
    async details(body) {
        const response = await axios.post("/user/details", body);
        return response.data;
    }
    async save(body) {
        const response = await axios.post("/user/save", body);
        return response.data;
    }
    async updateStatus(body) {
        const response = await axios.post("/user/update-status", body);
        return response.data;
    }
    async updateStatusByRestaurant(body) {
        const response = await axios.post("/user/update-status-ra", body);
        return response.data;
    }
    async remove(body) {
        const response = await axios.post("/user/remove", body);
        return response.data;
    }
}

export const CustomerService = new CustomerServiceController();