import axios from "axios";
import BaseService from "./baseService";

class DeviceServiceController extends BaseService {
  async RequestLog(body) {
    const response = await axios.post("/device/request-logs", body);
    return response.data;
  }

  async DownloadLog(body) {
    const response = await axios.post("device/download-logs", body);
    return response.data;
  }

  async ClearData(body) {
    const response = await axios.post("/device/clear-data", body);
    return response.data;
  }
}

export const DeviceService = new DeviceServiceController();
