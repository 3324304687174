import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import data from "./data";

const yFormatter = (num) => {
  if (num > 1000000) return parseFloat(num / 1000000).toFixed(1).toString() + "M";
  else if (num > 1000) return parseFloat(num / 1000).toFixed(1).toString() + "K";
  else return num.toString();
};

const StackedBarChart = (props) => (
  <ResponsiveContainer width="100%" height={250}>
    <BarChart
      data={props.data ? props.data : data}
      margin={{ top: 10, right: 0, left: -15, bottom: 0 }}
    >
      <XAxis dataKey="Name" />
      <YAxis type="number" tickFormatter={yFormatter} />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Legend />
      {/* <Bar dataKey="price" stackId="a" fill="#003366"/>
      <Bar dataKey="uv" stackId="a" fill="#FE9E15"/> */}
      <Bar
        dataKey={props.data ? props.labelX : "price"}
        stackId="a"
        fill={props.data ? (props.fill ? props.fill : "#326f57") : "#326f57"}
      />
    </BarChart>
  </ResponsiveContainer>
);

export default StackedBarChart;
