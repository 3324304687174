const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: "English (US)",
    icon: "us",
    cultureCode: "en-US",
  },
  // {
  //   languageId: 'chinese',
  //   locale: 'zh',
  //   name: 'Chinese',
  //   icon: 'cn'
  // },
  // {
  //   languageId: 'spanish',
  //   locale: 'es',
  //   name: 'Spanish',
  //   icon: 'es'
  // },
  // {
  //   languageId: 'french',
  //   locale: 'fr',
  //   name: 'French',
  //   icon: 'fr'
  // },
  // {
  //   languageId: 'italian',
  //   locale: 'it',
  //   name: 'Italian',
  //   icon: 'it'
  // },
  // {
  //   languageId: 'saudi-arabia',
  //   locale: 'ar',
  //   name: 'Arabic',
  //   icon: 'sa'
  // },
  {
    languageId: "swedish",
    locale: "sw",
    name: "Swedish",
    icon: "se",
    cultureCode: "sv-SE",
  },
];
export default languageData;
