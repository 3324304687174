import React from "react";
import IntlMessages from "util/IntlMessages";
import { Tooltip } from "antd";

const OrderFrom = {
  Web: 1,
  App: 2,
  Android: 3,
  Ios: 4,
  1: "Web",
  2: "App",
  3: "Android",
  4: "IOS",
};
export const OrderModelFilters = [
  { Type: "All Orders", Value: 0 },
  { Type: "Speedoy Orders", Value: 1 },
  { Type: "Whitelisting Orders", Value: 2 },
];

export const BykeaStatuses = {
  1: "Created",
  2: "Accepted",
  3: "Arrived",
  4: "Started",
  5: "Finished",
  8: "Expired",
  20: "Opened",
  24: "Created", // Tracking Available
  26: "Delivery Feedback / Completed",
};

export const AllOrderStatuses = [
  { label: <IntlMessages id="orders.accepted" />, text: "accepted", value: 1 },
  {
    label: <IntlMessages id="orders.onTheWay" />,
    text: "on the way",
    value: 2,
  },
  {
    label: <IntlMessages id="orders.completed" />,
    text: "completed",
    value: 3,
  },
  { label: <IntlMessages id="orders.pending" />, text: "pending", value: 4 },
  { label: <IntlMessages id="orders.rejected" />, text: "rejected", value: 5 },
  {
    label: <IntlMessages id="orders.acceptedByRider" />,
    text: "accepted by rider",
    value: 7,
  },
  /* changing text to 'Ready' from 'Ready for takeaway' as we are using this status in dine-in and takeaway */
  /* orders.readyForTakeaway TO orders.ready */
  { label: <IntlMessages id="orders.ready" />, text: "ready", value: 9 },
  {
    label: <IntlMessages id="orders.rejectedBySuperAdmin" />,
    text: "rejected",
    value: 10,
  },
  { label: <IntlMessages id="orders.pickedUp" />, text: "pickup", value: 11 },
];

export const RestaurantModes = [
  { label: <IntlMessages id="orders.test" />, value: true },
  { label: <IntlMessages id="orders.live" />, value: false },
];

export const OrderFromFilters = [
  { label: "Web", value: 1 },
  { label: "App", value: 2 },
  { label: "Android", value: 3 },
  { label: "Ios", value: 4 },
];

export const GetCustomerPlatform = (order) => {
  let platform = <></>;
  const description = `${OrderFrom[order.Platform] || "Web"} ${
    order.AppVersion ? "(v" + (order.AppVersion || "-") + ")" : ""
  }`;

  switch (order.Platform) {
    case OrderFrom.Ios:
      platform = (
        <>
          <Tooltip title={description}>
            <i className="fa fa-apple"></i>
          </Tooltip>
        </>
      );
      break;
    case OrderFrom.Android:
      platform = (
        <>
          <Tooltip title={description}>
            <i className="fa fa-android"></i>
          </Tooltip>
        </>
      );
      break;
    case OrderFrom.Web:
      platform = (
        <>
          <Tooltip title={description}>
            <i className="fa fa-desktop"></i>
          </Tooltip>
        </>
      );
      break;
    case OrderFrom.App:
      platform = (
        <>
          <Tooltip title={description}>
            <i className="fa fa-mobile fa-lg"></i>
          </Tooltip>
        </>
      );
      break;
    default:
      platform = (
        <>
          <Tooltip title={description}>
            <i className="fa fa-desktop"></i>
          </Tooltip>
        </>
      );
      break;
  }
  return platform;
};
