import React from "react";
import { Breadcrumb, Card, Collapse, Divider, Table, Modal } from "antd";
import {
  InputNumber,
  Radio,
  Spin,
  Rate,
  Slider,
  Switch,
  message,
  Upload,
} from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Tooltip,
  formLayout,
  Popconfirm,
} from "antd";
import { CategoryService } from "../../services";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";

// ---------------drag sorting
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import DraggableBodyRow from "./../menuList/menuInfo/DraggableBodyRow";
import MenuOutline from "@ant-design/icons/MenuOutlined";
import { sortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { AppSettings } from "../../constants/AppSettings";

const DragHandle = sortableHandle(() => (
  <MenuOutline style={{ cursor: "pointer", color: "#999" }} />
));
// ---------------drag sorting

const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;

const columns = [
  {
    title: <IntlMessages id="menuList.sort" />,
    dataIndex: "sort",
    width: 30,
    className: "drag-visible",
    render: () => <DragHandle />,
  },
  {
    title: <IntlMessages id="categoryList.category" />,
    dataIndex: "category",
    className: "drag-visible",
    key: "category",
  },
  {
    title: (
      <IntlMessages
        id="categoryList.allowedPromoCode"
        defaultMessage="Allowed PromoCode"
      />
    ),
    dataIndex: "allowedPromoCode",
    key: "allowedPromoCode",
  },
  {
    title: <IntlMessages id="categoryList.status" />,
    dataIndex: "status",
    key: "status",
  },
  {
    title: <IntlMessages id="categoryList.action" />,
    dataIndex: "action",
    key: "action",
  },
];

class menuCategoryList extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      data: [],
      listData: [],
      pageNo: 1,
      isAvailable: false,
      hasMore: true,
      spin: false,
      itemSpin: false,
      categoryId: "",
      formLayout: "horizontal",
      addItem: false,
      previewImage: "",
      previewVisible: false,
      fileList: [],
      isFileDeleted: false,
      selectedFilter: "all",
    };
  }

  // ---------drag sorting
  components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  onRow = (record, index) => ({
    index,
    moveRow: this.moveRow,
  });

  moveRow = (dragIndex, hoverIndex) => {
    const dataSource = this.state.data;
    console.log("before", this.state.data);

    if (dragIndex !== hoverIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        dragIndex,
        hoverIndex
      ).filter((el) => !!el);

      this.setState({
        data: newData,
      });

      console.log("after", this.state.data);
    }
  };
  // --------------drag sorting

  saveSortedList = async () => {
    const categoryList = this.state.data;
    let sortedList = [];

    for (let x = 0; x < categoryList.length; x++) {
      sortedList.push({
        MenuCategoryId: categoryList[x].key,
        SortOrder: x,
      });
    }

    const sortedData = {
      MenuCategoryItems: sortedList,
    };

    const response = await CategoryService.updateCategoryListSorting(
      sortedData
    );
    console.log(response);

    if (response && response.Success) {
      message.success("Sorted List Successfully Updated");
    } else {
      message.error(response.APIMessage);
    }
  };

  fetchMoreData = async (e) => {
    this.setState({
      loading: true,
    });
    const searchQuery = "";
    const pageSize = 15;
    const pageNo = this.state.pageNo + 1;
    const response = await CategoryService.getCategoriesList(
      searchQuery,
      pageSize,
      pageNo
    ); //isAvailable

    if (response.length == 0) {
      message.warning("End!");
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }

    let list = [];
    for (let i = 0; i < response.length; i++) {
      list.push({
        key: response[i].Id,
        image: <img src={require("assets/images/demo-img.png")} />,
        category: response[i].Name,
        allowedPromoCode: response[i].AllowedPromoCode ? (
          <IntlMessages id="global.yes" defaultMessage="Yes" />
        ) : (
          <IntlMessages id="global.no" defaultMessage="No" />
        ),
        status: response[i].IsAvailable ? (
          <IntlMessages id="categoryList.enabled" />
        ) : (
          <IntlMessages id="categoryList.disabled" />
        ),
        action: (
          <a
            onClick={() =>
              this.addItem(
                response[i].Id,
                response[i].Name,
                response[i].Descriptions,
                response[i].IsAvailable,
                response[i].AllowedPromoCode,
                response[i].BannerImage,
                response[i].IsGrocery
              )
            }
          >
            <i class="fa fa-pencil"></i>
          </a>
        ),
      });
    }

    let mergeList = [...this.state.data, ...list];
    this.setState({
      data: mergeList,
      listData: mergeList,
      pageNo: this.state.pageNo + 1,
      loading: false,
    });
  };

  loadInitialList = async () => {
    this.setState({ spin: true });
    const response = await CategoryService.getCategoriesList("", 0, 0);

    let list = [];
    for (let i = 0; i < response.length; i++) {
      list.push({
        key: response[i].Id,
        category: response[i].Name,
        allowedPromoCode: response[i].AllowedPromoCode ? (
          <IntlMessages id="global.yes" defaultMessage="Yes" />
        ) : (
          <IntlMessages id="global.no" defaultMessage="No" />
        ),
        status: response[i].IsAvailable ? (
          <IntlMessages id="categoryList.enabled" />
        ) : (
          <IntlMessages id="categoryList.disabled" />
        ),
        action: (
          <a
            onClick={() =>
              this.addItem(
                response[i].Id,
                response[i].Name,
                response[i].Descriptions,
                response[i].IsAvailable,
                response[i].AllowedPromoCode,
                response[i].BannerImage,
                response[i].EstimatedPreparationHours,
                response[i].IsGrocery
              )
            }
          >
            <i class="fa fa-pencil"></i>
          </a>
        ),
      });
    }

    this.setState({
      spin: false,
      data: list,
      listData: list,
    });
  };

  async componentDidMount() {
    this.loadInitialList();
  }

  searchHandler = (searchInput) => {
    if (searchInput) {
      let searchData = [];
      let data = [...this.state.listData];
      for (let i = 0; i < data.length; i++) {
        if (data[i].category.toLowerCase() == searchInput.toLowerCase()) {
          searchData.push(data[i]);
        }
      }

      this.setState({
        data: searchData,
      });
    }
  };

  handleIsAvailable = async (value) => {
    if (value == "all") {
      this.setState({
        data: this.state.listData,
        selectedFilter: value,
      });
      return;
    }
    let list = [];
    let data = [...this.state.listData];
    for (let i = 0; i < data.length; i++) {
      if (data[i].status?.props?.id === value) {
        list.push(data[i]);
      }
    }

    this.setState({
      data: list,
      selectedFilter: value,
    });
  };

  searchReset = (searchInput) => {
    if (searchInput.target.value == "") {
      this.setState({
        data: this.state.listData,
      });
    }
  };

  // Image Upload Events
  handleChange = (e) => {
    const isLt2M = e.file ? 5 < e.file.size / 1024 / 1024 < 15 : true;

    let resolutionVerified = false;

    if (e.file.status == "removed") {
      resolutionVerified = true;
      if (
        (e.file.Image ||
          e.file.type === "image/png" ||
          e.file.type === "image/jpeg" ||
          e.file.type === "image/gif") &&
        isLt2M &&
        resolutionVerified
      ) {
        this.setState((prev) => {
          prev.previewImage = "";
          prev.fileList = e.fileList;
          prev.isFileDeleted = true;
          return {
            prev,
          };
        });
      }
    } else {
      this.CheckResolution(e, isLt2M);
    }
  };
  customRequest = ({ file, onSuccess }) => {
    if (file.status !== "uploading") {
    }
    if (file.status === "done") {
      message.success(`${file.name} file uploaded successfully`);
    } else if (file.status === "error") {
      message.error(`${file.name} file upload failed.`);
    }
    setTimeout(() => onSuccess("ok"), 0);
  };

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/GIF file!");
    }
    const isLt2M = 5 < file.size / 1024 / 1024 < 15;
    if (!isLt2M) {
      message.error("Image size must be in between 5MB to 15MB!");
    }

    let resolutionVerified = false;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        const { width, height } = image;
        // set image width and height to your state here
        if (width < 1000 || height < 200) {
          message.error("Image Width and Height must be atleast 1000 x 200");
          resolutionVerified = false;
        } else resolutionVerified = true;
      });
    });

    return isJpgOrPng && isLt2M && resolutionVerified;
  };

  CheckResolution(e, isLt2M) {
    const reader = new FileReader();
    reader.readAsDataURL(e.file);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        const { width, height } = image;
        // set image width and height to your state here

        let resolutionVerified = false;

        if (width < 1000 || height < 200) {
          resolutionVerified = false;
        } else {
          resolutionVerified = true;
        }

        if (
          (e.file.Image ||
            e.file.type === "image/png" ||
            e.file.type === "image/jpeg" ||
            e.file.type === "image/gif") &&
          isLt2M &&
          resolutionVerified
        ) {
          this.setState((prev) => {
            prev.fileList = e.fileList;
            prev.previewImage = e.file.name;
            return {
              prev,
            };
          });
        }
      });
    });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handleSubmit = async (event) => {
    event.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const Id = this.state.categoryId;
        this.setState({ itemSpin: true });

        let form = new FormData();
        form.append("Id", Id);
        form.append("Name", values.name);
        form.append("Description", values.description);
        form.append("IsAvailable", values.isAvailable);
        form.append("AllowedPromoCode", values.AllowedPromoCode);
        form.append(
          "EstimatedPreparationHours",
          values.EstimatedPreparationHours
        );
        form.append("IsGrocery", values.IsGrocery || false);
        form.append("IsFileDeleted", this.state.isFileDeleted);
        if (this.state.fileList.length > 0)
          form.append("BannerFile", this.state.fileList[0].originFileObj);
        else form.append("BannerFile", null);

        const response = await CategoryService.addUpdateCategory(
          form
          //{ Id, ...values }
        );
        if (response && response.Success) {
          this.closeModal();
          this.setState(
            {
              hasMore: true,
              pageNo: 1,
              itemSpin: false,
              selectedFilter: "all",
            },
            () => {
              this.loadInitialList();
            }
          );
        }
      }
    });
  };

  addItem = (
    id,
    name,
    description,
    status,
    allowedPromoCode,
    bannerImage,
    estimatedPreparationHours,
    isGrocery
  ) => {
    this.setState({
      addItem: !this.state.addItem,
      categoryId: id,
      previewImage: bannerImage ? bannerImage : "",
      fileList:
        bannerImage != null
          ? [
              {
                uid: bannerImage ? bannerImage : "",
                Image: bannerImage ? bannerImage : "",
                url: bannerImage ? bannerImage : "",
              },
            ]
          : [],
    });
    this.props.form.setFieldsValue({
      name: name || "",
      description: description || "",
      isAvailable: id ? status : true,
      AllowedPromoCode: id ? allowedPromoCode : true,
      EstimatedPreparationHours: estimatedPreparationHours || 0,
      IsGrocery: isGrocery || false,
    });
  };

  closeModal = () => {
    console.log("closed modal call");
    this.setState({
      addItem: !this.state.addItem,
      categoryId: "",
      isFileDeleted: false,
    });
  };

  render() {
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { xs: 24, sm: 8 },
            wrapperCol: { xs: 24, sm: 14 },
          }
        : null;
    const buttonItemLayout =
      formLayout === "horizontal"
        ? {
            wrapperCol: { xs: 24, sm: { span: 14, offset: 6 } },
          }
        : null;

    const children = [];
    for (let i = 1; i < 10; i++) {
      children.push(<Option key={i.toString(36) + i}>{"test"}</Option>);
    }

    const { getFieldDecorator } = this.props.form;

    const { intl } = this.props;
    const nameHere = intl.formatMessage({ id: "categoryList.nameHere" });
    const descriptionHere = intl.formatMessage({
      id: "categoryList.descriptionHere",
    });

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list">
        <div className="payout-report-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div className="collaps-main">
              <div className="ant-row">
                <div className="search-box ant-col-md-12">
                  <IntlMessages id="categoryList.search">
                    {(text) => (
                      <Search
                        placeholder={text}
                        onSearch={(value) => this.searchHandler(value)}
                        onChange={(value) => this.searchReset(value)}
                        style={{ width: 200 }}
                      />
                    )}
                  </IntlMessages>
                </div>
                <div className="ant-col-md-12">
                  <div className="orders-item top-section filter-box flex-direction-reverse-col">
                    <div className="drop-filter-cover">
                      <i class="fa fa-filter filter-icon"></i>
                      <Select
                        className="select-box"
                        defaultValue={this.state.selectedFilter}
                        onChange={(value) => this.handleIsAvailable(value)}
                        value={this.state.selectedFilter}
                      >
                        <Option value="all">
                          {" "}
                          <IntlMessages id="categoryList.all" />
                        </Option>
                        <Option value="categoryList.enabled">
                          {" "}
                          <IntlMessages id="categoryList.enabled" />
                        </Option>
                        <Option value="categoryList.disabled">
                          <IntlMessages id="categoryList.disabled" />
                        </Option>
                      </Select>
                    </div>
                    <Button
                      type="primary"
                      className="color-white"
                      onClick={() => this.addItem("")}
                    >
                      <i class="icon icon-add"></i>{" "}
                      <IntlMessages id="categoryList.add" />
                    </Button>
                    <Button
                      type="primary"
                      className="color-white"
                      onClick={(e) => this.saveSortedList()}
                    >
                      <i className="fa fa-floppy-o"></i>
                      <IntlMessages id="menuList.saveSortedList" />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="ant-row">
                <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
                  {this.state.spin ? (
                    <Spin
                      style={{ marginLeft: "45%", marginRight: "45%" }}
                      size="large"
                      tip="Loading..."
                    />
                  ) : (
                    <div
                      className="infinite-container 3"
                      style={{ height: "300px", overflow: "auto" }}
                    >
                      <Table
                        columns={columns}
                        pagination={false}
                        dataSource={this.state.data}
                        rowKey="index"
                        components={this.components}
                        onRow={this.onRow}
                      />

                      {this.state.loading && this.state.hasMore && (
                        <div
                          className="spinner"
                          style={{
                            position: "absolute",
                            textAlign: "center",
                            width: "100%",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <Spin />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>

        <Modal
          title={<IntlMessages id="categoryList.addCategory" />}
          visible={this.state.addItem}
          onCancel={this.closeModal}
          footer={null}
        >
          <div className="item-detail-edit-box">
            <Form onSubmit={(e) => this.handleSubmit(e)} layout={formLayout}>
              <FormItem
                label={<IntlMessages id="categoryList.name" />}
                {...formItemLayout}
              >
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: "Required!",
                    },
                  ],
                })(<Input placeholder={nameHere} />)}
              </FormItem>

              <FormItem
                label={<IntlMessages id="categoryList.description" />}
                {...formItemLayout}
              >
                {getFieldDecorator("description", {
                  rules: [],
                })(<Input placeholder={descriptionHere} />)}
              </FormItem>

              <FormItem
                label={
                  <IntlMessages id="categoryList.estimatedPreparationHours" />
                }
                {...formItemLayout}
              >
                {getFieldDecorator("EstimatedPreparationHours", {
                  rules: [],
                })(<InputNumber min={0} step={1} precision={0} />)}
              </FormItem>
              {this.props.whitelisting.Country == AppSettings.Country.UAE && (
                <FormItem
                  label={<IntlMessages id="categoryList.groceryCategory" />}
                  {...formItemLayout}
                >
                  {getFieldDecorator("IsGrocery", {
                    valuePropName: "checked",
                  })(<Switch />)}
                </FormItem>
              )}
              <FormItem
                label={
                  <IntlMessages
                    id="categoryList.allowedPromoCode"
                    defaultMessage="Allowed PromoCode"
                  />
                }
                {...formItemLayout}
              >
                {getFieldDecorator("AllowedPromoCode", {
                  valuePropName: "checked",
                  initialValue: true,
                })(<Switch />)}
              </FormItem>

              <FormItem
                label={<IntlMessages id="categoryList.status" />}
                {...formItemLayout}
              >
                {getFieldDecorator("isAvailable", {
                  valuePropName: "checked",
                  initialValue: true,
                })(<Switch />)}
              </FormItem>

              <FormItem
                label={
                  <IntlMessages
                    id="categoryList.Banner"
                    defaultMessage="Banner Image"
                  />
                }
                {...formItemLayout}
              >
                {getFieldDecorator(
                  "BannerFile",
                  {}
                )(
                  <div>
                    <Upload
                      listType="picture-card"
                      fileList={this.state.fileList}
                      onPreview={this.handlePreview}
                      customRequest={this.customRequest}
                      onChange={(e) => this.handleChange(e)}
                      beforeUpload={this.beforeUpload}
                      //onRemove={this.handleRemove}
                      accept="image/jpeg, image/png,image/gif"
                    >
                      {this.state.previewImage.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal
                      visible={this.state.previewVisible}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <img
                        alt=""
                        style={{ width: "100%" }}
                        src={this.state.previewImage}
                      />
                    </Modal>
                    {this.state.previewImage != "" && (
                      <div className="ant-col-md-4 rite-btn dlt-with-img">
                        <div className="action-btn">
                          <a href="" title="Delete">
                            <Popconfirm
                              title="Are you sure delete this ?"
                              onConfirm={() => {
                                this.setState((prev) => {
                                  prev.previewImage = "";
                                  prev.fileList = [];
                                  prev.isFileDeleted = true;
                                  return prev;
                                });
                              }}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <span className="gx-link" title="Delete">
                                <i class="icon icon-trash"></i>
                              </span>
                            </Popconfirm>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </FormItem>

              <FormItem {...buttonItemLayout}>
                {this.state.itemSpin ? (
                  <Spin />
                ) : (
                  <Button htmlType="submit" type="primary">
                    <IntlMessages id="categoryList.submit" />
                  </Button>
                )}
              </FormItem>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  whitelisting: state.settings.whitelisting,
});
const WrappedLogin = injectIntl(
  Form.create()(connect(mapStateToProps)(menuCategoryList))
);
export default DragDropContext(HTML5Backend)(WrappedLogin);
