import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Dashboard from "./dashboard/index";
import MenuEditor from "./menuEditor/index";
import MenuCategoryList from "./menuCategoryList/index";
import MenuList from "./menuList/index";
import MenuInfo from "./menuList/menuInfo/index";
import OptionList from "./option/index";
import OptionTemplates from "./optionTemplates/listing";
import OptionTemplatesAdd from "./optionTemplates/index";
import OptionAddSingal from "./option/optionAddSingal/index";
import OptionAddMulty from "./option/optionAddMulti/index";
import PromoCodeList from "./promoCode/listing";
import SavePromoCode from "./promoCode/save";
import PayoutReports from "./payoutReports/index";
import ActivityList from "./activityTracker/listing";
import NotFoundPage from "./customViews/errorPages/404/index";
import { SUPER_ADMIN_ID } from "../constants/UserRoles";

import CustomerList from "./customerManagement/listing";
import SaveCustomer from "./customerManagement/save";
import Settings from "./settings/appSetting";
//import SavePromoCode from "./promoCode/save";

import DomainWhiteList from "./domainWhitelist/listing";
import SaveDomainWhitelist from "./domainWhitelist/save";

import PushMessageList from "./pushMessage/listing";
import SendPushMessage from "./pushMessage/send";

import SaRestaurantList from "./sa_restaurantList/index";
import SaEditCreateRestaurant from "./sa_restaurantList/manageRestaurant/index";

import RestaurantOrders from "./restaurantOrders/index";
import RestaurantDashboard from "./restaurantDashboard";
import RestaurantCustomerList from "./restaurantCustomers/listing";

import SEOSettings from "./seoSettings";

import ChangePassword from "./settings/changePassword";
import { DomainType } from "../constants/AppSettings";

import DevicesList from "./devices/index";

const App = ({ match, authUser }) => {
  const { domainWhitelist } = useSelector(({ settings }) => settings);
  return (
    <div className="gx-main-content-wrapper">
      {authUser == SUPER_ADMIN_ID ? (
        <Switch>
          <Route exact path={`${match.url}dashboard`} component={Dashboard} />
          <Route
            exact
            path={`${match.url}restaurants`}
            component={SaRestaurantList}
          />
          <Route
            exact
            path={`${match.url}restaurants/create`}
            component={SaEditCreateRestaurant}
          />
          <Route
            exact
            path={`${match.url}restaurants/update/:id`}
            component={SaEditCreateRestaurant}
          />
          <Route exact path={`${match.url}orders`} component={PayoutReports} />
          <Route exact path={`${match.url}devices`} component={DevicesList} />
          <Route
            exact
            path={`${match.url}promocodes`}
            component={PromoCodeList}
          />
          <Route
            exact
            path={`${match.url}promocodes/create`}
            component={SavePromoCode}
          />
          <Route
            exact
            path={`${match.url}push-messages`}
            component={PushMessageList}
          />
          <Route
            exact
            path={`${match.url}push-messages/send`}
            component={SendPushMessage}
          />
          <Route
            exact
            path={`${match.url}promocodes/update/:id`}
            component={SavePromoCode}
          />
          <Route
            exact
            path={`${match.url}customers`}
            component={CustomerList}
          />

          <Route
            exact
            path={`${match.url}domain-whitelisting`}
            component={DomainWhiteList}
          />
          <Route
            exact
            path={`${match.url}domain-whitelisting/create`}
            component={SaveDomainWhitelist}
          />
          <Route
            exact
            path={`${match.url}domain-whitelisting/update/:id`}
            component={SaveDomainWhitelist}
          />

          <Route
            exact
            path={`${match.url}customers/update/:id`}
            component={SaveCustomer}
          />
          <Route
            exact
            path={`${match.url}activitytracker`}
            component={ActivityList}
          />
          <Route exact path={`${match.url}settings`} component={Settings} />
          <Route
            exact
            path={`${match.url}seoSettings`}
            component={SEOSettings}
          />
          {<Route path="*" component={NotFoundPage} />}
        </Switch>
      ) : (
        <Switch>
          {/*  <Route path={`${match.url}menuEditor`} component={MenuEditor} /> */}
          <Route
            path={`${match.url}restaurant-dashboard`}
            component={RestaurantDashboard}
          />
          <Route
            path={`${match.url}restaurant-categories`}
            component={MenuCategoryList}
          />
          <Route
            path={`${match.url}restaurant-categories`}
            component={MenuCategoryList}
          />
          <Route path={`${match.url}menuList`} component={MenuList} />
          <Route path={`${match.url}menuInfo`} component={MenuInfo} />
          <Route
            path={`${match.url}restaurant-orders`}
            component={RestaurantOrders}
          />
          <Route path={`${match.url}optionList`} component={OptionList} />
          <Route
            path={`${match.url}optionTemplates`}
            component={OptionTemplates}
          />
          <Route
            path={`${match.url}optionTemplatesAdd`}
            component={OptionTemplatesAdd}
          />
          <Route
            path={`${match.url}optionAddSingal`}
            component={OptionAddSingal}
          />
          <Route
            path={`${match.url}optionAddMulty`}
            component={OptionAddMulty}
          />

          <Route
            path={`${match.url}restaurant-customers`}
            component={RestaurantCustomerList}
          />
          {domainWhitelist?.Type == DomainType.B2B ? (
            <Route
              exact
              path={`${match.url}customers/update/:id`}
              component={SaveCustomer}
            />
          ) : null}
          <Route
            path={`${match.url}changePassword`}
            component={ChangePassword}
          />
          <Route
            exact
            path={`${match.url}push-messages`}
            component={PushMessageList}
          />
          <Route
            exact
            path={`${match.url}push-messages/send`}
            component={SendPushMessage}
          />
          {/*<Route path="*" component={NotFoundPage} />*/}
        </Switch>
      )}
    </div>
  );
};

export default App;
