import { Container } from "unstated";
//import $ from "jquery";
// import { Yup, googleMapApi } from "../utility";
// import yupArLocale from "../localization/yup-locale-sw";
// import yupEnLocale from "../localization/yup-locale-en";
import { EventEmitter } from "fbemitter";
import moment from "moment";
import _ from "lodash";
//import momentLocalizer from "react-widgets-moment";
import axios from "axios";
//Constants
import { WHITELISTING } from "../constants/ActionTypes";
import { RESTAURANT_ID_KEY } from "../constants/UserRoles";
//const NodeGeocoder = require("node-geocoder");
const options = {
  provider: "google",
  httpAdapter: "https",
  //apiKey: googleMapApi,
  formatter: null,
};
//const geocoder = NodeGeocoder(options);
function isRunInSimpleMode() {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get("simple");
  return value === "1" || window.location.host.indexOf("simple") >= 0;
}

export class AppStateContainer extends Container {
  emitter = new EventEmitter();
  state = {
    tmpToken: this.loadObj("tmpToken"),
    token: this.loadObj("token"),
    loaded: false,
    language: this.normalizeLanguageCode(this.loadString("language") || "en"),
    sidebarActive: false,
    simpleMode: isRunInSimpleMode(),
    editingTranslationId: null,
    editingTranslationDefault: null,
    messages: {},
    currentLongitude: undefined,
    currentLatitude: undefined,
    whitelisting: {
      Currency: "kr",
      Delimiter: ",",
      AdminDateFormat: "DD-MM-YYYY",
      AdminShortTimeFormat: "HH:mm",
      AdminDateTimeFormat: "DD-MM-YYYY HH:mm",
    },
  };

  normalizeLanguageCode(code) {
    if (code.indexOf("-") >= 0) {
      return code.split("-")[0];
    }
    return code;
  }

  persistObj(key, obj) {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  loadObj(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  loadWhitelisting() {
    try {
      return JSON.parse(localStorage.getItem(WHITELISTING));
    } catch (e) {
      console.error(e);
      return this.state.whitelisting;
    }
  }

  loadRestaurantId() {
    const rId = localStorage.getItem(RESTAURANT_ID_KEY);
    return rId && rId !== "null" ? rId : null;
  }

  persistString(key, value) {
    localStorage.setItem(key, value);
  }

  loadString(key) {
    return localStorage.getItem(key);
  }

  IsSuperAdminLoginedAs() {
    var id = localStorage.getItem("temp_user_id");
    return (
      id != null &&
      id != "null" &&
      id != "" &&
      id != undefined &&
      id != "undefined"
    );
  }

  constructor() {
    super();

    // //Load any async state here
    // let locale = this.state.language === "sw" ? yupArLocale : yupEnLocale;
    // Yup.setLocale(locale);
    // moment.locale(this.state.language);
    // momentLocalizer();
    // this.setState({ loaded: true }, () => {
    //   let locale = this.state.language === "sw" ? yupArLocale : yupEnLocale;
    //   Yup.setLocale(locale);
    //   moment.locale(this.state.language);
    //   momentLocalizer();
    // });
  }

  async setToken(token) {
    this.persistObj("token", token);
    await this.setState({ token });
  }

  async setLoginAsToken(token) {
    const tmpToken = this.state.token;
    this.state.token.route = undefined;
    this.persistObj("tmpToken", this.state.token);
    this.persistObj("token", token);
    await this.setState({ token, tmpToken });
  }

  setHeaderTitle(title, titleId) {
    this.persistString("title", title);
    this.persistString("titleId", titleId);
    this.setState({ title: title, titleId });
  }
  // async setLanguage(language) {
  //   this.persistString("language", language);
  //   let locale = language === "sw" ? yupArLocale : yupEnLocale;
  //   Yup.setLocale(locale);
  //   moment.locale(language);
  //   momentLocalizer();
  //   this.setState({ language }, () => {
  //     this.emitter.emit("onLanguageChange");
  //   });
  //   if (
  //     _.get(this, "state.token.role") === "sadmin" ||
  //     _.get(this, "state.token.role") === "wsadmin" ||
  //     _.get(this, "state.token.role") === "spadmin"
  //   ) {
  //     //await services.changeLanguage(language);
  //   }
  // }

  // setSidebarActive(isActive) {
  //   this.setState({ sidebarActive: isActive }, () => {
  //     if (this.state.sidebarActive) {
  //       $("body").addClass("overflow backdrop");
  //       $(".navbar-header").css("padding-left", "0px");
  //     } else {
  //       $("body").removeClass("overflow backdrop");
  //     }
  //   });
  // }

  changeLanguage() {
    if (this.state.language === "sw") this.setLanguage("en");
    else this.setLanguage("sw");
  }

  changeLanguageTo(language) {
    this.setLanguage(language);
  }

  toggleSidebar(options = {}) {
    if (options.sidebarClose && !this.state.sidebarActive) {
      return;
    }
    this.setSidebarActive(!this.state.sidebarActive);
  }

  // async getCurrentLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(async currentPosition => {
  //       const reverserGeoCodeData = await geocoder.reverse({
  //         lat: currentPosition.coords.latitude,
  //         lon: currentPosition.coords.longitude
  //       });
  //       this.setState({
  //         currentLongitude: currentPosition.coords.longitude,
  //         currentLatitude: currentPosition.coords.latitude,
  //         countryCode: reverserGeoCodeData[0].countryCode,
  //         address: reverserGeoCodeData[0].formattedAddress,
  //         city: reverserGeoCodeData[0].city
  //       });
  //     });
  //   }
  // }
}

const appState = new AppStateContainer();

window.APP_STATE = appState;

export default appState;
