import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  AppstoreOutlined,
  BarChartOutlined,
  PieChartOutlined,
  UserOutlined,
  TrophyOutlined,
  ContactsOutlined,
  DatabaseOutlined,
  ContainerOutlined,
  ProfileOutlined,
  AreaChartOutlined,
  NotificationOutlined,
  AndroidOutlined,
} from "@ant-design/icons";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { RESTAURENT_ADMIN, SUPER_ADMIN_ID } from "../../constants/UserRoles";

import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { RestaurantService } from "../../services";
import appState from "../../state/AppStateContainer";
import RestaurantDashboard from "../../routes/restaurantDashboard";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {
  let { navStyle, themeType, pathname } = useSelector(
    ({ settings }) => settings
  );
  let { authUser } = useSelector(({ auth }) => auth);

  const [restaurantName, setRestaurantName] = useState("");

  const [category, setCategory] = useState(false);
  const [menu, setMenu] = useState(false);
  const [option, setOption] = useState(false);
  const [optionTemplate, setOptionTemplate] = useState(false);
  const [orders, setOrders] = useState(false);
  const [customers, setCustomers] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const [pushMessages, setPushMessages] = useState(false);

  useEffect(() => {
    if (authUser != SUPER_ADMIN_ID) {
      const id = appState.loadString("rId");

      async function fetchApi() {
        if (id && id != "null" && id != null && id != undefined) {
          const restaurantDetail =
            await RestaurantService.getRestaurantDetailsForRa(id);
          setRestaurantName(restaurantDetail.data.Data.Name);

          const selectedSidebarItem = getEnabledSidebarList(
            restaurantDetail.data.Data.AccessControl
          );
          setCategory(
            selectedSidebarItem.includes("Categories") ||
              appState.IsSuperAdminLoginedAs()
          );
          setMenu(
            selectedSidebarItem.includes("Menu") ||
              appState.IsSuperAdminLoginedAs()
          );
          setOption(
            selectedSidebarItem.includes("Option") ||
              appState.IsSuperAdminLoginedAs()
          );
          setOptionTemplate(
            selectedSidebarItem.includes("OptionTemplates") ||
              appState.IsSuperAdminLoginedAs()
          );
          setOrders(selectedSidebarItem.includes("Orders"));
          setCustomers(selectedSidebarItem.includes("Customers"));
          setDashboard(true);
          setPushMessages(selectedSidebarItem.includes("PushMessages"));
        }
      }
      if (
        id != null &&
        id != "null" &&
        id != undefined &&
        restaurantName == ""
      ) {
        fetchApi().then();
      }
    }
  });

  const getEnabledSidebarList = (number) => {
    const SIDEBAR = {
      Categories: 1,
      Menu: 2,
      Option: 4,
      OptionTemplates: 8,
      Orders: 128,
      Customers: 256,
      PushMessages: 512,
    };

    let selected = [];

    if ((number & SIDEBAR.Categories) === SIDEBAR.Categories)
      selected.push("Categories");
    if ((number & SIDEBAR.Menu) === SIDEBAR.Menu) selected.push("Menu");
    if ((number & SIDEBAR.Option) === SIDEBAR.Option) selected.push("Option");
    if ((number & SIDEBAR.OptionTemplates) === SIDEBAR.OptionTemplates)
      selected.push("OptionTemplates");
    if ((number & SIDEBAR.Orders) === SIDEBAR.Orders) selected.push("Orders");
    if ((number & SIDEBAR.Customers) === SIDEBAR.Customers)
      selected.push("Customers");
    if ((number & SIDEBAR.PushMessages) === SIDEBAR.PushMessages)
      selected.push("PushMessages");

    return selected;
  };

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  let selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  if (
    selectedKeys.includes("restaurants/update") ||
    selectedKeys.includes("restaurants/create")
  ) {
    selectedKeys = "restaurants";
  } else if (
    selectedKeys.includes("promocodes/update") ||
    selectedKeys.includes("promocodes/create")
  ) {
    selectedKeys = "promocodes";
  } else if (
    selectedKeys.includes("domain-whitelisting/update") ||
    selectedKeys.includes("domain-whitelisting/create")
  ) {
    selectedKeys = "domain-whitelisting";
  } else if (selectedKeys.includes("customers/update")) {
    selectedKeys = "customers";
  } else if (selectedKeys.includes("push-messages/send")) {
    selectedKeys = "push-messages";
  }

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile name={restaurantName} />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          {authUser == SUPER_ADMIN_ID ? (
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
            >
              <Menu.Item key="dashboard">
                <Link to="/dashboard">
                  <i className="icon icon-chart-area"></i>
                  <span>
                    <IntlMessages id="sidebar.dashboard" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="restaurants">
                <Link to="/restaurants">
                  <i className="icon icon-company"></i>
                  <span>
                    <IntlMessages id="sidebar.restaurants" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="orders">
                <Link to="/orders">
                  <i className="icon icon-burger"></i>
                  <span>
                    <FormattedMessage
                      id="sidebar.orders"
                      defaultMessage="Reports"
                    />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="devices">
                <Link to="/devices">
                  <AndroidOutlined className="icon" />
                  <span>
                    <FormattedMessage
                      id="sidebar.devices"
                      defaultMessage="Device Controls"
                    />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="promocodes">
                <Link to="/promocodes">
                  <i className="icon icon-tickets"></i>
                  <span>
                    <FormattedMessage
                      id="sidebar.promocodes"
                      defaultMessage="Promo Codes"
                    />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="customers">
                <Link to="/customers">
                  <i className="icon icon-avatar"></i>
                  <span>
                    <FormattedMessage
                      id="sidebar.customers"
                      defaultMessage="Customers"
                    />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="domain-whitelisting">
                <Link to="/domain-whitelisting">
                  <i className="icon icon-link"></i>
                  <span>
                    <FormattedMessage
                      id="sidebar.domain-whitelisting"
                      defaultMessage="Domain Whitelisting"
                    />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="push-messages">
                <Link to="/push-messages">
                  <i className="icon icon-alert"></i>
                  <span>
                    <FormattedMessage
                      id="sidebar.pushMessages"
                      defaultMessage="Push Messages"
                    />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="activitytracker">
                <Link to="/activitytracker">
                  <i className="icon icon-schedule"></i>
                  <span>
                    <FormattedMessage
                      id="sidebar.activitytracker"
                      defaultMessage="Activity Tracker"
                    />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="settings">
                <Link to="/settings">
                  <i className="icon icon-setting"></i>
                  <span>
                    <FormattedMessage
                      id="sidebar.settings"
                      defaultMessage="Settings"
                    />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="seoSettings">
                <Link to="/seoSettings">
                  <i className="icon icon-setting"></i>
                  <span>
                    <FormattedMessage
                      id="sidebar.seoSettings"
                      defaultMessage="Seo Settings"
                    />
                  </span>
                </Link>
              </Menu.Item>
            </Menu>
          ) : (
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
            >
              {dashboard ? (
                <Menu.Item key="restaurant-dashboard">
                  <Link to="/restaurant-dashboard">
                    <AreaChartOutlined />
                    <span>
                      <IntlMessages id="sidebar.dashboard" />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}
              {category ? (
                <Menu.Item key="restaurant-categories">
                  <Link to="/restaurant-categories">
                    <ProfileOutlined />
                    <span>
                      <IntlMessages id="sidebar.categories" />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}

              {menu ? (
                <Menu.Item key="menuList">
                  <Link to="/menuList">
                    <AppstoreOutlined />
                    <span>
                      <IntlMessages id="sidebar.menu" />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}

              {option ? (
                <Menu.Item key="optionList">
                  <Link to="/optionList">
                    <ContainerOutlined />
                    <span>
                      <IntlMessages id="sidebar.option" />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}

              {optionTemplate ? (
                <Menu.Item key="optionTemplates">
                  <Link to="/optionTemplates">
                    <DatabaseOutlined />
                    <span>
                      <IntlMessages id="sidebar.optionTemplate" />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}
              {orders ? (
                <Menu.Item key="restaurant-orders">
                  <Link to="/restaurant-orders">
                    <ContainerOutlined />
                    <span>
                      <IntlMessages
                        id="sidebar.restaurOrders"
                        defaultMessage="Orders"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}
              {customers ? (
                <Menu.Item key="restaurant-customers">
                  <Link to="/restaurant-customers">
                    <UserOutlined />
                    <span>
                      <FormattedMessage
                        id="sidebar.customers"
                        defaultMessage="Customers"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}
              {pushMessages ? (
                <Menu.Item key="restaurant-push-messages">
                  <Link to="/push-messages">
                    <NotificationOutlined />
                    <span>
                      <FormattedMessage
                        id="sidebar.pushMessages"
                        defaultMessage="Push Messages"
                      />
                    </span>
                  </Link>
                </Menu.Item>
              ) : null}
            </Menu>
          )}
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
