import React, { useState } from "react";
import {
  Breadcrumb,
  Input,
  Spin,
  message,
  Modal,
  Typography,
  Select,
  Tooltip,
  DatePicker,
  Icon,
  Checkbox,
  Table,
  Button,
  Popover,
  Popconfirm,
  Collapse,
  Tag,
} from "antd";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import IntlMessages from "util/IntlMessages";
import { Statistic, Card, Row, Col, Badge, Skeleton } from "antd";
import {
  createFromIconfontCN,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import {
  ReportService,
  SettingService,
  RestaurantService,
} from "../../services";
import moment from "moment";
import { connect } from "react-redux";
import "moment-timezone";
import { paymentOptions } from "../sa_restaurantList/manageRestaurant/tabs/paymentOptions";
import appState from "../../state/AppStateContainer";
import InfiniteScrollTable from "./../../components/table/InfiniteScrollTable";
import { DomainType, AppSettings } from "../../constants/AppSettings";
import { convertToDecimal } from "../../components/Custom/DecimalWrapper/DecimalWrapperFunction";
import {
  OrderModelFilters,
  BykeaStatuses,
  AllOrderStatuses,
  OrderFromFilters,
  GetCustomerPlatform,
} from "../payoutReports/order-common";
import {
  getDeliveryServiceName,
  DeliveryServiceModel,
  DeliveryServiceProviders,
} from "../sa_restaurantList/manageRestaurant/tabs/common";
import { getArticlePropertyName } from "../menuList/common";
import MultipleSelect from "../../components/Select/MultipleSelect";

const reactStringReplace = require("react-string-replace");
const { confirm } = Modal;
const { Text } = Typography;
const ButtonGroup = Button.Group;
const CheckboxGroup = Checkbox.Group;
const { Panel } = Collapse;

const IconFont = createFromIconfontCN({
  scriptUrl: [
    "//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js", // icon-javascript, icon-java, icon-shoppingcart (overrided)
    "//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js", // icon-shoppingcart, icon-python
  ],
});
const Search = Input.Search;
const Option = Select.Option;
const { RangePicker } = DatePicker;

const OrderStatuses = {
  None: 0,
  Accepted: 1,
  OnTheWay: 2,
  Delivered: 3,
  Pending: 4,
  Rejected: 5,
  ReadyForDelivery: 6, //deprecated
  AcceptedByRider: 7,
  Completed: 8, //deprecated
  ReadyForTakeaway: 9,
  RejectedBySuperAdmin: 10,
  PickedUp: 11,
};
const DeliveryModel = {
  None: 0,
  WithRider: 1,
  WithoutRider: 2,
};
const OrderType = {
  Delivery: 1,
  Takeaway: 2,
  DineIn: 3,
};

// Last flag used = 1 << 26
// flags placed in random places
let columns = [
  {
    title: <IntlMessages id="report.orderSerial" defaultMessage="#" />,
    dataIndex: "orderSerial",
    key: "orderSerial",
    flag: 1 << 0,
    // render: text => <span className="gx-link">{text}</span>,
  },
  {
    title: <IntlMessages id="report.orderNumber" />,
    dataIndex: "orderNumber",
    key: "orderNumber",
    flag: 1 << 1,
    // render: text => <span className="gx-link">{text}</span>,
  },
  {
    title: <IntlMessages id="report.date" />,
    dataIndex: "date",
    key: "date",
    flag: 1 << 2,
  },
  {
    title: <IntlMessages id="report.customerName" />,
    dataIndex: "customerName",
    key: "customerName",
    flag: 1 << 4,
  },
  {
    title: <IntlMessages id="report.riderName" />,
    dataIndex: "riderName",
    key: "riderName",
    flag: 1 << 5,
  },
  {
    title: <IntlMessages id="report.deliveryAddress" />,
    dataIndex: "deliveryAddress",
    key: "deliveryAddress",
    flag: 1 << 6,
  },
  {
    title: <IntlMessages id="report.status" />,
    dataIndex: "status",
    key: "status",
    flag: 1 << 7,
  },
  {
    title: <IntlMessages id="report.subTotal" defaultMessage="Sub Total" />,
    dataIndex: "subTotal",
    key: "subTotal",
    flag: 1 << 8,
  },
  {
    title: <IntlMessages id="report.minimumOrderAdjustment" />,
    dataIndex: "minimumOrderAdjustment",
    key: "minimumOrderAdjustment",
    flag: 1 << 10,
  },
  {
    title: (
      <IntlMessages id="report.deliveryFee" defaultMessage="Delivery Fee" />
    ),
    dataIndex: "deliveryFee",
    key: "deliveryFee",
    flag: 1 << 11,
  },
  {
    title: (
      <IntlMessages
        id="report.platformCharges"
        defaultMessage="Platform Charges"
      />
    ),
    dataIndex: "platformCharges",
    key: "platformCharges",
    flag: 1 << 26,
  },
  {
    title: <IntlMessages id="report.discount" defaultMessage="Discount" />,
    dataIndex: "discount",
    key: "discount",
    flag: 1 << 13,
  },
  {
    title: <IntlMessages id="report.totall" />,
    dataIndex: "total",
    key: "total",
    flag: 1 << 14,
  },
  {
    title: <IntlMessages id="report.totalTax" />,
    dataIndex: "totalTax",
    key: "totalTax",
    flag: 1 << 18, //need to show this field before Total of order.
  },
  {
    title: <IntlMessages id="report.commissionWithoutTax" />,
    dataIndex: "commission",
    key: "commission",
    flag: 1 << 9,
  },
  {
    title: <IntlMessages id="report.commissionWithTax" />,
    dataIndex: "commissionWithTax",
    key: "commissionWithTax",
    flag: 1 << 19,
  },
  {
    title: <IntlMessages id="report.taxOnCommission" />,
    dataIndex: "taxOnCommission",
    key: "taxOnCommission",
    flag: 1 << 22,
  },
  {
    title: (
      <IntlMessages
        id="report.totalWithoutCommission"
        defaultMessage="Total Without Commission" //Text changed on requirment
      />
    ),
    dataIndex: "totalWithoutCommission",
    key: "totalWithoutCommission",
    flag: 1 << 12,
  },
  {
    title: <IntlMessages id="report.orderType" />,
    dataIndex: "orderType",
    key: "orderType",
    flag: 1 << 15,
  },
  {
    title: <IntlMessages id="report.paymentType" />,
    dataIndex: "paymentType",
    key: "paymentType",
    flag: 1 << 16,
  },
  {
    title: (
      <IntlMessages
        id="report.deliveryService"
        defaultMessage="Delivery Service"
      />
    ),
    dataIndex: "deliveryService",
    key: "deliveryService",
    flag: 1 << 24,
  },
  {
    title: (
      <IntlMessages
        id="report.deliveryServiceCharges"
        defaultMessage="Delivery Service Charges"
      />
    ),
    dataIndex: "deliveryServiceCharges",
    key: "deliveryServiceCharges",
    flag: 1 << 25,
  },
  {
    title: <IntlMessages id="report.action" defaultMessage="Action" />,
    dataIndex: "action",
    key: "action",
    flag: 1 << 17,
  },
];

const orderTableColumns = [
  {
    title: <span style={{ color: "#8bc53f" }}>S.No</span>,
    dataIndex: "serialNo",
    key: "serialNo",
    // render: text => <span className="gx-link">{text}</span>,
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="report.itemName" />
      </span>
    ),
    dataIndex: "itemName",
    key: "itemName",
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="report.quantity" />
      </span>
    ),
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="report.unitPrice" />
      </span>
    ),
    dataIndex: "unitPrice",
    key: "unitPrice",
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="report.price" />
      </span>
    ),
    dataIndex: "price",
    key: "price",
  },
];

let B2BorderTableColumns = [
  {
    title: <span style={{ color: "#8bc53f" }}>S.No</span>,
    dataIndex: "serialNo",
    key: "serialNo",
    // render: text => <span className="gx-link">{text}</span>,
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="menuInfo.articleNumber" />
      </span>
    ),
    dataIndex: "articleNumber",
    key: "articleNumber",
    responsive: ["sm", "md"],
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="menuInfo.department" />
      </span>
    ),
    dataIndex: "department",
    key: "department",
    responsive: ["sm", "md"],
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="report.itemName" />
      </span>
    ),
    dataIndex: "itemName",
    key: "itemName",
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="report.quantity" />
      </span>
    ),
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="report.unitPrice" />
      </span>
    ),
    dataIndex: "unitPrice",
    key: "unitPrice",
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="report.price" />
      </span>
    ),
    dataIndex: "price",
    key: "price",
  },
];

let dubaiOrderTableColumns = [
  {
    title: <span style={{ color: "#8bc53f" }}>S.No</span>,
    dataIndex: "serialNo",
    key: "serialNo",
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="menuList.sku" />
      </span>
    ),
    dataIndex: "articleNumber",
    key: "articleNumber",
    responsive: ["sm", "md"],
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="report.itemName" />
      </span>
    ),
    dataIndex: "itemName",
    key: "itemName",
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="report.quantity" />
      </span>
    ),
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="report.unitPrice" />
      </span>
    ),
    dataIndex: "unitPrice",
    key: "unitPrice",
  },
  {
    title: (
      <span style={{ color: "#8bc53f" }}>
        <IntlMessages id="report.price" />
      </span>
    ),
    dataIndex: "price",
    key: "price",
  },
];

const MAX_TAG_COUNT = 3;

const initalState = {
  pageNo: 1,
  pageSize: 15,
  restaurantId: "",
  riderId: "",
  searchQuery: "",
  status: 0,
  delivery: false,
  takeaway: false,
  dineIn: false,
  loadingExport: false,

  loading: true,
  hasMore: true,

  deliveryOrders: 0,
  takeawayOrders: 0,
  dineInOrders: 0,
  totalOrders: 0,

  startDate: moment().subtract(1, "days"), //undefined,
  endDate: moment(), //undefined,
  initDateRange: true,
  data: [],
  restaurantNameList: [],
  riderNameList: [],
  visible: false,
  modalTitle: "",
  assignOrderIdForDrawer: null,
  settings: null,
  loadingAutoUpdate: false,
  showExportModal: false,

  paymentMethod: 0,
  taxDisplayText: "",
  isB2B: false,
  orderFrom: 0,
  serviceProvider: 0,
  //multiple filters
  multipleRestaurantIds: [],
  multipleOrderStatuses: [],
  multiplePaymentMethods: [],
  multipleOrderPlatforms: [],
  filterRequest: {},
  isFiltered: false,
};

class RestaurantOrders extends React.Component {
  constructor() {
    super();
    this.state = initalState;
  }

  getBadge = (index, msg, orderType) => {
    let badges = [
      <Badge
        className={"width-150px"}
        text={
          <>
            <IntlMessages id="report.none" /> {msg}
          </>
        }
      />,
      <Badge
        className={"width-150px"}
        color="green"
        text={
          <>
            <IntlMessages id="report.accepted" /> {msg}
          </>
        }
      />,
      <Badge
        className={"width-150px"}
        color="blue"
        text={
          <>
            <IntlMessages id="report.onTheWay" /> {msg}
          </>
        }
      />,
      <Badge
        className={"width-150px"}
        color="green"
        text={
          <>
            <IntlMessages id="report.completed" /> {msg}
          </>
        }
      />,
      <Badge
        className={"width-150px"}
        color="orange"
        text={
          <>
            <IntlMessages id="report.pending" /> {msg}
          </>
        }
      />,
      <Badge
        className={"width-150px"}
        color="red"
        text={
          <>
            <IntlMessages id="report.rejected" /> {msg}
          </>
        }
      />,
      <Badge
        className={"width-150px"}
        color="pink"
        text={
          <>
            <IntlMessages id="report.readyForDelivery" /> {msg}
          </>
        }
      />, //deprecated
      <Badge
        className={"width-150px"}
        color="purple"
        text={
          <>
            <IntlMessages id="report.acceptedByRider" /> {msg}
          </>
        }
      />,
      <Badge
        className={"width-150px"}
        color="green"
        text={
          <>
            <IntlMessages id="report.completed" /> {msg}
          </>
        }
      />, //deprecated completed
      <Badge
        className={"width-150px"}
        color="pink"
        text={
          <>
            {orderType == OrderType.DineIn ? (
              <IntlMessages id="report.readyForDineIn" />
            ) : (
              <IntlMessages id="report.readyForTakeaway" />
            )}
            {msg}
          </>
        }
      />,
      <Badge
        className={"width-150px"}
        color="red"
        text={
          <>
            <IntlMessages id="report.rejectedBySuperAdmin" /> {msg}
          </>
        }
      />,
      <Badge
        className={"width-150px"}
        color="blue"
        text={
          <>
            <IntlMessages id="report.pickedUp" /> {msg}
          </>
        }
      />,
    ];
    return badges[index];
  };

  openOrderDetails = async (orderId, restaurantName) => {
    const body = {
      OrderId: orderId,
    };

    const response = await ReportService.getOrderDetailsRa(body);
    let list = [];
    response.Data.DetailedMenuItemList.forEach((v, i) => {
      list.push({
        key: i + "",
        serialNo: i + "",
        itemName: v.MenuItem.Name,
        quantity: v.MenuItem.Quantity,
        unitPrice: v.MenuItem.Price,
        price: v.MenuItem.Price * v.MenuItem.Quantity,
        articleNumber: v.MenuItem.ArticleNumber,
        department: v.MenuItem.Department,
      });
      v.SubMenuItemsList.forEach((sv, si) => {
        list.push({
          key: i + "." + si,
          serialNo: i + "." + si,
          itemName: (
            <span>
              {v.MenuItem.Name}{" "}
              <small style={{ color: "gray" }}>{sv.Name}</small>
            </span>
          ),
          quantity: sv.Quantity,
          unitPrice: sv.UnitPrice,
          price: sv.Quantity * sv.UnitPrice,
        });
      });
    });
    console.log("list", list);

    const orderDetails = {
      restaurantName: restaurantName,
      orderNumber: response.Data.Id,
      orderSerial: response.Data.CustomOrderId,
      orderStatus: this.formateOrderStatus(
        response.Data.OrderStatus,
        response.Data.OrderStatusDescription,
        null,
        response.Data.OrderType,
        response.ReminderCounts
      ),
      address:
        response.Data.Address == null ? "N/A" : response.Data.Address.Area,
      detailAddress:
        response.Data.Address == null ? "N/A" : response.Data.Address.Apartment,
      nearByLocation:
        response.Data.Address == null
          ? "N/A"
          : response.Data.Address.SpecialIntructions,
      dateTime: response.Data.CreateTime,
      orderDetails: list,
      subTotal: response.Data.SubTotal,
      deliveryFee: response.Data.DeliveryFee,
      platformCharges: response.Data.PlatformCharges,
      totalTax: response.Data.TotalTax,
      orderType: response.Data.OrderType,
      minimumOrderAdjument: response.Data.MinimumOrderAdjustmentAmount,
      total: response.Data.TotalAmount,
      totalDiscount: response.Data.TotalDiscount,
      user: response.Data.User,
      promoCode: response.Data.PromoCode,
      restaurantLogo: response.Data.RestaurantLogo,
      taxDisplayText: this.state.taxDisplayText,
      specialInstructions: response.Data.SpecialInstructions,
      // paymentType: PaymentMethod,
    };

    this.setState({
      visible: true,
      modalTitle: restaurantName,
      orderDetails: orderDetails,
      isB2B: response.Data.BusinessType == DomainType.B2B ? true : false,
    });
  };

  async performResearch() {
    this.setState(
      {
        pageNo: 1,
        hasMore: true,
        loading: true,
      },
      async () => {
        let list = await this.getDataList(1);
        this.setState({ data: list, loading: false });
      }
    );
  }

  async downloadReport() {
    this.setState({ loadingExport: true });
    const filterParameters = this.createFilterParameter(0);
    try {
      let response = await ReportService.downloadOrdersExcelReportRa(
        filterParameters
      );
      if (response && response.Success) {
        window.location.href = response.Data;
      } else {
        console.error(response.APIMessage);
      }
    } catch (ex) {
      console.error(ex.message);
    }
    this.setState({ loadingExport: false });
  }

  searchMark = (txt) => {
    return this.state.searchQuery == ""
      ? txt
      : reactStringReplace(txt, this.state.searchQuery, (match, i) => (
          <Text mark>{match}</Text>
        ));
  };

  getDeliveryServiceDetails = (deliveryDetails) => {
    if (deliveryDetails) {
      let popOverContent = <></>;
      if (deliveryDetails.ServiceProvider == DeliveryServiceProviders.Bykea) {
        popOverContent = (
          <table>
            <tbody>
              <tr>
                <td>Batch Id</td>
                <td>
                  <small style={{ padding: 10 }}>
                    <b>{deliveryDetails?.BatchBookingId}</b>
                  </small>
                </td>
              </tr>
              <tr>
                <td>Batch Number</td>
                <td>
                  <small style={{ padding: 10 }}>
                    <b>{deliveryDetails?.BatchBookingNumber}</b>
                  </small>
                </td>
              </tr>
              <tr>
                <td>Trip Id</td>
                <td>
                  <small style={{ padding: 10 }}>
                    <b>{deliveryDetails?.BookingId}</b>
                  </small>
                </td>
              </tr>
              <tr>
                <td>Current Status</td>
                <td>
                  <small style={{ padding: 10 }}>
                    <b>{BykeaStatuses[deliveryDetails?.CurrentStatus]}</b>
                  </small>
                </td>
              </tr>
              {deliveryDetails?.PartnerDetails && (
                <tr>
                  <td style={{ display: "block" }}>Partner Details</td>
                  <td>
                    <pre style={{ paddingLeft: 10 }}>
                      {JSON.stringify(
                        JSON.parse(deliveryDetails?.PartnerDetails),
                        null,
                        2
                      )}
                    </pre>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        );
      }

      let returnElement = (
        <>
          <Popover
            content={popOverContent}
            title="Delivery Details"
            className="order-delivery-details"
          >
            <IntlMessages
              id="report.deliveryDetails"
              defaultMessage="Delivery Details"
            />{" "}
            <InfoCircleFilled />
          </Popover>
        </>
      );

      return returnElement;
    }
  };

  parseOrderToMapObject = (order) => {
    return {
      key: order.Id,
      orderSerial: order.CustomOrderId,
      orderNumber: (
        <>
          {order.OrderNumber} {GetCustomerPlatform(order)}
        </>
      ),
      createTime: order.OrderDate,
      date: moment(order.OrderDate).format(
        this.props.whitelisting.AdminDateTimeFormat
      ),
      customerName: order.CustomerName,
      riderName: this.searchMark(order.RiderName),
      deliveryAddress: this.searchMark(order.DeliveryAddress),
      status: this.formateOrderStatus(
        order.OrderStatus,
        order.OrderStatusDescription,
        order.Id,
        order.OrderType,
        order.ReminderCounts
      ),
      subTotal: order.SubTotal,
      totalWithoutCommission:
        // (order.SubTotal || 0) +
        // (order.Commission || 0) +
        // (order.MinimumOrderAdjustment || 0) +
        // (order.DeliveryFee || 0)
        convertToDecimal(
          (order.Total || 0) - (order.Commission + order.CommissionTax || 0)
        ),
      discount: this.formatePromoCodeDiscount(
        order.PromoCodeDiscount,
        order.TotalDiscount,
        0
      ),
      totalTax: order.TotalTax,
      total: order.Total,
      commission: convertToDecimal(order.Commission),
      commissionWithTax: convertToDecimal(
        order.Commission + order.CommissionTax
      ),
      taxOnCommission: convertToDecimal(order.CommissionTax || 0),
      deliveryFee: order.DeliveryFee,
      platformCharges: convertToDecimal(order.PlatformCharges),
      minimumOrderAdjustment: order.MinimumOrderAdjustment,
      deliveryService:
        order.DeliveryModel == DeliveryModel.WithRider ? (
          <>
            {getDeliveryServiceName(order.DeliveryDetails)}
            <br />
            {this.getDeliveryServiceDetails(order.DeliveryDetails)}
          </>
        ) : (
          <IntlMessages id="config.withoutRider" />
        ),
      deliveryServiceCharges: order.DeliveryDetails?.DeliveryCharges ?? 0,
      orderType: (
        <>
          {order.OrderType == OrderType.Delivery ? (
            <IntlMessages id="report.delivery" />
          ) : order.OrderType == OrderType.DineIn ? (
            <IntlMessages id="report.dineIn" />
          ) : (
            <IntlMessages id="report.takeaway" />
          )}
          {order.ScheduleDateTime && (
            <>
              {" "}
              <small style={{ color: "green" }}>(Schedule)</small>
              <br /> <i class="fa fa-calendar"></i>{" "}
              {moment(order.ScheduleDateTime).format(
                this.props.whitelisting.AdminDateTimeFormat
              )}{" "}
              <small>({moment(order.ScheduleDateTime).fromNow()})</small>
            </>
          )}
        </>
      ),
      paymentType: this.getPaymentMethodName(order.PaymentMethod),
      action: (
        <>
          <a
            onClick={() =>
              this.openOrderDetails(order.Id, order.RestaurantName)
            }
          >
            <i class="fa fa-external-link-square"></i>{" "}
            <IntlMessages id="app.details" />
          </a>
          <br />
          <a onClick={() => this.handlePrintOrder(order.Id)}>
            <i class="fa fa-print"></i>{" "}
            <IntlMessages id="app.printOrder" defaultMessage="Print Order" />
          </a>
        </>
      ),
    };
  };

  getPaymentMethodName = (methodNumber) => {
    if (methodNumber == 1) {
      return <IntlMessages id="report.cash" />;
    } else if (methodNumber == 8) {
      return <IntlMessages id="report.swish" />;
    } else {
      return <IntlMessages id="report.card" />;
    }
  };

  async getDataList(pageNo) {
    let filterParameters = this.createFilterParameter(pageNo);
    this.setState({ filterRequest: filterParameters });

    const queryParameters = {
      startDate:
        this.state.startDate == null || this.state.initDateRange == false
          ? null
          : this.state.startDate
              .format(this.props.whitelisting.AdminDateFormat)
              .toString(), //this.state.startDate,//"1601510400",
      endDate:
        this.state.endDate == null || this.state.initDateRange == false
          ? null
          : this.state.endDate
              .format(this.props.whitelisting.AdminDateFormat)
              .toString(), //this.state.endDate,//"1601683200",
      searchQuery: this.state.searchQuery == "" ? null : this.state.searchQuery,
      status: this.state.status == 0 ? null : this.state.status,
      delivery: this.state.delivery == false ? null : true,
      takeaway: this.state.takeaway == false ? null : true,
      dineIn: this.state.dineIn == false ? null : true,
      paymentMethod:
        this.state.paymentMethod == 0 ? null : this.state.paymentMethod,
      orderFrom: this.state.orderFrom == 0 ? null : this.state.orderFrom,
      serviceProvider:
        this.state.serviceProvider == 0 ? null : this.state.serviceProvider,
    };
    var queryString = [];
    for (var key in queryParameters) {
      if (queryParameters[key] != null || queryParameters[key] != undefined) {
        queryString.push(key + "=" + queryParameters[key]);
      }
    }
    this.props.history.push("/restaurant-orders?" + queryString.join("&"));

    const res = await ReportService.getAllOrdersRa(filterParameters);
    console.log(res);
    if (res && res.Success) {
      const response = res.Data.OrdersList;

      let list = [];
      if (response != null) {
        list = response.map(this.parseOrderToMapObject);
        this.setState({
          deliveryOrders: res.Data.DeliveryOrders,
          takeawayOrders: res.Data.TakeawayOrders,
          dineInOrders: res.Data.DineInOrders,
          totalOrders: res.Data.TotalOrders,
        });
      }
      return list;
    } else {
      window.location.href = "/";
      return [];
    }
  }

  getFilterServiceType = () => {
    let filterServiceType =
      this.state.delivery && this.state.takeaway && this.state.dineIn
        ? 0
        : (this.state.delivery ? 1 : 0) +
          (this.state.takeaway ? 2 : 0) +
          (this.state.dineIn ? 8 : 0);

    return filterServiceType;
  };

  loadSettings = async () => {
    try {
      var response = await SettingService.fetchSuperAdminSettings();
      if (response && response.Success) {
        this.setState({ settings: response.Data.Setting });
      } else {
        console.error(response.APIMessage);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  startStatusUpdatePolling = async () => {
    await this.loadSettings();
    setInterval(async () => {
      if (
        this.state.settings != null &&
        this.state.loadingAutoUpdate == false
      ) {
        this.setState({ loadingAutoUpdate: true });
        try {
          var startDateValue = this.state.startDate;
          var endDateValue = this.state.endDate;
          if (startDateValue)
            startDateValue.set({ hour: 0, minute: 0, second: 0 });
          if (endDateValue)
            endDateValue.set({ hour: 23, minute: 59, second: 0 });

          const filterParameters = {
            ...this.state.filterRequest,
            PageSize: 20,
          };
          var firstDate = this.state.data[0]?.createTime ?? null;
          var orderIds = this.state.data.map((x) => x.key);
          var request = {
            FirstDate: firstDate,
            OrderIds: orderIds,
            Filters: filterParameters,
          };

          var response = await ReportService.getUpdatedOrdersStatusesRa(
            request
          );
          if (response && response.Success) {
            if (!this.state.loading) {
              this.updateStatuses(response.Data.Statuses);
              this.mergeNewOrders(response.Data.NewOrders);
            }
          } else {
            console.error(response.APIMessage);
          }
        } catch (ex) {
          console.error(ex);
        } finally {
          this.setState({ loadingAutoUpdate: false });
        }
      }
    }, (this.state.settings?.OrderStatusUpdateAfterSeconds || 10) * 1000);
  };

  updateStatuses = (stasuses) => {
    var updatedOrders = this.state.data.map((o) => {
      var found = stasuses.find((s) => s.Id === o.key);
      if (found) {
        o.status = this.formateOrderStatus(
          found.OrderStatus,
          found.OrderStatusDescription,
          found.Id,
          found.OrderType,
          found.ReminderCounts
        );
        o.riderName = this.searchMark(found.RiderName);
        o.action = (
          <>
            <a
              onClick={() =>
                this.openOrderDetails(found.Id, found.RestaurantName)
              }
            >
              <i class="fa fa-external-link-square"></i>{" "}
              <IntlMessages id="app.details" />
            </a>
            <br />
            <a onClick={() => this.handlePrintOrder(found.Id)}>
              <i class="fa fa-print"></i>{" "}
              <IntlMessages id="app.printOrder" defaultMessage="Print Order" />
            </a>
            {found.OrderStatus == OrderStatuses.Accepted &&
              found.IsEligibleReadyForDeliveryScheduleOrder &&
              found.OrderType == OrderType.Delivery &&
              found.DeliveryModel == DeliveryModel.WithRider &&
              found.DeliveryDetails?.ServiceProvider ==
                DeliveryServiceProviders.Speedoy && (
                <Button
                  size="small"
                  onClick={() => this.assignOrderToRider(found.Id)}
                >
                  <IntlMessages id="orders.assignOrder" />
                </Button>
              )}
          </>
        );
      }
      return o;
    });
    this.setState({ data: updatedOrders });
  };

  mergeNewOrders = (orders) => {
    var ordersList = orders.OrdersList || [];
    this.setState({
      deliveryOrders: orders.DeliveryOrders,
      takeawayOrders: orders.TakeawayOrders,
      dineInOrders: orders.DineInOrders,
      totalOrders: orders.TotalOrders,
    });
    if (ordersList.length > 0) {
      var newList = ordersList.map(this.parseOrderToMapObject);
      var mergedOrders = [...newList, ...this.state.data];
      this.setState({
        data: mergedOrders,
      });
    }
  };

  deliver = async (id) => {
    const _this = this;
    confirm({
      title: "Do you really want to deliver this order?",
      icon: <ExclamationCircleOutlined />,
      content: "Click!",
      onOk() {
        console.log("OK");
        _this.changeStatusApi({
          OrderId: id,
          OrderStatus: OrderStatuses.Delivered,
          OrderStatusDescription: "Order delivered by super admin.",
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  reject = async (id) => {
    const _this = this;
    confirm({
      title: "Do you really want to reject this order?",
      icon: <ExclamationCircleOutlined />,
      content: "Click!",
      onOk() {
        console.log("OK");
        _this.changeStatusApi({
          OrderId: id,
          OrderStatus: OrderStatuses.RejectedBySuperAdmin,
          OrderStatusDescription: "Order rejected by super admin.",
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  accept = async (id) => {
    const _this = this;
    confirm({
      title: "Do you really want to accept this order?",
      icon: <ExclamationCircleOutlined />,
      content: "Click!",
      onOk() {
        console.log("OK");
        _this.changeStatusApi({
          OrderId: id,
          OrderStatus: OrderStatuses.Accepted,
          OrderStatusDescription: "Order accepted by super admin.",
          PreparationTime: 900, //Default Preparation time: 15 mins
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  changeStatusApi = async (changeStatusRequest) => {
    this.setState({ loading: true });
    try {
      const response = await ReportService.changeStatus(changeStatusRequest);
      if (response && response.Success) {
        message.success("Status Changed!");
      } else {
        message.error(`Failed! ${response.APIMessage}`);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      this.performResearch();
    }
  };

  formateOrderStatus = (
    statusCode,
    description,
    id,
    orderType,
    reminderCounts
  ) => {
    //Order Statuses
    // None : 0,
    // Accepted : 1,
    // OnTheWay : 2,
    // Delivered : 3,
    // Pending : 4,
    // Rejected : 5,
    // AcceptedByRider : 7,
    // ReadyForTakeaway : 9,
    // RejectedBySuperAdmin : 10,
    // PickedUp : 11

    //RemindersCount
    let remindersCountTag = "";
    if (reminderCounts) {
      remindersCountTag = (
        <Tooltip
          title={
            <IntlMessages
              id="reports.remindersCount"
              defaultMessage="Reminders count"
            />
          }
        >
          <Tag color="lime">
            <Icon type="sound" /> {reminderCounts}
          </Tag>
        </Tooltip>
      );
    }

    //can be accepted
    let acceptStatuses = [OrderStatuses.Pending];
    let acceptBtn = "";
    if (acceptStatuses.includes(statusCode) && id) {
      acceptBtn = (
        <Button
          type="default"
          onClick={() => {
            this.accept(id);
          }}
          size="small"
        >
          <IntlMessages id="report.accept" defaultMessage="Accept" />
        </Button>
      );
    }

    //can be rejected
    let rejectStatuses = [
      OrderStatuses.Accepted,
      OrderStatuses.OnTheWay,
      OrderStatuses.Delivered,
      OrderStatuses.Completed,
      OrderStatuses.AcceptedByRider,
      OrderStatuses.ReadyForTakeaway,
      OrderStatuses.ReadyForDelivery,
      OrderStatuses.PickedUp,
    ];
    let rejectBtn = "";
    if (rejectStatuses.includes(statusCode) && id) {
      rejectBtn = (
        <Button
          type="danger"
          onClick={() => {
            this.reject(id);
          }}
          size="small"
        >
          <IntlMessages id="report.reject" defaultMessage="Reject" />
        </Button>
      );
    }

    //can be delivered
    let deliverStatuses = [
      OrderStatuses.OnTheWay,
      OrderStatuses.AcceptedByRider,
      OrderStatuses.ReadyForTakeaway,
      OrderStatuses.ReadyForDelivery,
      OrderStatuses.PickedUp,
    ];
    let deliverBtn = "";
    if (deliverStatuses.includes(statusCode) && id) {
      deliverBtn = (
        <Button
          type="default"
          onClick={() => {
            this.deliver(id);
          }}
          size="small"
        >
          <IntlMessages id="report.complete" defaultMessage="Complete" />
        </Button>
      );
    }
    console.log("statusCode", statusCode);
    if (
      statusCode == OrderStatuses.Rejected ||
      statusCode == OrderStatuses.RejectedBySuperAdmin
    ) {
      //Rejected
      let statusBadge = this.getBadge(
        statusCode,
        <Tooltip title={description}>
          <QuestionCircleOutlined />
        </Tooltip>
      );
      return (
        <>
          {remindersCountTag}
          {statusBadge}
          <ButtonGroup>
            {rejectBtn}
            {deliverBtn}
          </ButtonGroup>
        </>
      );
    }
    let statusBadge = this.getBadge(statusCode, null, orderType);
    return (
      <>
        {remindersCountTag}
        {statusBadge}
        <ButtonGroup>
          {acceptBtn}
          {rejectBtn}
          {deliverBtn}
        </ButtonGroup>
      </>
    );
  };

  formatePromoCodeDiscount = (code, discountAmount, defalt = "") => {
    if (code == null || code == undefined || code.Id == null) {
      return defalt;
    } else {
      let discount =
        code.ValueType == 1
          ? `${discountAmount} ${this.props.whitelisting.Currency} / ${code.Value} %`
          : discountAmount + ` ${this.props.whitelisting.Currency}`;
      discount += ` (${code.Code})`;
      return discount;
    }
  };

  fetchMoreData = async (e) => {
    this.setState({
      loading: true,
    });

    const pageNo = this.state.pageNo + 1;
    let list = await this.getDataList(pageNo);

    if (list.length == 0) {
      message.warning("End!");
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }

    let mergeList = [...this.state.data, ...list];
    this.setState({
      data: mergeList,
      pageNo: pageNo,
      loading: false,
    });
  };

  createFilterParameter = (pageNo) => {
    var startDateValue = this.state.startDate;
    var endDateValue = this.state.endDate;
    if (startDateValue) startDateValue.set({ hour: 0, minute: 0, second: 0 });
    if (endDateValue) endDateValue.set({ hour: 23, minute: 59, second: 0 });

    let filterServiceType = this.getFilterServiceType();

    const filterParameters = {
      MultipleRestaurantIds: this.state.multipleRestaurantIds,
      MultiplePaymentMethods: this.state.multiplePaymentMethods,
      MultipleOrderStatuses: this.state.multipleOrderStatuses,
      MultipleOrderPlatforms: this.state.multipleOrderPlatforms,
      PageNo: pageNo,
      PageSize: this.state.pageSize,
      DateStart: this.state.initDateRange == false ? null : startDateValue, //this.state.startDate,//"1601510400",
      DateEnd: this.state.initDateRange == false ? null : endDateValue, //this.state.endDate,//"1601683200",
      SearchQuery: this.state.searchQuery,
      OrderStatus: this.state.status,
      OrderType: filterServiceType,
      PaymentMethod: this.state.paymentMethod,
      OrderFrom: this.state.orderFrom,
      ServiceProvider: this.state.serviceProvider,
    };
    return filterParameters;
  };

  async componentDidMount() {
    let queryObject = {};
    queryObject.initDateRange = false;
    let updateStatus = false;
    let queryParams = new URLSearchParams(this.props.location.search);
    queryParams.forEach((v, k) => {
      updateStatus = true;
      switch (k) {
        case "restaurantId":
          queryObject.restaurantId = v;
          break;
        case "riderId":
          queryObject.riderId = v;
          break;
        case "startDate":
          queryObject.startDate = moment.utc(
            v,
            this.props.whitelisting.AdminDateFormat
          );
          queryObject.initDateRange = true;
          break;
        case "endDate":
          queryObject.endDate = moment.utc(
            v,
            this.props.whitelisting.AdminDateFormat
          );
          queryObject.initDateRange = true;
          break;
        case "searchQuery":
          queryObject.searchQuery = v;
          break;
        case "status":
          queryObject.status = Number.parseInt(v);
          break;
        case "delivery":
          queryObject.delivery = v;
          break;
        case "takeaway":
          queryObject.takeaway = v;
          break;
        case "dineIn":
          queryObject.dineIn = v;
          break;
        case "paymentMethod":
          queryObject.paymentMethod = v;
          break;
      }
    });

    const id = appState.loadString("rId");
    if (id != "null" && id != null && id != undefined) {
      const restaurantDetail =
        await RestaurantService.getRestaurantDetailsForRa(id);
      this.setState({
        taxDisplayText: restaurantDetail.data.Data.TaxDisplayText,
      });
    }

    if (updateStatus) {
      this.setState({ ...queryObject, isFiltered: true }, this.performResearch);
    } else {
      this.performResearch();
    }
    this.startStatusUpdatePolling();
  }

  searchHandler = async (searchInput) => {
    this.setState({ searchQuery: searchInput, isFiltered: true }, async () => {
      await this.performResearch();
    });
  };

  searchReset = (searchInput) => {
    if (searchInput == "" || searchInput == null) {
      this.setState(
        {
          searchQuery: "",
          isFiltered: false,
        },
        async () => await this.performResearch()
      );
    } else {
      this.setState({ searchQuery: searchInput });
    }
  };

  handleFilterStatus = (value) => {
    if (value && value.length && value.includes("0"))
      value = AllOrderStatuses.map((a) => a.value);

    this.setState({ multipleOrderStatuses: value });
  };

  handleFilterByPaymentMethod = (value) => {
    if (value && value.length && value.includes("0"))
      value = paymentOptions
        .filter((x) =>
          (this.props.restaurant.PaymentMethodsList || []).includes(x.flag)
        )
        .map((a) => a.value);

    this.setState({ multiplePaymentMethods: value });
  };

  handleFilterByRestaurant = (value) => {
    this.setState({ restaurantId: value });
  };

  handleFilterByOrderFrom = (value) => {
    this.setState({ orderFrom: value });
  };

  handleFilterByServiceProvider = (value) => {
    this.setState({ serviceProvider: value });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleFilterByRider = (value) => {
    this.setState({ riderId: value });
  };

  handleFilterServiceTypeChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleDateFilter = (date, dateString) => {
    this.setState({
      startDate: date[0],
      endDate: date[1],
      initDateRange: true,
    });
  };

  handleFilterByOrderPlatforms = (value) => {
    if (value && value.length && value.includes("0"))
      value = OrderFromFilters.map((a) => a.value);

    this.setState({
      multipleOrderPlatforms: value,
    });
  };

  renderNullableString(obj) {
    if (obj == null) return "-";

    if (obj.length > 0) return obj;
  }

  async handlePrintOrder(orderId) {
    message.loading({
      content: "Downloading file..",
      duration: 0,
      key: orderId,
    });
    const response = await ReportService.printOrder(orderId);
    if (!response) {
      message.error("Something went wrong");
    }
    message.destroy(orderId);
  }

  handleFilterSearch = async () => {
    this.setState({ isFiltered: true });
    await this.performResearch();
  };

  clearFilter = () => {
    const clearState = {
      //filters
      pageNo: 1,
      pageSize: 15,
      restaurantId: "",
      riderId: "",
      searchQuery: "",
      status: 0,
      delivery: false,
      takeaway: false,
      dineIn: false,
      loadingExport: false,

      loading: true,
      hasMore: true,

      deliveryOrders: 0,
      takeawayOrders: 0,
      dineInOrders: 0,
      totalOrders: 0,

      startDate: undefined, //moment().subtract(1, "days"),
      endDate: undefined, //moment(),
      initDateRange: true,
      visible: false,
      modalTitle: "",
      assignOrderIdForDrawer: null,
      settings: null,
      loadingAutoUpdate: false,
      showExportModal: false,

      paymentMethod: 0,
      taxDisplayText: "",
      isB2B: false,
      orderFrom: 0,
      serviceProvider: 0,
      //multiple filters
      multipleRestaurantIds: [],
      multipleOrderStatuses: [],
      multiplePaymentMethods: [],
      multipleOrderPlatforms: [],
      filterRequest: {},
      isFiltered: false,
    };

    this.setState({ ...clearState }, async () => {
      await this.performResearch();
    });
  };

  render() {
    B2BorderTableColumns.find((a) => a.dataIndex == "articleNumber").title =
      getArticlePropertyName(this.props);
    const isDubai = this.props.whitelisting.Country == AppSettings.Country.UAE;

    const deliveryServicesList = DeliveryServiceModel.filter(
      (a) => a.Country == this.props.whitelisting.Country
    ).map((a) => {
      return { value: a.Value, label: a.ServiceProvider };
    });

    let taxDisplayText = this.state.taxDisplayText;
    if (
      taxDisplayText !== "" &&
      taxDisplayText != null &&
      taxDisplayText != undefined
    ) {
      let updatedColumns = [...columns];
      updatedColumns.find((a) => a.key == "totalTax").title = taxDisplayText;
      columns = [...updatedColumns];
    }
    return (
      <div className="payout-report-box">
        <Modal
          title={this.state.modalTitle}
          visible={this.state.visible}
          // maskStyle={{ backgroundColor: '#8bc53f' }}
          footer={false}
          closable={true}
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <img
            alt="speedoy"
            style={{ width: "100%", padding: "30px" }}
            src={require("assets/images/logo.png")}
          />
          {/* <img src={this.state.orderDetails&&this.state.orderDetails.restaurantLogo} /> */}
          <hr />
          <Row>
            <Col span={12}>
              <IntlMessages id="report.orderSerial" defaultMessage="#" />
            </Col>
            <Col span={12}>
              {this.state.orderDetails && this.state.orderDetails.orderSerial}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <IntlMessages id="report.restaurantName" />
            </Col>
            <Col span={12}>{this.state.modalTitle}</Col>
          </Row>
          <Row>
            <Col span={12}>
              <IntlMessages id="report.orderNumber" />
            </Col>
            <Col span={12}>
              {this.state.orderDetails && this.state.orderDetails.orderNumber}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <IntlMessages id="report.orderStatus" />
            </Col>
            <Col span={12}>
              {this.state.orderDetails && this.state.orderDetails.orderStatus}
            </Col>
          </Row>
          {/* <Row>
            <Col span={12}>Restaurant phone</Col>
            <Col span={12}>{this.state.orderDetails.restaurantName}</Col>
          </Row> */}
          <Row>
            <Col span={12}>
              <IntlMessages id="report.address" />
            </Col>
            <Col span={12}>
              {this.state.orderDetails && this.state.orderDetails.address}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <IntlMessages
                id="report.detailAddress"
                defaultMessage="Detail Address"
              />
            </Col>
            <Col span={12}>
              {this.state.orderDetails && this.state.orderDetails.detailAddress
                ? this.renderNullableString(
                    this.state.orderDetails.detailAddress
                  )
                : "-"}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <IntlMessages
                id="report.nearByLocation"
                defaultMessage="Near by location"
              />
            </Col>
            <Col span={12}>
              {this.state.orderDetails && this.state.orderDetails.nearByLocation
                ? this.renderNullableString(
                    this.state.orderDetails.nearByLocation
                  )
                : "-"}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <IntlMessages id="report.date" />
            </Col>
            <Col span={12}>
              {this.state.orderDetails &&
                moment(this.state.orderDetails.dateTime).format(
                  this.props.whitelisting.AdminDateTimeFormat
                )}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <IntlMessages id="report.customerName" />
            </Col>
            <Col span={12}>
              {this.state.orderDetails &&
                this.state.orderDetails.user.FirstName +
                  " " +
                  this.state.orderDetails.user.LastName}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <IntlMessages id="report.customerPhone" />
            </Col>
            <Col span={12}>
              {this.state.orderDetails &&
                this.state.orderDetails.user.PhoneNumber}
            </Col>
          </Row>
          {this.state.orderDetails?.specialInstructions && (
            <Row>
              <Col span={12}>
                <IntlMessages
                  id="report.specialInstructions"
                  defaultMessage="Special Instructions"
                />
              </Col>
              <Col span={12}>{this.state.orderDetails.specialInstructions}</Col>
            </Row>
          )}
          <hr />
          <h1>
            <IntlMessages id="report.orderDetails" />
          </h1>
          <hr />

          <div className="ant-row">
            <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
              <Table
                size="small"
                pagination={false}
                bordered
                columns={
                  this.state.isB2B
                    ? B2BorderTableColumns
                    : isDubai
                    ? dubaiOrderTableColumns
                    : orderTableColumns
                }
                dataSource={
                  this.state.orderDetails &&
                  this.state.orderDetails.orderDetails
                }
              />
            </div>
          </div>

          <hr />
          <Row>
            <Col span={12}>
              <IntlMessages id="report.subTotal" />
            </Col>
            <Col span={12}>
              {this.state.orderDetails && this.state.orderDetails.subTotal}
            </Col>
          </Row>
          {this.state.orderDetails &&
            this.state.orderDetails.orderType == 1 && (
              <Row>
                <Col span={12}>
                  <IntlMessages
                    id="report.deliveryFee"
                    defaultMessage="Delivery Fee"
                  />
                </Col>
                <Col span={12}>
                  {this.state.orderDetails &&
                    this.state.orderDetails.deliveryFee}
                </Col>
              </Row>
            )}
          <Row>
            <Col span={12}>
              <IntlMessages
                id="report.platformCharges"
                defaultMessage="Platform Charges"
              />
            </Col>
            <Col span={12}>
              {this.state.orderDetails &&
                this.state.orderDetails.platformCharges}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              {this.state.orderDetails &&
              this.state.orderDetails?.taxDisplayText ? (
                this.state.orderDetails?.taxDisplayText
              ) : (
                <IntlMessages id="report.totalTax" defaultMessage="Total Tax" />
              )}
            </Col>
            <Col span={12}>
              {this.state.orderDetails && this.state.orderDetails.totalTax}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <IntlMessages id="report.discount" defaultMessage="Discount" />
            </Col>
            <Col span={12}>
              {this.state.orderDetails &&
                this.formatePromoCodeDiscount(
                  this.state.orderDetails.promoCode,
                  this.state.orderDetails.totalDiscount,
                  0
                )}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <IntlMessages id="report.minimumOrderAdjustment" />
            </Col>
            <Col span={12}>
              {this.state.orderDetails &&
                this.state.orderDetails.minimumOrderAdjument}
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ color: "red" }}>
              <IntlMessages id="report.total" />{" "}
              {this.state.orderDetails &&
              this.state.orderDetails?.taxDisplayText ? (
                "(incl." + this.state.orderDetails?.taxDisplayText + ")"
              ) : (
                <IntlMessages
                  id="report.inclusiveTax"
                  defaultMessage=" (incl.Tax)"
                />
              )}
            </Col>
            <Col span={12} style={{ color: "red" }}>
              {this.state.orderDetails && this.state.orderDetails.total}
            </Col>
          </Row>
        </Modal>

        <Card className="gx-card breadcrumb-box" title="">
          <div className="collaps-main">
            <div className="ant-row gx-mb-1">
              <div className="ant-col-md-24">
                {/* Export Button */}
                <Button
                  className="gx-float-right"
                  loading={this.state.loadingExport}
                  onClick={() => this.downloadReport()}
                  type="primary"
                >
                  Export
                </Button>
              </div>
            </div>
            <Collapse defaultActiveKey={["1"]} className="gx-mb-1">
              <Panel
                header={
                  <IntlMessages id="orders.filter" defaultMessage="Fitlers" />
                }
                key="1"
              >
                <div className="ant-row gx-mb-1">
                  <div class="ant-col-md-8 gx-mb-1">
                    <div className="search-box">
                      <IntlMessages id="categoryList.search">
                        {(text) => (
                          <Search
                            placeholder={text}
                            value={this.state.searchQuery}
                            onSearch={(value) => this.searchHandler(value)}
                            onChange={(value) =>
                              this.searchReset(value.target.value)
                            }
                            //style={{ width: 350 }}
                          />
                        )}
                      </IntlMessages>
                    </div>
                  </div>
                  <div className="ant-col-md-8 gx-p-0 gx-mb-1">
                    <div className="check-box-fill top-margin-checkbox">
                      <ul>
                        <li>
                          <Checkbox
                            checked={this.state.delivery}
                            onChange={(e) =>
                              this.handleFilterServiceTypeChange(
                                "delivery",
                                !this.state.delivery
                              )
                            }
                          >
                            <IntlMessages id="orders.delivery" />
                          </Checkbox>
                        </li>
                        <li>
                          <Checkbox
                            checked={this.state.takeaway}
                            onChange={(e) =>
                              this.handleFilterServiceTypeChange(
                                "takeaway",
                                !this.state.takeaway
                              )
                            }
                          >
                            <IntlMessages id="orders.takeaway" />
                          </Checkbox>
                        </li>
                        <li>
                          <Checkbox
                            checked={this.state.dineIn}
                            onChange={(e) =>
                              this.handleFilterServiceTypeChange(
                                "dineIn",
                                !this.state.dineIn
                              )
                            }
                          >
                            <IntlMessages id="config.dineIn" />
                          </Checkbox>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="ant-row gx-mb-1">
                  <div className="ant-col-md-24">
                    <div className="orders-item top-section filter-box topFilterBox m-b">
                      <div className="select-box">
                        <RangePicker
                          onChange={(date, dateString) =>
                            this.handleDateFilter(date, dateString)
                          }
                          format={this.props.whitelisting.AdminDateFormat}
                          value={
                            this.state.initDateRange == true
                              ? [this.state.startDate, this.state.endDate]
                              : undefined
                          }
                        />
                      </div>

                      <div className="drop-filter-cover">
                        <i class="fa fa-filter filter-icon"></i>
                        <Select
                          style={{ minWidth: "170px" }}
                          className="select-box n-line-height"
                          value={this.state.multipleOrderStatuses}
                          onChange={(value) => this.handleFilterStatus(value)}
                          mode="multiple"
                          maxTagCount={MAX_TAG_COUNT}
                          placeholder="Order Statuses"
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return (
                              option.props.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          <Option value="0" label="0">
                            <IntlMessages
                              id="orders.allStatuses"
                              defaultMessage="All Statuses"
                            />
                          </Option>
                          {AllOrderStatuses.map((status) => {
                            return (
                              <Option value={status.value} label={status.text}>
                                {status.label}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>

                      <div className="drop-filter-cover ">
                        <i class="fa fa-filter filter-icon"></i>
                        <Select
                          style={{ minWidth: "200px" }}
                          className="select-box n-line-height"
                          value={this.state.multiplePaymentMethods}
                          defaultValue="paymentMethod"
                          onChange={(value) =>
                            this.handleFilterByPaymentMethod(value)
                          }
                          mode="multiple"
                          maxTagCount={MAX_TAG_COUNT}
                          placeholder="Payment Methods"
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return (
                              option.props.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          <Option key={0} value="0" label="0">
                            <IntlMessages
                              id="orders.allPaymentMethods"
                              defaultMessage="All payment methods"
                            />
                          </Option>
                          {paymentOptions
                            .filter((x) =>
                              (
                                this.props.restaurant.PaymentMethodsList || []
                              ).includes(x.flag)
                            )
                            .map((item, index) => {
                              return (
                                <Option
                                  key={index + 1}
                                  value={item.value}
                                  label={item.text}
                                >
                                  {item.label}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="drop-filter-cover">
                        <i class="fa fa-filter filter-icon"></i>
                        <Select
                          style={{ minWidth: "200px" }}
                          className="select-box m-b"
                          value={this.state.orderFrom}
                          onChange={(value) =>
                            this.handleFilterByOrderFrom(value)
                          }
                        >
                          {OrderModelFilters.map((item, index) => {
                            return (
                              <Option key={index + 1} value={item.Value}>
                                {item.Type}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>

                      {/* Service Provider Filters */}
                      <div className="drop-filter-cover">
                        <i class="fa fa-filter filter-icon"></i>
                        <Select
                          style={{ minWidth: "200px" }}
                          className="select-box m-b"
                          value={this.state.serviceProvider}
                          onChange={(value) =>
                            this.handleFilterByServiceProvider(value)
                          }
                        >
                          <Option key={0} value={0}>
                            All Service Providers
                          </Option>
                          {deliveryServicesList.map((item, index) => {
                            return (
                              <Option key={index + 1} value={item.value}>
                                {item.label}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>

                      <div className="drop-filter-cover">
                        <i class="fa fa-filter filter-icon"></i>
                        <MultipleSelect
                          style={{ minWidth: "170px" }}
                          className="select-box m-b n-line-height"
                          value={this.state.multipleOrderPlatforms}
                          onChange={(value) =>
                            this.handleFilterByOrderPlatforms(value)
                          }
                          maxTagCount={MAX_TAG_COUNT}
                          placeholder="Order Platforms"
                        >
                          <Option key={0} value="0" label="0">
                            <IntlMessages
                              id="orders.allPlatforms"
                              defaultMessage="All Platforms"
                            />
                          </Option>
                          {OrderFromFilters.map((item, index) => {
                            return (
                              <Option
                                key={index + 1}
                                value={item.value}
                                label={item.label}
                              >
                                {item.label}
                              </Option>
                            );
                          })}
                        </MultipleSelect>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ant-row mb-16" style={{ width: "100%" }}>
                  <div className="ant-col-md-12">
                    {/* Filter Button */}
                    <Button
                      loading={this.state.loading}
                      onClick={() => this.handleFilterSearch()}
                      type="primary"
                    >
                      Filter
                    </Button>

                    {/* Clear Button */}
                    <Popconfirm
                      title="Are you sure to clear filter?"
                      onConfirm={() => this.clearFilter()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        loading={this.state.loading}
                        type="primary"
                        disabled={!this.state.isFiltered}
                      >
                        Clear Filter
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              </Panel>
            </Collapse>

            <div className="site-statistic-demo-card statistic-content-media">
              <Row gutter={16}>
                <Col span={6}>
                  <Card>
                    {/* <Skeleton loading={true} active> */}
                    <Statistic
                      title={<IntlMessages id="report.totalOrders" />}
                      value={this.state.totalOrders}
                      valueStyle={{ color: "#3f8600" }}
                      prefix={<IconFont type="icon-shoppingcart" />}
                      // suffix="%"
                    />
                    {/* </Skeleton> */}
                  </Card>
                </Col>
                <Col span={6}>
                  <Card>
                    <Statistic
                      title={<IntlMessages id="report.deliveryOrders" />}
                      value={this.state.deliveryOrders}
                      valueStyle={{ color: "#3f8600" }}
                      prefix={<IconFont type="icon-shoppingcart" />}
                      // suffix="%"
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card>
                    <Statistic
                      title={<IntlMessages id="report.takeawayOrders" />}
                      value={this.state.takeawayOrders}
                      valueStyle={{ color: "#3f8600" }}
                      prefix={<IconFont type="icon-shoppingcart" />}
                      // suffix="%"
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card>
                    <Statistic
                      title={<IntlMessages id="report.dineInOrders" />}
                      value={this.state.dineInOrders}
                      valueStyle={{ color: "#3f8600" }}
                      prefix={<IconFont type="icon-shoppingcart" />}
                      // suffix="%"
                    />
                  </Card>
                </Col>
              </Row>
            </div>

            <div className="ant-row">
              <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
                <InfiniteScrollTable
                  columns={columns.filter((x) =>
                    (this.props.restaurant.OrdersReportColumns || []).includes(
                      x.flag
                    )
                  )}
                  dataSource={this.state.data}
                  loading={this.state.loading}
                  hasMore={this.state.hasMore}
                  height={"400px"}
                  className={"customColoumWidth"}
                  loadMore={this.fetchMoreData}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  whitelisting: state.settings.whitelisting,
  restaurant: state.settings.restaurant,
});
export default connect(mapStateToProps)(RestaurantOrders);
