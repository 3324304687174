import React, { Component } from "react";
import { Form, TimePicker, Row, Col, Icon, message, Popconfirm } from "antd";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { PlusOutlined } from "@ant-design/icons";
import { TimingsType } from "./common";

const FormItem = Form.Item;

class ScheduleTimings extends Component {
  constructor() {
    super();
    this.state = {
      timeFormat: "HH:mm",
      invalidDate: "Invalid date",
    };
  }

  componentDidMount() {
    const { schedule, index } = this.props;
    const { timeFormat } = this.state;
    if (schedule.timings?.length > 0) {
      schedule.timings.map((v, i) => {
        this.props.form.setFieldsValue({
          [index + "_StartTime_" + i]: moment(v.StartTime, timeFormat),
          [index + "_EndTime_" + i]: moment(v.EndTime, timeFormat),
        });
      });
    }
  }

  handleTimingsChange = (value, key, timingsType) => {
    let { schedule, index } = this.props;
    const timings = moment(value).format(this.state.timeFormat);

    if (timings !== this.state.invalidDate) {
      if (timingsType == TimingsType.StartTime)
        schedule.timings[key].StartTime = timings;
      else if (timingsType == TimingsType.EndTime)
        schedule.timings[key].EndTime = timings;
    }
    this.props.callBack(schedule, index);
  };

  removeTime = (key) => {
    let { schedule, index } = this.props;
    schedule.timingKeys = schedule.timingKeys.filter((o) => o != key);
    this.props.callBack(schedule, index);
  };

  addTime = () => {
    let { schedule, index } = this.props;
    const { timeFormat } = this.state;
    let newKey = Math.max.apply(Math, schedule.timingKeys) + 1;
    schedule.timingKeys.push(newKey);
    schedule.timings.push({
      StartTime: moment().format(timeFormat),
      EndTime: moment().format(timeFormat),
    });

    this.props.callBack(schedule, index);
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { schedule, index } = this.props;
    const { timeFormat } = this.state;
    const formItemLayout = { wrapperCol: { xs: 24, sm: 24 } };
    var timingForm = [];
    var length = schedule.timingKeys?.length;
    schedule.timingKeys.map((k, i) => {
      timingForm.push(
        <Row key={i + length} gutter={[24, 18]}>
          <Col span={10}>
            <FormItem {...formItemLayout}>
              {getFieldDecorator(`${index}_StartTime_${k}`, {
                rules: [{ required: true, message: "Time is Required" }],
                onChange: (value) =>
                  this.handleTimingsChange(value, k, TimingsType.StartTime),
              })(<TimePicker format={timeFormat} placeholder="Start time" />)}
            </FormItem>
          </Col>
          <Col span={10}>
            <FormItem {...formItemLayout}>
              {getFieldDecorator(`${index}_EndTime_${k}`, {
                rules: [{ required: true, message: "Time is Required" }],
                onChange: (value) =>
                  this.handleTimingsChange(value, k, TimingsType.EndTime),
              })(<TimePicker format={timeFormat} placeholder="End time" />)}
            </FormItem>
          </Col>
          {length > 1 && (
            <Col span={1}>
              <Popconfirm
                title="Are you sure to delete this time?"
                onConfirm={() => this.removeTime(k)}
                okText="Yes"
                cancelText="No"
              >
                <Icon
                  className="dynamic-delete-button"
                  type="delete-o"
                  style={{ fontSize: "16px", color: "#e02026" }}
                />
              </Popconfirm>
            </Col>
          )}
        </Row>
      );
    });

    return (
      <>
        {timingForm}
        <div className="ant-row">
          <div className="ant-col ant-col-md-12 gx-mb-3">
            <a style={{ color: "#4834d4" }} onClick={this.addTime}>
              <PlusOutlined />{" "}
              <IntlMessages id="menuInfo.addTime" defaultMessage="Add Time" />
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default ScheduleTimings;
