import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select, Card, Row, Col, Form, message, Spin } from "antd";
import IntlMessages from "util/IntlMessages";
import { CityService, TagService, SettingService } from "../../services";
import SEODetails from "./seoDetails";

const Option = Select.Option;
const MetaTypeValue = {
  City: 1,
  Tag: 2,
  Default: 3,
};
const SEOSettings = (props) => {
  const locale = useSelector(({ settings }) => settings.locale);

  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(0);
  const [selectedMetaTypeId, setSelectedMetaTypeId] = useState([]);
  const [metaTypeList, setMetaTypeList] = useState([]);
  const [metaDataDetails, setMetaDataDetails] = useState(null);
  const [selectedTypePropertyName, setSelectedTypePropertyName] =
    useState("City"); //default value
  const [isDefaultSelected, setIsDefaultSelected] = useState(false);
  const [metaType, setMetaType] = useState([
    { label: "City", value: 1 },
    { label: "Tag", value: 2 },
    { label: "Default", value: 3 },
  ]);
  useEffect(() => {});

  const changeType = (value) => {
    setLoading(true);
    getMetaData(value);
  };

  const getMetaData = async (value) => {
    let response;
    let isDefaultSelected = false;
    try {
      if (value === MetaTypeValue.City)
        response = await CityService.cityNameList();
      else if (value === MetaTypeValue.Tag)
        response = await TagService.tagsNameList();
      else if (value == MetaTypeValue.Default) {
        response = await SettingService.fetchMetaData();
        isDefaultSelected = true;
        setIsDefaultSelected(isDefaultSelected);
      }

      if (response && response.Success) {
        if (isDefaultSelected) {
          setMetaDataDetails(response.Data);
          setMetaTypeList([]);
        } else {
          setMetaTypeList(response.Data);
          setMetaDataDetails(null);
          setIsDefaultSelected(isDefaultSelected);
        }
        setLoading(false);
        setSelectedType(value);
        setSelectedMetaTypeId([]);
        setSelectedTypePropertyName(Object.keys(MetaTypeValue)[value - 1]);
      } else message.error(response.Data.APIMessage);
    } catch (e) {
      message.error("Some thing went wrong.");
    }
  };

  const getMetaTypeDetails = async (value) => {
    setLoading(true);
    let response;
    try {
      if (selectedType === MetaTypeValue.City)
        response = await CityService.getSEODetails({ Id: value });
      else if (selectedType === MetaTypeValue.Tag)
        response = await TagService.getSEODetails({ Id: value });
      if (response && response.Success) {
        setLoading(false);
        setMetaDataDetails(response.Data);
        setSelectedMetaTypeId(value);
      } else message.error(response.Data.APIMessage);
    } catch (e) {
      message.error("Some thing went wrong.");
    }
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  return (
    <>
      <Card className="gx-card breadcrumb-box" title="">
        <div className="title-sub">
          <h2>
            <IntlMessages
              id="settings.seoSettings"
              defaultMessage="SEO Settings"
            />
          </h2>
          <hr className="hr-line"></hr>
        </div>
        <div className="gx-main-content">
          <Form layout="horizontal">
            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 rest-info-box-lft">
              <Form.Item
                label={
                  <IntlMessages
                    id="settings.MetaType"
                    defaultMessage="Meta Type"
                  />
                }
                {...formItemLayout}
              >
                <Select
                  onChange={(e) => changeType(e)}
                  loading={loading}
                  disabled={loading}
                  placeholder="Select type for meta details"
                >
                  {metaType.map((v, i) => {
                    return (
                      <Option key={i} value={v.value}>
                        {v.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            {metaTypeList?.length > 0 && (
              <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 rest-info-box-lft">
                <Form.Item
                  label={
                    <IntlMessages
                      id="settings.MetaTypeList"
                      defaultMessage="List"
                    />
                  }
                  {...formItemLayout}
                >
                  <Select
                    onChange={(e) => getMetaTypeDetails(e)}
                    loading={loading}
                    disabled={loading || isDefaultSelected}
                    placeholder={`Select ${selectedTypePropertyName} for meta details`}
                    value={selectedMetaTypeId}
                  >
                    {metaTypeList.map((v, i) => {
                      return (
                        <Option key={i} value={v.Id}>
                          {v.Name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            )}
          </Form>
          {metaDataDetails && (
            <Spin spinning={loading}>
              <SEODetails
                key={selectedMetaTypeId}
                metaData={metaDataDetails}
                metaType={selectedType}
                metaTypeId={selectedMetaTypeId}
                isDefaultSelected={isDefaultSelected}
              />
            </Spin>
          )}
        </div>
      </Card>
    </>
  );
};

export default SEOSettings;
