import React, { useEffect } from "react";
import { Input, Form } from "antd";
import IntlMessages from "util/IntlMessages";
import { DeliveryServiceProviders } from "./common";

const DeliveryServiceProviderInput = (props) => {
  const { getFieldDecorator } = props.form;
  useEffect(() => {}, []);

  const conditionalServiceProviderRendering = (provider) => {
    let providerDetails = {};
    providerDetails = props.serviceInfo?.serviceProvidersDetails?.find(
      (a) => a.ServiceProvider == provider
    );
    // You can add more Providers here in Switch case for rendering
    switch (provider) {
      case DeliveryServiceProviders.Speedoy:
        return renderSpeedoyInputs(providerDetails);
      case DeliveryServiceProviders.Bykea:
        return renderBykeaInputs(providerDetails);
    }
  };

  // If any new service provider is added in future,
  // A new input renderer method will be created for that
  // and also when submitting the response we have to add
  // the additional changes in submit handler to post it.
  const renderSpeedoyInputs = (details) => {
    let hasDetails = false;
    if (details && details.ServiceProvider == DeliveryServiceProviders.Speedoy)
      hasDetails = true;

    return (
      <>
        <Form.Item label="Phone Number">
          {getFieldDecorator("Speedoy_PhoneNumber", {
            rules: [{ required: true, message: "Required!" }],
            initialValue: hasDetails ? details.PhoneNumber : "",
          })(<Input placeholder="Phone Number" />)}
        </Form.Item>
      </>
    );
  };

  const renderBykeaInputs = (details) => {
    let hasDetails = false;
    if (details && details.ServiceProvider == DeliveryServiceProviders.Bykea)
      hasDetails = true;

    return (
      <>
        <Form.Item label="Username">
          {getFieldDecorator("Bykea_Username", {
            rules: [{ required: true, message: "Required!" }],
            initialValue: hasDetails ? details.Username : "",
          })(<Input placeholder="Username" />)}
        </Form.Item>
        <Form.Item label="Password">
          {getFieldDecorator("Bykea_Password", {
            rules: [{ required: true, message: "Required!" }],
            initialValue: hasDetails ? details.Password : "",
          })(<Input placeholder="Password" />)}
        </Form.Item>
      </>
    );
  };

  // NOTE: Please go through the Submit handler "handleServiceInfoSubmit"
  // in config.js for posting new fields if added for new Service provider
  const renderServiceProviders = () => {
    return (
      <>
        <div className="title-sub">
          <h4>
            <span style={{ color: "red" }}>*</span>
            <IntlMessages
              id="config.serviceProviderDetails"
              defaultMessage="Service Provider Details"
            />
          </h4>
          <hr className="hr-line"></hr>
        </div>
        {props.serviceInfo?.serviceProviders?.map((p) => {
          return (
            <div>
              <div className="title-sub">
                <h5>
                  {" "}
                  {props.providerNameList?.find((a) => a.value == p).label}
                </h5>
              </div>
              {conditionalServiceProviderRendering(p)}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div>
      {props.serviceInfo?.serviceProviders?.length > 0 &&
        renderServiceProviders()}
      <hr />
    </div>
  );
};

export default React.memo(DeliveryServiceProviderInput);
