import axios from "axios";
import BaseService from "./baseService";

class TagServiceController extends BaseService {
  async fetch(body) {
    const response = await axios.post("/tag/fetch", body);
    return response.data;
  }
  async tagsNameList() {
    const response = await axios.post("/category/name-list");
    return response.data;
  }

  async getSEODetails(data) {
    const response = await axios.post("/category/get-seo-details", data);
    return response.data;
  }
}

export const TagService = new TagServiceController();
