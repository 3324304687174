//#region methods
export default function createRecurringTextHelper(startDate, endDate, intl) {
  let text = "";
  if (startDate) {
    let startFrom = intl.formatMessage({
      id: "menuInfo.startFrom",
      defaultMessage: "Start From",
    });
    text = `${startFrom} ${startDate}`;

    if (endDate) {
      let till = intl.formatMessage({
        id: "menuInfo.commaSeparatedTill",
        defaultMessage: ", Till",
      });

      text += `${till} ${endDate}`;
    }
  } else {
    if (endDate) {
      let till = intl.formatMessage({
        id: "menuInfo.till",
        defaultMessage: "Till",
      });

      text = `${till} ${endDate}`;
    }
  }

  return text;
}

//#endregion
//#region constants
export const DaysOptionList = [
  { name: "Mon", value: 1 },
  { name: "Tues", value: 2 },
  { name: "Wed", value: 3 },
  { name: "Thurs", value: 4 },
  { name: "Fri", value: 5 },
  { name: "Sat", value: 6 },
  { name: "Sun", value: 0 },
];

export const SelectedHoursList = [
  { label: "Set hours manually", value: 1 },
  { label: "Select 24 hours", value: 2 },
];

export const MenuAvailability = {
  None: 0,
  AlwaysAvailable: 1,
  ForSelectedHours: 2,
};

export const SelectedHours = {
  ManualHours: 1,
  FullDay: 2,
};

export const RecurrenceBy = {
  None: 0,
  Weekly: 2,
};

export const DatePickerProps = {
  displayWeekNumbers: true,
  weekNumber: "wk",
  calendarPosition: "top-left",
  fixMainPosition: true,
  fixMainPosition: true,
  placeholder: "Select date",
  editable: false,
};

export const TimingsType = {
  StartTime: 1,
  EndTime: 2,
};
//#endregion
