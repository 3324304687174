import React from "react";
import {useSelector} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const HorizontalNav = () => {

  const navStyle = useSelector(({settings}) => settings.navStyle);
  const pathname = useSelector(({settings}) => settings.pathname);

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";

    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (

    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal">
      <Menu.Item key="main/dashboard/crypto">
            <Link to="/main/dashboard/crypto">
              {/* <i className="icon icon-crypto"/> */}
              <IntlMessages id="Business Insights"/>
            </Link>
      </Menu.Item>
      <Menu.Item key="menuEditor">
            <Link to="/menuEditor">
              <IntlMessages id="Menu Editor"/>
            </Link>
      </Menu.Item> 
      <Menu.Item key="specials">
            <Link to="/specials"> 
              <IntlMessages id="Specials"/>
            </Link>
      </Menu.Item> 
      <Menu.Item key="restaurantDetails">
            <Link to="/restaurantDetails">
              <IntlMessages id="Restaurant Details"/>
            </Link>
      </Menu.Item> 
      <Menu.Item key="purchases">
            <Link to="/purchases">
              <IntlMessages id="Purchases"/>
            </Link>
      </Menu.Item> 
      <Menu.Item key="orders">
            <Link to="/orders">
              <IntlMessages id="Payout Reports"/>
            </Link>
      </Menu.Item>
      <Menu.Item key="userManagement">
            <Link to="/userManagement">
              <IntlMessages id="User Management"/>
            </Link>
      </Menu.Item>
      

      {/* <SubMenu className={getNavStyleSubMenuClass(navStyle)} key="main"
               title={<IntlMessages id="sidebar.main"/>}>

        <SubMenu className="gx-menu-horizontal" key="dashboard"
                 title={<span> <i className="icon icon-dasbhoard"/>
                         <IntlMessages id="sidebar.dashboard"/></span>}>
          <Menu.Item key="main/dashboard/crypto">
            <Link to="/main/dashboard/crypto">
              <i className="icon icon-crypto"/>
              <IntlMessages id="sidebar.dashboard.crypto"/>
            </Link>
          </Menu.Item>
          <Menu.Item key="main/dashboard/crm">
            <Link to="/main/dashboard/crm">
              <i className="icon icon-crm"/>
              <IntlMessages id="sidebar.dashboard.crm"/>
            </Link>
          </Menu.Item>
          <Menu.Item key="main/dashboard/listing">
            <Link to="/main/dashboard/listing">
              <i className="icon icon-listing-dbrd"/>
              <IntlMessages id="sidebar.dashboard.listing"/>
            </Link>
          </Menu.Item>
        </SubMenu>
        </SubMenu> */}

      {/* <SubMenu className={getNavStyleSubMenuClass(navStyle)} key="main"
               title={<IntlMessages id="sidebar.main"/>}>

      </SubMenu> */}

      

      

      

      

      

    </Menu>

  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;

