import React from "react";
import { Breadcrumb, Card, Collapse, Divider, Table, Modal, } from "antd";
import { InputNumber, Radio, Rate, Slider, Switch, message, Upload } from "antd";
import { AutoComplete, Button, Cascader, Checkbox, Col, Form, Icon, Input, Row, Select, Tooltip, formLayout } from "antd";

import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";




const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;




// import "./basic.less";


const Panel = Collapse.Panel;


// const text = `
//   A dog is a type of domesticated animal.
//   Known for its loyalty and faithfulness,
//   it can be found as a welcome guest in many households across the world.
// `;
const callback = (key) => {
  console.log(key);
};

// const Basic = () => {

// };
const columns = [
  {
    //   title: 'Name',
    dataIndex: 'name',
    key: 'name',
    //   render: text => <span className="gx-link">{text}</span>,
  },
  {
    //   title: 'Age',
    dataIndex: 'details',
    key: 'details',
  }

];

const data = [
  {
    key: '1',
    name: 'Item name',
    details: '1. Geang Keowan (Grön curry) (mycket starkt)',
  },
  {
    key: '2',
    name: 'Description',
    details: 'Tillagas med bambuskott, äggplantor och paprika.',
  },
  {
    key: '3',
    name: 'Price',
    details: 'SEK 125.00 including VAT 12%',
  },
  {
    key: '4',
    name: 'Image',
    details: <img src={require("assets/images/demo-img.png")} />,
  }
  ,
  {
    key: '5',
    name: 'Alcohol %',
    details: '1%',
  }
  ,
  {
    key: '6',
    name: 'POS ID',
    details: '001',
  }

];
// handleSubmit = (e) => {
//   e.preventDefault();
//   this.props.form.validateFields((err, values) => {
//     if (!err) {
//       console.log('Received values of form: ', values);
//     }
//   });
// };
//   upload-script start
const props = {
  name: 'file',
  action: '//jsonplaceholder.typicode.com/posts/',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
//   upload-script end

class menuEditor extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  }

  constructor() {
    super();
    this.state = {
      formLayout: 'horizontal',
      visible: false,
      addItem: false,
      addOption: false
    };
  }
  // form-scripts end
  // Modal-scripts start
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  addItem = (e) => {
    this.setState({
      addItem: !this.state.addItem,
    });
  };
  addOption = (e) => {
    this.setState({
      addOption: !this.state.addOption,
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  // Modal-scripts end
  render() {
    //   const {getFieldDecorator} = this.props.form;
    // const formItemLayout = {
    //   labelCol: {xs: 24, sm: 6},
    //   wrapperCol: {xs: 24, sm: 14},
    // };
    // form-scripts start
    const { formLayout } = this.state;
    const formItemLayout = formLayout === 'horizontal' ? {
      labelCol: { xs: 24, sm: 6 },
      wrapperCol: { xs: 24, sm: 16 },
    } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? {
      wrapperCol: { xs: 24, sm: { span: 14, offset: 6 } },
    } : null;
    // form-scripts end

    // MULTIPLE-scripts start
    const children = [];
    for (let i = 1; i < 10; i++) {
      children.push(<Option key={i.toString(36) + i}>{'test'}</Option>);
    }
    function handleChange(value) {
      console.log(`selected ${value}`);
    }
    // MULTIPLE-scripts End

    return (

      <div className="menu-editor-box card-pad-new">
        <div className="ant-row margin-btm-20">
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
            <div className="Breadcrumb-inner-box menu-ed">
              <Breadcrumb>
                <Breadcrumb.Item>Menu</Breadcrumb.Item>
                <Breadcrumb.Item>Thai House Wok - Folkungagatan</Breadcrumb.Item>
                <Breadcrumb.Item><span className="gx-link">View on Delivery Pizza.com</span></Breadcrumb.Item>
              </Breadcrumb>
              <div className="icon-more"><i class="icon icon-ellipse-h"></i></div>
            </div>
          </div>
        </div>

        <div className="ant-row">
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-10 ant-col-lg-10 ant-col-xl-8">
            <Card className="gx-card-widget">
              <div className="add-categories-lft-box">
                <div className="disp-inline">
                  <h2 className="title-inner">Categories</h2>
                  <span className="add-catgroey-btn"><a href="#" onClick={this.showModal}><i class="icon icon-add"></i> Add category</a></span>
                </div>
                <ul className="add-catgroe-ul">
                  <li>Thailändska grytor med kokosmjölk <span>(5)</span></li>
                  <li>Nudlar <span>(3)</span></li>
                  <li>Soppor <span>(4)</span></li>
                  <li>Wok <span>(8)</span></li>
                  <li>Sallader <span>(2)</span></li>
                  <li>Vegetariska rätter <span>(2)</span></li>
                  <li>Från grillen <span>(1)</span></li>
                </ul>

              </div>
            </Card>
          </div>
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-14 ant-col-lg-14 ant-col-xl-16">
            <Card className="gx-card-widget">
              <div className="collapse-rite-box">
                <div className="disp-inline">
                  <h2 className="title-inner">Items</h2>
                  <span className="add-catgroey-btn"><a href="#" onClick={this.addOption}><i class="icon icon-add"></i> Add option</a></span>
                </div>

                <Collapse defaultActiveKey={['1']} onChange={callback}>
                  <Panel header="1. Geang Keowan" key="1">
                    <p>
                      <div className="multi-dropdown-box">
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="Please select"
                          defaultValue={['Thailändska grytor med', 'Thailändska grytor med-2']}
                          onChange={handleChange}
                        >
                          {children}
                        </Select>

                      </div>
                      <div className="disp-inline">
                        <h2 className="title-inner">Item Details</h2>
                        <span className="add-catgroey-btn"><a href="#" onClick={this.addItem}>Edit</a></span>
                      </div>
                      <Table className="gx-table-responsive item-table-box tabil-box" columns={columns} dataSource={data} />

                    </p>

                  </Panel>
                  <Panel header="2. Geang Keowan" key="2">
                    <p>
                      <div className="multi-dropdown-box">

                      </div>
                      <div className="disp-inline">
                        <h2 className="title-inner">Item Details</h2>
                        <span className="add-catgroey-btn"><a href="#" onClick={this.showModal}>Edit</a></span>
                      </div>
                      <Table className="gx-table-responsive item-table-box tabil-box" columns={columns} dataSource={data} />

                    </p>
                  </Panel>

                </Collapse>

              </div>
            </Card>

          </div>
        </div>
        <Card className="gx-card breadcrumb-box d-none" title="">
          <div className="Breadcrumb-inner-box">
            <Breadcrumb>
              <Breadcrumb.Item>Menu</Breadcrumb.Item>
              <Breadcrumb.Item>Thai House Wok - Folkungagatan</Breadcrumb.Item>
              <Breadcrumb.Item><span className="gx-link">View on Delivery Pizza.com</span></Breadcrumb.Item>
            </Breadcrumb>
            <div className="icon-more"><i class="icon icon-ellipse-h"></i></div>
          </div>
          <div className="collaps-main">
            <div className="ant-row">
              <div className="add-categories-lft-box ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-lg-12 ant-col-xl-8">
                <div className="disp-inline">
                  <h2 className="title-inner">Categories</h2>
                  <span className="add-catgroey-btn"><a href="#" onClick={this.showModal}><i class="icon icon-add"></i> Add category</a></span>
                </div>
                <ul className="add-catgroe-ul">
                  <li>Thailändska grytor med kokosmjölk <span>(5)</span></li>
                  <li>Nudlar <span>(3)</span></li>
                  <li>Soppor <span>(4)</span></li>
                  <li>Wok <span>(8)</span></li>
                  <li>Sallader <span>(2)</span></li>
                  <li>Vegetariska rätter <span>(2)</span></li>
                  <li>Från grillen <span>(1)</span></li>
                </ul>

              </div>
              <div className="collapse-rite-box ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-16">
                <div className="disp-inline">
                  <h2 className="title-inner">Items</h2>
                  <span className="add-catgroey-btn"><a href="#" onClick={this.addOption}><i class="icon icon-add"></i> Add option</a></span>
                </div>

                <Collapse defaultActiveKey={['1']} onChange={callback}>
                  <Panel header="1. Geang Keowan" key="1">
                    <p>
                      <div className="multi-dropdown-box">
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="Please select"
                          defaultValue={['Thailändska grytor med', 'Thailändska grytor med-2']}
                          onChange={handleChange}
                        >
                          {children}
                        </Select>

                      </div>
                      <div className="disp-inline">
                        <h2 className="title-inner">Item Details</h2>
                        <span className="add-catgroey-btn"><a href="#" onClick={this.addItem}>Edit</a></span>
                      </div>
                      <Table className="gx-table-responsive item-table-box tabil-box" columns={columns} dataSource={data} />

                    </p>

                  </Panel>
                  <Panel header="2. Geang Keowan" key="2">
                    <p>
                      <div className="multi-dropdown-box">

                      </div>
                      <div className="disp-inline">
                        <h2 className="title-inner">Item Details</h2>
                        <span className="add-catgroey-btn"><a href="#" onClick={this.showModal}>Edit</a></span>
                      </div>
                      <Table className="gx-table-responsive item-table-box tabil-box" columns={columns} dataSource={data} />

                    </p>
                  </Panel>

                </Collapse>

              </div>
            </div>

          </div>
        </Card>

        {/* <Button type="primary" onClick={this.showModal}>Open</Button> */}
        {/* modal-edit */}
        <Modal
          title="Add Item"
          visible={this.state.addItem}
          onOk={this.addItem}
          onCancel={this.addItem}
        >
          <div className="item-detail-edit-box">
            {/* <p>Some contents...</p> */}
            <Form layout={formLayout}>
              {/* <FormItem
                                label="Form Layout"
                                {...formItemLayout}
                            >
                                <Radio.Group defaultValue="horizontal" onChange={this.handleFormLayoutChange}>
                                <Radio.Button value="horizontal">Horizontal</Radio.Button>
                                <Radio.Button value="vertical">Vertical</Radio.Button>
                                <Radio.Button value="inline">Inline</Radio.Button>
                                </Radio.Group>
                            </FormItem> */}
              <FormItem
                label="Item name"
                {...formItemLayout}
              >
                <Input placeholder="" />
              </FormItem>
              <FormItem
                label="Description"
                {...formItemLayout}
              >
                <Input placeholder="" />
              </FormItem>
              <FormItem
                label="Price"
                {...formItemLayout}
              >
                <Input placeholder="" />
              </FormItem>
              <FormItem
                label="Image"
                {...formItemLayout}
              >
                <Upload {...props}>
                  <Button className="btn-upload-cust">
                    <Icon type="upload" /> Upload
                                </Button>
                </Upload>
                {/* <Input placeholder=""/> */}
              </FormItem>
              <FormItem
                label="Alcohol %"
                {...formItemLayout}
              >
                <Input placeholder="" />
              </FormItem>
              <FormItem
                label="POS ID"
                {...formItemLayout}
              >
                <Input placeholder="" />
              </FormItem>
              {/* <FormItem
                                label="Field B"
                                {...formItemLayout}
                            >
                                <Input placeholder="input placeholder"/>
                            </FormItem> */}
              {/* <FormItem {...buttonItemLayout}>
                                <Button type="primary">Submit</Button>
                            </FormItem> */}
            </Form>

          </div>

        </Modal>{/* modal-edit-end */}

        {/* modal-edit */}
        <Modal
          title="Add Category"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="item-detail-edit-box">
            {/* <p>Some contents...</p> */}
            <Form layout={formLayout}>
              <FormItem
                label="Category name"
                {...formItemLayout}
              >
                <Input placeholder="" />
              </FormItem>

            </Form>

          </div>

        </Modal>
        {/* modal-add-option */}

        <Modal
          title="Add a new option"
          visible={this.state.addOption}
          onOk={this.addOption}
          onCancel={this.addOption}
        >
          <div className="item-detail-edit-box">
            <p>Option Details</p>
            <Form layout={formLayout}>
              <FormItem
                label="Optin type"
                {...formItemLayout}
              >
                {/* <Input placeholder=""/> */}
              </FormItem>
              <FormItem
                label="Name"
                {...formItemLayout}
              >
                <Input placeholder="" />
              </FormItem>

            </Form>

          </div>

        </Modal>


      </div>
    );
  }
}
export default menuEditor;