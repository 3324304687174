import {
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  WHITELISTING,
  RESTAURANT,
  WINDOW_WIDTH,
  DOMAINWHITELIST,
} from "constants/ActionTypes";
import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_FIXED,
  THEME_COLOR,
  THEME_TYPE,
  THEME_TYPE_SEMI_DARK,
  // THEME_TYPE_LITE
  //NAV_STYLE_DARK_HORIZONTAL
} from "../../constants/ThemeSetting";

import appState from "../../state/AppStateContainer";

const initialSettings = {
  navCollapsed: true,
  // navStyle: NAV_STYLE_DARK_HORIZONTAL,
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  // themeType: THEME_TYPE_LITE,
  themeColor: THEME_COLOR,

  pathname: "/",
  width: window.innerWidth,
  isDirectionRTL: false,
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
    cultureCode: "en-US",
  },
  restaurant: {},
  whitelisting: appState.loadWhitelisting(),
  domainWhitelist: appState.loadObj(DOMAINWHITELIST),
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType,
      };
    case THEME_COLOR:
      return {
        ...state,
        themeColor: action.themeColor,
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle,
      };
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType,
      };

    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      };
    case WHITELISTING:
      return {
        ...state,
        whitelisting: action.payload,
      };
    case RESTAURANT:
      return {
        ...state,
        restaurant: action.payload,
      };
    case DOMAINWHITELIST:
      return {
        ...state,
        domainWhitelist: action.payload,
      };
    default:
      return state;
  }
};

export default settings;
