import React from "react";
import { Select } from "antd";

const MultipleSelect = (props) => {
  return (
    <Select
      {...props}
      value={props.value}
      onChange={(value) => props.onChange(value)}
      mode="multiple"
      maxTagCount={props.maxTagCount}
      placeholder={props.placeholder}
      allowClear
      optionFilterProp="children"
      filterOption={(input, option) => {
        return (
          option.props?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
    >
      {props.children}
    </Select>
  );
};

export default MultipleSelect;
