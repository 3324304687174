import { AppSettings } from "../../../../constants/AppSettings";

export const DeliveryServiceModel = [
  {
    ServiceProvider: "Speedoy",
    Value: 1,
    Country: AppSettings.Country.Pakistan,
  },
  {
    ServiceProvider: "Speedoy",
    Value: 1,
    Country: AppSettings.Country.Sweden,
  },
  {
    ServiceProvider: "Speedoy",
    Value: 1,
    Country: AppSettings.Country.UAE,
  },
  {
    ServiceProvider: "Bykea",
    Value: 2,
    Country: AppSettings.Country.Pakistan,
  },
];

export const DeliveryServiceProviders = {
  Speedoy: 1,
  Bykea: 2,
};

export const SpeedoyDeliveryModels = {
  WithRider: 1,
  WithoutRider: 2,
};

export function getDeliveryServiceName(details) {
  if (details)
    return (
      DeliveryServiceModel.find((a) => a.Value == details?.ServiceProvider)
        .ServiceProvider || "-"
    );
  return "-";
}
