import axios from "axios";
import BaseService from "./baseService";

class MenuServiceController extends BaseService {
  async getMenuList(
    searchQuery,
    pageSize,
    pageNo,
    isAvailable,
    serviceInfoType
  ) {
    const body = {
      SearchQuery: searchQuery,
      PageSize: pageSize,
      PageNo: pageNo,
      IsAvailable: isAvailable,
      ServiceInfoType: serviceInfoType,
    };
    const response = await axios.post("/restaurant/getmenuwithfilters", body);
    return response.data.Data;
  }

  async addUpdateMenuList(formData) {
    const response = await axios.post("/restaurant/addupdatemenu", formData);
    return response.data;
  }

  async updateMenuImage(formData) {
    const response = await axios.post(
      "/restaurant/update-menu-image",
      formData
    );
    return response.data;
  }

  async uploadMenuItems(formData) {
    const response = await axios.post(
      "/restaurant/upload-menuItems-from-excel",
      formData
    );
    return response.data;
  }

  async deleteMenu(body) {
    const response = await axios.post("/restaurant/deletemenu", body);
    return response.data;
  }

  async updateMenuStatus(menuInfo) {
    const response = await axios.post(
      "/restaurant/updatemenustatusforra",
      menuInfo
    );
    return response.data;
  }

  async updateShowPriceStatus(menuInfo) {
    const response = await axios.post(
      "/restaurant/updateShowPriceStatusForRA",
      menuInfo
    );
    return response.data;
  }

  async updateMenuListSorting(sortedData) {
    const response = await axios.post(
      "/restaurant/addupdatemenulistsortorder",
      sortedData
    );
    return response.data;
  }

  async getAllOption() {
    const response = await axios.post("/restaurant/getalloptionsforra");
    return response.data.Data;
  }

  async addAssociateOptions(data) {
    const response = await axios.post("/restaurant/associateoptions", data);
    return response.data;
  }

  async getAllOptionTemplatesNames() {
    const response = await axios.get("/restaurant/get-option-templates-names");
    return response.data.Data;
  }

  async getAllOptionTemplete() {
    const response = await axios.get("/restaurant/getalloptiontemplates");
    return response.data.Data;
  }

  async getAllOptionTempleteInGroup() {
    const response = await axios.get(
      "/restaurant/getalloptiontemplateswithgrouping"
    );
    return response.data.Data;
  }

  async getOptionTemplateInGroup(templateId) {
    const response = await axios.get(
      "/restaurant/getalloptiontemplateswithgrouping?templateId=" + templateId
    );
    return response.data.Data;
  }

  async addAssociateOptionTemplate(data) {
    const response = await axios.post(
      "/restaurant/associateoptiontemplate",
      data
    );
    return response.data;
  }

  async addUpdateItemAvailability(data) {
    const response = await axios.post(
      "/restaurant/add-update-menu-availability",
      data
    );
    return response.data;
  }

  async getMenuItemAvailability(menuId, categoryId) {
    const response = await axios.get(
      `/restaurant/get-menu-availability/${menuId}/${categoryId}`
    );
    return response.data;
  }
}

export const MenuService = new MenuServiceController();
