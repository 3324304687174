import React from "react";
import { Breadcrumb, Card, Collapse, Divider, Table, Modal, Spin } from "antd";
import moment from "moment";
import {
  InputNumber,
  Radio,
  Rate,
  Slider,
  Switch,
  message,
  Upload,
} from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Tooltip,
  formLayout,
} from "antd";
import IntlMessages from "util/IntlMessages";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import { SettingService } from "../../services";
import TextArea from "antd/lib/input/TextArea";
const Search = Input.Search;
const FormItem = Form.Item;

class Settings extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      keys: {
        markActivity: "MarkActivity",
        htmlData: "HtmlData",
        systemAlerts: "SystemAlerts",
      },
      loading: false,
      formLayout: "horizontal",
      markActivity: undefined,
      scriptData: undefined,
      footerDescription: undefined,
      systemEmails: undefined,
    };
  }

  componentDidMount() {
    this.loadSetting();
  }
  loadSetting = async () => {
    try {
      var response = await SettingService.details({
        Key: this.state.keys.markActivity,
      });
      if (response && response.Success) {
        this.setState({
          markActivity: response.Data,
        });
      } else {
        message.error(response.APIMessage);
      }

      response = await SettingService.details({
        Key: this.state.keys.htmlData,
      });
      if (response && response.Success) {
        this.setState({
          scriptData: response.Data.Setting.ScriptData,
          footerDescription: response.Data.Setting.FooterDescription,
        });
      } else {
        message.error(response.APIMessage);
      }

      response = await SettingService.details({
        Key: this.state.keys.systemAlerts,
      });
      if (response && response.Success) {
        this.setState({
          systemEmails: response.Data.Setting.EmailsList,
        });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
  };

  saveSetting = async () => {
    this.setState({ loading: true });
    try {
      let setting = this.state.markActivity;
      setting.Setting.MarkActivity = !setting.Setting.MarkActivity;
      const response = await SettingService.save(setting);
      if (response && response.Success) {
        message.success("Saved!");
        this.setState({ markActivity: setting });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
    this.setState({ loading: false });
  };

  submit = async () => {
    this.setState({ loading: true });
    try {
      let setting = {
        Key: this.state.keys.htmlData,
        Setting: {
          ScriptData: this.state.scriptData,
          FooterDescription: this.state.footerDescription,
        },
      };

      let response = await SettingService.saveHtmlGlobal(setting);
      if (response && response.Success) {
        message.success("Saved!");
        //this.setState({ markActivity: setting });
      } else {
        message.error(response.APIMessage);
      }

      setting.Key = this.state.keys.systemAlerts;
      setting.Setting = {
        EmailsList: this.state.systemEmails,
      };
      response = await SettingService.saveSystemAlertSettings(setting);
      if (response && response.Success) {
        message.success("Saved!");
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
    this.setState({ loading: false });
  };

  render() {
    const { formLayout } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list option-list">
        <div className="payout-report-box option-add-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div>
              <div className="title-sub">
                <h2>
                  <IntlMessages
                    id="setting.markActivity"
                    defaultMessage="Mark Activity"
                  />
                </h2>
                <hr className="hr-line"></hr>
              </div>
              <div class="ant-row Platform-Charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <Switch
                    disabled={this.state.markActivity == undefined}
                    loading={this.state.loading}
                    checked={
                      this.state.markActivity &&
                      this.state.markActivity.Setting.MarkActivity
                    }
                    onClick={(value) => this.saveSetting()}
                  />
                </div>
              </div>
            </div>
            <br />
            <div>
              <div className="title-sub">
                <h2>
                  <IntlMessages
                    id="setting.scriptField"
                    defaultMessage="Scripts Meta-data"
                  />
                </h2>
                <hr className="hr-line"></hr>
              </div>
              <div class="ant-row Platform-Charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <TextArea
                    autoSize={{ minRows: 4 }}
                    value={this.state.scriptData}
                    onChange={(e) =>
                      this.setState({ scriptData: e.target.value })
                    }
                  ></TextArea>
                </div>
              </div>
              <br />
              <div className="title-sub">
                <h2>
                  <IntlMessages
                    id="setting.footerDescription"
                    defaultMessage="Footer Description"
                  />
                </h2>
                <hr className="hr-line"></hr>
              </div>
              <div class="ant-row Platform-Charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <TextArea
                    autoSize={{ minRows: 4 }}
                    value={this.state.footerDescription}
                    onChange={(e) =>
                      this.setState({ footerDescription: e.target.value })
                    }
                  ></TextArea>
                </div>
              </div>
              <br />
              <div className="title-sub">
                <h2>
                  <IntlMessages
                    id="setting.systemAlertSettings"
                    defaultMessage="System Alert Settings"
                  />
                </h2>
                <hr className="hr-line"></hr>
              </div>
              <div class="ant-row Platform-Charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <label>
                    <IntlMessages
                      id="setting.systemEmails"
                      defaultMessage="System Emails to alert (comma separted emails)"
                    />
                  </label>
                </div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <TextArea
                    autoSize={{ minRows: 4 }}
                    value={this.state.systemEmails}
                    onChange={(e) =>
                      this.setState({ systemEmails: e.target.value })
                    }
                  ></TextArea>
                </div>
              </div>
              <br />
              <Button
                loading={this.state.loading}
                onClick={this.submit}
                htmlType="submit"
                type="primary"
                class="ant-btn color-white ant-btn-primary"
              >
                <i class="icon icon-check"></i>
                <IntlMessages id="setting.save" defaultMessage="Save" />
              </Button>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const WrappedSettings = Form.create()(Settings);
export default WrappedSettings;
