import React from "react";
import moment from "moment";
import {
  Radio,
  Switch,
  Card,
  Tag,
  Table,
  message,
  Upload,
  Modal,
  Tooltip,
  Button,
  DatePicker,
  Form,
  Icon,
  Input,
  Tabs,
  Select,
  Checkbox,
} from "antd";
import { connect } from "react-redux";

import { InfoCircleFilled } from "@ant-design/icons";

import IntlMessages from "util/IntlMessages";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import { DomainWhitelistService, RestaurantService } from "../../services";
import TextArea from "antd/lib/input/TextArea";
import { AppSettings } from "../../constants/AppSettings";
import MultipleSelect from "../../components/Select/MultipleSelect";

const Search = Input.Search;
const { TabPane } = Tabs;
const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { RangePicker } = DatePicker;

// AddOption-tabile
const columnsRestaurants = [
  {
    title: (
      <IntlMessages
        id="domainwWhitelist.restaurant"
        defaultMessage="Restaurant Name"
      />
    ),
    dataIndex: "restaurant",
    key: "restaurant",
  },
  {
    title: (
      <IntlMessages
        id="domainwWhitelist.restaurantAddress"
        defaultMessage="Address"
      />
    ),
    dataIndex: "restaurantAddress",
    key: "restaurantAddress",
  },
];

const swedenColumnsRestaurants = [
  {
    title: (
      <IntlMessages
        id="domainwWhitelist.restaurant"
        defaultMessage="Restaurant Name"
      />
    ),
    dataIndex: "restaurant",
    key: "restaurant",
    width: 200,
  },
  {
    title: (
      <IntlMessages
        id="domainwWhitelist.restaurantAddress"
        defaultMessage="Address"
      />
    ),
    dataIndex: "restaurantAddress",
    key: "restaurantAddress",
    width: 250,
  },
  {
    title: (
      <IntlMessages
        id="domainwWhitelist.payeeAlias"
        defaultMessage="Payee Alias"
      />
    ),
    dataIndex: "payeeAlias",
    key: "payeeAlias",
    width: 200,
  },
];

const urlRouteType = [
  { label: "Restaurant", value: 0 },
  { label: "Mart", value: 1 },
  { label: "Shop", value: 2 },
];

const domainType = [
  { label: "Restaurant", value: 0 },
  { label: "B2B", value: 1 },
];

const domainLanguages = [
  { label: "English", value: 1 },
  { label: "Swden", value: 2 },
  { label: "Arabic", value: 4 },
];

class SaveDomainWhitelist extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      domainWhitelistId: "",
      domainWhitelist: {},
      restaurants: [],
      selectedRestaurants: [],
      filterdRestaurants: [],
      spin: false,
      restaurantSpin: true,
      formLayout: "horizontal",
      logoUrl: "",
      whitelistingLogo: null,
      wideLogoUrl: "",
      whitelistingWideLogo: null,
      faviconUrl: "",
      whitelistingFavicon: null,

      bannerUrl: "",
      whitelistingFooterBanner: null,

      tags: [],
      inputVisible: false,
      inputValue: "",
      swishToggle: false,
      showSwishModal: false,
      selectedModalRestaurantId: "",
      selectedModalRestaurantName: "",
      payeeSubmitLoading: false,
    };
  }

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    this.setState({ tags });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    console.log(tags);
    this.setState({
      tags,
      inputVisible: false,
      inputValue: "",
    });
  };

  saveInputRef = (input) => (this.input = input);

  async componentDidMount() {
    let domainWhitelistId = this.props.match.params.id;
    console.log(domainWhitelistId);
    if (domainWhitelistId != undefined) {
      await this.loadDomainWhitelist(domainWhitelistId);
    }
    await this.loadRestaurants();
  }

  loadRestaurants = async () => {
    try {
      var response =
        await RestaurantService.getActiveWhitelistRestaurantNameList();
      if (response && response.Success) {
        this.setState({
          restaurants: response.Data,
          filterdRestaurants: response.Data,
        });
      } else {
        message.error(response.APIMessage);
      }
    } catch {
      message.error("Something going wrong!");
    }
    this.setState({ restaurantSpin: false });
    console.log(this.state);
  };
  loadDomainWhitelist = async (id) => {
    try {
      this.setState({ domainWhitelistId: id });
      var response = await DomainWhitelistService.details({ Id: id });
      if (response && response.Success) {
        let pc = response.Data;
        this.setState({
          domainWhitelist: pc,
          swishToggle: pc?.ConfigInfo?.SwishSettings != null,
          tags: pc?.ConfigInfo?.SwishSettings?.SwishCertsFootPrints || [],
          selectedRestaurants: pc.Restaurants,
          logoUrl: pc.HeaderInfo?.Logo,
          wideLogoUrl: pc.HeaderInfo?.WideLogo,
          faviconUrl: pc.HeaderInfo?.Favicon,
          bannerUrl: pc.FooterInfo?.FooterBanner,
        });

        this.props.form.setFieldsValue({
          Name: pc.Name,
          DomainName: pc.DomainName,
          IsAvailable: pc.IsAvailable,
          "ConfigInfo.SwishSettings.PayeeAlias":
            pc.ConfigInfo?.SwishSettings?.PayeeAlias,
          "ConfigInfo.SwishSettings.CallbackUrl":
            pc.ConfigInfo?.SwishSettings?.CallbackUrl,
          "ConfigInfo.SwishSettings.RefundCallback":
            pc.ConfigInfo?.SwishSettings?.RefundCallback,
          "ConfigInfo.NoreplyEmail": pc.ConfigInfo?.NoreplyEmail,
          "ConfigInfo.LandingPageUrl": pc.ConfigInfo?.LandingPageUrl,
          "ConfigInfo.Type": pc.ConfigInfo?.Type || 0,
          "ConfigInfo.UrlRouteType": pc.ConfigInfo?.UrlRouteType || 0,
          "HeaderInfo.SeoSettings.MetaTitle":
            pc.HeaderInfo?.SeoSettings?.MetaTitle,
          "HeaderInfo.SeoSettings.MetaDescription":
            pc.HeaderInfo?.SeoSettings?.MetaDescription,
          "HeaderInfo.SeoSettings.SEOScriptKey":
            pc.HeaderInfo?.SeoSettings?.SEOScriptKey,
          "HeaderInfo.SeoSettings.MetaData":
            pc.HeaderInfo?.SeoSettings?.MetaData,
          "ConfigInfo.NoreplyEmail": pc.ConfigInfo?.NoreplyEmail,
          "FooterInfo.Contact.Email": pc.FooterInfo?.Contact?.Email,
          "FooterInfo.Contact.Address": pc.FooterInfo?.Contact?.Address,
          "FooterInfo.Contact.PhoneNumber": pc.FooterInfo?.Contact?.PhoneNumber,
          "FooterInfo.Contact.CompanyName": pc.FooterInfo?.Contact?.CompanyName,
          "FooterInfo.Contact.CompanyNumber":
            pc.FooterInfo?.Contact?.CompanyNumber,
          "FooterInfo.SocialLinks.Facebook":
            pc.FooterInfo?.SocialLinks?.Facebook,
          "FooterInfo.SocialLinks.Instagram":
            pc.FooterInfo?.SocialLinks?.Instagram,
          "FooterInfo.Applinks.IosAppStore":
            pc.FooterInfo?.Applinks?.IosAppStore,
          "FooterInfo.Applinks.AndroidPlayStore":
            pc.FooterInfo?.Applinks?.AndroidPlayStore,
          "FooterInfo.FooterDescription": pc.FooterInfo?.FooterDescription,
          EnableGuestUser: pc.EnableGuestUser,
          DomainLanguages: pc.ConfigInfo?.LanguagesList,
        });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
  };

  onRestaurantSelectChange = (selectedRestaurants) => {
    this.setState({ selectedRestaurants });
  };

  searchRestaurant = async (searchInput) => {
    let q = searchInput.target.value.toLowerCase();
    let filterResult = this.state.restaurants.filter((x) => {
      return x.Name.toLowerCase().includes(q);
    });
    this.setState({ filterdRestaurants: filterResult });
  };

  customRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  onChangeLogo = (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        console.log("uploading");
        nextState.selectedLogo = [info.file];
        break;
      case "done":
        console.log("done");
        nextState.whitelistingLogo = info.file;
        //nextState.selected = [info.file];
        this.logoURL(info);
        break;

      default:
        // error or removed
        nextState.whitelistingLogo = null;
        nextState.selectedLogo = [];
    }
    this.setState(() => nextState);
    console.log(this.state);
  };

  onChangeWideLogo = (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedWideLogo = [info.file];
        break;
      case "done":
        nextState.whitelistingWideLogo = info.file;
        this.wideLogoURL(info);
        break;
      default:
        nextState.whitelistingWideLogo = null;
        nextState.selectedWideLogo = [];
    }
    this.setState(() => nextState);
  };

  onChangeFaveicon = (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        console.log("uploading");
        nextState.selectedFaveicon = [info.file];
        break;
      case "done":
        console.log("done");
        nextState.whitelistingFavicon = info.file;
        //nextState.selectedFaveicon = [info.file];
        this.faviconURL(info);
        break;

      default:
        nextState.whitelistingFavicon = null;
        nextState.selectedFaveicon = [];
    }
    this.setState(() => nextState);
    console.log(this.state);
  };

  onChangeBanner = (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        console.log("uploading");
        nextState.selectedBanner = [info.file];
        break;
      case "done":
        console.log("done");
        nextState.whitelistingFooterBanner = info.file;
        //nextState.selectedBanner = [info.file];
        this.bannerURL(info);
        break;

      default:
        nextState.whitelistingFooterBanner = null;
        nextState.selectedBanner = [];
    }
    this.setState(() => nextState);
    console.log(this.state);
  };

  readFileDataUrl = async (info) => {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(info.file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };

  logoURL = async (info) =>
    this.setState({ logoUrl: [await this.readFileDataUrl(info)] });
  wideLogoURL = async (info) =>
    this.setState({ wideLogoUrl: [await this.readFileDataUrl(info)] });
  bannerURL = async (info) =>
    this.setState({ bannerUrl: [await this.readFileDataUrl(info)] });
  faviconURL = async (info) =>
    this.setState({ faviconUrl: [await this.readFileDataUrl(info)] });

  saveDomainWhitelist = async () => {
    let commonFields = [
      "Name",
      "DomainName",
      "IsAvailable",
      "EnableGuestUser",
      "DomainLanguages",
    ];
    let configFields = [
      "ConfigInfo.NoreplyEmail",
      "ConfigInfo.LandingPageUrl",
      "ConfigInfo.Type",
      "ConfigInfo.UrlRouteType",
    ];

    if (this.state.swishToggle) {
      configFields = [
        ...configFields,
        "ConfigInfo.SwishSettings.PayeeAlias",
        "ConfigInfo.SwishSettings.CallbackUrl",
        "ConfigInfo.SwishSettings.RefundCallback",
      ];
    }
    let headerFields = [
      "HeaderInfo.SeoSettings.MetaTitle",
      "HeaderInfo.SeoSettings.MetaDescription",
      "HeaderInfo.SeoSettings.SEOScriptKey",
      "HeaderInfo.SeoSettings.MetaData",
      "HeaderInfo.Logo",
      "HeaderInfo.WideLogo",
      "HeaderInfo.Favicon",
    ];
    let footerFields = [
      "FooterInfo.Contact.Email",
      "FooterInfo.Contact.Address",
      "FooterInfo.Contact.PhoneNumber",
      "FooterInfo.Contact.CompanyName",
      "FooterInfo.Contact.CompanyNumber",
      "FooterInfo.SocialLinks.Facebook",
      "FooterInfo.SocialLinks.Instagram",
      "FooterInfo.Applinks.IosAppStore",
      "FooterInfo.Applinks.AndroidPlayStore",
      "FooterInfo.FooterDescription",
    ];
    let fields = [
      ...commonFields,
      ...configFields,
      ...headerFields,
      ...footerFields,
    ];

    this.props.form.validateFields(fields, async (err, values) => {
      if (!err) {
        try {
          if (this.state.selectedRestaurants.length == 0) {
            message.error("Please select restaurant(s).");
            return;
          }
          let whitelistingLogo = this.state.whitelistingLogo
            ? this.state.whitelistingLogo.originFileObj
            : [];
          let whitelistingWideLogo = this.state.whitelistingWideLogo
            ? this.state.whitelistingWideLogo.originFileObj
            : [];
          let whitelistingFavicon = this.state.whitelistingFavicon
            ? this.state.whitelistingFavicon.originFileObj
            : [];
          let whitelistingFooterBanner = this.state.whitelistingFooterBanner
            ? this.state.whitelistingFooterBanner.originFileObj
            : [];

          const formData = new FormData();
          fields.forEach((x) =>
            formData.append(x, this.props.form.getFieldValue(x) ?? "")
          );
          this.state.selectedRestaurants.forEach((x) =>
            formData.append("Restaurants[]", x)
          );

          let languagesValues = 0;
          values.DomainLanguages.forEach((x) => (languagesValues += x));
          formData.append("ConfigInfo.Languages", languagesValues);

          if (this.state.swishToggle) {
            if (this.state.tags.length == 0) {
              message.error("Swish certificate thumbprints are missing!");
              return;
            }

            this.state.tags.forEach((x) =>
              formData.append(
                "ConfigInfo.SwishSettings.SwishCertsFootPrints[]",
                x
              )
            );
          }

          formData.append("logo", whitelistingLogo);
          formData.append("wideLogo", whitelistingWideLogo);
          formData.append("favicon", whitelistingFavicon);
          formData.append("banner", whitelistingFooterBanner);
          formData.append("Id", this.state.domainWhitelistId);

          this.setState({ spin: true });
          const response = await DomainWhitelistService.save(formData);
          if (response && response.Success) {
            message.success("Domain Whitelist Saved!");
            this.setState({
              spin: false,
              domainWhitelist: response.Data,
              domainWhitelistId: response.Data.Id,
            });
            this.props.history.push("/domain-whitelisting");
          } else if (response.APICode == 128) {
            message.error(response.APIMessage);
            this.props.form.setFieldsValue({ IsAvailable: false });
          } else {
            message.error(response.APIMessage);
          }
        } catch (ex) {
          message.error(ex.message);
        }
      } else {
        message.error("Some required fields are missing or have invalid data.");
      }
      this.setState({ spin: false });
    });
  };

  openSwishModal = (id, name, payeeAlias) => {
    this.setState({
      selectedModalRestaurantId: id,
      selectedModalRestaurantName: name,
      showSwishModal: true,
    });

    this.props.form.setFieldsValue({
      ModalPayeeAlias: payeeAlias || "",
    });
  };

  closeSwishModal = () => {
    this.setState({ showSwishModal: false, payeeSubmitLoading: false });
  };

  handlePayeeSubmit = async () => {
    this.props.form.validateFields(["ModalPayeeAlias"], async (err, values) => {
      if (!err) {
        try {
          this.setState({ payeeSubmitLoading: true });
          let formData = {
            RestaurantId: this.state.selectedModalRestaurantId,
            PayeeAlias: values.ModalPayeeAlias,
          };

          const response = await RestaurantService.saveRestaurantSwishSettings(
            formData
          );

          if (response && response.Success) {
            this.setState({ showSwishModal: false });
            this.loadRestaurants();
          } else message.error(response.APIMessage);
        } catch (ex) {
          message.error(ex.message);
        }
        this.setState({ payeeSubmitLoading: false });
      }
    });
  };

  render() {
    const {
      formLayout,
      tags,
      inputVisible,
      inputValue,
      selectedRestaurants,
      filterdRestaurants,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const restaurantsList = filterdRestaurants.map((v, i) => {
      return {
        key: v.Id,
        restaurant: v.Name,
        restaurantAddress: v.FullAddress,
        payeeAlias: v.SwishSettings?.PayeeAlias ? (
          <>
            <Button
              type="dashed"
              size="small"
              onClick={() =>
                this.openSwishModal(v.Id, v.Name, v.SwishSettings?.PayeeAlias)
              }
            >
              {v.SwishSettings?.PayeeAlias}
            </Button>
          </>
        ) : (
          <>
            <Button
              type="dashed"
              size="small"
              onClick={() => this.openSwishModal(v.Id, v.Name, null)}
            >
              <IntlMessages
                id="domainWhitelist.addPayeeAlias"
                defaultMessage="Add Payee Alias"
              />
            </Button>
          </>
        ),
      };
    });

    const rowSelection = {
      selectedRowKeys: selectedRestaurants,
      onChange: this.onRestaurantSelectChange,
    };

    const restaurantColumns =
      this.props.whitelisting.Country == AppSettings.Country.Sweden
        ? swedenColumnsRestaurants
        : columnsRestaurants;

    const formItemLayout =
      this.state.formLayout === "horizontal"
        ? {
            labelCol: { xs: 24, sm: 8 },
            wrapperCol: { xs: 24, sm: 14 },
          }
        : null;

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list option-list">
        <div className="payout-report-box option-add-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div>
              <div className="title-sub">
                <h2>
                  {this.state.domainWhitelistId == "" ? (
                    <IntlMessages
                      id="domainwWhitelist.create"
                      defaultMessage="Create Domain Whitelist"
                    />
                  ) : (
                    <IntlMessages
                      id="domainwWhitelist.update"
                      defaultMessage="Update Domain Whitelist"
                    />
                  )}
                </h2>
                <hr className="hr-line"></hr>
              </div>

              <Tabs defaultActiveKey="1">
                <TabPane tab="Common" key="1">
                  <div
                    class="ant-row Platform-Charges"
                    style={{ marginTop: 20 }}
                  >
                    <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                      <div className="detail-res-lft">
                        <div class="ant-row charge-form op-cnter new-opp-sa">
                          <div class="ant-col-8">
                            <FormItem
                              label={
                                <IntlMessages
                                  id="domainwWhitelist.name"
                                  defaultMessage="Name"
                                />
                              }
                            >
                              {getFieldDecorator("Name", {
                                rules: [
                                  {
                                    required: true,
                                    message: "Required!",
                                  },
                                ],
                              })(<Input placeholder="Name" />)}
                            </FormItem>
                          </div>

                          <div class="ant-col-8">
                            <FormItem
                              label={
                                <IntlMessages
                                  id="domainwWhitelist.domainName"
                                  defaultMessage="Domain"
                                />
                              }
                            >
                              {getFieldDecorator("DomainName", {
                                rules: [
                                  {
                                    required: true,
                                    message: "Required!",
                                  },
                                ],
                              })(<Input placeholder="example.com" />)}
                            </FormItem>
                          </div>
                          <div class="ant-col-8">
                            <FormItem
                              label={
                                <IntlMessages
                                  id="domainwWhitelist.isAvaiable"
                                  defaultMessage="Status"
                                />
                              }
                            >
                              {getFieldDecorator("IsAvailable", {
                                valuePropName: "checked",
                                initialValue: true,
                              })(<Switch />)}
                            </FormItem>
                          </div>
                          <div class="ant-col-8">
                            <FormItem
                              label={
                                <IntlMessages
                                  id="domainwWhitelist.expressCheckout"
                                  defaultMessage="Express Checkout"
                                />
                              }
                            >
                              {getFieldDecorator("EnableGuestUser", {
                                valuePropName: "checked",
                                initialValue: true,
                              })(<Switch />)}{" "}
                              <Tooltip
                                title={
                                  <IntlMessages
                                    id="domainwWhitelist.enableGuestUser"
                                    defaultMessage="Enable guest user"
                                  />
                                }
                                placement="right"
                              >
                                <InfoCircleFilled />
                              </Tooltip>
                            </FormItem>
                          </div>

                          <div class="ant-col-16">
                            <FormItem
                              label={
                                <IntlMessages
                                  id="domainwWhitelist.languages"
                                  defaultMessage="Languages"
                                />
                              }
                            >
                              {getFieldDecorator("DomainLanguages", {
                                rules: [
                                  {
                                    required: true,
                                    message: "Required!",
                                  },
                                ],
                              })(
                                <MultipleSelect
                                  style={{ minWidth: "130px" }}
                                  className="select-box m-b n-line-height"
                                  onChange={(value) => {}}
                                  maxTagCount={3}
                                  placeholder="Languages"
                                >
                                  {domainLanguages.map((d) => {
                                    return (
                                      <Option label={d.label} value={d.value}>
                                        {d.label}
                                      </Option>
                                    );
                                  })}
                                </MultipleSelect>
                              )}
                            </FormItem>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="title-sub">
                    <h2>
                      <IntlMessages
                        id="domainwWhitelist.restaurants"
                        defaultMessage="Restaurants"
                      />
                    </h2>
                    <hr className="hr-line"></hr>
                    <IntlMessages id="option.search">
                      {(text) => (
                        <Search
                          placeholder={text}
                          onChange={this.searchRestaurant}
                          style={{ width: 200 }}
                        />
                      )}
                    </IntlMessages>
                  </div>
                  <Table
                    scroll={{ y: 200 }}
                    loading={this.state.restaurantSpin}
                    rowSelection={rowSelection}
                    pagination={false}
                    className="gx-table-responsive user-manage-tabile"
                    columns={restaurantColumns}
                    dataSource={restaurantsList}
                  />
                </TabPane>
                <TabPane tab="Config" key="2">
                  <div
                    class="ant-row Platform-Charges"
                    style={{ marginTop: 20 }}
                  >
                    <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                      <h3>
                        <IntlMessages
                          id="domainwWhitelist.serviceEmails"
                          defaultMessage="Service Emails"
                        />
                      </h3>

                      <hr className="hr-line"></hr>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.noreplyEmail"
                                defaultMessage="Noreply Email"
                              />
                            }
                          >
                            {getFieldDecorator("ConfigInfo.NoreplyEmail", {
                              rules: [
                                {
                                  type: "email",
                                  message: "Invalid Email!",
                                },
                              ],
                            })(<Input placeholder="noreply@example.com" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.LandingPageUrl"
                                defaultMessage="Landing Page Url"
                              />
                            }
                          >
                            {getFieldDecorator("ConfigInfo.LandingPageUrl", {
                              rules: [
                                {
                                  type: "url",
                                  message: "Invalid Url!",
                                },
                              ],
                            })(<Input placeholder="Landing Page Url" />)}
                          </FormItem>
                        </div>
                      </div>
                    </div>
                    <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                      <h3>
                        <IntlMessages
                          id="domainwWhitelist.UrlRouteSettings"
                          defaultMessage="Url Route / Domain Settings"
                        />
                      </h3>

                      <hr className="hr-line"></hr>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.DomainType"
                                defaultMessage="Domain Type"
                              />
                            }
                          >
                            {getFieldDecorator("ConfigInfo.Type", {
                              initialValue: 0,
                            })(
                              <Select style={{ minWidth: "300px" }}>
                                {domainType.map((v, i) => {
                                  return (
                                    <Option key={i} value={v.value}>
                                      {v.label}
                                    </Option>
                                  );
                                })}
                              </Select>
                            )}
                          </FormItem>
                        </div>
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.UrlRouteType"
                                defaultMessage="Url Route Type"
                              />
                            }
                          >
                            {getFieldDecorator("ConfigInfo.UrlRouteType", {
                              initialValue: 0,
                            })(
                              <Select style={{ minWidth: "300px" }}>
                                {urlRouteType.map((v, i) => {
                                  return (
                                    <Option key={i} value={v.value}>
                                      {v.label}
                                    </Option>
                                  );
                                })}
                              </Select>
                            )}
                          </FormItem>
                        </div>
                      </div>
                    </div>
                    <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                      <h3>
                        <IntlMessages
                          id="domainwWhitelist.swishSettings"
                          defaultMessage="Swish Settings"
                        />
                        <Switch
                          onChange={(x) => {
                            this.setState({ swishToggle: x });
                          }}
                          checked={this.state.swishToggle}
                          style={{ marginLeft: 20 }}
                        />
                      </h3>

                      {this.state.swishToggle && (
                        <>
                          <hr className="hr-line"></hr>
                          <div class="ant-row charge-form op-cnter new-opp-sa">
                            <div class="ant-col-8">
                              <FormItem
                                label={
                                  <IntlMessages
                                    id="domainwWhitelist.PayeeAlias"
                                    defaultMessage="Payee Alias"
                                  />
                                }
                              >
                                {getFieldDecorator(
                                  "ConfigInfo.SwishSettings.PayeeAlias",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "Required!",
                                      },
                                    ],
                                  }
                                )(<Input placeholder="Payee Alias" />)}
                              </FormItem>
                            </div>
                            <div class="ant-col-8">
                              <FormItem
                                label={
                                  <IntlMessages
                                    id="domainwWhitelist.CallbackUrl"
                                    defaultMessage="Callback Url"
                                  />
                                }
                              >
                                {getFieldDecorator(
                                  "ConfigInfo.SwishSettings.CallbackUrl",
                                  {
                                    rules: [
                                      {
                                        type: "url",
                                        message: "Invalid Url!",
                                      },
                                      {
                                        required: true,
                                        message: "Required!",
                                      },
                                    ],
                                  }
                                )(<Input placeholder="Callback Url" />)}
                              </FormItem>
                            </div>
                            <div class="ant-col-8">
                              <FormItem
                                label={
                                  <IntlMessages
                                    id="domainwWhitelist.RefundCallback"
                                    defaultMessage="Refund"
                                  />
                                }
                              >
                                {getFieldDecorator(
                                  "ConfigInfo.SwishSettings.RefundCallback",
                                  {
                                    rules: [
                                      {
                                        type: "url",
                                        message: "Invalid Url!",
                                      },
                                      {
                                        required: true,
                                        message: "Required!",
                                      },
                                    ],
                                  }
                                )(<Input placeholder="Refund Callback" />)}
                              </FormItem>
                            </div>
                          </div>

                          <div>
                            <h5>Swish Certificate Thumbprints</h5>

                            {tags.map((tag, index) => {
                              const isLongTag = tag.length > 100;
                              const tagElem = (
                                <Tag
                                  key={tag}
                                  closable={true}
                                  onClose={() => this.handleClose(tag)}
                                >
                                  {isLongTag ? `${tag.slice(0, 100)}...` : tag}
                                </Tag>
                              );
                              return isLongTag ? (
                                <Tooltip title={tag} key={tag}>
                                  {tagElem}
                                </Tooltip>
                              ) : (
                                tagElem
                              );
                            })}
                            {inputVisible && (
                              <Input
                                ref={this.saveInputRef}
                                type="text"
                                size="small"
                                style={{ width: 217 }}
                                value={inputValue}
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputConfirm}
                                onPressEnter={this.handleInputConfirm}
                              />
                            )}
                            {!inputVisible && (
                              <Tag
                                onClick={this.showInput}
                                style={{
                                  background: "#fff",
                                  cursor: "pointer",
                                  borderStyle: "dashed",
                                }}
                              >
                                <Icon type="plus" /> New Swish Certificate
                                Thumbprint
                              </Tag>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Header" key="3">
                  <div
                    class="ant-row Platform-Charges"
                    style={{ marginTop: 20 }}
                  >
                    <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                      <h3>
                        <IntlMessages
                          id="domainwWhitelist.seoInfo"
                          defaultMessage="SEO Info"
                        />
                      </h3>
                      <hr className="hr-line"></hr>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.metaTitle"
                                defaultMessage="Title"
                              />
                            }
                          >
                            {getFieldDecorator(
                              "HeaderInfo.SeoSettings.MetaTitle",
                              {}
                            )(<Input placeholder="Meta Title" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.metaDescription"
                                defaultMessage="Description"
                              />
                            }
                          >
                            {getFieldDecorator(
                              "HeaderInfo.SeoSettings.MetaDescription",
                              {}
                            )(<Input placeholder="Meta Description" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.SEOScriptKey"
                                defaultMessage="SEO Script Key"
                              />
                            }
                          >
                            {getFieldDecorator(
                              "HeaderInfo.SeoSettings.SEOScriptKey",
                              {}
                            )(<Input placeholder="SEO script key" />)}
                          </FormItem>
                        </div>
                      </div>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col-24">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.metaData"
                                defaultMessage="Header meta data"
                              />
                            }
                          >
                            <div className="ant-col-24">
                              {getFieldDecorator(
                                "HeaderInfo.SeoSettings.MetaData",
                                {}
                              )(
                                <TextArea
                                  autoSize={{ minRows: 4 }}
                                  placeholder="Header meta data"
                                />
                              )}
                            </div>
                          </FormItem>
                        </div>
                      </div>

                      <h3>
                        <IntlMessages
                          id="domainwWhitelist.logo"
                          defaultMessage="Logo"
                        />
                      </h3>
                      <hr className="hr-line"></hr>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col-8">
                          <Upload
                            fileList={this.state.selectedLogo}
                            customRequest={this.customRequest}
                            onChange={this.onChangeLogo}
                            showUploadList={false}
                            beforeUpload={(file) => {
                              const isValidImage =
                                file.type === "image/jpeg" ||
                                file.type === "image/png" ||
                                file.type === "image/jpg";
                              if (!isValidImage) {
                                message.error(
                                  "You can only upload JPG or PNG file!"
                                );
                                return false;
                              } else {
                                return true;
                              }
                            }}
                          >
                            <Button>
                              <IntlMessages id="info.chooseLogo" />
                            </Button>
                          </Upload>
                          <div
                            className="detail-res-rite upload-img-box"
                            style={{ marginTop: "20px" }}
                          >
                            <img src={this.state.logoUrl} />
                          </div>
                        </div>
                      </div>

                      <h3 style={{ marginTop: "20px" }}>
                        <IntlMessages
                          id="domainwWhitelist.wideLogo"
                          defaultMessage="Wide Logo"
                        />
                      </h3>
                      <hr className="hr-line"></hr>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col-8">
                          <Upload
                            fileList={this.state.selectedWideLogo}
                            customRequest={this.customRequest}
                            onChange={this.onChangeWideLogo}
                            showUploadList={false}
                            beforeUpload={(file) => {
                              const isValidImage =
                                file.type === "image/jpeg" ||
                                file.type === "image/png" ||
                                file.type === "image/jpg";
                              if (!isValidImage) {
                                message.error(
                                  "You can only upload JPG or PNG file!"
                                );
                                return false;
                              } else {
                                return true;
                              }
                            }}
                          >
                            <Button>
                              <IntlMessages
                                id="info.chooseWideLogo"
                                defaultMessage="Choose Wide Logo"
                              />
                            </Button>
                          </Upload>
                          <div
                            className="detail-res-rite upload-img-box"
                            style={{ marginTop: "20px" }}
                          >
                            <img src={this.state.wideLogoUrl} />
                          </div>
                        </div>
                      </div>

                      <h3 style={{ marginTop: "20px" }}>
                        <IntlMessages
                          id="domainwWhitelist.favicon"
                          defaultMessage="Favicon"
                        />
                      </h3>
                      <hr className="hr-line"></hr>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col-8">
                          <Upload
                            fileList={this.state.selectedFaveicon}
                            customRequest={this.customRequest}
                            onChange={this.onChangeFaveicon}
                            showUploadList={false}
                            beforeUpload={(file) => {
                              const isValidImage =
                                file.type === "image/x-icon" ||
                                file.type === "image/jpeg" ||
                                file.type === "image/png" ||
                                file.type === "image/jpg";
                              if (!isValidImage) {
                                console.log(file.type);
                                message.error(
                                  "You can only upload JPG or PNG or ICON file!"
                                );
                                return false;
                              } else {
                                return true;
                              }
                            }}
                          >
                            <Button>
                              <IntlMessages
                                id="info.chooseFavicon"
                                defaultMessage="Choose Favicon"
                              />
                            </Button>
                          </Upload>
                          <div
                            className="detail-res-rite upload-img-box"
                            style={{ marginTop: "20px" }}
                          >
                            <img src={this.state.faviconUrl} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Footer" key="4">
                  <div
                    class="ant-row Platform-Charges"
                    style={{ marginTop: 20 }}
                  >
                    <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                      <h3>
                        <IntlMessages
                          id="domainwWhitelist.contact"
                          defaultMessage="Contact"
                        />
                      </h3>
                      <hr className="hr-line"></hr>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.address"
                                defaultMessage="Address"
                              />
                            }
                          >
                            {getFieldDecorator("FooterInfo.Contact.Address", {
                              rules: [
                                {
                                  required: true,
                                  message: "Required!",
                                },
                              ],
                            })(<Input placeholder="Address" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.phoneNumber"
                                defaultMessage="Phone"
                              />
                            }
                          >
                            {getFieldDecorator(
                              "FooterInfo.Contact.PhoneNumber",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "Required!",
                                  },
                                  {
                                    max: 15,
                                    message: "Maximun length is 15",
                                  },
                                ],
                              }
                            )(<Input placeholder="Phone Number" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.email"
                                defaultMessage="Email"
                              />
                            }
                          >
                            {getFieldDecorator("FooterInfo.Contact.Email", {
                              rules: [
                                {
                                  type: "email",
                                  message: "Invalid Email!",
                                },
                              ],
                            })(<Input placeholder="mail@example.com" />)}
                          </FormItem>
                        </div>
                      </div>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.companyName"
                                defaultMessage="Company Name"
                              />
                            }
                          >
                            {getFieldDecorator(
                              "FooterInfo.Contact.CompanyName"
                            )(<Input placeholder="Company Name" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.companyNumber"
                                defaultMessage="Company Number"
                              />
                            }
                          >
                            {getFieldDecorator(
                              "FooterInfo.Contact.CompanyNumber"
                            )(<Input placeholder="Company Number" />)}
                          </FormItem>
                        </div>
                      </div>

                      <h3>
                        <IntlMessages
                          id="domainwWhitelist.socialLinks"
                          defaultMessage="Social Links"
                        />
                      </h3>
                      <hr className="hr-line"></hr>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.facebook"
                                defaultMessage="Facebook"
                              />
                            }
                          >
                            {getFieldDecorator(
                              "FooterInfo.SocialLinks.Facebook",
                              {
                                rules: [
                                  {
                                    type: "url",
                                    message: "Invalid Url!",
                                  },
                                ],
                              }
                            )(<Input placeholder="https://www.domain.com" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.instagram"
                                defaultMessage="Instagram"
                              />
                            }
                          >
                            {getFieldDecorator(
                              "FooterInfo.SocialLinks.Instagram",
                              {
                                rules: [
                                  {
                                    type: "url",
                                    message: "Invalid Url!",
                                  },
                                ],
                              }
                            )(<Input placeholder="https://www.domain.com" />)}
                          </FormItem>
                        </div>
                      </div>

                      <h3>
                        <IntlMessages
                          id="domainwWhitelist.appLinks"
                          defaultMessage="App Links"
                        />
                      </h3>
                      <hr className="hr-line"></hr>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.android"
                                defaultMessage="Android"
                              />
                            }
                          >
                            {getFieldDecorator(
                              "FooterInfo.Applinks.AndroidPlayStore",
                              {
                                rules: [
                                  {
                                    type: "url",
                                    message: "Invalid Url!",
                                  },
                                ],
                              }
                            )(<Input placeholder="https://www.domain.com" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.ios"
                                defaultMessage="iOS"
                              />
                            }
                          >
                            {getFieldDecorator(
                              "FooterInfo.Applinks.IosAppStore",
                              {
                                rules: [
                                  {
                                    type: "url",
                                    message: "Invalid Url!",
                                  },
                                ],
                              }
                            )(<Input placeholder="https://www.domain.com" />)}
                          </FormItem>
                        </div>
                      </div>

                      <h3>
                        <IntlMessages
                          id="domainwWhitelist.footerBanner"
                          defaultMessage="Footer Banner"
                        />
                      </h3>
                      <hr className="hr-line"></hr>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col-8">
                          <Upload
                            accept=".jpeg, .png, .jpg"
                            fileList={this.state.selectedBanner}
                            onChange={this.onChangeBanner}
                            customRequest={this.customRequest}
                            showUploadList={false}
                            beforeUpload={(file) => {
                              const acceptTypes = [
                                "image/jpeg",
                                "image/png",
                                "image/jpg",
                              ];
                              if (!acceptTypes.includes(file.type)) {
                                message.error(
                                  "You can only upload JPG or PNG file!"
                                );
                                return false;
                              } else {
                                return true;
                              }
                            }}
                          >
                            <Button>
                              <IntlMessages
                                id="info.chooseBanner"
                                defaultMessage="Choose Footer Banner"
                              />
                            </Button>
                          </Upload>
                          <div
                            className="detail-res-rite upload-img-box"
                            style={{ marginTop: "20px" }}
                          >
                            <img src={this.state.bannerUrl} />
                          </div>
                        </div>
                      </div>

                      <h3>
                        <IntlMessages
                          id="domainwWhitelist.footerDescription"
                          defaultMessage="Footer Description"
                        />
                      </h3>
                      <hr className="hr-line"></hr>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col-8">
                          <FormItem
                            label={
                              <IntlMessages
                                id="domainwWhitelist.footerDescription"
                                defaultMessage="Description"
                              />
                            }
                          >
                            {getFieldDecorator("FooterInfo.FooterDescription", {
                              rules: [],
                            })(
                              <TextArea
                                autoSize={{ minRows: 4 }}
                                placeholder="Footer Description"
                              />
                            )}
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
            <div style={{ float: "right", marginTop: "10px" }}>
              <Button
                type="default"
                onClick={() => {
                  this.props.history.push("/domain-whitelisting");
                }}
              >
                <IntlMessages
                  id="domainwWhitelist.cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                loading={this.state.spin}
                onClick={this.saveDomainWhitelist}
                htmlType="submit"
                type="primary"
                class="ant-btn color-white ant-btn-primary"
              >
                <i class="icon icon-add"></i>
                {this.state.domainWhitelistId == "" ? (
                  <IntlMessages
                    id="domainwWhitelist.create"
                    defaultMessage="Create Domain Whitelist"
                  />
                ) : (
                  <IntlMessages
                    id="domainwWhitelist.update"
                    defaultMessage="Update Domain Whitelist"
                  />
                )}
              </Button>
            </div>
          </Card>
        </div>
        <Modal
          visible={this.state.showSwishModal}
          title={
            this.state.selectedModalRestaurantName || "Restaurant Payee Alias"
          }
          okText="Save"
          onOk={this.handlePayeeSubmit}
          okButtonProps={{ loading: this.state.payeeSubmitLoading }}
          onCancel={this.closeSwishModal}
        >
          <Form onSubmit={(e) => this.handlePayeeSubmit(e)} layout={formLayout}>
            <FormItem
              label={
                <IntlMessages
                  id="domainWhitelist.payeeAlias"
                  defaultMessage="Payee Alias"
                />
              }
              {...formItemLayout}
            >
              {getFieldDecorator(
                "ModalPayeeAlias",
                {}
              )(<Input placeholder="enter payee alias" type="text" />)}
            </FormItem>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  whitelisting: state.settings.whitelisting,
});
const WrappedSaveDomainWhitelist = Form.create()(SaveDomainWhitelist);
export default connect(mapStateToProps)(WrappedSaveDomainWhitelist);
