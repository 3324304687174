import axios from "axios";
import BaseService from "./baseService";

class SettingServiceController extends BaseService {
  async fetch(body) {
    const response = await axios.post("/setting/fetch", body);
    return response.data;
  }

  async details(body) {
    const response = await axios.post("/setting/details", body);
    return response.data;
  }

  async fetchSuperAdminSettings() {
    const response = await axios.post("/setting/fetch-super-admin-settings");
    return response.data;
  }
  async fetchCultureConfiguration() {
    const response = await axios.post("/setting/getconfigurationsettings");
    return response.data;
  }

  async save(optionData) {
    const response = await axios.post("/setting/save", optionData);
    return response.data;
  }

  async saveHtmlGlobal(optionData) {
    const response = await axios.post("/setting/saveHtmlGlobal", optionData);
    return response.data;
  }

  async fetchMetaData() {
    const response = await axios.get("/setting/fetch-meta-data");
    return response.data;
  }

  async saveSystemAlertSettings(data) {
    const response = await axios.post(
      "/setting/save-system-alert-settings",
      data
    );
    return response.data;
  }
}

export const SettingService = new SettingServiceController();
