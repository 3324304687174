import React from "react";
import { Breadcrumb, Card, Collapse, Divider, Table, Modal } from "antd";
import {
  InputNumber,
  Radio,
  Spin,
  DatePicker,
  Rate,
  Slider,
  Switch,
  message,
  Upload,
} from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Typography,
  Tooltip,
  formLayout,
} from "antd";
import { DomainWhitelistService } from "../../services";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import { Link } from "react-router-dom";
import AccessWhitelistModal from "./AccessWhitelist";
import InfiniteScrollTable from "./../../components/table/InfiniteScrollTable";
const reactStringReplace = require("react-string-replace");
const { Text } = Typography;
const { RangePicker } = DatePicker;
const Search = Input.Search;
const { confirm } = Modal;

const columns = [
  {
    title: <IntlMessages id="domainWhitelist.name" defaultMessage="Name" />,
    dataIndex: "name",
    key: "name",
  },
  {
    title: (
      <IntlMessages
        id="domainWhitelist.domainName"
        defaultMessage="Domain Name"
      />
    ),
    dataIndex: "domainName",
    key: "domainName",
  },
  {
    title: <IntlMessages id="domainWhitelist.Status" defaultMessage="Status" />,
    dataIndex: "status",
    key: "status",
  },
  {
    title: <IntlMessages id="domainWhitelist.action" defaultMessage="Action" />,
    dataIndex: "action",
    key: "action",
  },
];

class DomainWhiteList extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      filter: {
        SearchQuery: "",
        PageNo: 1,
        PageSize: 15,
      },
      domainWhitelists: [],
      loading: false,
      hasMore: true,
      formLayout: "horizontal",
    };
  }

  async componentDidMount() {
    let searchQuery = new URLSearchParams(this.props.location.search).get(
      "searchQuery"
    );
    if (searchQuery != null) {
      let filter = this.state.filter;
      filter.SearchQuery = searchQuery;
      this.setState({ filter: filter }, this.handleFilter);
    } else {
      this.handleFilter();
    }
  }

  handleFilter = async () => {
    this.setState({ loading: true });
    try {
      const response = await DomainWhitelistService.fetch(this.state.filter);
      if (response && response.Success) {
        let hasMore = response.Data.length > 0;
        if (!hasMore) message.warning("End!");
        let merge = [...this.state.domainWhitelists, ...response.Data];
        this.setState({ domainWhitelists: merge, hasMore: hasMore });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
    this.setState({ loading: false });
  };

  updateStatus = async (value, id, i) => {
    var domainWhitelists = this.state.domainWhitelists;
    domainWhitelists[i].SwitchLoading = true;
    this.setState({ domainWhitelists: domainWhitelists });

    const request = {
      Id: id,
      IsAvailable: value,
    };
    const response = await DomainWhitelistService.updateStatus(request);
    if (response && response.Success) {
      domainWhitelists[i].IsAvailable = value;
      message.success("Status Changed!");
    } else {
      message.error(response.APIMessage);
    }
    domainWhitelists[i].SwitchLoading = false;
    this.setState({ domainWhitelists: domainWhitelists, loading: false });
  };

  delete = async (id) => {
    const _this = this;
    confirm({
      title: "Do you really want to delete this?",
      icon: <ExclamationCircleOutlined />,
      content: "Click!",
      onOk: async () => {
        console.log("OK");
        await _this.deleteApi({ Id: id });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  deleteApi = async (deleteRequest) => {
    this.setState({ loading: true });
    const response = await DomainWhitelistService.remove(deleteRequest);
    if (response && response.Success) {
      message.success("Domain Whitelist Deleted!");
      let filter = this.state.filter;
      filter.PageNo = 1;
      this.setState({
        filter: filter,
        domainWhitelists: [],
      });
      this.handleFilter();
    } else {
      message.error("Failed!");
    }
    this.setState({ loading: false });
  };

  fetchMoreData = async (e) => {
    if (!this.state.loading) {
      let filter = this.state.filter;
      filter.PageNo = filter.PageNo + 1;
      this.setState(
        {
          filter: filter,
          loading: true,
        },
        this.handleFilter
      );
    }
  };

  searchHandler = async (searchInput) => {
    this.props.history.push("/domain-whitelisting?searchQuery=" + searchInput);
    let filter = this.state.filter;
    filter.PageNo = 1;
    filter.SearchQuery = searchInput;
    this.setState(
      {
        filter: filter,
        domainWhitelists: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  searchReset = (searchInput) => {
    if (searchInput.target.value == null || searchInput.target.value == "") {
      this.props.history.push("/domain-whitelisting");
      let filter = this.state.filter;
      filter.PageNo = 1;
      filter.SearchQuery = "";
      this.setState(
        {
          filter: filter,
          domainWhitelists: [],
          loading: true,
        },
        this.handleFilter
      );
    } else {
      let filter = this.state.filter;
      filter.SearchQuery = searchInput.target.value;
      this.setState({ filter });
    }
  };

  searchMark = (txt) => {
    return this.state.filter.SearchQuery == ""
      ? txt
      : reactStringReplace(txt, this.state.filter.SearchQuery, (match, i) => (
          <Text mark>{match}</Text>
        ));
  };

  render() {
    const domainWhitelistingMaps = this.state.domainWhitelists.map((v, i) => {
      return {
        key: i,
        name: this.searchMark(v.Name),
        domainName: this.searchMark(v.DomainName),
        status: (
          <Switch
            loading={v.SwitchLoading == true}
            checked={v.IsAvailable}
            onClick={(value) => this.updateStatus(value, v.Id, i)}
          />
        ),
        action: (
          <a className="action-btn">
            <i
              onClick={() =>
                this.props.history.push("domain-whitelisting/update/" + v.Id)
              }
              className="fa fa-pencil"
            ></i>
            <i
              onClick={() => this.delete(v.Id)}
              style={{ marginLeft: "20px" }}
              class="fa fa-trash"
            ></i>
          </a>
        ),
      };
    });

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list">
        <AccessWhitelistModal visible={true}></AccessWhitelistModal>
        <div className="payout-report-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div className="collaps-main">
              <div className="ant-row">
                <div className="search-box ant-col-md-18">
                  <IntlMessages id="option.search">
                    {(text) => (
                      <Search
                        placeholder={text}
                        onSearch={(value) => this.searchHandler(value)}
                        onChange={(value) => this.searchReset(value)}
                        style={{ width: 200 }}
                        value={this.state.filter.SearchQuery}
                      />
                    )}
                  </IntlMessages>
                </div>
                <div className="ant-col-md-6">
                  <div className="orders-item top-section filter-box ">
                    <a
                      onClick={() => {
                        this.props.history.push("domain-whitelisting/create");
                      }}
                      type="primary"
                      className="color-white btn btn-primary"
                    >
                      <i class="icon icon-add"></i>
                      <IntlMessages id="option.add" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="ant-row">
                <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
                  <InfiniteScrollTable
                    columns={columns}
                    dataSource={domainWhitelistingMaps}
                    loading={this.state.loading}
                    hasMore={this.state.hasMore}
                    height={"calc(100vh - 205px)"}
                    loadMore={this.fetchMoreData}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
export default DomainWhiteList;
