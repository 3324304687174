import React from "react";
import { Card, Col, Row, Table, message, Spin, Button } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";

import StackedBarChart from "../extensions/charts/recharts/bar/Components/StackedBarChart";
import DonutChart from "../extensions/charts/amchart/pie/Components/DonutChart";
import DonutChart2 from "../extensions/charts/amchart/pie/Components/DonutChart2";
import ColumnAndLineMix from "../extensions/charts/amchart/bar/Components/ColumnAndLineMix";
import IntlMessages from "util/IntlMessages";

import { DashboardService, RestaurantService } from "../../services";
import InfiniteScrollTable from "./../../components/table/InfiniteScrollTable";

const columns = [
  {
    title: <IntlMessages id="customer.firstName" defaultMessage="First Name" />,
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: <IntlMessages id="customer.lastName" defaultMessage="Last Name" />,
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: <IntlMessages id="customer.email" defaultMessage="Email" />,
    dataIndex: "email",
    key: "email",
  },
  {
    title: <IntlMessages id="customer.phone" defaultMessage="Phone Number" />,
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: <IntlMessages id="customer.orders" defaultMessage="Orders" />,
    dataIndex: "orders",
    key: "orders",
  },
];

const data = [
  {
    key: "1",
    name: "Average",
    money: "$ 300,000.00",
    address: "222",
    address1: "Average",
    address2: "10%",
    address3: "",
  },
  {
    key: "2",
    name: "Average",
    money: "$ 300,000.00",
    address: "222",
    address1: "Average",
    address2: "10%",
    address3: "",
  },
  {
    key: "3",
    name: "Average",
    money: "$ 300,000.00",
    address: "222",
    address1: "Average",
    address2: "10%",
    address3: "",
  },
  {
    key: "4",
    name: "Average",
    money: "$ 300,000.00",
    address: "222",
    address1: "Average",
    address2: "10%",
    address3: "",
  },
  {
    key: "5",
    name: "Average",
    money: "$ 300,000.00",
    address: "222",
    address1: "Average",
    address2: "10%",
    address3: "",
  },
  {
    key: "6",
    name: "Average",
    money: "$ 300,000.00",
    address: "222",
    address1: "Average",
    address2: "10%",
    address3: "",
  },
];

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      filter: {
        PageNo: 1,
        PageSize: 5,
      },
      totalOrders: [],
      totalSales: [],
      totalOrdersByStatus: [],
      newAndRepeatCustomers: [],
      businessOrdersByWeek: [],
      businessOrdersByMonth: [],
      topCustomers: [],
      hasMore: true,
      loading: true,
      startDate: new Date().setDate(new Date().getDate() - 29),
      seeMore: false,
    };
  }
  async componentDidMount() {
    let response = await DashboardService.getTotalOrderSummary_SA();

    if (response.Data) {
      let res = response.Data;

      this.setState({
        totalOrders: res.OrderCount,
        totalSales: res.SalesCount,
      });
    }

    response = await DashboardService.getOrderAndCustomerByStatus_SA();
    if (response.Data) {
      this.setState({
        totalOrdersByStatus: response.Data.OrderTypeCount,
        newAndRepeatCustomers: response.Data.CustomersByOrders,
      });
    }

    response = await DashboardService.getBusinessOrders_SA();
    if (response.Data) {
      this.setState({
        businessOrdersByWeek: response.Data.BusinessCountByWeek,
        businessOrdersByMonth: response.Data.BusinessCountByMonth,
      });
    }

    this.handleFilter();
  }

  handleFilter = async () => {
    this.setState({ loading: true });
    try {
      const response = await DashboardService.getTopCustomers_SA(
        this.state.filter
      );
      if (response && response.Success) {
        let hasMore = response.Data.TopCustomers.length > 0;
        if (!hasMore) message.warning("End!");
        let mergeCustomers = [
          ...this.state.topCustomers,
          ...response.Data.TopCustomers,
        ];
        this.setState({ topCustomers: mergeCustomers, hasMore: hasMore });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
    this.setState({ loading: false });
  };

  fetchMoreData = async (e) => {
    if (!this.state.loading) {
      let filter = this.state.filter;
      filter.PageNo = filter.PageNo + 1;
      this.setState(
        {
          filter: filter,
          loading: true,
        },
        this.handleFilter
      );
    }
  };

  render() {
    const customerList = this.state.topCustomers.map((v, i) => {
      return {
        key: i,
        firstName: v.FirstName || "",
        lastName: v.LastName || "",
        email: v.Email,
        phone: v.PhoneNumber,
        orders: (
          <Link
            to={
              "/orders?searchQuery=cid:" +
              v.Id +
              "&startDate=" +
              moment(this.state.startDate).format(
                this.props.whitelisting.AdminDateFormat
              )
            }
          >
            {v.OrdersCount}
          </Link>
        ),
      };
    });

    return (
      <div className="gx-main-content">
        <Row>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Card className="gx-card" title="Number of Orders">
              {<StackedBarChart data={this.state.totalOrders} labelX="Count" />}
            </Card>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Card className="gx-card" title="Sales Order Value">
              {
                <StackedBarChart
                  data={this.state.totalSales}
                  labelX="Sum"
                  fill="#f0932b"
                />
              }
            </Card>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Card className="gx-card" title="Repeat and New Customer">
              {
                <DonutChart
                  data={this.state.newAndRepeatCustomers}
                  title="CustomerType"
                  value="TotalCustomers"
                />
              }
            </Card>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Card className="gx-card" title="Total Orders by status">
              {
                <DonutChart2
                  data={this.state.totalOrdersByStatus}
                  title="OrderStatus"
                  value="OrderCount"
                />
              }
            </Card>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Card className="gx-card" title="Business by week">
              <ColumnAndLineMix data={this.state.businessOrdersByWeek} />
            </Card>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24}>
            <Card className="gx-card" title="Business by month">
              <ColumnAndLineMix data={this.state.businessOrdersByMonth} />
            </Card>
          </Col>
          <Col lg={24} md={12} sm={24} xs={24}>
            <Card title="Top 5 Customer">
              <InfiniteScrollTable
                columns={columns}
                dataSource={customerList}
                loading={this.state.loading}
                hasMore={this.state.hasMore && this.state.seeMore}
                height={"275px"}
                marginBottom={"20px"}
                loadMore={this.fetchMoreData}
              />

              {!this.state.seeMore && (
                <Button
                  style={{ margin: "auto", display: "block" }}
                  onClick={() => {
                    this.setState({ seeMore: true });
                    this.fetchMoreData();
                  }}
                >
                  See more
                </Button>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  whitelisting: state.settings.whitelisting,
});
export default connect(mapStateToProps)(Dashboard);
