import React from "react";
import { Breadcrumb, Card, Collapse, Divider, Table, Modal, Spin } from "antd";
import moment from "moment";
import {connect} from 'react-redux'
import {
  InputNumber,
  Radio,
  Rate,
  Slider,
  Switch,
  message,
  Upload,
} from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Tooltip,
  formLayout,
} from "antd";
import IntlMessages from "util/IntlMessages";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import { PromoCodeService, RestaurantService,CityService } from "../../services";
const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { RangePicker } = DatePicker;

// AddOption-tabile
const columnsRestaurants = [
  {
    title: <IntlMessages id="promocode.restaurant" defaultMessage="Restaurant Name" />,
    dataIndex: "restaurant",
    key: "restaurant",
  },
  {
    title: <IntlMessages id="promocode.city" defaultMessage="City" />,
    dataIndex: "city",
    key: "city",
  }
];

class SavePromoCode extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      promoCodeId: "",
      promoCode: {},
      restaurants: [],
      selectedRestaurants: [],
      filterdRestaurants: [],
      cityRestaurants:[],
      cities: [],
      selectedCities: [],
      spin: false,
      restaurantSpin:true,
      citySpin:true,
      formLayout: "horizontal"
    };
  }
  disabledStartDate = startValue => {
    const { To } = this.state.promoCode;
    if (!startValue || !To) {
      return false;
    }
    return startValue.valueOf() > To.valueOf();
  };

  disabledEndDate = endValue => {
    const { From } = this.state.promoCode;
    if (!endValue || !From) {
      return false;
    }
    return endValue.valueOf() <= From.valueOf();
  };

  onDateRangeChange = (field, value) => {
    let promoCode=this.state.promoCode;
    promoCode[field]=value;
    this.setState({
      promoCode: promoCode,
    },()=>console.log(this.state));
    
  };
  onStartChange = value => {
    this.onDateRangeChange('From', value);
  };

  onEndChange = value => {
    this.onDateRangeChange('To', value);
  };

  async componentDidMount() {
    let promoCodeId = this.props.match.params.id;
    console.log(promoCodeId);
    if (promoCodeId != undefined) {
      await this.loadPromoCode(promoCodeId);
    }
    await this.loadCities();
    await this.loadRestaurants();
    await this.init();
  }
  init=async()=>{
    if(this.state.selectedCities.length==0){
      let selected=this.state.cities.map(v=>v.Id);
      this.setState({selectedCities:selected});
    }
    if(this.state.selectedRestaurants.length==0){
      let selectedRestaurants=this.state.restaurants.filter(x=>this.state.selectedCities.includes(x.ServiceCityId));
      let selectedRestaurantsIds=selectedRestaurants?.map(v=>v.Id);
      this.setState({
        selectedRestaurants:selectedRestaurantsIds,
        filterdRestaurants:selectedRestaurants,
        cityRestaurants:selectedRestaurants
      });
    }else{
      let selectedRestaurants=this.state.restaurants.filter(x=>this.state.selectedCities.includes(x.ServiceCityId));
      this.setState({
        filterdRestaurants:selectedRestaurants,
        cityRestaurants:selectedRestaurants
      });
    }
  }
  loadCities=async()=>{
    try{
        var response=await CityService.cityNameList();
        if(response && response.Success){
            this.setState({cities:response.Data})
        }else{
          message.error(response.APIMessage);
        }
    }catch{
      message.error("Something going wrong!");
    }
    this.setState({citySpin:false});
  }

  loadRestaurants=async()=>{
      try{
          var response=await RestaurantService.getActiveRestaurantNameList();
          if(response && response.Success){
              this.setState({restaurants:response.Data})
          }else{
            message.error(response.APIMessage);
          }
      }catch{
        message.error("Something going wrong!");
      }
      this.setState({restaurantSpin:false});
      console.log(this.state);
  }
  loadPromoCode = async (id) => {
    try {
      this.setState({ promoCodeId: id });
      var response = await PromoCodeService.details({ Id: id });
      if (response && response.Success) {
        let pc = response.Data;
        pc.From=moment(pc.From);
        pc.To=moment(pc.To);
        this.setState({
          promoCode: pc,
          selectedRestaurants: pc.Restaurants,
          selectedCities:pc.Cities
        });

        this.props.form.setFieldsValue({
          Code: pc.Code,
          IsAvailable: pc.IsAvailable,
          Value: pc.Value,
          AllowPerCustomer:pc.PerCustomerCount==null?2:1,
          ValueType: pc.ValueType,
          From: moment(pc.From),
          To: moment(pc.To),
        });
        this.props.form.setFieldsValue({PerCustomerCount:pc.PerCustomerCount});
      } else {
        message.error(response.APIMessage);
      }
    } catch {
      message.error("Something going wrong!");
    }
  };

  onRestaurantSelectChange = selectedRestaurants => {
    this.setState({ selectedRestaurants });
  };

  searchRestaurant = async (searchInput) => {
    let q=searchInput.target.value.toLowerCase();
    let filterResult=this.state.cityRestaurants.filter((x)=>{
      return x.Name.toLowerCase().includes(q);
    })
    this.setState({filterdRestaurants:filterResult})
  }
  onCitySelectChange = selectedCities => {
    var cityRestaurants=this.state.restaurants.filter(x=>selectedCities.includes(x.ServiceCityId));
    var cityRestaurantsIds=cityRestaurants?.map(x=>x.Id);
    this.setState({ selectedCities,selectedRestaurants:cityRestaurantsIds,filterdRestaurants : cityRestaurants });
  };

  savePromoCode = async () => {
    this.props.form.validateFields(
      ["Code", "From", "To", "ValueType","AllowPerCustomer", "PerCustomerCount", "Value", "IsAvailable"],
      async (err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          if(this.state.selectedCities.length==0){
            message.error("Please select city(s).");
            return;
          }
          if(this.state.selectedRestaurants.length==0){
            message.error("Please select restaurant(s).");
            return;
          }
          const form = values;
          form.PerCustomerCount=form.AllowPerCustomer==1?form.PerCustomerCount:null;
          form.Restaurants =this.state.selectedRestaurants.length==this.state.cityRestaurants.length?[]:this.state.selectedRestaurants;
          form.Cities =this.state.selectedCities.length==this.state.cities.length?[]:this.state.selectedCities;
          form.Id=this.state.promoCodeId;
          this.setState({ spin: true });
          const response = await PromoCodeService.save(form);
          if (response && response.Success) {
            message.success("Promo Code Saved!");
            this.setState({
              spin: false,
              promoCode: response.Data,
              promoCodeId: response.Data.Id,
            });
            this.props.history.push("/promocodes");
          } 
          else if(response.APICode==115){
            message.error(response.APIMessage);
            this.props.form.setFieldsValue({IsAvailable:false});
          }
          else {
            message.error(response.APIMessage);
          }
        }
        this.setState({spin: false})
      }
    );
  };

  render() {
    const { formLayout } = this.state;
    const { getFieldDecorator,getFieldValue } = this.props.form;
    const { selectedRestaurants,filterdRestaurants } = this.state;
    const restaurantsList=filterdRestaurants.map((v,i)=>
    {
        return {
            key:v.Id,
            restaurant:v.Name,
            city:this.state.cities.find(x=>x.Id==v.ServiceCityId)?.Name
        }
    });

    const rowSelection = {
      selectedRowKeys:selectedRestaurants, 
      onChange: this.onRestaurantSelectChange
    };

    const discountValue=getFieldValue("ValueType")==1?
    {
      required: true,
      type: "number",
      min: 0,
      max:100,
      message: "Invalid Discount!",
    }
    :{
      required: true,
      type: "number",
      min: 0,
      message: "Invalid Discount!",
    };

    const perCustomerValue=getFieldValue("AllowPerCustomer")==1?
    {
      required: true,
      type: "number",
      min: 1,
      message: "Invalid Allow Count!",
    }:{};

    const showPerCustomerCount=getFieldValue("AllowPerCustomer")==1;

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list option-list">
        <div className="payout-report-box option-add-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div>
              <div className="title-sub">
                <h2>
                  {this.state.promoCodeId == "" ? (
                    <IntlMessages id="promocode.create" defaultMessage="Create Promo Code" />
                  ) : (
                    <IntlMessages id="promocode.update" defaultMessage="Update Promo Code" />
                  )}
                </h2>
                <hr className="hr-line"></hr>
              </div>
              <div class="ant-row Platform-Charges">
                <Form onSubmit={(e) => e.preventDefault()} layout={formLayout}>
                  <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                    <div className="detail-res-lft">
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col" style={{marginLeft:"50px"}}>
                          <FormItem
                            label={<IntlMessages id="promocode.code" defaultMessage="Code" />}
                          >
                            {getFieldDecorator("Code", {
                              rules: [
                                {
                                  required: true,
                                  message: "Required!",
                                },
                              ],
                            })(<Input placeholder="Promo Code" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col" style={{marginLeft:"50px"}}>
                          <FormItem
                            label={<IntlMessages id="promocode.value" defaultMessage="Discount" />}
                          >
                            {getFieldDecorator("Value", {
                              rules: [
                                discountValue
                              ],
                            })(<InputNumber placeholder="0" />)}
                          </FormItem>
                        </div>

                        <div class="ant-col" style={{marginLeft:"50px"}}>
                          <FormItem
                            label={<IntlMessages id="promocode.valueType" defaultMessage="Discount Type" />}
                          >
                            {getFieldDecorator("ValueType", {
                              rules: [
                                {
                                  required: true,
                                  message: "Required!",
                                },
                              ]
                            })(
                              <Radio.Group>
                                <Radio value={1}>Percent</Radio>
                                <Radio value={2}>Amount ({this.props.whitelisting.Currency})</Radio>
                              </Radio.Group>
                            )}
                          </FormItem>
                        </div>
                      </div>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col" style={{marginLeft:"50px"}}>
                          <FormItem
                            label={<IntlMessages id="promocode.from" defaultMessage="From" />}
                          >
                            {getFieldDecorator("From", {
                              rules: [
                                {
                                  required: true,
                                  message: "Required!",
                                },
                              ],
                            })(<DatePicker 
                              showTime={{
                                format: this.props.whitelisting.AdminShortTimeFormat
                              }}
                                format={this.props.whitelisting.AdminDateTimeFormat}
                                onChange={this.onStartChange}
                                disabledDate={this.disabledStartDate}
                             />)}
                          </FormItem>
                        </div>

                        <div class="ant-col" style={{marginLeft:"50px"}}>
                          <FormItem label={<IntlMessages id="promocode.to" defaultMessage="To" />}>
                            {getFieldDecorator("To", {
                              rules: [
                                {
                                  required: true,
                                  message: "Required!",
                                },
                              ],
                            })(<DatePicker 
                              showTime={{
                                format: this.props.whitelisting.AdminShortTimeFormat
                              }}
                              format={this.props.whitelisting.AdminDateTimeFormat}
                              disabledDate={this.disabledEndDate}
                              onChange={this.onEndChange}
                            />)}
                          </FormItem>
                        </div> 
                        <div class="ant-col" style={{marginLeft:"50px"}}>
                          <FormItem
                            label={<IntlMessages id="promocode.isAvaiable" defaultMessage="Status" />}
                          >
                            {getFieldDecorator("IsAvailable", {
                              valuePropName: "checked",
                              initialValue: true,
                            })(<Switch />)}
                          </FormItem>
                        </div>
                      </div>
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col" style={{marginLeft:"50px"}}>
                          <FormItem
                            label={<IntlMessages id="promocode.allowPerCustomer" defaultMessage="Allow Per Customer" />}
                          >
                            {getFieldDecorator("AllowPerCustomer", {
                              rules: [
                                {
                                  required: true,
                                  message: "Required!",
                                },
                              ]
                            })(
                              <Radio.Group>
                                <Radio value={1}>Limited</Radio>
                                <Radio value={2}>Unlimited</Radio>
                              </Radio.Group>
                            )}
                          </FormItem>
                        </div>
                        {showPerCustomerCount&&<div class="ant-col" style={{marginLeft:"50px"}}>
                          <FormItem >
                            {getFieldDecorator("PerCustomerCount", {
                              rules: [
                                perCustomerValue
                              ],
                            })(<InputNumber placeholder="0" />)}
                          </FormItem>
                        </div>}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>

                <div className="title-sub">
                <h2>
                  <IntlMessages id="promocode.restaurants" defaultMessage="Restaurants" />
                </h2>
                <hr className="hr-line"></hr>
                <IntlMessages id="option.search">
                    {text => (
                      <Search
                        placeholder={text}
                        onChange={this.searchRestaurant}
                        style={{ width: 200 }}
                      />
                    )}
                  </IntlMessages>

                  <Select
                  loading={this.state.citySpin}
        mode="multiple"
        placeholder="Select Cities"
        onChange={this.onCitySelectChange}
        value={this.state.selectedCities}
        style={{ width: '100%' }}
      >
        {this.state.cities.map(item => (
          <Select.Option key={item.Id} value={item.Id}>
            {item.Name}
          </Select.Option>
        ))}
      </Select>

              </div>
              <Table
                scroll={{y:200}}
                loading={this.state.restaurantSpin}
                rowSelection={rowSelection}
                pagination={false}
                className="gx-table-responsive user-manage-tabile"
                columns={columnsRestaurants}
                dataSource={restaurantsList}
              />



            </div>
            <div style={{float:"right",marginTop:"10px"}}>
              <Button type="default" onClick={()=>{this.props.history.push("/promocodes")}}><IntlMessages id="promocode.cancel" defaultMessage="Cancel" /></Button>
              <Button
                loading={this.state.spin}
                onClick={this.savePromoCode}
                htmlType="submit"
                type="primary"
                class="ant-btn color-white ant-btn-primary"
              >
                <i class="icon icon-add"></i>
                {this.state.promoCodeId == "" ? (
                  <IntlMessages id="promocode.create" defaultMessage="Create Promo Code" />
                ) : (
                  <IntlMessages id="promocode.update" defaultMessage="Update Promo Code" />
                )}
              </Button>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  whitelisting: state.settings.whitelisting
});
const WrappedSavePromoCode = Form.create()(SavePromoCode);
export default connect(mapStateToProps)(WrappedSavePromoCode);
