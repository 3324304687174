import React from "react";
import { Breadcrumb, Card, Collapse, Divider, Table, Modal } from "antd";
import {
  InputNumber,
  Radio,
  Spin,
  Badge,
  DatePicker,
  Rate,
  Slider,
  Switch,
  message,
  Upload,
} from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Typography,
  Tooltip,
  formLayout,
} from "antd";
import { PushMessageService } from "../../services";
import moment from "moment";
import { connect } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import { Link } from "react-router-dom";
import InfiniteScrollTable from "./../../components/table/InfiniteScrollTable";
import appState from "../../state/AppStateContainer";
import { fileToObject } from "antd/lib/upload/utils";
const reactStringReplace = require("react-string-replace");
const { Text } = Typography;
const { RangePicker } = DatePicker;
const Search = Input.Search;
const { confirm } = Modal;

const columns = [
  {
    title: <IntlMessages id="pushMessage.title" defaultMessage="Title" />,
    dataIndex: "title",
    key: "title",
  },
  {
    title: <IntlMessages id="pushMessage.message" defaultMessage="Message" />,
    dataIndex: "message",
    key: "message",
  },
  {
    title: <IntlMessages id="pushMessage.date" defaultMessage="Date" />,
    dataIndex: "date",
    key: "date",
  },
  {
    title: <IntlMessages id="pushMessage.audience" defaultMessage="Audience" />,
    dataIndex: "audience",
    key: "audience",
  },
  {
    title: <IntlMessages id="pushMessage.Status" defaultMessage="Status" />,
    dataIndex: "status",
    key: "status",
  },
  {
    title: <IntlMessages id="pushMessage.action" defaultMessage="Action" />,
    dataIndex: "action",
    key: "action",
  },
];

class PushMessageList extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      filter: {
        SearchQuery: "",
        PageNo: 1,
        PageSize: 15,
        From: undefined,
        To: undefined,
        RestaurantId: "",
      },
      pushMessages: [],
      loading: false,
      hasMore: true,
      formLayout: "horizontal",
    };
  }

  async componentDidMount() {
    let rId = appState.loadString("rId");
    let searchQuery = new URLSearchParams(this.props.location.search).get(
      "searchQuery"
    );

    if (this.props.tempUser && rId) {
      let filter = this.state.filter;
      filter.RestaurantId = rId;
      this.setState({ filter });
    }

    if (searchQuery != null) {
      let filter = this.state.filter;
      filter.SearchQuery = searchQuery;
      this.setState({ filter: filter }, this.handleFilter);
    } else {
      this.handleFilter();
    }
  }

  handleFilter = async () => {
    this.setState({ loading: true });
    try {
      const response = await PushMessageService.fetch(this.state.filter);
      if (response && response.Success) {
        let hasMore = response.Data.length > 0;
        if (!hasMore) message.warning("End!");
        let merge = [...this.state.pushMessages, ...response.Data];
        this.setState({ pushMessages: merge, hasMore: hasMore });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
    this.setState({ loading: false });
  };

  delete = async (id) => {
    const _this = this;
    confirm({
      title: "Do you really want to delete this?",
      icon: <ExclamationCircleOutlined />,
      content: "Click!",
      onOk() {
        console.log("OK");
        _this.deleteApi({ Id: id });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  deleteApi = async (deleteRequest) => {
    this.setState({ loading: true });
    const response = await PushMessageService.remove(deleteRequest);
    if (response && response.Success) {
      message.success("Message Deleted!");
      var filter = this.state.filter;
      filter.PageNo = 1;
      this.setState({ filter, pushMessages: [] }, this.handleFilter);
    } else {
      message.error("Failed!");
    }
  };

  fetchMoreData = async (e) => {
    if (!this.state.loading) {
      let filter = this.state.filter;
      filter.PageNo = filter.PageNo + 1;
      this.setState(
        {
          filter: filter,
          loading: true,
        },
        this.handleFilter
      );
    }
  };

  searchHandler = async (searchInput) => {
    this.props.history.push("/push-messages?searchQuery=" + searchInput);
    let filter = this.state.filter;
    filter.PageNo = 1;
    filter.SearchQuery = searchInput;
    this.setState(
      {
        filter: filter,
        pushMessages: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  rangeFilter = (value) => {
    let filter = this.state.filter;
    filter.PageNo = 1;
    filter.From = value.length && moment(value[0]._d);
    filter.To = value.length && moment(value[1]._d);
    filter.From = filter.From == 0 ? null : filter.From;
    filter.To = filter.To == 0 ? null : filter.To;
    this.setState(
      {
        filter: filter,
        pushMessages: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  searchReset = (searchInput) => {
    if (searchInput.target.value == null || searchInput.target.value == "") {
      this.props.history.push("/push-messages");
      let filter = this.state.filter;
      filter.PageNo = 1;
      filter.SearchQuery = "";
      this.setState(
        {
          filter: filter,
          pushMessages: [],
          loading: true,
        },
        this.handleFilter
      );
    } else {
      let filter = this.state.filter;
      filter.SearchQuery = searchInput.target.value;
      this.setState({ filter });
    }
  };

  searchMark = (txt) => {
    return this.state.filter.SearchQuery == ""
      ? txt
      : reactStringReplace(txt, this.state.filter.SearchQuery, (match, i) => (
          <Text mark>{match}</Text>
        ));
  };

  getAudienceBadge = (index) => {
    const badges = [
      <IntlMessages id="pushMessage.none" defaultMessage="None" />,
      <IntlMessages id="pushMessage.customers" defaultMessage="Customers" />,
      <IntlMessages id="pushMessage.riders" defaultMessage="Riders" />,
    ];
    return badges[index];
  };
  getStatusBadge = (index) => {
    const badges = [
      <Badge
        text={
          <>
            <IntlMessages id="pushMessage.none" defaultMessage="None" />
          </>
        }
      />,
      <Badge
        color="blue"
        status="processing"
        text={
          <>
            <IntlMessages id="pushMessage.sending" defaultMessage="Sending" />
          </>
        }
      />,
      <Badge
        color="green"
        text={
          <>
            <IntlMessages
              id="pushMessage.delivered"
              defaultMessage="Delivered"
            />
          </>
        }
      />,
      <Badge
        color="red"
        text={
          <>
            <IntlMessages
              id="pushMessage.deliveredWithErrors"
              defaultMessage="Delivered With Errors"
            />
          </>
        }
      />,
    ];
    return badges[index];
  };

  render() {
    const pushMessagesMaps = this.state.pushMessages.map((v, i) => {
      return {
        key: i,
        title: this.searchMark(v.Title),
        message: this.searchMark(v.Message),
        date: moment(v.CreateTime).format(
          this.props.whitelisting.AdminDateTimeFormat
        ),
        audience: this.getAudienceBadge(v.Audience),
        status: this.getStatusBadge(v.PushMessageStatus),
        action: (
          <a>
            <i
              onClick={() => this.delete(v.Id)}
              style={{ paddingLeft: "20px" }}
              class="fa fa-trash"
            ></i>
          </a>
        ),
      };
    });

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list">
        <div className="payout-report-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div className="collaps-main">
              <div className="ant-row">
                <div className="search-box ant-col-md-5">
                  <IntlMessages id="option.search">
                    {(text) => (
                      <Search
                        placeholder={text}
                        onSearch={(value) => this.searchHandler(value)}
                        onChange={(value) => this.searchReset(value)}
                        style={{ width: 200 }}
                        value={this.state.filter.SearchQuery}
                      />
                    )}
                  </IntlMessages>
                </div>
                <div className="search-box ant-col-md-13">
                  <div className="check-box-fill">
                    <ul>
                      <li>
                        <RangePicker
                          onChange={(value) => this.rangeFilter(value)}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ant-col-md-6">
                  <div className="orders-item top-section filter-box ">
                    <a
                      onClick={() => {
                        this.props.history.push("push-messages/send");
                      }}
                      type="primary"
                      className="color-white btn btn-primary"
                    >
                      <i class="icon icon-add"></i>
                      <IntlMessages
                        id="option.sendNew"
                        defaultMessage="Send New"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="ant-row">
                <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
                  <InfiniteScrollTable
                    columns={columns}
                    dataSource={pushMessagesMaps}
                    loading={this.state.loading}
                    hasMore={this.state.hasMore}
                    height={"calc(100vh - 205px)"}
                    loadMore={this.fetchMoreData}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  whitelisting: state.settings.whitelisting,
  tempUser: state.auth.tempUser,
});
export default connect(mapStateToProps)(PushMessageList);
