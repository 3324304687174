import React, { useState } from "react";
import {
  Modal,
  Upload,
  Popconfirm,
  message,
  Button,
  Icon,
  Input,
  Select,
  Card,
  Form,
} from "antd";
import { AuthSvc } from "../../services";
import _ from "lodash";
import IntlMessages from "util/IntlMessages";
import { FETCH_ALL_NOTES_SUCCESS } from "../../constants/ActionTypes";
import restaurantOrders from "../restaurantOrders";

class ChangePassword extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }
  async componentDidMount() {}

  submit = async (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      ["oldPassword", "newPassword", "retypePassword"],
      async (err, values) => {
        if (!err) {
          try {
            this.setState({ loading: true });
            const form = {
              NewPassword: values.newPassword,
              OldPassword: values.oldPassword,
            };

            var response = await AuthSvc.changePassword(form);

            if (response && response.Success) {
              message.success("Password changed!");
              this.props.form.resetFields();
            } else {
              message.error(response.APIMessage);
            }
          } catch (ex) {
            message.error(ex.APIMessage);
          } finally {
            this.setState({ loading: false });
          }
        }
      }
    );
  };

  validateForm = (rule, value, callback) => {
    const newPassword = this.props.form.getFieldValue("newPassword");

    if (value != newPassword) callback("retype password not same");

    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { autoCompleteResult } = this.state;
    return (
      <Card className="gx-card breadcrumb-box" title="">
        <div>
          <div className="ant-row hd-title gx-m-0">
            <div className="title-sub gx-mt-3">
              <h2>
                <IntlMessages id="info.changePassword" />
              </h2>
              <hr className="hr-line"></hr>
            </div>
            <div class="ant-row Platform-Charges">
              <Form onSubmit={this.submit} layout="horizontal">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-32 platform-charges-lft">
                  <div className="detail-res-lft">
                    <div class="ant-row charge-form op-cnter new-opp-sa">
                      <div class="ant-col" style={{ marginLeft: "50px" }}>
                        <Form.Item
                          label={
                            <IntlMessages
                              id="info.oldPassword"
                              defaultMessage="Old Password"
                            />
                          }
                        >
                          {getFieldDecorator("oldPassword", {
                            rules: [
                              {
                                required: true,
                                message: "Required!",
                              },
                            ],
                          })(
                            <Input
                              type="password"
                              placeholder="Type old password"
                            />
                          )}
                        </Form.Item>
                      </div>
                      <div class="ant-col" style={{ marginLeft: "50px" }}>
                        <Form.Item
                          label={
                            <IntlMessages
                              id="info.newPassword"
                              defaultMessage="New Password"
                            />
                          }
                        >
                          {getFieldDecorator("newPassword", {
                            rules: [
                              {
                                required: true,
                                message: "Required!",
                              },
                            ],
                          })(
                            <Input
                              type="password"
                              placeholder="Type new password"
                            />
                          )}
                        </Form.Item>
                      </div>
                      <div class="ant-col" style={{ marginLeft: "50px" }}>
                        <Form.Item
                          label={
                            <IntlMessages
                              id="info.retypePassword"
                              defaultMessage="Retype Password"
                            />
                          }
                        >
                          {getFieldDecorator("retypePassword", {
                            rules: [
                              {
                                required: true,
                                message: "Required!",
                              },
                              ,
                              { validator: this.validateForm },
                            ],
                          })(
                            <Input
                              type="password"
                              placeholder="Retype password"
                            />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                    <Button
                      loading={this.state.loading}
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

const ChangePasswordWrapped = Form.create()(ChangePassword);
export default ChangePasswordWrapped;
