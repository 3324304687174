import antdSV from "antd/lib/locale-provider/sv_SE";
import svMessages from "../locales/sv_SE.json";

const SvLang = {
  messages: {
    ...svMessages
  },
  antd: antdSV,
  locale: 'sw'
};
export default SvLang;
