import React from "react";
import { Breadcrumb, Card, Collapse, Divider, Table, Modal } from "antd";
import {
  InputNumber,
  Radio,
  Spin,
  DatePicker,
  Rate,
  Slider,
  Switch,
  message,
  Upload,
} from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Tooltip,
  formLayout,
} from "antd";
import { OptionService } from "../../services";
import moment from "moment";
import { connect } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";

// Switch script start
function onChange(checked) {
  console.log(`switch to ${checked}`);
}
// Switch script start

const { RangePicker } = DatePicker;
// search-script
const Search = Input.Search;
const { confirm } = Modal;

// search-script-end

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

// import "./basic.less";

const Panel = Collapse.Panel;

// const text = `
//   A dog is a type of domesticated animal.
//   Known for its loyalty and faithfulness,
//   it can be found as a welcome guest in many households across the world.
// `;
const callback = (key) => {
  console.log(key);
};

// const Basic = () => {

// };

const columns = [
  {
    title: <IntlMessages id="option.optionName" />,
    dataIndex: "optionName",
    key: "optionName",
  },
  {
    title: <IntlMessages id="option.type" />,
    dataIndex: "type",
    key: "type",
  },
  {
    title: <IntlMessages id="option.primary" />,
    dataIndex: "primary",
    key: "primary",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: <IntlMessages id="option.action" />,
    dataIndex: "action",
    key: "action",
  },
];

// AddOption-tabile
const columnsAddOption = [
  {
    title: "Items",
    dataIndex: "items",
    key: "items",
  },
  {
    title: "Price(Excl Of Tax)",
    dataIndex: "priceExcl",
    key: "priceExcl",
  },
];

const dataAddOption = [
  {
    key: "1",
    items: "Olives",
    priceExcl: "5",
  },
  {
    key: "2",
    items: "Onion",
    priceExcl: "5",
  },
  {
    key: "3",
    items: "Tomamtos",
    priceExcl: "5",
  },
];
// AddOption-tabile-end

// AddMultiOption-tabile
const columnsAddMultiOption = [
  {
    title: "Items",
    dataIndex: "items",
    key: "items",
  },
  {
    title: "Min",
    dataIndex: "min",
    key: "min",
  },
  {
    title: "Max",
    dataIndex: "max",
    key: "max",
  },
  {
    title: "Price(Excl Of Tax)",
    dataIndex: "priceExcl",
    key: "priceExcl",
  },
];

const dataAddMultiOption = [
  {
    key: "1",
    items: "Coke",
    min: "5",
    max: "5",
    priceExcl: "10",
  },
  {
    key: "2",
    items: "Sprinte",
    min: "5",
    max: "10",
    priceExcl: "10",
  },
  {
    key: "3",
    items: "Fanta",
    min: "5",
    max: "10",
    priceExcl: "10",
  },
];
// AddMultiOption-tabile-end

//   upload-script start
const props = {
  name: "file",
  action: "//jsonplaceholder.typicode.com/posts/",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
//   upload-script end

class optionList extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      search: "",
      isPrimary: undefined,
      type: 0,
      startTimeStamp: 0,
      endTimeStamp: 0,
      optionList: [],
      optionsList: [],
      spin: false,
      formLayout: "horizontal",
      visible: false,
      addItem: false,
      addOption: false,
    };
  }

  createOptionListing = (response) => {
    let optionList = [];
    for (let i = 0; i < response.length; i++) {
      optionList.push({
        key: i,
        optionName: response[i].Name,
        type:
          response[i].Type == 1 ? (
            <IntlMessages id="option.singleSelection" />
          ) : (
            <IntlMessages id="option.multiSelection" />
          ),
        primary: response[i].IsPrimary ? (
          <IntlMessages id="option.yes" />
        ) : (
          <IntlMessages id="option.no" />
        ),
        status: (
          <Switch
            defaultChecked={response[i].IsAvailable}
            onChange={(value) => this.updateOptionStatus(value, response[i].Id)}
          />
        ),
        action: (
          <a className="action-btn">
            <i
              onClick={() =>
                this.openMenuInfo(
                  response[i].Id,
                  response[i].Name,
                  response[i].IsPrimary,
                  response[i].IsAvailable,
                  response[i].MaxSelectable,
                  response[i].OptionItems,
                  response[i].Type
                )
              }
              className="fa fa-pencil"
            ></i>
            <i
              onClick={() => this.deleteOption(response[i].Id)}
              style={{ paddingLeft: "20px" }}
              class="fa fa-trash"
            ></i>
          </a>
        ),
      });
    }

    this.setState({
      optionList: optionList,
      spin: false,
    });
  };

  async componentDidMount() {
    this.setState({ spin: true });
    const body = {
      SearchQuery: "",
      PageSize: 0,
      PageNo: 0,
    };
    const response = await OptionService.getAllOptionsList(body);
    this.setState({ optionsList: response, spin: false });
  }

  updateOptionStatus = async (value, optionId, i) => {
    var ot = this.state.optionsList;
    ot[i].SwitchLoading = true;
    this.setState({ optionsList: ot });

    const optionInfo = {
      OptionId: optionId,
      OptionItemId: null,
      IsAvailable: value,
    };
    const response = await OptionService.updateOptionStatus(optionInfo);
    if (response && response.Success) {
      ot[i].IsAvailable = value;
      message.success("Status Changed!");
    } else if (response.APICode == 14) {
      ot[i].IsAvailable = true;
      Modal.error({
        title: response.APIMessage,
        content: (
          <span>
            Here is the associated list.{" "}
            <ul>
              {response.Data.map((v) => (
                <li>{v.Name}</li>
              ))}
            </ul>
          </span>
        ),
      });
    } else if (response.APICode == 114) {
      ot[i].IsAvailable = true;
      Modal.error({
        title: response.APIMessage,
        content: (
          <span>
            Here is the associated menu item list.{" "}
            <ul>
              {response.Data.map((v) => (
                <li>{v.Name}</li>
              ))}
            </ul>
          </span>
        ),
      });
    } else {
      message.error("Failed!");
    }

    ot[i].SwitchLoading = false;
    this.setState({ optionsList: ot, spin: false });
  };

  deleteOption = async (optionId) => {
    const _this = this;
    const optionInfo = {
      OptionId: optionId,
    };

    confirm({
      title: "Do you really want to delete this?",
      icon: <ExclamationCircleOutlined />,
      content: "Click!",
      onOk() {
        console.log("OK");
        _this.deleteApi(optionInfo);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  deleteApi = async (optionInfo) => {
    this.setState({ spin: true });
    const body = {
      SearchQuery: "",
      PageSize: 0,
      PageNo: 0,
    };

    const response = await OptionService.deleteOption(optionInfo);
    if (response && response.Success) {
      message.success("Option Deleted!");
    } else if (response && !response.Success && response.APICode == 14) {
      Modal.error({
        title: response.APIMessage,
        content: (
          <span>
            Here is the associated list.{" "}
            <ul>
              {response.Data.map((v) => (
                <li>{v.Name}</li>
              ))}
            </ul>
          </span>
        ),
      });
    } else if (response.APICode == 114) {
      Modal.error({
        title: response.APIMessage,
        content: (
          <span>
            Here is the associated menu item list.{" "}
            <ul>
              {response.Data.map((v) => (
                <li>{v.Name}</li>
              ))}
            </ul>
          </span>
        ),
      });
    } else {
      message.error("Failed!");
    }

    const rs = await OptionService.getAllOptionsList(body);
    this.setState({ optionsList: rs, spin: false });
  };

  searchHandler = async (searchInput) => {
    this.setState(
      {
        search: searchInput,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  primaryFilter = (e) => {
    this.setState(
      {
        isPrimary: e.target.checked,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  rangeFilter = (value) => {
    let start = value.length && moment(value[0]._d).unix();
    let end = value.length && moment(value[1]._d).unix();
    this.setState(
      {
        startTimeStamp: start,
        endTimeStamp: end,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  searchReset = async (searchInput) => {
    if (searchInput.target.value == "") {
      this.setState({
        search: "",
      });
      const body = {
        SearchQuery: "",
        PageSize: 0,
        PageNo: 0,
      };

      const response = await OptionService.getAllOptionsList(body);
      this.setState({ optionsList: response, spin: false });
    } else {
      this.setState({
        search: searchInput.target.value,
      });
    }
  };

  handleIsAvailable = (value) => {
    this.setState(
      {
        type: value,
      },
      () => {
        this.handleFilter();
      }
    );
  };

  handleFilter = async () => {
    this.setState({ spin: true });
    let { search, isPrimary, type, startTimeStamp, endTimeStamp } = this.state;

    const body = {
      SearchQuery: search,
      PageSize: 0,
      PageNo: 0,
      IsPrimaryOption: isPrimary,
      OptionType: type,
      StartTimeStamp: startTimeStamp,
      EndTimeStamp: endTimeStamp,
    };

    Object.keys(body).forEach((key) =>
      body[key] === undefined ? delete body[key] : {}
    );

    const response = await OptionService.getAllOptionsList(body);
    this.setState({
      optionsList: response,
      spin: false,
    });
  };

  openMenuInfo = (
    id,
    name,
    isPrimary,
    isAvailable,
    maxSelectable,
    optionItems,
    type
  ) => {
    this.props.history.push({
      pathname: type == 1 ? "/optionAddSingal" : "OptionAddMulty",
      info: {
        optionId: id,
        name: name,
        isPrimary: isPrimary,
        isAvailable: isAvailable,
        maxSelectable: maxSelectable,
        optionItems: optionItems,
      },
    });
  };

  clearFilter = async () => {
    this.setState({ spin: true });
    const body = {
      SearchQuery: "",
      PageSize: 0,
      PageNo: 0,
    };
    const response = await OptionService.getAllOptionsList(body);
    this.setState({
      optionsList: response,
      spin: false,
      search: "",
      type: 0,
      startTimeStamp: 0,
      endTimeStamp: 0,
      isPrimary: false,
    });
  };

  // form-scripts end
  // Modal-scripts start
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  addItem = (e) => {
    this.setState({
      addItem: !this.state.addItem,
    });
  };
  addOption = (e) => {
    this.setState({
      addOption: !this.state.addOption,
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  // Modal-scripts end
  render() {
    //   const {getFieldDecorator} = this.props.form;
    // const formItemLayout = {
    //   labelCol: {xs: 24, sm: 6},
    //   wrapperCol: {xs: 24, sm: 14},
    // };
    // form-scripts start
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 16 },
          }
        : null;
    const buttonItemLayout =
      formLayout === "horizontal"
        ? {
            wrapperCol: { xs: 24, sm: { span: 14, offset: 6 } },
          }
        : null;
    // form-scripts end

    // MULTIPLE-scripts start
    const children = [];
    for (let i = 1; i < 10; i++) {
      children.push(<Option key={i.toString(36) + i}>{"test"}</Option>);
    }
    function handleChange(value) {
      console.log(`selected ${value}`);
    }
    // MULTIPLE-scripts End

    const optionsList = this.state.optionsList.map((v, i) => {
      return {
        key: i,
        optionName: v.Name,
        type:
          v.Type == 1 ? (
            <IntlMessages id="option.singleSelection" />
          ) : (
            <IntlMessages id="option.multiSelection" />
          ),
        primary: v.IsPrimary ? (
          <IntlMessages id="option.yes" />
        ) : (
          <IntlMessages id="option.no" />
        ),
        status: (
          <Switch
            loading={v.SwitchLoading == true}
            checked={v.IsAvailable}
            onClick={(value) => this.updateOptionStatus(value, v.Id, i)}
          />
        ),
        action: (
          <a className="action-btn">
            <i
              onClick={() =>
                this.openMenuInfo(
                  v.Id,
                  v.Name,
                  v.IsPrimary,
                  v.IsAvailable,
                  v.MaxSelectable,
                  v.OptionItems,
                  v.Type
                )
              }
              className="fa fa-pencil"
            ></i>
            <i
              onClick={() => this.deleteOption(v.Id)}
              style={{ marginLeft: "20px" }}
              class="fa fa-trash"
            ></i>
          </a>
        ),
      };
    });

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list option-list option-list-vh">
        <div className="ant-row margin-btm-20 d-none">
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
            <div className="Breadcrumb-inner-box menu-ed">
              <Breadcrumb>
                <Breadcrumb.Item>Menu</Breadcrumb.Item>
                <Breadcrumb.Item>
                  Thai House Wok - Folkungagatan
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span className="gx-link">View on Delivery Pizza.com</span>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div className="icon-more">
                <i class="icon icon-ellipse-h"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="payout-report-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div className="Breadcrumb-inner-box d-none">
              <Breadcrumb>
                <Breadcrumb.Item>Payout reports</Breadcrumb.Item>
                <Breadcrumb.Item>
                  Thai House Wok - Folkungagatan
                </Breadcrumb.Item>
                {/* <Breadcrumb.Item><span className="gx-link">View on Delivery Pizza.com</span></Breadcrumb.Item> */}
              </Breadcrumb>
              {/* <div className="icon-more"><i class="icon icon-ellipse-h"></i></div> */}
            </div>
            <div className="collaps-main">
              <div className="ant-row">
                <div className="search-box ant-col-md-5">
                  <IntlMessages id="option.search">
                    {(text) => (
                      <Search
                        placeholder={text}
                        onSearch={(value) => this.searchHandler(value)}
                        onChange={(value) => this.searchReset(value)}
                        style={{ width: 200 }}
                        value={this.state.search}
                      />
                    )}
                  </IntlMessages>
                </div>
                <div className="search-box ant-col-md-13">
                  <div className="check-box-fill">
                    <ul>
                      <li>
                        <Button
                          onClick={(e) => this.clearFilter(e)}
                          type="button"
                          class="ant-btn color-white ant-btn-primary"
                        >
                          <IntlMessages id="option.reset" />
                        </Button>
                      </li>
                      <li>
                        <Checkbox
                          onChange={(e) => this.primaryFilter(e)}
                          checked={this.state.isPrimary}
                        >
                          <IntlMessages id="option.primary" />
                        </Checkbox>
                      </li>
                      <li>
                        <RangePicker
                          onChange={(value) => this.rangeFilter(value)}
                          format={this.props.whitelisting.AdminDateFormat}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ant-col-md-6">
                  <div className="orders-item top-section filter-box ">
                    <div className="drop-filter-cover">
                      <i class="fa fa-filter filter-icon"></i>
                      <Select
                        className="select-box"
                        onChange={(value) => this.handleIsAvailable(value)}
                        style={{ width: 155 }}
                        value={this.state.type}
                      >
                        {/* <Option value="Select"><IntlMessages id="option.type" /></Option> */}
                        <Option value={0}>
                          <IntlMessages id="option.allSelection" />
                        </Option>
                        <Option value={1}>
                          <IntlMessages id="option.singleSelection" />
                        </Option>
                        <Option value={2}>
                          <IntlMessages id="option.multiSelection" />
                        </Option>
                      </Select>
                    </div>
                    <a
                      onClick={() => {
                        this.props.history.push("optionAddSingal");
                      }}
                      type="primary"
                      className="color-white btn btn-primary"
                    >
                      <i class="icon icon-add"></i>
                      <IntlMessages id="option.add" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="ant-row">
                <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
                  {this.state.spin ? (
                    <Spin
                      style={{ marginLeft: "45%", marginRight: "45%" }}
                      size="large"
                      tip="Loading..."
                    />
                  ) : (
                    <Table
                      className="gx-table-responsive payout-report menu-cate-lisi-table"
                      columns={columns}
                      dataSource={optionsList}
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="payout-report-box option-add-box d-none">
          <Card className="gx-card breadcrumb-box" title="">
            <div>
              <div className="title-sub">
                <h2>Options</h2>
                <hr className="hr-line"></hr>
              </div>
              <div class="ant-row Platform-Charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <div className="detail-res-lft">
                    <div class="ant-row charge-form">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>Type :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-6 gx-mb-3">
                        <Select
                          className=""
                          defaultValue="Select-1"
                          onChange={handleChange}
                        >
                          <Option value="Select-1">
                            Single Selection Option
                          </Option>
                          <Option value="Select-2">
                            Multi Selection Option
                          </Option>
                        </Select>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>Disable/Enable :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-6 gx-mb-3">
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                    </div>
                    <div class="ant-row charge-form op-cnter">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>Name :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-6 gx-mb-3">
                        <Input placeholder="Topping" />
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <Checkbox>Primary</Checkbox>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>Max Selectable :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-2 gx-mb-3">
                        <Input
                          className="input-number"
                          type="number"
                          placeholder="0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="title-sub">
                <h2>Add Option Details</h2>
                <hr className="hr-line"></hr>
              </div>
              <div class="ant-row Platform-Charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <div className="detail-res-lft">
                    <div class="ant-row charge-form op-cnter">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>Item Name :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-6 gx-mb-3">
                        <Input placeholder="Olives" />
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-2 gx-mb-3 charge-lable">
                        <label>Price :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-6 gx-mb-3">
                        <Input type="text" placeholder="5" />
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-6 gx-mb-3 add-btn-rite">
                        <button
                          type="button"
                          class="ant-btn color-white ant-btn-primary"
                        >
                          <i class="icon icon-add"></i>
                          <span> Add</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="title-sub">
                <h2>Option Details</h2>
                <hr className="hr-line"></hr>
              </div>
              <Table
                className="gx-table-responsive user-manage-tabile"
                columns={columnsAddOption}
                dataSource={dataAddOption}
              />
              <div class="ant-row Platform-Charges delivery-charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <div className="detail-res-lft"></div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="payout-report-box option-add-multi-box d-none">
          <Card className="gx-card breadcrumb-box" title="">
            <div>
              <div className="title-sub">
                <h2>Options</h2>
                <hr className="hr-line"></hr>
              </div>
              <div class="ant-row Platform-Charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <div className="detail-res-lft">
                    <div class="ant-row charge-form">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>Type :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-6 gx-mb-3">
                        <Select
                          className=""
                          defaultValue="Select-2"
                          onChange={handleChange}
                        >
                          <Option value="Select-1">
                            Single Selection Option
                          </Option>
                          <Option value="Select-2">
                            Multi Selection Option
                          </Option>
                        </Select>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>Disable/Enable :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-6 gx-mb-3">
                        <Switch defaultChecked onChange={onChange} />
                      </div>
                    </div>
                    <div class="ant-row charge-form op-cnter">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>Name :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-6 gx-mb-3">
                        <Input placeholder="Drinks" />
                      </div>
                      {/*<div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                      <Checkbox>Primary</Checkbox>
                      </div>*/}
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>Max Selectable :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-2 gx-mb-3">
                        <Input
                          className="input-number"
                          type="number"
                          placeholder="0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="title-sub">
                <h2>Add Option Details</h2>
                <hr className="hr-line"></hr>
              </div>
              <div class="ant-row Platform-Charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <div className="detail-res-lft">
                    <div class="ant-row charge-form op-cnter">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>Item Name :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-6 gx-mb-3">
                        <Input placeholder="Fanta" />
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>Minimum Quantity :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-2 gx-mb-3">
                        <Input
                          className="input-number"
                          type="number"
                          placeholder="5"
                        />
                      </div>
                    </div>
                    <div class="ant-row charge-form op-cnter">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>Price :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-6 gx-mb-3">
                        <Input placeholder="5" />
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                        <label>Max Quantity :</label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-2 gx-mb-3">
                        <Input
                          className="input-number"
                          type="number"
                          placeholder="7"
                        />
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-sm-8 gx-mb-3 add-btn-rite">
                        <button
                          type="button"
                          class="ant-btn color-white ant-btn-primary"
                        >
                          <i class="icon icon-add"></i>
                          <span> Add</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="title-sub">
                <h2>Option Details</h2>
                <hr className="hr-line"></hr>
              </div>
              <Table
                className="gx-table-responsive user-manage-tabile"
                columns={columnsAddMultiOption}
                dataSource={dataAddMultiOption}
              />
              <div class="ant-row Platform-Charges delivery-charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <div className="detail-res-lft"></div>
                </div>
              </div>
            </div>
          </Card>
        </div>

        <Modal
          title="Add Category"
          visible={this.state.addItem}
          onOk={this.addItem}
          onCancel={this.addItem}
        >
          <div className="item-detail-edit-box">
            {/* <p>Some contents...</p> */}
            <Form layout={formLayout}>
              <FormItem label="Category Name" {...formItemLayout}>
                <Input placeholder="" />
              </FormItem>
              {/* <FormItem
                                label="Price"
                                {...formItemLayout}
                            >
                                <Input placeholder=""/>
                           </FormItem>*/}
              <FormItem label="Image" {...formItemLayout}>
                <Upload {...props}>
                  <Button className="btn-upload-cust">
                    <Icon type="upload" /> Upload
                  </Button>
                </Upload>
                {/* <Input placeholder=""/> */}
              </FormItem>
            </Form>
          </div>
        </Modal>
        {/* modal-edit-end */}

        {/* modal-edit */}
        <Modal
          title="Add Category"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="item-detail-edit-box">
            {/* <p>Some contents...</p> */}
            <Form layout={formLayout}>
              <FormItem label="Category name" {...formItemLayout}>
                <Input placeholder="" />
              </FormItem>
            </Form>
          </div>
        </Modal>
        {/* modal-add-option */}

        <Modal
          title="Add a new option"
          visible={this.state.addOption}
          onOk={this.addOption}
          onCancel={this.addOption}
        >
          <div className="item-detail-edit-box">
            <p>Option Details</p>
            <Form layout={formLayout}>
              <FormItem label="Optin type" {...formItemLayout}>
                {/* <Input placeholder=""/> */}
              </FormItem>
              <FormItem label="Name" {...formItemLayout}>
                <Input placeholder="" />
              </FormItem>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  whitelisting: state.settings.whitelisting,
});
export default connect(mapStateToProps)(optionList);
