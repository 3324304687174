import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Form,
  Icon,
  Input,
  message,
  Spin,
  Modal,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { AuthSvc, RestaurantService } from "../services";
import { FormattedMessage, injectIntl } from "react-intl";
import appState from "../state/AppStateContainer";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
//import * as Yup from "yup";

import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
  userChangePassword,
} from "appRedux/actions/Auth";
import { switchLanguage } from "../appRedux/actions/Setting"; //../../appRedux/actions/Setting

import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import { SelectionState } from "react-draft-wysiwyg";
import { AppSettings } from "../constants/AppSettings";

const FormItem = Form.Item;

const SignIn = (props) => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();
  const [spin, setSpin] = useState(false);
  const [showFPModal, setShowFPModal] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/");
    }
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    props.form.validateFields(["email", "password"], async (err, values) => {
      try {
        if (!err) {
          // dispatch(showAuthLoader());
          setSpin(true);
          const responseToken = await AuthSvc.createToken();
          if (responseToken && responseToken.data.APICode == 0) {
            await appState.setToken(responseToken.data.Data.Token);
          }

          const response = await AuthSvc.loginRestaurantAdmin(
            values.email,
            values.password,
            responseToken.data.Data.Token
          );

          if (response && response.data.Success) {
            appState.persistString("rId", response.data.Data.RestaurantId);
            dispatch(userSignIn(response.data.Data));
            setSpin(false);
          } else {
            message.error(response.data.APIMessage);
            setSpin(false);
            // let updatedToken = await appState.setToken(null);
          }
        }
      } catch (e) {
        setSpin(false);
        message.error(
          _.get(
            e,
            "response.data.APIMessage",
            "Unexpected error occured while processing your request."
          )
        );
      }
    });
  };

  const handleForgotPassSubmit = () => {
    props.form.validateFields(["ForgotEmail"], async (err, values) => {
      try {
        if (!err) {
          setLoading(true);
          const response = await AuthSvc.changeForgotPassword({
            Email: values.ForgotEmail,
          });

          if (response && response.Success) {
            dispatch(userChangePassword(response.Data));
            history.push("/ForgotPassword", { email: values.ForgotEmail });
          } else {
            message.error(response.data.APIMessage);
            // let updatedToken = await appState.setToken(null);
          }

          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        message.error(
          _.get(
            e,
            "response.data.APIMessage",
            "Unexpected error occured while processing your request."
          )
        );
      }
    });
  };

  const handleModalCancel = () => {
    setShowFPModal(false);
  };

  const { getFieldDecorator } = props.form;
  return (
    <div
      className="gx-app-login-wrap "
      style={{
        backgroundImage:
          "url(" + require("assets/images/home_section_1.jpg") + ")",
      }}
    >
      <div className="gx-app-login-container">
        <div className="hero_single">
          <img alt="example" src={require("assets/images/logo.png")} />
          {/* <h1><IntlMessages id="app.userAuth.signInHeading" /></h1>
        <p><IntlMessages id="app.userAuth.signInDescription" /></p> */}
        </div>
        <div className="gx-app-login-main-content">
          {/* <div className="gx-app-logo-content"> */}
          {/* <div className="gx-app-logo-content-bg">
              <img src={require('assets/images/log-bg.jpg')} alt='Neature' />
            </div> */}
          {/* <div className="gx-app-logo-wid"> */}
          {/* <h2 onClick={() => {
                dispatch(switchLanguage(
                  {
                    languageId: 'swedish',
                    locale: 'sw',
                    name: 'Swedish',
                    icon: 'sw'
                  }
                ))
              }}>
                <FormattedMessage id="login.signIn" defaultMessage="Login English" />
              </h2> */}
          {/* <h1><IntlMessages id="app.userAuth.signInHeading" /></h1>
              <p><IntlMessages id="app.userAuth.signInDescription" /></p> */}
          {/* <p><IntlMessages id="app.userAuth.getAccount" /></p> */}
          {/* </div>
            <div className="gx-app-logo">
              <img alt="example" src={require("assets/images/logo.png")} />
            </div>
          </div> */}
          <div className="gx-app-login-content">
            <Form
              onSubmit={handleSubmit}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem>
                {getFieldDecorator("email", {
                  // initialValue: "minpizza.online@gmail.com",
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ],
                })(<Input placeholder="Email" />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("password", {
                  // initialValue: "pass123#@",
                  rules: [
                    { required: true, message: "Please input your Password!" },
                  ],
                })(<Input type="password" placeholder="Password" />)}
              </FormItem>
              {/* <FormItem>
                {getFieldDecorator('remember', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(
                  <Checkbox><IntlMessages id="appModule.iAccept" /></Checkbox>
                )}
                <span className="gx-signup-form-forgot gx-link"><IntlMessages
                  id="appModule.termAndCondition" /></span>
              </FormItem> */}
              {/* <FormItem> */}
              {
                // spin ? <Spin /> :
                <Button
                  type="primary"
                  className="gx-mb-0"
                  htmlType="submit"
                  loading={spin}
                >
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              }
              {/* <span><IntlMessages id="app.userAuth.or" /></span> <Link to="/signup"><IntlMessages
                  id="app.userAuth.signUp" /></Link> */}
              {/* </FormItem> */}
              {/* <div className="gx-flex-row gx-justify-content-between">
                <span><IntlMessages id="app.userAuth.connectWith" /></span>
                <ul className="gx-social-link">
                  <li>
                    <Icon type="google" onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userGoogleSignIn());
                    }} />
                  </li>
                  <li>
                    <Icon type="facebook" onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userFacebookSignIn());
                    }} />
                  </li>
                  <li>
                    <Icon type="github" onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userGithubSignIn());
                    }} />
                  </li>
                  <li>
                    <Icon type="twitter" onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userTwitterSignIn());
                    }} />
                  </li>
                </ul>
              </div> 
             <span
                className="gx-text-light gx-fs-sm"> demo user email: 'demo@example.com' and password: 'demo#123'</span> */}
              <Button
                block
                type="link"
                onClick={() => {
                  setShowFPModal(true);
                }}
              >
                Forgot Password
              </Button>
            </Form>

            {/* Modal for forgot password */}
            <Modal
              title="Forgot Password"
              visible={showFPModal}
              footer={[
                <Button
                  key="back"
                  disabled={loading}
                  onClick={handleModalCancel}
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleForgotPassSubmit}
                >
                  Submit
                </Button>,
              ]}
            >
              <Form onSubmit={handleSubmit}>
                <Form.Item
                  wrapperCol={{ sm: 24 }}
                  style={{ width: "100%", marginRight: 0 }}
                >
                  {getFieldDecorator("ForgotEmail", {
                    rules: [
                      { required: true, message: "Please input your Email!" },
                      { type: "email", message: "Please provide valid Email!" },
                    ],
                  })(<Input type="email" placeholder="Verification Email" />)}
                </Form.Item>
              </Form>
            </Modal>
          </div>

          {/* {spin ?
            <div className="gx-loader-view">
              <CircularProgress />
            </div> : null} */}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(SignIn);

export default WrappedNormalLoginForm;
