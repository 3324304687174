const EXPIRED_TIME = "_expiredTime";

class SessionHandler {
  constructor({ timeout, onTimeout, onExpired }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;

    try {
      const expiredTime = parseInt(localStorage.getItem(EXPIRED_TIME), 10);
      if (expiredTime > 0 && expiredTime < Date.now()) {
        onExpired();
        return;
      }
    } catch {
      this.updateExpiredTime();
    }

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      try {
        const expiredTime = parseInt(localStorage.getItem(EXPIRED_TIME), 10);
        if (expiredTime < Date.now()) {
          if (this.onTimeout) {
            this.onTimeout();
            this.cleanUp();
          }
        }
      } catch {
        this.updateExpiredTime();
      }
    }, 150000); //150000 ms interval over 2.5 mins
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem(EXPIRED_TIME, Date.now() + this.timeout * 1000);
    }, 300);
  }

  tracker() {
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  cleanUp() {
    localStorage.removeItem(EXPIRED_TIME);
    clearInterval(this.interval);
    window.removeEventListener("mousemove", this.eventHandler);
    window.removeEventListener("scroll", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
  }
}

export default SessionHandler;
