import React from "react";
import { Breadcrumb, Card, Collapse, Divider, Table, Modal, Spin } from "antd";
import { InputNumber, Radio, Rate, Slider, Switch, message, Upload } from "antd";
import { AutoComplete, Button, Cascader, Checkbox, Col, Form, Icon, Input, Row, Select, Tooltip, formLayout } from "antd";
import IntlMessages from "util/IntlMessages";
import { OptionService } from "../../../services";

import "../../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../../src/routes/components/dataEntry/Form/otherFormControls.less";

// Switch script start
function onChange(checked) {
  console.log(`switch to ${checked}`);
}
// Switch script start

// search-script
const Search = Input.Search;
// search-script-end

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

// import "./basic.less";

const Panel = Collapse.Panel;


// const text = `
//   A dog is a type of domesticated animal.
//   Known for its loyalty and faithfulness,
//   it can be found as a welcome guest in many households across the world.
// `;
const callback = (key) => {
  console.log(key);
};

// const Basic = () => {

// };

const columns = [
  {
    title: 'Option Name',
    dataIndex: 'optionName',
    key: 'optionName',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  }
];

const data = [
  {
    key: '1',
    optionName: 'Toppings',
    type: 'Single Selection',
  },
  {
    key: '2',
    optionName: 'Drinks',
    type: 'Multi Selection',
  },
  {
    key: '3',
    optionName: 'Bread',
    type: 'Single Selection',
  },
  {
    key: '4',
    optionName: 'Size',
    type: 'Single Selection (Primary)',
  }

];

// AddOption-tabile
const columnsAddOption = [
  {
    title: 'Items',
    dataIndex: 'items',
    key: 'items',
  },
  {
    title: 'Price(Excl Of Tax)',
    dataIndex: 'priceExcl',
    key: 'priceExcl',
  }
];

const dataAddOption = [
  {
    key: '1',
    items: 'Olives',
    priceExcl: '5',
  },
  {
    key: '2',
    items: 'Onion',
    priceExcl: '5',
  },
  {
    key: '3',
    items: 'Tomamtos',
    priceExcl: '5',
  }

];
// AddOption-tabile-end

// AddMultiOption-tabile
const columnsAddMultiOption = [
  {
    title: <IntlMessages id="option.items" />,
    dataIndex: 'items',
    key: 'items',
  },
  {
    title: <IntlMessages id="option.min" />,
    dataIndex: 'min',
    key: 'min',
  },
  {
    title: <IntlMessages id="option.max" />,
    dataIndex: 'max',
    key: 'max',
  },
  {
    title: <IntlMessages id="option.priceExclOfTax" />,
    dataIndex: 'priceExcl',
    key: 'priceExcl',
  },
  {
    title: <IntlMessages id="option.action" />,
    dataIndex: 'action',
    key: 'action',
  }
];

const dataAddMultiOption = [
  {
    key: '1',
    items: 'Coke',
    min: '5',
    max: '5',
    priceExcl: '10',
  },
  {
    key: '2',
    items: 'Sprinte',
    min: '5',
    max: '10',
    priceExcl: '10',
  },
  {
    key: '3',
    items: 'Fanta',
    min: '5',
    max: '10',
    priceExcl: '10',
  }

];
// AddMultiOption-tabile-end


// handleSubmit = (e) => {
//   e.preventDefault();
//   this.props.form.validateFields((err, values) => {
//     if (!err) {
//       console.log('Received values of form: ', values);
//     }
//   });
// };



//   upload-script start
const props = {
  name: 'file',
  action: '//jsonplaceholder.typicode.com/posts/',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
//   upload-script end

class optionAddMulti extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  }

  constructor() {
    super();
    this.state = {
      optionId: "",
      optionItemId: "",
      itemList: [],

      isAddButton: true,
      spin: false,
      spinForItem: false,

      formLayout: 'horizontal',
      visible: false,
      addItem: false,
      addOption: false
    };
  }

  componentDidMount() {
    console.log("Multi selection", this.props.location.info);
    this.props.form.setFieldsValue({
      name: this.props.location.info && this.props.location.info.name || "",
      isAvailableForOption: this.props.location.info && this.props.location.info.isAvailable,
      maxSelectable: this.props.location.info && this.props.location.info.maxSelectable || ""
    })

    let items = [];
    const optionItemList = this.props.location.info && this.props.location.info.optionItems || [];
    for (let i = 0; i < optionItemList.length; i++) {
      items.push({
        key: i,
        items: optionItemList[i].Name,
        min: optionItemList[i].MinCount,
        max: optionItemList[i].MaxCount,
        priceExcl: optionItemList[i].Price,
        action: <a onClick={() => this.openMenuInfo(optionItemList[i].Id, optionItemList[i].Name, optionItemList[i].MinCount, optionItemList[i].MaxCount, optionItemList[i].Price, optionItemList[i].IsAvailable)}><i className="fa fa-pencil"></i></a>
      })
    }

    console.log(items)
    this.setState({
      optionId: this.props.location.info && this.props.location.info.optionId,
      itemList: items,
      isAddButton: this.props.location.info ? false : true
    })
  }


  addOptions = async (event) => {
    event.preventDefault();

    this.props.form.validateFields(['name', 'maxSelectable', 'isAvailableForOption'], async (err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);

        const optionData = {
          OptionId: this.state.optionId || "",
          Name: values.name,
          MaxSelectable: values.maxSelectable,
          IsAvailable: values.isAvailableForOption
        }

        this.setState({ spin: true })
        const response = await OptionService.addOptionsForMultiSelect(optionData);

        if (response && response.APICode == 8) {
          message.error('Option name already exists');
        } else if (response && response.Success) {
          if(this.state.optionId){
            message.success('Option Updated');
          }else{
            message.success('Option Added');
          }
          
          // this.props.form.resetFields();
          const optionList = response.Data;
          this.setState({
            optionId: optionList[optionList.length - 1].Id
          })
        }
        else if(response.APICode == 14){
          this.props.form.setFieldsValue({ isAvailableForOption: true });
          Modal.error({
            title: response.APIMessage,
            content: <span>Here is the associated list. <ul>{response.Data.map(v=><li>{v.Name}</li>)}</ul></span>,
          });
        }
        else if(response.APICode == 114){
          this.props.form.setFieldsValue({ isAvailableForOption: true });
          Modal.error({
            title: response.APIMessage,
            content: <span>Here is the associated menu item list. <ul>{response.Data.map(v=><li>{v.Name}</li>)}</ul></span>,
          });
        }
        else {
          message.error(response.APIMessage);
        }
        this.setState({ spin: false })
      }
    });
  }

  addOptionDetails = async (event) => {
    event.preventDefault();
    this.props.form.validateFields(['itemName', 'price', 'minQuantity', 'maxQuantity', 'isAvailableForOptionDetails'], async (err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);

        const optionDetailsData = {
          OptionId: this.state.optionId,
          OptionItemId: this.state.optionItemId || "",
          Name: values.itemName,
          DefaultPrice: values.price,
          IsAvailable: values.isAvailableForOptionDetails,
          MinCount: values.minQuantity,
          MaxCount: values.maxQuantity
        }

        this.setState({ spinForItem: true })
        const response = await OptionService.addOptionDetails(optionDetailsData);

        if (response && response.Success) {
          message.success('Item Added');
          this.setState({ spinForItem: false })
          this.props.form.resetFields(['itemName', 'price', 'minQuantity', 'maxQuantity', 'isAvailableForOptionDetails']);

          let optionItemList = response.Data;
          for (let j = 0; j < optionItemList.length; j++) {
            if (optionItemList[j].Id == this.state.optionId) {
              optionItemList = optionItemList[j].OptionItems;
              this.setState({
                optionItemId: ""
              })
            }
          }

          let items = [];

          for (let i = 0; i < optionItemList.length; i++) {
            items.push({
              key: i,
              items: optionItemList[i].Name,
              min: optionItemList[i].MinCount,
              max: optionItemList[i].MaxCount,
              priceExcl: optionItemList[i].Price,
              action: <a onClick={() => this.openMenuInfo(optionItemList[i].Id, optionItemList[i].Name, optionItemList[i].MinCount, optionItemList[i].MaxCount, optionItemList[i].Price, optionItemList[i].IsAvailable)}><i className="fa fa-pencil"></i></a>
            })
          }

          console.log(items)
          this.setState({
            itemList: items
          })

        }
        else {
          if (response.APIMessage == "Option not found") {
            message.error("Please add option first than option details..!");
          } else {
            message.error(response.APIMessage);
          }
        }
      }
    });
  }

  openMenuInfo = (itemId, itemName, itemMin, itemMax, itemPrice, isAvailable) => {
    this.props.form.setFieldsValue({
      itemName: itemName || "",
      minQuantity: itemMin,
      maxQuantity: itemMax,
      price: itemPrice || "",
      isAvailableForOptionDetails: isAvailable || ""
    })

    this.setState({
      optionItemId: itemId
    })
  }

  // form-scripts end
  // Modal-scripts start
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  addItem = (e) => {
    this.setState({
      addItem: !this.state.addItem,
    });
  };
  addOption = (e) => {
    this.setState({
      addOption: !this.state.addOption,
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  // Modal-scripts end
  render() {
    //   const {getFieldDecorator} = this.props.form;
    // const formItemLayout = {
    //   labelCol: {xs: 24, sm: 6},
    //   wrapperCol: {xs: 24, sm: 14},
    // };
    // form-scripts start
    const { formLayout } = this.state;
    const formItemLayout = formLayout === 'horizontal' ? {
      labelCol: { xs: 24, sm: 6 },
      wrapperCol: { xs: 24, sm: 16 },
    } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? {
      wrapperCol: { xs: 24, sm: { span: 14, offset: 6 } },
    } : null;
    // form-scripts end

    // MULTIPLE-scripts start
    const children = [];
    for (let i = 1; i < 10; i++) {
      children.push(<Option key={i.toString(36) + i}>{'test'}</Option>);
    }
    function handleChange(value) {
      console.log(`selected ${value}`);
    }
    // MULTIPLE-scripts End

    const { getFieldDecorator } = this.props.form;

    return (

      <div className="menu-editor-box card-pad-new menu-cate-list option-list Add-op-multi">
        <div className="ant-row margin-btm-20 d-none">
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
            <div className="Breadcrumb-inner-box menu-ed">
              <Breadcrumb>
                <Breadcrumb.Item>Menu</Breadcrumb.Item>
                <Breadcrumb.Item>Thai House Wok - Folkungagatan</Breadcrumb.Item>
                <Breadcrumb.Item><span className="gx-link">View on Delivery Pizza.com</span></Breadcrumb.Item>
              </Breadcrumb>
              <div className="icon-more"><i class="icon icon-ellipse-h"></i></div>
            </div>
          </div>
        </div>

        <div className="payout-report-box option-add-multi-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div>
              <div className="title-sub"><h2><IntlMessages id="option.options" /></h2>
                <hr className="hr-line"></hr>
              </div>

              <div class="ant-row Platform-Charges">
                <Form onSubmit={(e) => this.addOptions(e)} layout={formLayout}>
                  <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                    <div className="detail-res-lft">
                      <div class="ant-row charge-form">
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                          <label><IntlMessages id="option.type" /> :</label>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-8 gx-mb-3">
                          <Select disabled={this.props.location.info} className="" defaultValue="Select-2" onChange={handleChange}>
                            <Option value="Select-1" onClick={() => { this.props.history.push("optionAddSingal") }}><IntlMessages id="option.singleSelectionOption" /></Option>
                            <Option value="Select-2" onClick={() => { this.props.history.push("optionAddMulty") }}><IntlMessages id="option.multiSelectionOption" /></Option>
                          </Select>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 gx-ml-3 charge-lable">
                          <label><IntlMessages id="option.disableEnable" /> :</label>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3">
                          <FormItem
                            // label={<IntlMessages id="option.disableEnable" />}
                            {...formItemLayout}
                          >
                            {getFieldDecorator('isAvailableForOption', {
                              valuePropName: 'checked',
                              initialValue: true,
                            })(
                              <Switch />
                            )}
                          </FormItem>
                        </div>
                      </div>
                      <div class="ant-row charge-form">
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                          <label><IntlMessages id="option.name" /> :</label>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 gx-ml-3">
                          <FormItem
                          // label={<IntlMessages id="option.name" />}
                          // {...formItemLayout}
                          >
                            {getFieldDecorator('name', {
                              rules: [{
                                required: true, message: 'Required!',
                              }],
                            })(
                              <Input placeholder="Drinks" />
                            )}
                          </FormItem>
                        </div>
                        {/*<div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                      <Checkbox>Primary</Checkbox>
                      </div>*/}
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                          <label><IntlMessages id="option.maxSelectable" /> :</label>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3">
                          <FormItem
                            // label={<IntlMessages id="option.maxSelectable" />}
                            {...formItemLayout}
                          >
                            {getFieldDecorator('maxSelectable', {
                              rules: [{
                                required: true, type: 'number', min: 0, message: 'Should be positive number!',
                              }],
                            })(
                              <InputNumber placeholder="0" />
                            )}
                          </FormItem>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 add-btn-rite">
                          {
                            this.state.spin ? <Spin /> :
                              <Button htmlType="submit" type="primary" class="ant-btn color-white ant-btn-primary">
                                <i class="icon icon-add"></i>
                                {this.state.isAddButton ? <IntlMessages id="option.addOption" /> : <IntlMessages id="option.updateOption" />}
                              </Button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>

              <div className="title-sub"><h2><IntlMessages id="option.addOptionDetails" /></h2>
                <hr className="hr-line"></hr>
              </div>
              <div class="ant-row Platform-Charges">
                <Form onSubmit={(e) => this.addOptionDetails(e)} layout={formLayout}>
                  <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                    <div className="detail-res-lft">

                      <div class="ant-row charge-form">
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                          <label><IntlMessages id="option.itemName" /> :</label>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3">
                          <FormItem
                          // label={<IntlMessages id="option.itemName" />}
                          // {...formItemLayout}
                          >
                            {getFieldDecorator('itemName', {
                              rules: [{
                                required: true, message: 'Required!',
                              }],
                            })(
                              <Input disabled={this.state.optionId ? false : true} placeholder="Fanta" />
                            )}
                          </FormItem>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                          <label><IntlMessages id="option.minQuantity" /> :</label>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-3 gx-mb-3">
                          <FormItem
                          // label={<IntlMessages id="option.maxSelectable" />}
                          // {...formItemLayout}
                          >
                            {getFieldDecorator('minQuantity', {
                              rules: [{
                                required: true, type: 'number', min: 1, message: 'Should be min one!',
                              }],
                            })(
                              <InputNumber disabled={this.state.optionId ? false : true} placeholder="0" />
                            )}
                          </FormItem>
                        </div>
                      </div>
                      <div class="ant-row charge-form">
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                          <label><IntlMessages id="option.price" /> :</label>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3">
                          <FormItem
                          // label={<IntlMessages id="option.price" />}
                          // {...formItemLayout}
                          >
                            {getFieldDecorator('price', {
                              rules: [{
                                required: true, type: 'number', min: 0, message: 'Should be positive number!',
                              }],
                            })(
                              <InputNumber disabled={this.state.optionId ? false : true} placeholder="5" />
                            )}
                          </FormItem>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                          <label><IntlMessages id="option.maxQuantity" /> :</label>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-3 gx-mb-3">
                          <FormItem
                          // label={<IntlMessages id="option.maxSelectable" />}
                          // {...formItemLayout}
                          >
                            {getFieldDecorator('maxQuantity', {
                              rules: [{
                                required: true, type: 'number', min: 1, message: 'Should be min one!',
                              }],
                            })(
                              <InputNumber disabled={this.state.optionId ? false : true} placeholder="0" />
                            )}
                          </FormItem>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 charge-lable">
                          <label><IntlMessages id="option.disableEnable" /> :</label>
                        </div>
                        <div class="ant-col ant-col-xs-24 ant-col-sm-2 gx-mb-3">
                          <FormItem
                          // label={<IntlMessages id="option.disableEnable" />}
                          // {...formItemLayout}
                          >
                            {getFieldDecorator('isAvailableForOptionDetails', {
                              valuePropName: 'checked',
                              initialValue: true,
                            })(
                              <Switch disabled={this.state.optionId ? false : true} />
                            )}
                          </FormItem>
                        </div>

                        <div class="ant-col ant-col-xs-24 ant-col-sm-3 gx-mb-3 add-btn-rite">
                          {
                            this.state.spinForItem ? <Spin /> :
                              <Button disabled={this.state.optionId ? false : true} htmlType="submit" type="primary" class="ant-btn color-white ant-btn-primary">
                                <i class="icon icon-add"></i>
                                {this.state.optionItemId ? <IntlMessages id="option.update" /> : <IntlMessages id="option.add" />}
                              </Button>
                          }
                          {/* <button type="button" class="ant-btn color-white ant-btn-primary"><i class="icon icon-add"></i><span> Add</span></button> */}
                        </div>

                      </div>

                    </div>
                  </div>
                </Form>
              </div>
              <div className="title-sub"><h2><IntlMessages id="option.optionDetails" /></h2>
                <hr className="hr-line"></hr>
              </div>
              <Table className="gx-table-responsive user-manage-tabile" columns={columnsAddMultiOption} dataSource={this.state.itemList} />
              <div class="ant-row Platform-Charges delivery-charges">
                <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                  <div className="detail-res-lft">

                  </div>
                </div>

              </div>
            </div>
          </Card>

        </div>


        <Modal
          title="Add Category"
          visible={this.state.addItem}
          onOk={this.addItem}
          onCancel={this.addItem}
        >
          <div className="item-detail-edit-box">
            {/* <p>Some contents...</p> */}
            <Form layout={formLayout}>

              <FormItem
                label="Category Name"
                {...formItemLayout}
              >
                <Input placeholder="" />
              </FormItem>
              {/* <FormItem
                                label="Price"
                                {...formItemLayout}
                            >
                                <Input placeholder=""/>
                           </FormItem>*/}
              <FormItem
                label="Image"
                {...formItemLayout}
              >
                <Upload {...props}>
                  <Button className="btn-upload-cust">
                    <Icon type="upload" /> Upload
                                </Button>
                </Upload>
                {/* <Input placeholder=""/> */}
              </FormItem>
            </Form>

          </div>

        </Modal>{/* modal-edit-end */}

        {/* modal-edit */}
        <Modal
          title="Add Category"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="item-detail-edit-box">
            {/* <p>Some contents...</p> */}
            <Form layout={formLayout}>
              <FormItem
                label="Category name"
                {...formItemLayout}
              >
                <Input placeholder="" />
              </FormItem>

            </Form>

          </div>

        </Modal>
        {/* modal-add-option */}

        <Modal
          title="Add a new option"
          visible={this.state.addOption}
          onOk={this.addOption}
          onCancel={this.addOption}
        >
          <div className="item-detail-edit-box">
            <p>Option Details</p>
            <Form layout={formLayout}>
              <FormItem
                label="Optin type"
                {...formItemLayout}
              >
                {/* <Input placeholder=""/> */}
              </FormItem>
              <FormItem
                label="Name"
                {...formItemLayout}
              >
                <Input placeholder="" />
              </FormItem>

            </Form>

          </div>

        </Modal>


      </div>
    );
  }
}

const WrappedOptionAddMulti = Form.create()(optionAddMulti)
export default WrappedOptionAddMulti;