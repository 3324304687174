import React from "react";
import { Breadcrumb, Card, Collapse, Divider, Table, Modal } from "antd";
import {
  InputNumber,
  Radio,
  Spin,
  DatePicker,
  Rate,
  Slider,
  Switch,
  message,
  Upload,
} from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Typography,
  Tooltip,
  formLayout,
} from "antd";
import { PromoCodeService } from "../../services";
import moment from "moment";
import { connect } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import { Link } from "react-router-dom";
import { forEach } from "lodash";
import InfiniteScrollTable from "./../../components/table/InfiniteScrollTable";

const reactStringReplace = require("react-string-replace");
const { Text } = Typography;
const { RangePicker } = DatePicker;
const Search = Input.Search;
const { confirm } = Modal;

const columns = [
  {
    title: <IntlMessages id="promocode.code" defaultMessage="Code" />,
    dataIndex: "code",
    key: "code",
  },
  {
    title: <IntlMessages id="promocode.from" defaultMessage="From" />,
    dataIndex: "from",
    key: "from",
  },
  {
    title: <IntlMessages id="promocode.to" defaultMessage="To" />,
    dataIndex: "to",
    key: "to",
  },
  {
    title: <IntlMessages id="promocode.discount" defaultMessage="Discount" />,
    dataIndex: "discount",
    key: "discount",
  },
  {
    title: <IntlMessages id="promocode.orders" defaultMessage="Orders" />,
    dataIndex: "orders",
    key: "orders",
  },
  {
    title: <IntlMessages id="promocode.Status" defaultMessage="Status" />,
    dataIndex: "status",
    key: "status",
  },
  {
    title: <IntlMessages id="promocode.action" defaultMessage="Action" />,
    dataIndex: "action",
    key: "action",
    //fixed: "right",
  },
];

class PromoCodeList extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      filter: {
        SearchQuery: "",
        PageNo: 1,
        PageSize: 15,
        From: undefined,
        To: undefined,
      },
      promoCodes: [],
      loading: false,
      hasMore: true,
      formLayout: "horizontal",
    };
  }

  async componentDidMount() {
    let searchQuery = new URLSearchParams(this.props.location.search).get(
      "searchQuery"
    );
    if (searchQuery != null) {
      let filter = this.state.filter;
      filter.SearchQuery = searchQuery;
      this.setState({ filter: filter }, this.handleFilter);
    } else {
      this.handleFilter();
    }
  }

  handleFilter = async () => {
    this.setState({ loading: true });
    try {
      const response = await PromoCodeService.fetch(this.state.filter);
      if (response && response.Success) {
        let hasMore = response.Data.length > 0;
        if (!hasMore) message.warning("End!");
        let mergePromoCodes = [...this.state.promoCodes, ...response.Data];
        this.setState({ promoCodes: mergePromoCodes, hasMore: hasMore });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
    this.setState({ loading: false });
  };

  updateStatus = async (value, id, i) => {
    var promoCodes = this.state.promoCodes;
    promoCodes[i].SwitchLoading = true;
    this.setState({ promoCodes: promoCodes });

    const request = {
      Id: id,
      IsAvailable: value,
    };
    const response = await PromoCodeService.updateStatus(request);
    if (response && response.Success) {
      promoCodes[i].IsAvailable = value;
      message.success("Status Changed!");
    } else {
      message.error(response.APIMessage);
    }
    promoCodes[i].SwitchLoading = false;
    this.setState({ promoCodes: promoCodes, loading: false });
  };

  delete = async (id) => {
    const _this = this;
    confirm({
      title: "Do you really want to delete this?",
      icon: <ExclamationCircleOutlined />,
      content: "Click!",
      onOk: async () => {
        console.log("OK");
        await _this.deleteApi({ Id: id });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  deleteApi = async (deleteRequest) => {
    this.setState({ loading: true });
    const response = await PromoCodeService.remove(deleteRequest);
    if (response && response.Success) {
      message.success("Promo Code Deleted!");
      let filter = this.state.filter;
      filter.PageNo = 1;
      this.setState({
        filter: filter,
        promoCodes: [],
      });
      this.handleFilter();
    } else {
      message.error("Failed!");
    }
    this.setState({ loading: false });
  };

  fetchMoreData = async (e) => {
    if (!this.state.loading) {
      let filter = this.state.filter;
      filter.PageNo = filter.PageNo + 1;
      this.setState(
        {
          filter: filter,
          loading: true,
        },
        this.handleFilter
      );
    }
  };

  searchHandler = async (searchInput) => {
    this.props.history.push("/promocodes?searchQuery=" + searchInput);
    let filter = this.state.filter;
    filter.PageNo = 1;
    filter.SearchQuery = searchInput;
    this.setState(
      {
        filter: filter,
        promoCodes: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  rangeFilter = (value) => {
    let filter = this.state.filter;
    filter.PageNo = 1;
    filter.From = value.length && moment(value[0]._d);
    filter.To = value.length && moment(value[1]._d);
    filter.From = filter.From == 0 ? null : filter.From;
    filter.To = filter.To == 0 ? null : filter.To;
    this.setState(
      {
        filter: filter,
        promoCodes: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  searchReset = (searchInput) => {
    if (searchInput.target.value == null || searchInput.target.value == "") {
      this.props.history.push("/promocodes");
      let filter = this.state.filter;
      filter.PageNo = 1;
      filter.SearchQuery = "";
      this.setState(
        {
          filter: filter,
          promoCodes: [],
          loading: true,
        },
        this.handleFilter
      );
    } else {
      let filter = this.state.filter;
      filter.SearchQuery = searchInput.target.value;
      this.setState({ filter });
    }
  };

  searchMark = (txt) => {
    return this.state.filter.SearchQuery == ""
      ? txt
      : reactStringReplace(txt, this.state.filter.SearchQuery, (match, i) => (
          <Text mark>{match}</Text>
        ));
  };

  render() {
    const promoCodesMaps = this.state.promoCodes.map((v, i) => {
      return {
        key: i,
        code: this.searchMark(v.Code),
        from: moment(v.From).format(
          this.props.whitelisting.AdminDateTimeFormat
        ),
        to: moment(v.To).format(this.props.whitelisting.AdminDateTimeFormat),
        discount:
          v.ValueType == 1
            ? v.Value + " %"
            : v.Value + " " + this.props.whitelisting.Currency,
        orders: (
          <Link to={"/orders?searchQuery=pc:" + v.Code}>{v.OrdersCount}</Link>
        ),
        status: (
          <Switch
            loading={v.SwitchLoading == true}
            checked={v.IsAvailable}
            onClick={(value) => this.updateStatus(value, v.Id, i)}
          />
        ),
        action: (
          <a>
            <i
              onClick={() =>
                this.props.history.push("promocodes/update/" + v.Id)
              }
              className="fa fa-pencil"
            ></i>
            <i
              onClick={() => this.delete(v.Id)}
              style={{ marginLeft: "20px" }}
              class="fa fa-trash"
            ></i>
          </a>
        ),
      };
    });

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list">
        <div className="payout-report-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div className="collaps-main">
              <div className="ant-row">
                <div className="search-box ant-col-md-5">
                  <IntlMessages id="option.search">
                    {(text) => (
                      <Search
                        placeholder={text}
                        onSearch={(value) => this.searchHandler(value)}
                        onChange={(value) => this.searchReset(value)}
                        style={{ width: 200 }}
                        value={this.state.filter.SearchQuery}
                      />
                    )}
                  </IntlMessages>
                </div>
                <div className="search-box ant-col-md-13">
                  <div className="check-box-fill">
                    <ul>
                      <li>
                        <RangePicker
                          format={this.props.whitelisting.AdminDateFormat}
                          onChange={(value) => this.rangeFilter(value)}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ant-col-md-6">
                  <div className="orders-item top-section filter-box ">
                    <a
                      onClick={() => {
                        this.props.history.push("promocodes/create");
                      }}
                      type="primary"
                      className="color-white btn btn-primary"
                    >
                      <i class="icon icon-add"></i>
                      <IntlMessages id="option.add" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="ant-row">
                <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
                  <InfiniteScrollTable
                    columns={columns}
                    dataSource={promoCodesMaps}
                    loading={this.state.loading}
                    hasMore={this.state.hasMore}
                    height={"calc(100vh - 205px)"}
                    loadMore={this.fetchMoreData}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  whitelisting: state.settings.whitelisting,
});
export default connect(mapStateToProps)(PromoCodeList);
