import axios from "axios";
import BaseService from "./baseService";

class TaxServiceController extends BaseService {

    async getAllTaxes() {
        const response = await axios.get("/tax/getalltaxes");
        return response.data;
    }
}

export const TaxService = new TaxServiceController();