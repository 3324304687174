import React, { useState, useEffect } from "react";
import { InputNumber, Radio } from "antd";
import { Checkbox, Col, Form, Row, Button } from "antd";
import IntlMessages from "util/IntlMessages";
import RadioGroup from "antd/lib/radio/group";

const FormItem = Form.Item;

const PlatformTypes = [
  { label: "Amount", value: 0 },
  { label: "Percentage (%)", value: 1 },
];

const PlatformCharges = (props) => {
  const [enabled, setEnabled] = useState(false);
  const { getFieldDecorator, formItemLayout } = props;
  const { platformType } = props;

  useEffect(() => {
    if (props.isEnabled) setEnabled(props.isEnabled);
  }, [props.isEnabled]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props.callBack) props.callBack(e);
  };

  const handleEnableCheckChange = (ev) => {
    setEnabled(ev.target.checked);
  };
  return (
    <>
      <Form
        className="ant-row platform-from"
        style={{ marginLeft: "0px" }}
        onSubmit={async (e) => await handleSubmit(e)}
        layout={formItemLayout}
      >
        <div class="ant-col ant-col-xs-20  gx-mb-3 charge-label-box">
          <FormItem
            label={<IntlMessages id="charges.platformChargesEnable" />}
            {...formItemLayout}
          >
            {getFieldDecorator(`Platform${platformType}Enabled`, {
              valuePropName: "checked",
              initialValue: enabled,
            })(
              <Checkbox onChange={handleEnableCheckChange} checked={enabled} />
            )}
          </FormItem>
          <FormItem
            label={<IntlMessages id="charges.platformChargesType" />}
            {...formItemLayout}
          >
            {getFieldDecorator(`Platform${platformType}Type`, {
              rules: [
                {
                  required: true,
                  message: "Value is required!",
                },
              ],
              initialValue: 0,
            })(<RadioGroup disabled={!enabled} options={PlatformTypes} />)}
          </FormItem>
          <FormItem
            label={<IntlMessages id="charges.platformChargesAmount" />}
            {...formItemLayout}
          >
            {getFieldDecorator(`Platform${platformType}Value`, {
              rules: [
                {
                  required: true,
                  message: "Value is required!",
                },
                {
                  type: "number",
                  min: 0,
                  message: "Should be positive number!",
                },
              ],
              hidden: !enabled,
            })(
              <InputNumber
                defaultValue={100}
                min={0}
                style={{ width: 200 }}
                placeholder="amount"
                disabled={!enabled}
              />
            )}
          </FormItem>
        </div>
        <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 add-btn-form-box">
          <FormItem {...formItemLayout} className="add-btn-form">
            <Button loading={props.loading} htmlType="submit" type="primary">
              <IntlMessages id="charges.save" />
            </Button>
          </FormItem>
        </div>
      </Form>
    </>
  );
};

export default PlatformCharges;
