import React, { useState, useEffect } from "react";
import { Modal, Form, Input, message } from "antd";
import IntlMessages from "util/IntlMessages";
import { values } from "lodash";
import { AuthSvc } from "../../services";

const FormItem = Form.Item;
const FormLayout = "horizontal";
const formItemLayout =
  FormLayout === "horizontal"
    ? {
        labelCol: { xs: 24, sm: 8 },
        wrapperCol: { xs: 24, sm: 14 },
      }
    : null;
const AccessWhitelist = (props) => {
  const [loading, setLoading] = useState(false);
  const [accessGrant, setAccessGrant] = useState(false);
  const inputRef = React.useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  const handleAccess = (e) => {
    e.preventDefault();
    setLoading(true);
    props.form.validateFields(["PassCode"], async (err, value) => {
      let formData = new FormData();
      formData.append("Code", value.PassCode);
      var response = await AuthSvc.verifyAdministrativeAccess(formData);
      if (response && response.Success) {
        setAccessGrant(true);
      } else {
        message.error("wrong code");
      }
      setLoading(false);
    });
  };

  const handleCancel = () => {
    window.location.href = "/dashboard";
  };

  const { getFieldDecorator } = props.form;
  return (
    <Modal
      visible={props.visible && !accessGrant}
      title="Whitelist Access Code"
      okText="Access Whitelist"
      onOk={handleAccess}
      okButtonProps={{ loading: loading }}
      onCancel={handleCancel}
    >
      <Form onSubmit={(e) => handleAccess(e)} layout={FormLayout}>
        <FormItem
          label={
            <IntlMessages id="domainWhitelist.code" defaultMessage="Code" />
          }
          {...formItemLayout}
        >
          {getFieldDecorator("PassCode", {
            rules: [
              {
                required: true,
                message: "Required!",
              },
            ],
          })(
            <Input
              placeholder="Please provide access code"
              type="password"
              ref={inputRef}
            />
          )}
        </FormItem>
      </Form>
    </Modal>
  );
};

const wrappedAccessComponent = Form.create()(AccessWhitelist);
export default wrappedAccessComponent;
