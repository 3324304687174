import axios from "axios";
import BaseService from "./baseService";

class PushMessageServiceController extends BaseService {
    async fetch(body) {
        const response = await axios.post("/push-message/fetch", body);
        return response.data;
    }

    async send(body) {
        const response = await axios.post("/push-message/send", body);
        return response.data;
    }

    async remove(body) {
        const response = await axios.post("/push-message/remove", body);
        return response.data;
    }
}

export const PushMessageService = new PushMessageServiceController();