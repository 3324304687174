import axios from "axios";
import BaseService from "./baseService";

class CategoryServiceController extends BaseService {

  async getCategoriesList(searchQuery, pageSize, pageNo, isAvailable) {
    const body = {
      "SearchQuery": searchQuery,
      "PageSize": pageSize,
      "PageNo": pageNo,
      "IsAvailable": isAvailable
    }
    const response = await axios.post("/restaurant/getcategorieswithfilters", body);
    return response.data.Data;
  }

  async updateCategoryListSorting(sortedData) {
    const response = await axios.post("/restaurant/addupdatemenucategoriessortorder", sortedData);
    return response.data;
}

  async addUpdateCategory(body) {
    const response = await axios.post("/restaurant/addupdatecategory", body);
    return response.data;
  }
}

export const CategoryService = new CategoryServiceController();
