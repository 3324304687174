import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Table, Spin } from "antd";
import {
  initStickyTableHead,
  removeStickyTableHead,
} from "./../../util/scrollHelper";

export default function InfiniteScrollTable({
  loading,
  loadMore,
  hasMore,
  dataSource,
  columns,
  height,
  className,
  initialLoad = false,
  marginBottom,
}) {
  const [scrollEventAdded, setScrollEventAdded] = useState(false);
  useEffect(() => {
    if (!scrollEventAdded) {
      initStickyTableHead();
      setScrollEventAdded(true);
    }
    return removeStickyTableHead;
  }, []);

  return (
    <div
      className={`infinite-container ${className}`}
      style={{ height, overflow: "auto" }}
    >
      <InfiniteScroll
        loadMore={loadMore}
        threshold={20}
        useWindow={false}
        initialLoad={initialLoad}
        hasMore={!loading && hasMore}
      >
        <Table columns={columns} pagination={false} dataSource={dataSource} />
        {loading && hasMore && (
          <div
            className="spinner"
            style={{
              bottom: "40px",
              position: "absolute",
              textAlign: "center",
              width: "100%",
            }}
          >
            <Spin />
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
}
