import axios from "axios";
import BaseService from "./baseService";

class ActivityServiceController extends BaseService {
  async fetch(body) {
    const response = await axios.post("/activity/fetch", body);
    return response.data;
  }
  async fetchEvents() {
    const response = await axios.post("/activity/fetch-events");
    return response.data;
  }

  async details(body) {
    const response = await axios.post("/activity/details", body);
    return response.data;
  }

  async remove(templateInfo) {
    const response = await axios.post("/activity/remove", templateInfo);
    return response.data;
  }

  async GetSubActivityDetails(body) {
    const response = await axios.post("/activity/get-sub-activity", body);
    return response.data;
  }
}

export const ActivityService = new ActivityServiceController();
