import React from "react";
import { Form, Input, Icon, Button,Row,Col } from "antd";
import { TimePicker } from "antd";
import "./style.css";

let id = 0;

class DynamicFieldSet extends React.Component {
  remove = (k,keysName) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue(keysName);
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter((key) => key !== k),
    });
  };

  add = (keysName) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue(keysName);
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { keys, names } = values;
        console.log("Received values of form: ", values);
        console.log(
          "Merged values:",
          keys.map((key) => names[key])
        );
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 0 },
        sm: { span: 0 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };

    
    getFieldDecorator("keys", { initialValue: [] });
    const keys = getFieldValue("keys");
    const formItems = keys.map((k, index) => (
      <Input.Group style={{margin:"0px", padding:"opx"}}  name={`times[${k}]`}  key={k} className="ant-row">



      <Row>
      <Col span={8}>
      <Form.Item
        {...formItemLayoutWithOutLabel}
        required={true}
        key={k}
      >
        {getFieldDecorator(`from[${k}]`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              message: "Required!",
            },
          ],
        })(<TimePicker use12Hours format="h:mm a" size={"small"} style={{ width: "100%"}}
        />)}
      </Form.Item>
      </Col>
      <Col span={8}>
      <Form.Item
        {...formItemLayoutWithOutLabel}
        required={true}
        key={k}
      >
        {getFieldDecorator(`to[${k}]`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              message: "Required!",
            },
          ],
        })(<TimePicker use12Hours format="h:mm a" size={"small"} style={{ width: "100%"}}
        />)}
      </Form.Item>
      </Col>
      <Col span={8}>
      {keys.length > 1 ? (
              <div className="ant-col-8">
            <Icon
              className="dynamic-delete-button"
              type="minus-circle-o"
              onClick={() => this.remove(k,"keys")}
            />
            </div>
          ) : null}
      </Col>
    </Row>
      </Input.Group>
    ));
    return (
      <Form layout="horizontal" onSubmit={this.handleSubmit}>
        {formItems}
        <Form.Item {...formItemLayoutWithOutLabel}>
          <Button size="small" type="dashed" onClick={(e)=>{this.add("keys")}} style={{ width: "100%" }}>
            <Icon type="plus" /> Add field
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedDynamicFieldSet = Form.create({ name: "dynamic_form_item" })(
  DynamicFieldSet
);
export default WrappedDynamicFieldSet;
