import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import { userSignOut } from "appRedux/actions/Auth";
import { AuthSvc, RestaurantService } from "../../services";
import CircularProgress from "../../components/CircularProgress/index";
import { RESTAURENT_ADMIN, SUPER_ADMIN_ID } from "../../constants/UserRoles";
import appState from "../../state/AppStateContainer";

import { showAuthLoader, branchSwitch } from "appRedux/actions/Auth";
import IntlMessages from "../../util/IntlMessages";
import { AppSettings } from "../../constants/AppSettings";

const UserProfile = (props) => {
  const { showMessage, authUser, loader, tempUser } = useSelector(
    ({ auth }) => auth
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [restaurantsBranchesNames, setRestaurantsBranchesNames] = useState([]);

  useEffect(async () => {
    if (authUser != SUPER_ADMIN_ID) {
      const response = await RestaurantService.getRestaurantsBranchesNames();
      if (response && response.data.Success) {
        setRestaurantsBranchesNames(response.data.Data);
      } else {
        message.error(response.data.APIMessage);
      }
    }
  }, []);

  const switchBranch = async (restaurantId) => {
    try {
      message.loading("Please wait...");
      const response = await AuthSvc.loginAsBranch(restaurantId);
      if (response && response.data.Success) {
        appState.persistString("rId", restaurantId);
        dispatch(branchSwitch(response.data.Data));
        window.location.href = "/";
      } else {
        message.error(response.data.APIMessage);
      }
    } catch (e) {
      message.error("Unexpected error occured while processing your request.");
    }
  };

  const signOut = async () => {
    if (tempUser != null || tempUser != undefined) {
      const key = "updatable";

      try {
        message.loading({ content: "Loading...", key });
        const response = await AuthSvc.loginAsUndo(tempUser);
        if (response && response.data.Success) {
          dispatch(userSignOut());
          //history.push('/restaurants')
          window.location.href = "/5461c537bbe8e8a6d71cbcb24eb9ad47/login";
        } else {
          localStorage.clear();
          window.location.href = "/";
          message.error({
            content: response.data.APIMessage,
            key,
            duration: 2,
          });
        }
      } catch (ex) {
        message.error({ content: ex.message, key, duration: 2 });
      }
    } else {
      dispatch(userSignOut());
      if (authUser == SUPER_ADMIN_ID) {
        window.location.href = "/5461c537bbe8e8a6d71cbcb24eb9ad47/login";
      }
      window.location.href = "/";
    }
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {restaurantsBranchesNames.length > 0 && (
        <>
          {restaurantsBranchesNames.map((x) => (
            <li onClick={(e) => switchBranch(x.RestaurantId)}>
              {x.RestaurantName}
            </li>
          ))}
          <hr />
        </>
      )}
      {authUser != SUPER_ADMIN_ID && (
        <li
          onClick={() => {
            history.push("/changePassword");
          }}
        >
          <IntlMessages
            id="info.changePassword"
            defaultMessage="Change Password"
          />
        </li>
      )}
      <li onClick={signOut}>Logout</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        {authUser == SUPER_ADMIN_ID ? (
          <span className="gx-avatar-name">
            {"Admin Speedoy"}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </span>
        ) : (
          <span className="gx-avatar-name">
            {props.name || "Restaurant Admin"}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </span>
        )}
      </Popover>
    </div>
  );
};

export default UserProfile;
