import React from "react";
import { connect } from "react-redux";
import Map from "./Map";
// import {Breadcrumb, Card, Collapse, Divider, Icon, Table, Modal} from "antd";
import {
  Breadcrumb,
  Card,
  Collapse,
  Divider,
  Table,
  Modal,
  getFieldDecorator,
  Menu,
  Dropdown,
} from "antd";
import {
  InputNumber,
  Radio,
  Rate,
  Slider,
  Switch,
  message,
  Upload,
} from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Spin,
  Tooltip,
  formLayout,
} from "antd";
import { Tabs } from "antd";
import SimpleMap from "../../../../src/routes/extensions/map/googlemap/simple/index.js";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";
import { AppSettings } from "../../../constants/AppSettings";

import _ from "lodash";

// import "./basic.less";
import "../../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../../src/routes/components/dataEntry/Form/otherFormControls.less";

import { RestaurantService, AuthSvc, CityService } from "../../../services";
import RestaurantConfig from "./tabs/config";
import RestaurantCalendar from "./tabs/calendar";
import RestaurantOrders from "./tabs/orders";
import RestaurantGallery from "./tabs/gallery";
import RestaurantSlider from "./tabs/slider";
import { fromAddress } from "react-geocode";
import { chargeablePaymentMethods } from "./tabs/paymentOptions";
import InfiniteScrollTable from "./../../../components/table/InfiniteScrollTable";
import PlatformCharges from "./restaurantComponents/platformCharges";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;
function onChange(checkedValues) {
  console.log("checked = ", checkedValues);
}

const Panel = Collapse.Panel;
const { TextArea } = Input;

// charge-table-data
const columns = [
  {
    title: (
      <IntlMessages id="charges.perKMAmount" defaultMessage="Per Km Amount" />
    ),
    dataIndex: "perKMAmount",
    key: "perKMAmount",
  },

  {
    title: <IntlMessages id="charges.amount" />,
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: <IntlMessages id="charges.uptoDistance" />,
    dataIndex: "uptodistance",
    key: "uptodistance",
    // render: text => <span className="gx-link">{text}</span>,
  },
  {
    title: <IntlMessages id="categoryList.action" />,
    dataIndex: "action",
    key: "action",
  },
];
const inputRules = {
  noRule: {},
  required: { required: true, message: "Required!" },
};

const customRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

class SaEditCreateRestaurant extends React.Component {
  async componentDidMount() {
    const id = this.props.match.params.id;
    if (id != undefined && id != null) {
      this.setState({ id: id });

      const response = await RestaurantService.getRestaurant(id);
      if (response && response.data.Success) {
        const r = response.data.Data;
        let map = (
          <Map
            google={this.props.google}
            center={{
              lat: r.Address.Latitude ?? this.state.lat,
              lng: r.Address.Longitude ?? this.state.lng,
            }}
            height="300px"
            zoom={15}
            showMarker={
              r.Address.Latitude != 0 && r.Address.Longitude != 0 ? true : false
            }
            addressChanged={(x) => this.handleAddressChanged(x)}
          />
        );

        this.setState({
          ordersCount: r.OrdersCount,
          ordersRejectionsCount: r.OrderRejectionsCount,
          ordersClosuresCount: r.OrderClosuresCount,
          rating: Math.floor(r.Rating),
          isFeatured: r.IsFeatured,
          IsEcoFriendly: r.IsEcoFriendly,
          enableSlider: r.EnableSlider,
          enableRestaurant: r.Status == 1,
          map: map,
          itemViewType: r.ItemViewType,
          showTax: r.ShowTax,
          allowOtp: r.AllowOtp,
          isExclusiveTax: r.IsExclusiveTax,
          businessType: r.BusinessType,
          orderWithoutHeartBeat: r.OrderWithoutHeartBeat,
          excludeFromSpeedoyModel: r.ExcludeFromSpeedoyModel,
          withoutPos: r.WithoutPos,
          autoAcceptOrder: r.AutoAcceptOrder,
          isItemQuantityEditable: r.IsItemQuantityEditable,
          isTestRestaurant: r.IsTestRestaurant,
        });

        if (r.Address.Latitude != 0 && r.Address.Longitude != 0) {
          this.setState({
            lat: r.Address.Latitude,
            lng: r.Address.Longitude,
            showMarker: true,
            cityCheck: r.Address.City ? true : false,
            coverUrl: r.Image,
            logoUrl: r.Logo,
            thumbnailUrl: r.ImageThumbnail,
            serviceCity: r.ServiceCityId,
          });
        }
        this.props.form.setFieldsValue({
          CompanyNumber: r.CompanyNumber || "",
          VAT: r.VAT || "",
          CompanyName: r.CompanyName || "",
          Name: r.Name || "",
          IsFeatured: r.IsFeatured || false,
          IsEcoFriendly: r.IsEcoFriendly || false,
          Email: r.Email || "",
          RestaurantPhone: r.RestaurantPhone || "",
          ContactName: r.ContactName || "",
          PhoneNumber: r.PhoneNumber || "",
          PhoneNumber2: r.PhoneNumber2 || "",
          FullAddress: r.Address.FullAddress || "",
          Province: r.Address.Province || "",
          City: r.Address.City || "",
          ServiceCityId: r.ServiceCityId || "",
          ZipCode: r.Address.ZipCode || "",
          Latitude: r.Address.Latitude || "",
          Longitude: r.Address.Longitude || "",
          Cuisines: r.Tags,
          Status: r.Status == 1 ? true : false,
          EnableSlider: r.EnableSlider || false,
          ItemViewType: r.ItemViewType || 1,
          ShowTax: r.ShowTax || false,
          AllowOtp: r.AllowOtp,
          IsExclusiveTax: r.IsExclusiveTax || false,
          TaxDisplayText: r.TaxDisplayText,
          BusinessType: r.BusinessType || 0,
          OrderWithoutHeartBeat: r.OrderWithoutHeartBeat || false,
          ExcludeFromSpeedoyModel: r.ExcludeFromSpeedoyModel || false,
          WithoutPos: r.WithoutPos || false,
          AutoAcceptOrder: r.AutoAcceptOrder || false,
          IsItemQuantityEditable: r.IsItemQuantityEditable || false,
          IsTestRestaurant: r.IsTestRestaurant || false,
          ThirdPartyAppId: r.ThirdPartyAppId || "",
        });
      } else {
        this.setState({ id: null });
        message.error(response.data.APIMessage);
        let map = (
          <Map
            google={this.props.google}
            center={{ lat: 59.329, lng: 18.0686 }}
            height="300px"
            zoom={15}
            showMarker={false}
            addressChanged={(x) => this.handleAddressChanged(x)}
          />
        );
        this.setState({ map: map });
      }
    } else {
      let map = (
        <Map
          google={this.props.google}
          center={{ lat: 59.329, lng: 18.0686 }}
          height="300px"
          zoom={15}
          showMarker={false}
          addressChanged={(x) => this.handleAddressChanged(x)}
        />
      );
      this.setState({ map: map });
    }

    this.loadCities();

    const response = await RestaurantService.getCusinesList();
    if (response && response.data.Success) {
      let data = response.data.Data;
      let children = [];
      for (let i = 0; i < data.length; i++) {
        children.push(<Option key={data[i].Id}>{data[i].Name}</Option>);
      }

      this.setState({ cusines: children });
    } else {
      message.error(response.data.APIMessage);
    }
  }

  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      isTableEmpty: false,
      fixAmountId: "",
      perKMAmount: "",

      cityCheck: false,

      selectedLogo: [],
      selectedThumbnail: [],
      selectedCover: [],

      logo: null,
      cover: null,
      thumbnail: null,

      logoUrl: "",
      thumbnailUrl: "",
      coverUrl: "",

      formLayout: "horizontal",
      cusines: [],
      loading: false,
      restaurant: {},
      id: null,
      ordersCount: 0,
      ordersRejectionsCount: 0,
      ordersClosuresCount: 0,
      rating: 0,
      showMarker: false,
      lat: 59.3293,
      lng: 18.0686,
      map: null,
      enableRestaurant: false,
      isFeatured: false,
      IsEcoFriendly: false,

      //Delivery charges states
      dataDeliveryCharges: [],
      hasMoreDeliveryCharges: true,
      loadingDeliveryCharges: false,
      fixedOrDistance: 4,
      fixedOrPercentCommission: 0,
      loadingSaveCharges: false,
      loadingFreeDeliveryFromAmount: false,
      loadingFixedValue: false,
      loadingSavePlatformCharges: false,
      loadingDeliveryPlatformCharges: false,
      loadingTakeawayPlatformCharges: false,
      loadingDineInPlatformCharges: false,
      loadingCreditCharges: false,
      loadingForStatus: false,
      loadingDeliveryTax: false,
      tempFixedFeeKmAmount: 0,

      didMount: false,

      cities: [],
      serviceCity: null,
      enableSlider: false,
      itemViewType: 1,
      showTax: false,
      allowOtp: true,
      isExclusiveTax: false,
      creditCharges: [],
      businessType: 0,
      orderWithoutHeartBeat: false,
      withoutPos: false,
      autoAcceptOrder: false,
      isItemQuantityEditable: false,
      deliveryChargesEnabled: false,
      takeawayChargesEnabled: false,
      dineInChargesEnabled: false,
      isTestRestaurant: false,
    };
  }

  onChangeThumbnail = async (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        console.log("uploading");
        nextState.selectedThumbnail = [info.file];
        break;
      case "done":
        console.log("done");
        nextState.thumbnail = info.file;
        nextState.selected = [info.file];
        await this.thumbnailURL(info);
        break;

      default:
        // error or removed
        nextState.thumbnail = null;
        nextState.selectedThumbnail = [];
    }
    this.setState(() => nextState);
    console.log(this.state);
  };

  thumbnailURL = async (info) => {
    let src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(info.file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
    this.setState({
      thumbnailUrl: [src],
    });
    console.log("thumbnailUrl", this.state.thumbnailUrl);
  };

  onChangeLogo = (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        console.log("uploading");
        nextState.selectedLogo = [info.file];
        break;
      case "done":
        console.log("done");
        nextState.logo = info.file;
        nextState.selected = [info.file];
        this.logoURL(info);
        break;

      default:
        // error or removed
        nextState.logo = null;
        nextState.selectedLogo = [];
    }
    this.setState(() => nextState);
    console.log(this.state);
  };

  logoURL = async (info) => {
    let src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(info.file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
    this.setState({
      logoUrl: [src],
    });
  };

  onChangeCover = (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        console.log("uploading");
        nextState.selectedCover = [info.file];
        break;
      case "done":
        console.log("done");
        nextState.cover = info.file;
        nextState.selectedCover = [info.file];
        this.coverURL(info);
        break;

      default:
        // error or removed
        nextState.cover = null;
        nextState.selectedCover = [];
    }
    this.setState(() => nextState);
    console.log(this.state);
  };

  coverURL = async (info) => {
    let src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(info.file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
    this.setState({
      coverUrl: [src],
    });
  };

  async getDataListDeliveryCharges() {
    let list = [];
    if (this.state.id != null) {
      const response = await RestaurantService.getRestaurantCharges(
        this.state.id
      );

      let dc = response.DeliveryCharge;
      let mo = response.MinimumOrder;
      let pcd = response.PlatformChargeDelivery;
      let pct = response.PlatformChargeTakeAway;
      let pcdineIn = response.PlatformChargeDineIn;
      let creditCharges = response.CreditCharges;

      let creditChargesFieldValues =
        this.props.form.getFieldValue("PaymentMethod");

      if (dc != null) {
        if (dc.FixAmounts != null) {
          let totalActiveAmounts = 0;

          for (let i = 0; i < dc.FixAmounts.length; i++) {
            if (dc.FixAmounts[i].Status != 2) {
              totalActiveAmounts = totalActiveAmounts + 1;
              console.log("fixedFeeActive", dc.FixAmounts[i]);
            }
          }

          for (let i = 0; i < dc.FixAmounts.length; i++) {
            if (dc.FixAmounts[i].Status != 2) {
              list.push({
                uptodistance: dc.FixAmounts[i].DistanceUpToKM,
                amount: dc.FixAmounts[i].Amount,
                perKMAmount:
                  dc.IsFixedFee == false && dc.IsDistanceBased
                    ? dc.PerKMAmount
                    : "",
                action: (
                  <a>
                    <i
                      onClick={() =>
                        this.updateRestaurantFixAmount(
                          dc.FixAmounts[i].Id,
                          dc.FixAmounts[i].Amount,
                          dc.FixAmounts[i].DistanceUpToKM
                        )
                      }
                      class="fa fa-pencil"
                    ></i>
                    {totalActiveAmounts > 1 && (
                      <i
                        onClick={() =>
                          this.deleteRestaurantFixAmount(dc.FixAmounts[i].Id)
                        }
                        style={{ paddingLeft: "20px" }}
                        class="fa fa-trash"
                      ></i>
                    )}
                  </a>
                ),
              });
            }
          }
        }

        let fixedOrDistance;
        if (dc.IsFixedFee == false && dc.IsDistanceBased == false) {
          fixedOrDistance = 4;
        } else {
          fixedOrDistance =
            (dc.IsFixedFee ? 1 : 0) + (dc.IsDistanceBased ? 2 : 0);
        }

        this.setState({
          fixedOrDistance: fixedOrDistance,
          tempFixedFeeKmAmount: dc.PerKMAmount,
          perKMAmount: dc.PerKMAmount,
        });

        this.props.form.setFieldsValue({
          PerKMAmount: dc.PerKMAmount || "",
          FreeDeliveryFromAmount: dc.FreeDeliveryFromAmount || "",
          DeliveryTax: dc.DeliveryTax || 0,
        });
      }

      if (pcd != null) {
        let deliveryChargesEnabled = pcd["PlatformChargesEnabled"] || false;
        this.setState({ deliveryChargesEnabled });
        this.props.form.setFieldsValue({
          CommissionPercentDelivery: pcd["CommissionPercent"] || 0,
          CommissionTaxDelivery: pcd["CommissionTaxPercent"],
          PlatformDeliveryEnabled: deliveryChargesEnabled,
          PlatformDeliveryType: pcd["PlatformType"],
          PlatformDeliveryValue: pcd["PlatformValue"],
        });
      }

      if (pct != null) {
        let takeawayChargesEnabled = pct["PlatformChargesEnabled"] || false;
        this.setState({ takeawayChargesEnabled });
        this.props.form.setFieldsValue({
          CommissionPercentTakeaway: pct["CommissionPercent"] || 0,
          CommissionTaxTakeaway: pct["CommissionTaxPercent"],
          PlatformTakeawayEnabled: takeawayChargesEnabled,
          PlatformTakeawayType: pct["PlatformType"],
          PlatformTakeawayValue: pct["PlatformValue"],
        });
      }

      if (pcdineIn != null) {
        let dineInChargesEnabled = pcdineIn["PlatformChargesEnabled"] || false;
        this.setState({ dineInChargesEnabled });
        this.props.form.setFieldsValue({
          CommissionPercentDineIn: pcdineIn["CommissionPercent"] || 0,
          CommissionTaxDineIn: pcdineIn["CommissionTaxPercent"],
          PlatformDineInEnabled: dineInChargesEnabled,
          PlatformDineInType: pcdineIn["PlatformType"],
          PlatformDineInValue: pcdineIn["PlatformValue"],
        });
      }

      if (mo != null) {
        this.props.form.setFieldsValue({
          MinimumOrderAmount: mo["Amount"] || 0,
        });
      }

      if (creditCharges != null) {
        try {
          let chargesArray = [];
          let chargesTaxArray = [];

          for (let i = 0; i < creditChargesFieldValues.length; i++) {
            let paymentMethod = creditChargesFieldValues[i];
            const charges = creditCharges.find(
              (a) => a.PaymentMethod == paymentMethod
            );
            if (charges) {
              chargesArray[i] = charges.ChargeAmount;
              chargesTaxArray[i] = charges.ChargeTax;
            }
          }
          this.props.form.setFieldsValue({
            ChargeAmount: chargesArray,
            ChargeTax: chargesTaxArray,
          });
        } catch (e) {
          console.error("cr", e);
        }
      }
    }
    return list;
  }

  updateRestaurantFixAmount = (fixAmountId, amount, distanceUpToKM) => {
    this.props.form.setFieldsValue({
      Amount: amount || "",
      DistanceUpToKM: distanceUpToKM || "",
    });

    this.setState({
      fixAmountId: fixAmountId,
    });
  };

  deleteRestaurantFixAmount = async (fixAmountId) => {
    const restaurantInfo = {
      RestaurantId: this.state.id,
      FixAmountId: fixAmountId,
    };

    const response = await RestaurantService.deleteRestaurantFixAmount(
      restaurantInfo
    );
    if (response && response.data.Success) {
      const fixedFeeList = response.data.Data;
      this.fixedFeeTableCreatingWithList(fixedFeeList);

      message.success("Deleted!");
    } else {
      message.error("Failed!");
    }
  };

  fetchMoreDeliveryCharges = async (e) => {
    this.setState({
      loadingDeliveryCharges: true,
    });
    try {
      let list = await this.getDataListDeliveryCharges();
      this.setState({
        dataDeliveryCharges: list,
        hasMoreDeliveryCharges: false,
        loadingDeliveryCharges: false,
      });
    } catch {
      message.error("Something went wrong!");
    }
  };

  getLocation() {
    return {
      lat: this.state.lat,
      lng: this.state.lng,
    };
  }

  handleAddressChanged(place) {
    if (this.state.didMount) {
      this.props.form.setFieldsValue({
        FullAddress: place.address || "",
        Province: place.state || "",
        City: place.city || place.area || "",
        Latitude: place.markerPosition.lat || "",
        Longitude: place.markerPosition.lng || "",
      });

      this.setState({ cityCheck: place.city || place.area ? true : false });
      console.log("AddressChanged", place);
    }
    this.setState({ didMount: true });
  }

  fixedFeeTableCreatingWithList = (fixedFeeList) => {
    let list = [];
    let totalActiveAmounts = 0;

    for (let i = 0; i < fixedFeeList.length; i++) {
      if (fixedFeeList[i].Status != 2) {
        totalActiveAmounts = totalActiveAmounts + 1;
        console.log("fixedFeeActive", fixedFeeList[i]);
      }
    }

    var opt = this.state.fixedOrDistance;
    for (let i = 0; i < fixedFeeList.length; i++) {
      if (fixedFeeList[i].Status != 2) {
        list.push({
          uptodistance: fixedFeeList[i].DistanceUpToKM,
          amount: fixedFeeList[i].Amount,
          perKMAmount: opt == 2 || opt == 3 ? this.state.perKMAmount : "",
          action: (
            <a>
              <i
                onClick={() =>
                  this.updateRestaurantFixAmount(
                    fixedFeeList[i].Id,
                    fixedFeeList[i].Amount,
                    fixedFeeList[i].DistanceUpToKM
                  )
                }
                class="fa fa-pencil"
              ></i>
              {totalActiveAmounts > 1 && (
                <i
                  onClick={() =>
                    this.deleteRestaurantFixAmount(fixedFeeList[i].Id)
                  }
                  style={{ paddingLeft: "20px" }}
                  class="fa fa-trash"
                ></i>
              )}
            </a>
          ),
        });
      }
    }
    this.setState({ dataDeliveryCharges: list });
  };

  handleFreeDeliveryFromAmount = async (event, v) => {
    event.preventDefault();

    let validateFields = ["FreeDeliveryFromAmount"];
    this.props.form.validateFields(validateFields, async (err, values) => {
      if (!err) {
        try {
          const data = {
            RestaurantId: this.state.id,
            FreeDeliveryFromAmount: values["FreeDeliveryFromAmount"],
          };
          this.setState({ loadingFreeDeliveryFromAmount: true });
          const response = await RestaurantService.updateFreeDeliveryFromAmount(
            data
          );
          if (response && response.data.Success) {
            message.success("Updated!");
          } else {
            message.error(response.data.APIMessage);
          }
          this.setState({ loadingFreeDeliveryFromAmount: false });
        } catch (ex) {
          this.setState({ loadingFreeDeliveryFromAmount: false });
          message.error(ex.message);
        }
      } else {
        message.error("Some fields are required or contains invalid value!");
      }
    });
  };

  handleDeliveryTax = async (e, v) => {
    e.preventDefault();

    let validateFields = ["DeliveryTax"];
    this.props.form.validateFields(validateFields, async (err, values) => {
      if (!err) {
        try {
          let taxValue = values["DeliveryTax"];

          if (taxValue == undefined || taxValue == null) taxValue = 0;

          const data = {
            RestaurantId: this.state.id,
            DeliveryTax: taxValue,
          };

          this.setState({ loadingDeliveryTax: true });
          const response = await RestaurantService.updateDeliveryTax(data);
          if (response && response.data.Success) {
            message.success("Updated!");
          } else {
            message.error(response.data.APIMessage);
          }
          this.setState({ loadingDeliveryTax: false });
        } catch (ex) {
          this.setState({ loadingDeliveryTax: false });
          message.error(ex.message);
        }
      } else {
        message.error("Some fields are required or contains invalid value!");
      }
    });
  };

  handleFixedValueSubmit = async (event, v) => {
    event.preventDefault();
    try {
      let validateFields = ["Amount", "DistanceUpToKM"];
      this.props.form.validateFields(validateFields, async (err, values) => {
        if (!err) {
          if (this.state.fixAmountId) {
            const restaurantInfo = {
              RestaurantId: this.state.id,
              FixAmountId: this.state.fixAmountId,
              Amount: values["Amount"],
              DistanceUpToKM: values["DistanceUpToKM"],
            };

            this.setState({ loadingFixedValue: true });
            const response = await RestaurantService.updateRestaurantFixAmount(
              restaurantInfo
            );
            if (response && response.data.Success) {
              const fixedFeeList = response.data.Data;
              this.fixedFeeTableCreatingWithList(fixedFeeList);

              message.success("Updated!");
              this.props.form.resetFields(["Amount", "DistanceUpToKM"]);
              this.setState({ fixAmountId: "" });
            } else {
              message.error("Failed!");
            }
            this.setState({ loadingFixedValue: false });
          } else {
            let opt = this.state.fixedOrDistance;
            let amount = {
              Id: this.state.id,
              Amount: values["Amount"],
              DistanceUpToKM: values["DistanceUpToKM"],
            };

            this.setState({ loadingFixedValue: true });

            const response = await RestaurantService.addDeliveryFixAmount(
              amount
            );

            console.log(response);
            if (response && response.data.Success) {
              const fixedFeeList = response.data.Data;
              this.fixedFeeTableCreatingWithList(fixedFeeList);

              message.success(`Fix amount successfully saved!`);
              this.props.form.setFieldsValue({
                Amount: "",
                DistanceUpToKM: "",
              });

              if (this.state.isTableEmpty) {
                let charges = {
                  Id: this.state.id,
                  IsFixedFee: true,
                  IsDistanceBased: false,
                };

                const res = await RestaurantService.saveDeliverychargetype(
                  charges
                );

                console.log(res);
                if (res && res.data.Success) {
                  message.success(`Fixed Fee Selected!`);
                } else {
                  message.error(res.data.APIMessage);
                }
              }
            } else {
              message.error(response.data.APIMessage);
            }
            this.setState({ loadingFixedValue: false });
          }
        } else {
          message.error("Some fields are required or contains invalid value!");
        }
      });
    } catch {
      message.error("Unexpected error occured while adding fix amount.");
    }
  };

  validateNumber = (value) => {
    if (value == "" || value == null || value == undefined) {
      return 0;
    } else {
      try {
        return Number.parseFloat(value + "");
      } catch {
        return 0;
      }
    }
  };

  handleDeliveryPlatformChargesSubmit = async (event, v) => {
    event.preventDefault();
    this.props.form.validateFields(
      [
        "CommissionPercentDelivery",
        "CommissionTaxDelivery",
        "PlatformDeliveryEnabled",
        "PlatformDeliveryType",
        "PlatformDeliveryValue",
      ],
      async (err, values) => {
        if (!err) {
          this.props.form.setFieldsValue({
            CommissionPercent: this.validateNumber(
              values["CommissionPercentDelivery"]
            ),
          });

          let charges = {
            Id: this.state.id,
            IsOrderPercent: true,
            isDelievryCharges: true,
            CommissionPercent: this.validateNumber(
              values["CommissionPercentDelivery"]
            ),
            CommissionTaxPercent: this.validateNumber(
              values["CommissionTaxDelivery"]
            ),
            PlatformChargesEnabled: values["PlatformDeliveryEnabled"],
            PlatformType: values["PlatformDeliveryType"],
            PlatformValue: this.validateNumber(values["PlatformDeliveryValue"]),
          };

          try {
            this.setState({ loadingDeliveryPlatformCharges: true });
            const response = await RestaurantService.savePlatformCharges(
              charges
            );

            console.log(response);
            if (response && response.data.Success) {
              message.success(`Amount successfully saved!`);
            } else {
              message.error(response.data.APIMessage);
            }
          } catch {
            message.error("Unexpected error occured while saving amount.");
          } finally {
            this.setState({ loadingDeliveryPlatformCharges: false });
          }
        } else {
          message.error("Some fields are required or contains invalid value!");
        }
      }
    );
  };

  handleTakeawayPlatformChargesSubmit = async (event, v) => {
    event.preventDefault();

    this.props.form.validateFields(
      [
        "CommissionPercentTakeaway",
        "CommissionTaxTakeaway",
        "PlatformTakeawayEnabled",
        "PlatformTakeawayType",
        "PlatformTakeawayValue",
      ],
      async (err, values) => {
        if (!err) {
          this.props.form.setFieldsValue({
            CommissionPercent: this.validateNumber(
              values["CommissionPercentTakeaway"]
            ),
          });

          let charges = {
            Id: this.state.id,
            IsOrderPercent: true,
            isDelievryCharges: false,
            CommissionPercent: this.validateNumber(
              values["CommissionPercentTakeaway"]
            ),
            CommissionTaxPercent: this.validateNumber(
              values["CommissionTaxTakeaway"]
            ),
            PlatformChargesEnabled: values["PlatformTakeawayEnabled"],
            PlatformType: values["PlatformTakeawayType"],
            PlatformValue: this.validateNumber(values["PlatformTakeawayValue"]),
          };

          try {
            this.setState({ loadingTakeawayPlatformCharges: true });

            const response = await RestaurantService.savePlatformCharges(
              charges
            );

            console.log(response);
            if (response && response.data.Success) {
              message.success(`Amount successfully saved!`);
            } else {
              message.error(response.data.APIMessage);
            }
          } catch {
            message.error("Unexpected error occured while saving amount.");
          } finally {
            this.setState({ loadingTakeawayPlatformCharges: false });
          }
        } else {
          message.error("Some fields are required or contains invalid value!");
        }
      }
    );
  };

  handleDineInPlatformChargesSubmit = async (event, v) => {
    event.preventDefault();

    this.props.form.validateFields(
      [
        "CommissionPercentDineIn",
        "CommissionTaxDineIn",
        "PlatformDineInEnabled",
        "PlatformDineInType",
        "PlatformDineInValue",
      ],
      async (err, values) => {
        if (!err) {
          this.props.form.setFieldsValue({
            CommissionPercent: this.validateNumber(
              values["CommissionPercentDineIn"]
            ),
          });

          let charges = {
            Id: this.state.id,
            IsOrderPercent: true,
            isDelievryCharges: false,
            IsDineInCharges: true,
            CommissionPercent: this.validateNumber(
              values["CommissionPercentDineIn"]
            ),
            CommissionTaxPercent: this.validateNumber(
              values["CommissionTaxDineIn"]
            ),
            PlatformChargesEnabled: values["PlatformDineInEnabled"],
            PlatformType: values["PlatformDineInType"],
            PlatformValue: this.validateNumber(values["PlatformDineInValue"]),
          };

          try {
            this.setState({ loadingDineInPlatformCharges: true });

            const response = await RestaurantService.savePlatformCharges(
              charges
            );

            console.log(response);
            if (response && response.data.Success) {
              message.success(`Amount successfully saved!`);
            } else {
              message.error(response.data.APIMessage);
            }
          } catch {
            message.error("Unexpected error occured while saving amount.");
          } finally {
            this.setState({ loadingDineInPlatformCharges: false });
          }
        } else {
          message.error("Some fields are required or contains invalid value!");
        }
      }
    );
  };

  handleSavePlatformChargesSubmit = async (event, v) => {
    event.preventDefault();

    // this.props.form.validateFields(["FixedAmount", "OnOrderAmountUpto", "CommissionPercent"], async (err, values) => {
    this.props.form.validateFields(
      ["FixedAmount", "CommissionPercent"],
      async (err, values) => {
        if (!err) {
          this.props.form.setFieldsValue({
            FixedAmount: this.validateNumber(values["FixedAmount"]),
            // OnOrderAmountUpto: this.validateNumber(values["OnOrderAmountUpto"]),
            CommissionPercent: this.validateNumber(values["CommissionPercent"]),
          });

          let opt = this.state.fixedOrPercentCommission;

          let charges = {
            Id: this.state.id,
            IsFixedAmount: opt == 1 || opt == 3 ? true : false,
            IsOrderPercent: opt == 2 || opt == 3 ? true : false,
            FixedAmount: this.validateNumber(values["FixedAmount"]),
            // OnOrderAmountUpto: this.validateNumber(values["OnOrderAmountUpto"]),
            CommissionPercent: this.validateNumber(values["CommissionPercent"]),
          };

          try {
            this.setState({ loadingSavePlatformCharges: true });

            const response = await RestaurantService.savePlatformCharges(
              charges
            );

            console.log(response);
            if (response && response.data.Success) {
              message.success(`Amount successfully saved!`);
            } else {
              message.error(response.data.APIMessage);
            }
          } catch {
            message.error("Unexpected error occured while saving amount.");
          } finally {
            this.setState({ loadingSavePlatformCharges: false });
          }
        } else {
          message.error("Some fields are required or contains invalid value!");
        }
      }
    );
  };

  handleCreditChargesSubmit = async (event, v) => {
    event.preventDefault();
    let fields = ["PaymentMethod", "ChargeAmount", "ChargeTax"];
    this.props.form.validateFields(fields, async (err, values) => {
      if (!err) {
        this.setState({ loadingCreditCharges: true });
        let creditCharges = [];

        for (var i = 0; i < values.PaymentMethod.length; i++) {
          creditCharges.push({
            PaymentMethod: values.PaymentMethod[i],
            ChargeAmount: values.ChargeAmount[i],
            ChargeTax: values.ChargeTax[i],
          });
        }
        let request = {
          Id: this.state.id,
          CreditCharges: creditCharges,
        };

        try {
          var response = await RestaurantService.saveCreditCharges(request);

          if (response && response.Success) {
            message.success(`Charges successfully saved!`);
          } else {
            message.error(response.APIMessage);
          }
        } catch (e) {
          message.error("Unexpected error occured while saving amount.");
        } finally {
          this.setState({ loadingCreditCharges: false });
        }
      }
    });
  };

  handleMinimumOrderSubmit = async (event, v) => {
    event.preventDefault();

    let validateFields = ["MinimumOrderAmount"];
    this.props.form.validateFields(validateFields, async (err, values) => {
      if (!err) {
        let charges = {
          Id: this.state.id,
          Amount: values["MinimumOrderAmount"],
        };

        try {
          this.setState({ loadingMinimumOrder: true });

          const response = await RestaurantService.saveMinimumOrderAmount(
            charges
          );

          console.log(response);
          if (response && response.data.Success) {
            message.success(`Amount successfully saved!`);
          } else {
            message.error(response.data.APIMessage);
          }
        } catch {
          message.error("Unexpected error occured while saving amount.");
        }

        this.setState({ loadingMinimumOrder: false });
      } else {
        message.error("Some fields are required or contains invalid value!");
      }
    });
  };

  handleSaveChargesSubmit = async (event, v) => {
    event.preventDefault();

    let validateFields = ["FixedOrDistance", "PerKMAmount"];
    this.props.form.validateFields(validateFields, async (err, values) => {
      if (!err) {
        try {
          this.props.form.setFieldsValue({
            PerKMAmount: this.validateNumber(values["PerKMAmount"]),
          });

          let opt = this.state.fixedOrDistance;
          let charges = {
            Id: this.state.id,
            IsFixedFee: opt == 1 || opt == 3 ? true : false,
            IsDistanceBased: opt == 2 || opt == 3 ? true : false,
            PerKMAmount: this.validateNumber(values["PerKMAmount"]),
          };

          var tempDeliveryCharges = this.state.dataDeliveryCharges.map((x) => {
            return {
              ...x,
              perKMAmount: opt == 2 || opt == 3 ? values["PerKMAmount"] : "",
            };
          });
          this.setState({ dataDeliveryCharges: tempDeliveryCharges });

          this.setState({ loadingSaveCharges: true });

          const response = await RestaurantService.saveDeliveryCharges(charges);

          console.log(response);
          if (response && response.data.Success) {
            message.success(`Charges successfully saved!`);
          } else {
            message.error(response.data.APIMessage);
          }
        } catch {
          message.error("Unexpected error occured while saving charges.");
        }
        this.setState({ loadingSaveCharges: false });
      } else {
        message.error("Some fields are required or contains invalid value!");
      }
    });
  };

  handleRestaurantStatus = async (event) => {
    event.preventDefault();

    this.props.form.validateFields(["Status"], async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.setState({ loadingForStatus: true });
        const status = values.Status;
        const id = this.state.id;

        const response = await RestaurantService.changeRestaurantStatus(
          status,
          id
        );
        if (response && response.data.Success) {
          message.success("Changed");
          this.setState({ loadingForStatus: false });
        } else if (response.data.APICode == 13) {
          this.setState({
            enableRestaurant: false,
            loadingForStatus: false,
          });
          Modal.error({
            title: response.data.APIMessage,
            content: (
              <span>
                <ul>
                  {response.data.Data.map((v) => (
                    <li>{v}</li>
                  ))}
                </ul>
              </span>
            ),
          });
        } else {
          this.setState({
            enableRestaurant: false,
            loadingForStatus: false,
          });
          message.error(response.data.APIMessage);
        }
      }
    });
  };

  handleSubmit = async (event, v) => {
    event.preventDefault();

    let validateFields = [
      "CompanyNumber",
      "VAT",
      "CompanyName",
      "Name",
      "IsFeatured",
      "IsEcoFriendly",
      "Email",
      "RestaurantPhone",
      "ContactName",
      "PhoneNumber",
      "PhoneNumber2",
      "FullAddress",
      "Province",
      "ServiceCityId",
      "ZipCode",
      "Latitude",
      "Longitude",
      "Cuisines",
      "Password",
      "EnableSlider",
      "ItemViewType",
      "ShowTax",
      "AllowOtp",
      "IsExclusiveTax",
      "TaxDisplayText",
      "BusinessType",
      "OrderWithoutHeartBeat",
      "ExcludeFromSpeedoyModel",
      "WithoutPos",
      "AutoAcceptOrder",
      "IsItemQuantityEditable",
      "IsTestRestaurant",
      "ThirdPartyAppId",
    ];

    try {
      this.props.form.validateFields(validateFields, async (err, values) => {
        console.log(values);
        if (!err) {
          this.setState({ loading: true }, async () => {
            try {
              let logo = this.state.logo ? this.state.logo.originFileObj : [];
              let cover = this.state.cover
                ? this.state.cover.originFileObj
                : [];
              let thumbnail = this.state.thumbnail
                ? this.state.thumbnail.originFileObj
                : [];

              let formData = new FormData();
              formData.append("Id", this.state.id || "");

              formData.append("ServiceCityId", values.ServiceCityId);
              formData.append("CompanyName", values.CompanyName);
              formData.append("CompanyNumber", values.CompanyNumber);
              formData.append("ContactName", values.ContactName);
              const cuisines = values.Cuisines;
              cuisines.forEach((item) => formData.append("cuisines[]", item));
              formData.append("Email", values.Email);
              formData.append("FullAddress", values.FullAddress);
              formData.append("IsFeatured", values.IsFeatured || false);
              formData.append("IsEcoFriendly", values.IsEcoFriendly || false);
              formData.append("Latitude", values.Latitude);
              formData.append("Longitude", values.Longitude);
              formData.append("Name", values.Name);
              formData.append("Password", values.Password);
              formData.append("PhoneNumber", values.PhoneNumber);
              formData.append("PhoneNumber2", values.PhoneNumber2);
              formData.append("Province", values.Province);
              formData.append("RestaurantPhone", values.RestaurantPhone);
              // formData.append('Status', this.state.enableRestaurant);
              formData.append("VAT", values.VAT);
              formData.append("ZipCode", values.ZipCode);
              formData.append("EnableSlider", values.EnableSlider || false);
              formData.append("ItemViewType", values.ItemViewType);
              formData.append("BusinessType", values.BusinessType);
              formData.append("ShowTax", values.ShowTax || false);
              formData.append("AllowOtp", values.AllowOtp);
              formData.append("IsExclusiveTax", values.IsExclusiveTax);
              formData.append("TaxDisplayText", values.TaxDisplayText);
              formData.append(
                "OrderWithoutHeartBeat",
                values.OrderWithoutHeartBeat
              );
              formData.append(
                "ExcludeFromSpeedoyModel",
                values.ExcludeFromSpeedoyModel || false
              );
              formData.append("WithoutPos", values.WithoutPos || false);
              formData.append(
                "AutoAcceptOrder",
                values.AutoAcceptOrder || false
              );
              formData.append(
                "IsItemQuantityEditable",
                values.IsItemQuantityEditable || false
              );

              formData.append(
                "IsTestRestaurant",
                values.IsTestRestaurant || false
              );
              formData.append("ThirdPartyAppId", values.ThirdPartyAppId);

              if (this.state.logo) {
                formData.append("Logo", logo);
              }
              if (this.state.cover) {
                formData.append("Image", cover);
              }
              if (this.state.thumbnail) {
                formData.append("Thumbnail", thumbnail);
              }
              const response = await RestaurantService.createRestaurant(
                formData
                // upDatedValues
              );
              console.log(response);

              if (response && response.data.Success) {
                this.setState({ id: response.data.Data.Id }, () => {
                  message.success(`Restaurant successfully saved!`);
                  this.props.history.push(
                    "/restaurants/update/" + response.data.Data.Id
                  );
                });
              } else {
                message.error(response.data.APIMessage);
              }

              this.setState({ loading: false });
            } catch (e) {
              message.error(
                _.get(
                  e,
                  "response.data.APIMessage",
                  "Unexpected error occured while creating restaurant."
                )
              );
              this.setState({ loading: false });
            }
          });
        } else {
          message.error("Some fields are required or contains invalid value!");
        }
      });
    } catch (e) {
      console.log("error", e);
      message.error(
        _.get(
          e,
          "response.data.APIMessage",
          "Unexpected error occured while creating restaurant."
        )
      );
    } finally {
    }
  };

  loadCities = async () => {
    try {
      var response = await CityService.cityNameList();
      if (response && response.Success) {
        let citiesData = response.Data;
        let sortedAscCities = citiesData?.sort((a, b) => {
          return a.Name.localeCompare(b.Name);
        });
        this.setState({ cities: sortedAscCities });
      } else {
        message.error(response.APIMessage);
      }
    } catch {
      message.error("Something going wrong!");
    }
    this.setState({ loadingCity: false });
    console.log(this.state);
  };

  // Modal-scripts end
  render() {
    // form-scripts start
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 24, xs: 24, sm: 24 },
            wrapperCol: { xs: 24, sm: 24 },
          }
        : null;
    const formItemLayout2 =
      formLayout === "horizontal"
        ? {
            labelCol: { xs: 24, sm: 8 },
            wrapperCol: { xs: 24, sm: 8 },
          }
        : null;
    const buttonItemLayout = null;

    // tab-script-start
    const TabPane = Tabs.TabPane;
    const Option = Select.Option;
    // tab-script-end

    function handleChange(value) {
      console.log(`selected ${value}`);
    }
    const { getFieldDecorator, setFieldsValue } = this.props.form;

    const { intl } = this.props;
    const companyRegistration = intl.formatMessage({
      id: "info.companyRegistration",
    });
    const vat = intl.formatMessage({ id: "info.vat" });
    const companyName = intl.formatMessage({ id: "info.companyName" });
    const restaurantName = intl.formatMessage({ id: "info.restaurantName" });
    const email = intl.formatMessage({ id: "info.email" });
    const password = intl.formatMessage({ id: "info.password" });
    const restaurantPhone = intl.formatMessage({ id: "info.restaurantPhone" });
    const conatctName = intl.formatMessage({ id: "info.conatctName" });
    const conatctPhoneOne = intl.formatMessage({ id: "info.conatctPhoneOne" });
    const conatctPhoneTwo = intl.formatMessage({ id: "info.conatctPhoneTwo" });
    const address = intl.formatMessage({ id: "info.address" });
    const province = intl.formatMessage({ id: "info.province" });
    const city = intl.formatMessage({ id: "info.city" });
    const zipCode = intl.formatMessage({ id: "info.zipCode" });
    const latitude = intl.formatMessage({ id: "info.latitude" });
    const longitude = intl.formatMessage({ id: "info.longitude" });
    const pleaseSelect = intl.formatMessage({ id: "info.pleaseSelect" });

    const amount = intl.formatMessage({ id: "charges.amount" });
    const enterPerKmAmount = intl.formatMessage({
      id: "charges.enterPerKmAmount",
    });
    const fixedAmount = intl.formatMessage({ id: "charges.fixedAmount" });
    const distanceKm = intl.formatMessage({ id: "charges.distanceKm" });
    const taxDisplayText = intl.formatMessage({ id: "info.taxDisplayText" });
    const thirdPartyAppIdPlaceholder = intl.formatMessage({
      id: "info.thirdPartyAppId",
    });

    // This is for multiple Credit charges, this part will be used in future so commenting this out for now
    // const creditChargesFormItems = chargeablePaymentMethods.filter(
    //   (a) => a.country == this.props.whitelisting.country
    // ).map(a=>{
    //   return (

    //    )
    // });
    // MULTIPLE-scripts End
    return (
      <div className="resturantDetail-main">
        <Card className="gx-card breadcrumb-box" title="">
          <div>
            <div className="ant-row">
              <div className="purchases-tabs-box ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
                <Tabs tabPosition="top">
                  <TabPane
                    tab={<IntlMessages id="info.restaurantInfo" />}
                    key="1"
                  >
                    <Form
                      onSubmit={(e) => this.handleSubmit(e)}
                      layout={formLayout}
                    >
                      <div className="rest-info-box">
                        <div className="title-sub">
                          <h2>
                            <span style={{ color: "red" }}>*</span>
                            <IntlMessages id="info.restaurantInfo" />
                          </h2>
                          <hr className="hr-line"></hr>
                        </div>

                        <div class="ant-row">
                          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 rest-info-box-lft">
                            <div className="detail-res-lft">
                              <div className="gx-mb-3">
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.companyRegistration"
                                      defaultMessage="Company Registration #"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("CompanyNumber", {
                                    rules: [
                                      { required: true, message: "Required!" },
                                    ],
                                  })(
                                    <Input placeholder={companyRegistration} />
                                  )}
                                </FormItem>
                              </div>
                              <div className="gx-mb-3">
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.vat"
                                      defaultMessage="VAT"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("VAT", {
                                    rules: [
                                      { required: true, message: "Required!" },
                                    ],
                                  })(<Input placeholder={vat} />)}
                                </FormItem>
                              </div>
                              <div className="gx-mb-3">
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.companyName"
                                      defaultMessage="Company Name"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("CompanyName", {
                                    rules: [
                                      { required: true, message: "Required!" },
                                    ],
                                  })(<Input placeholder={companyName} />)}
                                </FormItem>
                              </div>
                              <div className="gx-mb-3">
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.restaurantName"
                                      defaultMessage="Restaurant Name"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("Name", {
                                    rules: [
                                      { required: true, message: "Required!" },
                                    ],
                                  })(<Input placeholder={restaurantName} />)}
                                </FormItem>
                              </div>
                              <div className="gx-mt-4">
                                <FormItem
                                  {...formItemLayout}
                                  valuePropName="checked"
                                >
                                  {getFieldDecorator("IsFeatured", {
                                    rules: [],
                                  })(
                                    <Checkbox
                                      checked={this.state.isFeatured}
                                      onClick={(e) =>
                                        this.setState({
                                          isFeatured: e.target.checked,
                                        })
                                      }
                                      setFieldsValue={true}
                                    >
                                      <IntlMessages id="info.featured" />
                                    </Checkbox>
                                  )}
                                </FormItem>
                                <FormItem
                                  {...formItemLayout}
                                  valuePropName="checked"
                                >
                                  {getFieldDecorator("IsEcoFriendly", {
                                    rules: [],
                                  })(
                                    <Checkbox
                                      checked={this.state.IsEcoFriendly}
                                      onClick={(e) =>
                                        this.setState({
                                          IsEcoFriendly: e.target.checked,
                                        })
                                      }
                                      setFieldsValue={true}
                                    >
                                      <IntlMessages id="info.isEcoFriendly" />
                                    </Checkbox>
                                  )}
                                </FormItem>
                                <FormItem
                                  {...formItemLayout}
                                  valuePropName="checked"
                                >
                                  {getFieldDecorator("EnableSlider", {
                                    rules: [],
                                  })(
                                    <Checkbox
                                      checked={this.state.enableSlider}
                                      onClick={(e) =>
                                        this.setState({
                                          enableSlider: e.target.checked,
                                        })
                                      }
                                      setFieldsValue={true}
                                    >
                                      <IntlMessages id="info.enableSlider" />
                                    </Checkbox>
                                  )}
                                </FormItem>
                                <FormItem
                                  {...formItemLayout}
                                  valuePropName="checked"
                                >
                                  {getFieldDecorator("ShowTax", {
                                    rules: [],
                                  })(
                                    <Checkbox
                                      checked={this.state.showTax}
                                      onClick={(e) =>
                                        this.setState({
                                          showTax: e.target.checked,
                                        })
                                      }
                                      setFieldsValue={true}
                                    >
                                      <IntlMessages
                                        id="info.showTax"
                                        defaultMessage="Show Exclusive VAT Tax"
                                      />
                                    </Checkbox>
                                  )}
                                </FormItem>
                                <FormItem
                                  {...formItemLayout}
                                  valuePropName="checked"
                                >
                                  {getFieldDecorator("AllowOtp", {
                                    rules: [],
                                    initialValue: this.state.allowOtp,
                                  })(
                                    <Checkbox
                                      checked={this.state.allowOtp}
                                      onClick={(e) =>
                                        this.setState({
                                          allowOtp: e.target.checked,
                                        })
                                      }
                                      setFieldsValue={true}
                                    >
                                      <IntlMessages
                                        id="info.allowOtp"
                                        defaultMessage="Allow guest otp"
                                      />
                                    </Checkbox>
                                  )}
                                </FormItem>
                                <FormItem
                                  {...formItemLayout}
                                  valuePropName="checked"
                                >
                                  {getFieldDecorator("IsExclusiveTax", {
                                    rules: [],
                                    initialValue: this.state.isExclusiveTax,
                                  })(
                                    <Checkbox
                                      checked={this.state.isExclusiveTax}
                                      onClick={(e) =>
                                        this.setState({
                                          isExclusiveTax: e.target.checked,
                                        })
                                      }
                                      setFieldsValue={true}
                                    >
                                      <IntlMessages
                                        id="info.isExclusiveTax"
                                        defaultMessage="Exclusive of tax"
                                      />
                                    </Checkbox>
                                  )}
                                </FormItem>
                                <FormItem
                                  {...formItemLayout}
                                  valuePropName="checked"
                                >
                                  {getFieldDecorator("OrderWithoutHeartBeat", {
                                    rules: [],
                                    initialValue:
                                      this.state.orderWithoutHeartBeat,
                                  })(
                                    <Checkbox
                                      checked={this.state.orderWithoutHeartBeat}
                                      onClick={(e) =>
                                        this.setState({
                                          orderWithoutHeartBeat:
                                            e.target.checked,
                                        })
                                      }
                                      setFieldsValue={true}
                                    >
                                      <IntlMessages
                                        id="info.orderWithoutHeartBeat"
                                        defaultMessage="Order without heart beat"
                                      />
                                    </Checkbox>
                                  )}
                                </FormItem>
                                <FormItem
                                  {...formItemLayout}
                                  valuePropName="checked"
                                >
                                  {getFieldDecorator(
                                    "ExcludeFromSpeedoyModel",
                                    {
                                      rules: [],
                                      initialValue:
                                        this.state.excludeFromSpeedoyModel,
                                    }
                                  )(
                                    <Checkbox
                                      checked={
                                        this.state.excludeFromSpeedoyModel
                                      }
                                      onClick={(e) =>
                                        this.setState({
                                          excludeFromSpeedoyModel:
                                            e.target.checked,
                                        })
                                      }
                                      setFieldsValue={true}
                                    >
                                      <IntlMessages
                                        id="info.excludeFromSpeedoyModel"
                                        defaultMessage="Exclude from Speedoy Model"
                                      />
                                    </Checkbox>
                                  )}
                                </FormItem>
                                <FormItem
                                  {...formItemLayout}
                                  valuePropName="checked"
                                >
                                  {getFieldDecorator("WithoutPos", {
                                    rules: [],
                                    initialValue: this.state.withoutPos,
                                  })(
                                    <Checkbox
                                      checked={this.state.withoutPos}
                                      onClick={(e) =>
                                        this.setState({
                                          withoutPos: e.target.checked,
                                        })
                                      }
                                      setFieldsValue={true}
                                    >
                                      <IntlMessages
                                        id="info.withoutPos"
                                        defaultMessage="Without POS"
                                      />
                                    </Checkbox>
                                  )}
                                </FormItem>
                                <FormItem
                                  {...formItemLayout}
                                  valuePropName="checked"
                                >
                                  {getFieldDecorator("AutoAcceptOrder", {
                                    rules: [],
                                    initialValue: this.state.autoAcceptOrder,
                                  })(
                                    <Checkbox
                                      checked={this.state.autoAcceptOrder}
                                      onClick={(e) =>
                                        this.setState({
                                          autoAcceptOrder: e.target.checked,
                                        })
                                      }
                                      setFieldsValue={true}
                                    >
                                      <IntlMessages
                                        id="info.autoAcceptOrder"
                                        defaultMessage="Auto Accept Order"
                                      />
                                    </Checkbox>
                                  )}
                                </FormItem>
                                <FormItem
                                  {...formItemLayout}
                                  valuePropName="checked"
                                >
                                  {getFieldDecorator("IsItemQuantityEditable", {
                                    rules: [],
                                    initialValue:
                                      this.state.isItemQuantityEditable,
                                  })(
                                    <Checkbox
                                      checked={
                                        this.state.isItemQuantityEditable
                                      }
                                      onClick={(e) =>
                                        this.setState({
                                          isItemQuantityEditable:
                                            e.target.checked,
                                        })
                                      }
                                      setFieldsValue={true}
                                    >
                                      <IntlMessages
                                        id="info.isItemQuantityEditable"
                                        defaultMessage="Item Quantity Editable"
                                      />
                                    </Checkbox>
                                  )}
                                </FormItem>
                                <FormItem
                                  {...formItemLayout}
                                  valuePropName="checked"
                                >
                                  {getFieldDecorator("IsTestRestaurant", {
                                    rules: [],
                                    initialValue: this.state.isTestRestaurant,
                                  })(
                                    <Checkbox
                                      checked={this.state.isTestRestaurant}
                                      onClick={(e) =>
                                        this.setState({
                                          isTestRestaurant: e.target.checked,
                                        })
                                      }
                                      setFieldsValue={true}
                                    >
                                      <IntlMessages
                                        id="info.isTestRestaurant"
                                        defaultMessage="Test Restaurant"
                                      />
                                    </Checkbox>
                                  )}
                                </FormItem>
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.taxDisplayText"
                                      defaultMessage="Display Text for Tax"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator(
                                    "TaxDisplayText",
                                    {}
                                  )(
                                    <Input
                                      placeholder={taxDisplayText}
                                      maxLength="10"
                                    />
                                  )}
                                </FormItem>
                              </div>

                              <div className="gx-mb-3">
                                <FormItem {...formItemLayout}>
                                  <Upload
                                    fileList={this.state.selectedLogo}
                                    customRequest={customRequest}
                                    onChange={this.onChangeLogo}
                                    showUploadList={false}
                                    beforeUpload={(file) => {
                                      const isValidImage =
                                        file.type === "image/jpeg" ||
                                        file.type === "image/png" ||
                                        file.type === "image/jpg";
                                      if (!isValidImage) {
                                        message.error(
                                          "You can only upload JPG or PNG file!"
                                        );
                                        return false;
                                      } else {
                                        return true;
                                      }
                                    }}
                                  >
                                    <Button>
                                      <IntlMessages id="info.chooseLogo" />
                                    </Button>
                                  </Upload>
                                </FormItem>
                                <div className="detail-res-rite upload-img-box">
                                  <img src={this.state.logoUrl} />
                                </div>
                              </div>

                              <div className="gx-mb-3">
                                <FormItem {...formItemLayout}>
                                  <Upload
                                    fileList={this.state.selectedCover}
                                    customRequest={customRequest}
                                    onChange={this.onChangeCover}
                                    showUploadList={false}
                                    beforeUpload={(file) => {
                                      const isValidImage =
                                        file.type === "image/jpeg" ||
                                        file.type === "image/png" ||
                                        file.type === "image/jpg";
                                      if (!isValidImage) {
                                        message.error(
                                          "You can only upload JPG or PNG file!"
                                        );
                                        return false;
                                      } else {
                                        return true;
                                      }
                                    }}
                                  >
                                    <Button>
                                      <IntlMessages id="info.chooseCover" />
                                    </Button>
                                  </Upload>
                                </FormItem>
                                <div className="detail-res-rite upload-img-box">
                                  <img src={this.state.coverUrl} />
                                </div>
                              </div>

                              <div className="gx-mb-3">
                                <FormItem {...formItemLayout}>
                                  <Upload
                                    fileList={this.state.selectedThumbnail}
                                    customRequest={customRequest}
                                    onChange={this.onChangeThumbnail}
                                    showUploadList={false}
                                    beforeUpload={(file) => {
                                      const isValidImage =
                                        file.type === "image/jpeg" ||
                                        file.type === "image/png" ||
                                        file.type === "image/jpg";
                                      if (!isValidImage) {
                                        message.error(
                                          "You can only upload JPG or PNG file!"
                                        );
                                        return false;
                                      } else {
                                        return true;
                                      }
                                    }}
                                  >
                                    <Button>
                                      <IntlMessages
                                        id="info.chooseThumbnail"
                                        defaultMessage="Choose Thumbnail"
                                      />
                                    </Button>
                                  </Upload>
                                </FormItem>
                                <div className="detail-res-rite upload-img-box">
                                  <img src={this.state.thumbnailUrl} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 rest-info-box-rite">
                            <div className="detail-res-rite">
                              <div className="gx-mb-3">
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.email"
                                      defaultMessage="Email"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("Email", {
                                    rules: [
                                      { required: true, message: "Required!" },
                                      {
                                        type: "email",
                                        message: "Please enter valid email.",
                                      },
                                    ],
                                  })(<Input placeholder={email} />)}
                                </FormItem>
                              </div>

                              <div className="gx-mb-3">
                                <Form.Item
                                  label={
                                    <IntlMessages
                                      id="info.password"
                                      defaultMessage="Password"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("Password", {
                                    rules: [
                                      this.state.id == null
                                        ? inputRules.required
                                        : inputRules.noRule,
                                    ],
                                  })(
                                    <Input.Password
                                      autocomplete="new-password"
                                      placeholder={password}
                                    />
                                  )}
                                </Form.Item>
                              </div>
                              <div className="gx-mb-3">
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.restaurantPhone"
                                      defaultMessage="Restaurant Phone"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("RestaurantPhone", {
                                    rules: [
                                      { required: true, message: "Required!" },
                                      {
                                        max: 15,
                                        message: "Invalid Restaurant Phone!",
                                      },
                                    ],
                                  })(<Input placeholder={restaurantPhone} />)}
                                </FormItem>
                              </div>

                              <div className="gx-mb-3">
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.conatctName"
                                      defaultMessage="Contact Name"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("ContactName", {
                                    rules: [
                                      { required: true, message: "Required!" },
                                    ],
                                  })(<Input placeholder={conatctName} />)}
                                </FormItem>
                              </div>
                              <div className="gx-mb-3">
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.conatctPhoneOne"
                                      defaultMessage="Contact Phone 1"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("PhoneNumber", {
                                    rules: [
                                      {
                                        max: 15,
                                        message: "Invalid Phone Number!",
                                      },
                                    ],
                                  })(<Input placeholder={conatctPhoneOne} />)}
                                </FormItem>
                              </div>
                              <div className="gx-mb-3">
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.conatctPhoneTwo"
                                      defaultMessage="Contact Phone 2"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("PhoneNumber2", {
                                    rules: [
                                      {
                                        max: 15,
                                        message: "Invalid Phone Number 2!",
                                      },
                                    ],
                                  })(<Input placeholder={conatctPhoneTwo} />)}
                                </FormItem>
                              </div>
                              <div className="gx-mb-3">
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.thirdPartyAppId"
                                      defaultMessage="3rd Party App ID"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator(
                                    "ThirdPartyAppId",
                                    {}
                                  )(
                                    <Input
                                      placeholder={thirdPartyAppIdPlaceholder}
                                    />
                                  )}
                                </FormItem>
                              </div>
                              <div className="gx-mb-3">
                                <Form.Item
                                  label={
                                    <IntlMessages
                                      id="info.viewType"
                                      defaultMessage="Item View Type"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("ItemViewType", {
                                    initialValue: 1,
                                  })(
                                    <Select style={{ minWidth: "300px" }}>
                                      <Option key={1} value={1}>
                                        Item List View
                                      </Option>
                                      <Option key={2} value={2}>
                                        Item Grid View
                                      </Option>
                                    </Select>
                                  )}
                                </Form.Item>
                              </div>
                              <div className="gx-mb-3">
                                <Form.Item
                                  label={
                                    <IntlMessages
                                      id="info.businessType"
                                      defaultMessage="Business Type"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("BusinessType", {
                                    initialValue: 0,
                                  })(
                                    <Select style={{ minWidth: "300px" }}>
                                      <Option key={0} value={0}>
                                        Restaurant
                                      </Option>
                                      <Option key={1} value={1}>
                                        B2B
                                      </Option>
                                    </Select>
                                  )}
                                </Form.Item>
                              </div>
                              {/*<div className="gx-mb-3"><Checkbox >Featured</Checkbox></div>*/}
                            </div>
                          </div>
                        </div>

                        {/* <div class="ant-row">
                          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24">
                            <div className="title-sub">
                              <h2><IntlMessages id="info.thisWeek" /></h2>
                              <hr className="hr-line"></hr>
                              <div className="rating-box">
                                <ul>
                                  <li>
                                    <span>{this.state.rating}</span>
                                    <Rate
                                      allowHalf
                                      value={this.state.rating}
                                      setFieldsValue={this.state.rating}
                                    />
                                  </li>
                                  <li>
                                    <span>{this.state.ordersCount}</span> <IntlMessages id="info.orders" />
                                  </li>
                                  <li>
                                    <span>
                                      {this.state.ordersRejectionsCount}
                                    </span>{" "}
                                    <IntlMessages id="info.rejections" />
                                  </li>
                                  <li>
                                    <span>
                                      {this.state.ordersClosuresCount}
                                    </span>{" "}
                                    <IntlMessages id="info.closures" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className="title-sub gx-mt-3">
                          <h2>
                            <IntlMessages id="info.address" />
                          </h2>
                          <hr className="hr-line"></hr>
                        </div>
                        <div class="ant-row">
                          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 rest-info-box-lft">
                            <div className="detail-res-lft">
                              <div className="gx-mb-3">
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.address"
                                      defaultMessage="Address"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("FullAddress", {
                                    rules: [
                                      { required: true, message: "Required!" },
                                    ],
                                  })(
                                    <Input.TextArea
                                      rows={4}
                                      placeholder={address}
                                    />
                                  )}
                                </FormItem>
                              </div>
                              <div className="gx-mb-3">
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.province"
                                      defaultMessage="Province"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("Province", {
                                    rules: [],
                                  })(<Input placeholder={province} />)}
                                </FormItem>
                              </div>
                              <div className="gx-mb-3">
                                <Form.Item
                                  label={
                                    <IntlMessages
                                      id="info.city"
                                      defaultMessage="Service City"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("ServiceCityId", {
                                    rules: [
                                      { required: true, message: "Required!" },
                                    ],
                                  })(
                                    <Select style={{ minWidth: "300px" }}>
                                      {this.state.cities.map((item, index) => (
                                        <Option key={index + 1} value={item.Id}>
                                          {item.Name}
                                        </Option>
                                      ))}
                                    </Select>
                                  )}
                                </Form.Item>
                              </div>
                              <div className="gx-mb-3">
                                <FormItem
                                  label={
                                    <IntlMessages
                                      id="info.zipCode"
                                      defaultMessage="Zip Code"
                                    />
                                  }
                                  {...formItemLayout}
                                >
                                  {getFieldDecorator("ZipCode", {
                                    rules: [
                                      { required: true, message: "Required!" },
                                    ],
                                  })(<Input placeholder={zipCode} />)}
                                </FormItem>
                              </div>
                              <div className="ant-input-group ant-input-group-lg gx-mb-3">
                                <div className="ant-col ant-col-xs-12 ant-col-sm-12">
                                  <FormItem
                                    label={
                                      <IntlMessages
                                        id="info.latitude"
                                        defaultMessage="Latitude"
                                      />
                                    }
                                    {...formItemLayout}
                                  >
                                    {getFieldDecorator("Latitude", {
                                      rules: [],
                                    })(
                                      <Input placeholder={latitude} disabled />
                                    )}
                                  </FormItem>
                                </div>
                                <div className="ant-col ant-col-xs-12 ant-col-sm-12">
                                  <FormItem
                                    label={
                                      <IntlMessages
                                        id="info.longitude"
                                        defaultMessage="Longitude"
                                      />
                                    }
                                    {...formItemLayout}
                                  >
                                    {getFieldDecorator("Longitude", {
                                      rules: [],
                                    })(
                                      <Input placeholder={longitude} disabled />
                                    )}
                                  </FormItem>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 rest-info-box-rite">
                            <div className="detail-res-rite map-box">
                              {this.state.map}
                            </div>
                          </div>
                        </div>
                        <div className="title-sub gx-mt-3">
                          <h2>
                            <IntlMessages id="info.availableCuisine" />/
                            <IntlMessages id="info.selectCuisine" />
                          </h2>
                          <hr className="hr-line"></hr>
                        </div>
                        <div class="ant-row">
                          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12">
                            <div className="multi-dropdown-box">
                              <FormItem {...formItemLayout}>
                                {getFieldDecorator("Cuisines", {
                                  rules: [
                                    {
                                      required: true,
                                      message:
                                        "Please select at least one cuisine!",
                                    },
                                  ],
                                })(
                                  <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder={pleaseSelect}
                                    onChange={handleChange}
                                  >
                                    {this.state.cusines}
                                  </Select>
                                )}
                              </FormItem>
                            </div>
                          </div>
                        </div>
                        {/* <div className="title-sub gx-mt-3">
                          <h2><IntlMessages id="info.actions" /></h2>
                          <hr className="hr-line"></hr>
                        </div> */}
                        <div class="ant-row">
                          <div class="ant-col-24 rating-box">
                            <ul>
                              {/* <li className="m-5">
                                <span><IntlMessages id="info.enable" />/<IntlMessages id="info.disable" /> </span>
                                <FormItem {...formItemLayout}>
                                  {getFieldDecorator("Status", {
                                  })(<Switch checked={this.state.enableRestaurant} onChange={(v) => { this.setState({ enableRestaurant: v }) }} />)}
                                </FormItem>
                              </li> */}
                              <li className="m-5">
                                <FormItem {...buttonItemLayout}>
                                  <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={this.state.loading}
                                  >
                                    <IntlMessages id="info.save" />
                                  </Button>
                                </FormItem>
                              </li>
                              {/*
                              <li>
                                <FormItem {...buttonItemLayout}>
                                  <Button
                                    disabled
                                    type="primary"
                                    className="color-white"
                                    onClick={(e) => {}}
                                  >
                                    Login As
                                  </Button>
                                </FormItem>
                              </li>
                              <li>
                                <FormItem {...buttonItemLayout}>
                                  <Button
                                    disabled
                                    type="primary"
                                    className="color-white"
                                    onClick={(e) => {}}
                                  >
                                    Duplicate
                                  </Button>
                                </FormItem>
                              </li>
                              <li>
                                <FormItem {...buttonItemLayout}>
                                  <Button
                                    disabled
                                    type="primary"
                                    className="color-white"
                                    onClick={(e) => {}}
                                  >
                                    Delete
                                  </Button>
                                </FormItem>
                              </li>*/}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Form>

                    <div className="title-sub gx-mt-3">
                      <h2>
                        <IntlMessages id="info.actions" />
                      </h2>
                      <hr className="hr-line"></hr>
                    </div>
                    <div class="ant-row">
                      <Form onSubmit={(e) => this.handleRestaurantStatus(e)}>
                        <div class="ant-col-24 rating-box">
                          <ul>
                            <li className="m-5">
                              <span>
                                <IntlMessages id="info.disable" />/
                                <IntlMessages id="info.enable" />
                              </span>
                              <FormItem {...formItemLayout}>
                                {getFieldDecorator("Status", {
                                  // initialValue: false,
                                })(
                                  <Switch
                                    checked={this.state.enableRestaurant}
                                    onChange={(v) => {
                                      this.setState({ enableRestaurant: v });
                                    }}
                                  />
                                )}
                              </FormItem>
                            </li>
                            <li className="m-5">
                              <FormItem {...buttonItemLayout}>
                                <Button
                                  htmlType="submit"
                                  type="primary"
                                  loading={this.state.loadingForStatus}
                                >
                                  <IntlMessages id="info.changeStatus" />
                                </Button>
                              </FormItem>
                            </li>
                          </ul>
                        </div>
                      </Form>
                    </div>
                  </TabPane>
                  <TabPane
                    disabled={this.state.id == null}
                    tab={<IntlMessages id="charges.charges" />}
                    key="2"
                    className="Charges-tabs"
                  >
                    <div className="title-sub">
                      <h2>
                        <span style={{ color: "red" }}>*</span>
                        <IntlMessages id="charges.commissionChargesDelivery" />
                        <span style={{ fontSize: "12px" }}>
                          {" "}
                          (<IntlMessages id="charges.ofOrder" />)
                        </span>
                      </h2>
                      <hr className="hr-line"></hr>
                    </div>

                    <div class="ant-row charges-tab Platform-Charges">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                        <div className="detail-res-lft">
                          <Form
                            className="ant-row platform-from"
                            style={{ marginLeft: "0px" }}
                            onSubmit={(e) =>
                              this.handleDeliveryPlatformChargesSubmit(e)
                            }
                            layout={formItemLayout2}
                          >
                            <div class="ant-col ant-col-xs-24 ant-col-sm-20  gx-mb-3 charge-label-box">
                              <FormItem
                                label={
                                  <IntlMessages id="charges.commissionOnTransaction" />
                                }
                                {...formItemLayout2}
                              >
                                {getFieldDecorator(
                                  "CommissionPercentDelivery",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        type: "number",
                                        min: 0,
                                        message: "Should be positive number!",
                                      },
                                    ],
                                  }
                                )(
                                  <InputNumber
                                    defaultValue={100}
                                    min={0}
                                    max={100}
                                    style={{ width: 200 }}
                                    placeholder="%"
                                  />
                                )}
                              </FormItem>
                              <FormItem
                                label={
                                  <IntlMessages id="charges.commissionTax" />
                                }
                                {...formItemLayout2}
                              >
                                {getFieldDecorator("CommissionTaxDelivery", {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Value is required!",
                                    },
                                    {
                                      type: "number",
                                      min: 0,
                                      message: "Should be positive number!",
                                    },
                                  ],
                                })(
                                  <InputNumber
                                    defaultValue={100}
                                    min={0}
                                    max={100}
                                    style={{ width: 200 }}
                                    placeholder="%"
                                  />
                                )}
                              </FormItem>
                            </div>
                            <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 add-btn-form-box">
                              <FormItem
                                {...formItemLayout}
                                className="add-btn-form"
                              >
                                <Button
                                  loading={
                                    this.state.loadingDeliveryPlatformCharges
                                  }
                                  htmlType="submit"
                                  type="primary"
                                >
                                  <IntlMessages id="charges.save" />
                                </Button>
                              </FormItem>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className="title-sub">
                      <h2>
                        <span style={{ color: "red" }}>*</span>
                        <IntlMessages id="charges.commissionChargesTakeaway" />
                        <span style={{ fontSize: "12px" }}>
                          {" "}
                          (<IntlMessages id="charges.ofOrder" />)
                        </span>
                      </h2>
                      <hr className="hr-line"></hr>
                    </div>

                    <div class="ant-row charges-tab Platform-Charges">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                        <div className="detail-res-lft">
                          <Form
                            className="ant-row platform-from"
                            style={{ marginLeft: "0px" }}
                            onSubmit={(e) =>
                              this.handleTakeawayPlatformChargesSubmit(e)
                            }
                            layout={formItemLayout2}
                          >
                            <div class="ant-col ant-col-xs-20  gx-mb-3 charge-label-box">
                              <FormItem
                                label={
                                  <IntlMessages id="charges.commissionOnTransaction" />
                                }
                                {...formItemLayout2}
                              >
                                {getFieldDecorator(
                                  "CommissionPercentTakeaway",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        type: "number",
                                        min: 0,
                                        message: "Should be positive number!",
                                      },
                                    ],
                                  }
                                )(
                                  <InputNumber
                                    defaultValue={100}
                                    min={0}
                                    max={100}
                                    style={{ width: 200 }}
                                    placeholder="%"
                                  />
                                )}
                              </FormItem>
                              <FormItem
                                label={
                                  <IntlMessages id="charges.commissionTax" />
                                }
                                {...formItemLayout2}
                              >
                                {getFieldDecorator("CommissionTaxTakeaway", {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Value is required!",
                                    },
                                    {
                                      type: "number",
                                      min: 0,
                                      message: "Should be positive number!",
                                    },
                                  ],
                                })(
                                  <InputNumber
                                    defaultValue={100}
                                    min={0}
                                    max={100}
                                    style={{ width: 200 }}
                                    placeholder="%"
                                  />
                                )}
                              </FormItem>
                            </div>
                            <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 add-btn-form-box">
                              <FormItem
                                {...formItemLayout}
                                className="add-btn-form"
                              >
                                <Button
                                  loading={
                                    this.state.loadingTakeawayPlatformCharges
                                  }
                                  htmlType="submit"
                                  type="primary"
                                >
                                  <IntlMessages id="charges.save" />
                                </Button>
                              </FormItem>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className="title-sub">
                      <h2>
                        <span style={{ color: "red" }}>*</span>
                        <IntlMessages id="charges.commissionChargesDineIn" />
                        <span style={{ fontSize: "12px" }}>
                          {" "}
                          (<IntlMessages id="charges.ofOrder" />)
                        </span>
                      </h2>
                      <hr className="hr-line"></hr>
                    </div>

                    <div class="ant-row charges-tab Platform-Charges">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                        <div className="detail-res-lft">
                          <Form
                            className="ant-row platform-from"
                            style={{ marginLeft: "0px" }}
                            onSubmit={(e) =>
                              this.handleDineInPlatformChargesSubmit(e)
                            }
                            layout={formItemLayout2}
                          >
                            <div class="ant-col ant-col-xs-20  gx-mb-3 charge-label-box">
                              <FormItem
                                label={
                                  <IntlMessages id="charges.commissionOnTransaction" />
                                }
                                {...formItemLayout2}
                              >
                                {getFieldDecorator("CommissionPercentDineIn", {
                                  rules: [
                                    {
                                      required: true,
                                      type: "number",
                                      min: 0,
                                      message: "Should be positive number!",
                                    },
                                  ],
                                })(
                                  <InputNumber
                                    defaultValue={100}
                                    min={0}
                                    max={100}
                                    style={{ width: 200 }}
                                    placeholder="%"
                                  />
                                )}
                              </FormItem>
                              <FormItem
                                label={
                                  <IntlMessages id="charges.commissionTax" />
                                }
                                {...formItemLayout2}
                              >
                                {getFieldDecorator("CommissionTaxDineIn", {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Value is required!",
                                    },
                                    {
                                      type: "number",
                                      min: 0,
                                      message: "Should be positive number!",
                                    },
                                  ],
                                })(
                                  <InputNumber
                                    defaultValue={100}
                                    min={0}
                                    max={100}
                                    style={{ width: 200 }}
                                    placeholder="%"
                                  />
                                )}
                              </FormItem>
                            </div>
                            <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 add-btn-form-box">
                              <FormItem
                                {...formItemLayout}
                                className="add-btn-form"
                              >
                                <Button
                                  loading={
                                    this.state.loadingDineInPlatformCharges
                                  }
                                  htmlType="submit"
                                  type="primary"
                                >
                                  <IntlMessages id="charges.save" />
                                </Button>
                              </FormItem>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>

                    {/* Platform Charges For Delivery */}
                    <div className="title-sub">
                      <h2>
                        <IntlMessages id="charges.platformChargesDelivery" />
                      </h2>
                      <hr className="hr-line"></hr>
                    </div>

                    <div class="ant-row charges-tab Platform-Charges">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                        <div className="detail-res-lft">
                          <PlatformCharges
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout2}
                            platformType="Delivery"
                            loading={this.state.loadingDeliveryPlatformCharges}
                            callBack={async (e) =>
                              await this.handleDeliveryPlatformChargesSubmit(e)
                            }
                            isEnabled={this.state.deliveryChargesEnabled}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Platform Charges For Takeaway */}

                    <div className="title-sub">
                      <h2>
                        <IntlMessages id="charges.platformChargesTakeaway" />
                      </h2>
                      <hr className="hr-line"></hr>
                    </div>

                    <div class="ant-row charges-tab Platform-Charges">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                        <div className="detail-res-lft">
                          <PlatformCharges
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout2}
                            platformType="Takeaway"
                            loading={this.state.loadingTakeawayPlatformCharges}
                            callBack={async (e) =>
                              await this.handleTakeawayPlatformChargesSubmit(e)
                            }
                            isEnabled={this.state.takeawayChargesEnabled}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Platform Charges For DineIn */}

                    <div className="title-sub">
                      <h2>
                        <IntlMessages id="charges.platformChargesDineIn" />
                      </h2>
                      <hr className="hr-line"></hr>
                    </div>

                    <div class="ant-row charges-tab Platform-Charges">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                        <div className="detail-res-lft">
                          <PlatformCharges
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout2}
                            platformType="DineIn"
                            loading={this.state.loadingDineInPlatformCharges}
                            callBack={async (e) =>
                              await this.handleDineInPlatformChargesSubmit(e)
                            }
                            isEnabled={this.state.dineInChargesEnabled}
                          />
                        </div>
                      </div>
                    </div>

                    {this.props.whitelisting.Country ==
                      AppSettings.Country.Pakistan && (
                      <>
                        <div className="title-sub">
                          <h2>
                            <span style={{ color: "red" }}>*</span>
                            <IntlMessages id="charges.creditCharges" />
                            <span style={{ fontSize: "12px" }}></span>
                          </h2>
                          <hr className="hr-line"></hr>
                        </div>
                        <div class="ant-row charges-tab Platform-Charges">
                          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                            <div className="detail-res-lft">
                              <Form
                                className="ant-row platform-from"
                                style={{ marginLeft: "0px" }}
                                onSubmit={(e) =>
                                  this.handleCreditChargesSubmit(e)
                                }
                                layout={formItemLayout2}
                              >
                                <div class="ant-col ant-col-xs-24 ant-col-sm-20  gx-mb-3 charge-label-box">
                                  <FormItem
                                    label={
                                      <IntlMessages
                                        id="charges.paymentMethod"
                                        defaultMessage="Payment Method"
                                      />
                                    }
                                    {...formItemLayout2}
                                    hidden={true}
                                    initialValue={256}
                                  >
                                    {getFieldDecorator("PaymentMethod[0]", {
                                      initialValue: 256,
                                    })(<Input style={{ width: 200 }} />)}
                                  </FormItem>
                                  <FormItem
                                    label={
                                      <IntlMessages
                                        id="charges.creditFees"
                                        defaultMessage="UBL Etisalat Fees %"
                                      />
                                    }
                                    {...formItemLayout2}
                                  >
                                    {getFieldDecorator("ChargeAmount[0]", {
                                      initialValue: 2.5,
                                    })(
                                      <InputNumber
                                        min={0}
                                        max={100}
                                        style={{ width: 200 }}
                                        placeholder="%"
                                      />
                                    )}
                                  </FormItem>
                                  <FormItem
                                    label={
                                      <IntlMessages
                                        id="charges.creditFees"
                                        defaultMessage="UBL Etisalat Fees Tax %"
                                      />
                                    }
                                    {...formItemLayout2}
                                  >
                                    {getFieldDecorator("ChargeTax[0]", {
                                      initialValue: 2.5,
                                    })(
                                      <InputNumber
                                        min={0}
                                        max={100}
                                        style={{ width: 200 }}
                                        placeholder="%"
                                      />
                                    )}
                                  </FormItem>
                                </div>
                                <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 add-btn-form-box">
                                  <FormItem
                                    {...formItemLayout}
                                    className="add-btn-form"
                                  >
                                    <Button
                                      loading={this.state.loadingCreditCharges}
                                      htmlType="submit"
                                      type="primary"
                                    >
                                      <IntlMessages id="charges.save" />
                                    </Button>
                                  </FormItem>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {this.props.whitelisting.Country ==
                      AppSettings.Country.Sweden && (
                      <>
                        <div className="title-sub">
                          <h2>
                            <span style={{ color: "red" }}>*</span>
                            <IntlMessages id="charges.creditCharges" />
                            <span style={{ fontSize: "12px" }}></span>
                          </h2>
                          <hr className="hr-line"></hr>
                        </div>
                        <div class="ant-row charges-tab Platform-Charges">
                          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                            <div className="detail-res-lft">
                              <Form
                                className="ant-row platform-from"
                                style={{ marginLeft: "0px" }}
                                onSubmit={(e) =>
                                  this.handleCreditChargesSubmit(e)
                                }
                                layout={formItemLayout2}
                              >
                                <div class="ant-col ant-col-xs-24 ant-col-sm-20  gx-mb-3 charge-label-box">
                                  <FormItem
                                    label={
                                      <IntlMessages
                                        id="charges.paymentMethod"
                                        defaultMessage="Payment Method"
                                      />
                                    }
                                    {...formItemLayout2}
                                    hidden={true}
                                    initialValue={128}
                                  >
                                    {getFieldDecorator("PaymentMethod[0]", {
                                      initialValue: 128,
                                    })(<Input style={{ width: 200 }} />)}
                                  </FormItem>
                                  <FormItem
                                    label={
                                      <IntlMessages
                                        id="charges.cardFees"
                                        defaultMessage="Card Fees %"
                                      />
                                    }
                                    {...formItemLayout2}
                                  >
                                    {getFieldDecorator("ChargeAmount[0]", {
                                      initialValue: 0,
                                      rules: [
                                        { message: "Required", required: true },
                                      ],
                                    })(
                                      <InputNumber
                                        min={0}
                                        max={100}
                                        precision={1}
                                        style={{ width: 200 }}
                                        placeholder="%"
                                      />
                                    )}
                                  </FormItem>
                                  <FormItem
                                    label={
                                      <IntlMessages
                                        id="charges.cardFeesTax"
                                        defaultMessage="Card Fees Tax %"
                                      />
                                    }
                                    {...formItemLayout2}
                                  >
                                    {getFieldDecorator("ChargeTax[0]", {
                                      initialValue: 0,
                                      rules: [
                                        { message: "Required", required: true },
                                      ],
                                    })(
                                      <InputNumber
                                        min={0}
                                        max={100}
                                        precision={1}
                                        style={{ width: 200 }}
                                        placeholder="%"
                                      />
                                    )}
                                  </FormItem>
                                </div>
                                <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 add-btn-form-box">
                                  <FormItem
                                    {...formItemLayout}
                                    className="add-btn-form"
                                  >
                                    <Button
                                      loading={this.state.loadingCreditCharges}
                                      htmlType="submit"
                                      type="primary"
                                    >
                                      <IntlMessages id="charges.save" />
                                    </Button>
                                  </FormItem>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="title-sub">
                      <h2>
                        <span style={{ color: "red" }}>*</span>
                        <IntlMessages id="charges.minOrderAmountonDelivery" />
                      </h2>
                      <hr className="hr-line"></hr>
                    </div>

                    <div class="ant-row charges-tab Platform-Charges">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                        <div className="detail-res-lft">
                          <Form
                            className="ant-row"
                            onSubmit={(e) => this.handleMinimumOrderSubmit(e)}
                            layout={formItemLayout2}
                          >
                            <div class="ant-col ant-col-xs-24 ant-col-md-20">
                              <FormItem
                                {...formItemLayout2}
                                label={
                                  <IntlMessages id="charges.minOrderAmount" />
                                }
                              >
                                {getFieldDecorator("MinimumOrderAmount", {
                                  rules: [
                                    { required: true, message: "Required!" },
                                  ],
                                })(
                                  <InputNumber
                                    style={{ width: 200 }}
                                    min={0}
                                    placeholder={amount}
                                  />
                                )}
                              </FormItem>
                            </div>
                            <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 add-btn-form-box">
                              <FormItem
                                {...formItemLayout2}
                                className="add-btn-form"
                              >
                                <Button
                                  htmlType="submit"
                                  type="primary"
                                  loading={this.state.loadingMinimumOrder}
                                >
                                  <IntlMessages id="charges.save" />
                                </Button>
                              </FormItem>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className="title-sub">
                      <h2>
                        <IntlMessages id="charges.deliveryCharges" />
                      </h2>
                      <hr className="hr-line"></hr>
                    </div>

                    <div class="ant-row charges-tab Platform-Charges delivery-charges">
                      <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                        <div className="detail-res-lft">
                          <Form
                            className="ant-row"
                            style={{ marginLeft: "0px" }}
                            onSubmit={(e) => this.handleSaveChargesSubmit(e)}
                            layout={formItemLayout2}
                            // layout={null}
                          >
                            <div class="ant-col ant-col-xs-24 gx-mb-3">
                              <RadioGroup
                                name="FixedOrDistance"
                                disabled={
                                  this.state.fixedOrDistance == 3 ||
                                  this.state.fixedOrDistance == 4
                                }
                                className="gx-mb-3"
                                onChange={(e) => {
                                  this.setState({
                                    fixedOrDistance: e.target.value,
                                  });
                                }}
                                value={this.state.fixedOrDistance}
                              >
                                <Radio
                                  checked={this.state.fixedOrDistance == 1}
                                  onChange={async (e) => {
                                    if (this.state.dataDeliveryCharges.length) {
                                      let charges = {
                                        Id: this.state.id,
                                        IsFixedFee: true,
                                        IsDistanceBased: false,
                                      };

                                      var opt = e.target.value;
                                      var tempDeliveryCharges =
                                        this.state.dataDeliveryCharges.map(
                                          (x) => {
                                            return {
                                              ...x,
                                              perKMAmount:
                                                opt == 2 || opt == 3
                                                  ? this.state.perKMAmount
                                                  : "",
                                            };
                                          }
                                        );
                                      this.setState({
                                        dataDeliveryCharges:
                                          tempDeliveryCharges,
                                      });

                                      const response =
                                        await RestaurantService.saveDeliverychargetype(
                                          charges
                                        );

                                      console.log(response);
                                      if (response && response.data.Success) {
                                        message.success(`Fixed Fee Selected!`);
                                        this.setState({ isTableEmpty: false });
                                      } else {
                                        message.error(response.data.APIMessage);
                                      }
                                    } else {
                                      message.info(
                                        "There is no value in the list. Please add!"
                                      );
                                      this.setState({ isTableEmpty: true });
                                    }
                                  }}
                                  value={1}
                                >
                                  <IntlMessages id="charges.fixedFee" />
                                </Radio>
                                <Radio
                                  checked={this.state.fixedOrDistance == 2}
                                  value={2}
                                  onChange={(e) => {
                                    var opt = e.target.value;
                                    var tempDeliveryCharges =
                                      this.state.dataDeliveryCharges.map(
                                        (x) => {
                                          return {
                                            ...x,
                                            perKMAmount:
                                              opt == 2 || opt == 3
                                                ? this.state.perKMAmount
                                                : "",
                                          };
                                        }
                                      );
                                    this.setState({
                                      dataDeliveryCharges: tempDeliveryCharges,
                                    });
                                  }}
                                >
                                  <IntlMessages id="charges.distanceBased" />
                                </Radio>
                                <Checkbox
                                  checked={this.state.fixedOrDistance == 3}
                                  disabled={this.state.fixedOrDistance == 4}
                                  onChange={(e) => {
                                    var opt = e.target.checked
                                      ? e.target.value
                                      : 0;
                                    var tempDeliveryCharges =
                                      this.state.dataDeliveryCharges.map(
                                        (x) => {
                                          return {
                                            ...x,
                                            perKMAmount:
                                              opt == 2 || opt == 3
                                                ? this.state.perKMAmount
                                                : "",
                                          };
                                        }
                                      );
                                    this.setState({
                                      dataDeliveryCharges: tempDeliveryCharges,
                                    });

                                    this.setState({
                                      fixedOrDistance: e.target.checked
                                        ? e.target.value
                                        : 0,
                                    });
                                  }}
                                  value={3}
                                >
                                  <IntlMessages id="charges.both" />
                                </Checkbox>

                                <Checkbox
                                  checked={this.state.fixedOrDistance == 4}
                                  onChange={async (e) => {
                                    this.setState({
                                      fixedOrDistance: e.target.checked
                                        ? e.target.value
                                        : 0,
                                    });

                                    var opt = e.target.value;
                                    var tempDeliveryCharges =
                                      this.state.dataDeliveryCharges.map(
                                        (x) => {
                                          return {
                                            ...x,
                                            perKMAmount:
                                              opt == 2 || opt == 3
                                                ? this.state.perKMAmount
                                                : "",
                                          };
                                        }
                                      );
                                    this.setState({
                                      dataDeliveryCharges: tempDeliveryCharges,
                                    });

                                    if (e.target.checked) {
                                      let charges = {
                                        Id: this.state.id,
                                        IsFixedFee: false,
                                        IsDistanceBased: false,
                                        PerKMAmount:
                                          this.state.tempFixedFeeKmAmount,
                                      };

                                      const response =
                                        await RestaurantService.saveDeliveryCharges(
                                          charges
                                        );

                                      console.log(response);
                                      if (response && response.data.Success) {
                                        message.success(
                                          `Charges successfully saved!`
                                        );
                                      } else {
                                        message.error(response.data.APIMessage);
                                      }
                                    }
                                  }}
                                  value={4}
                                >
                                  <IntlMessages id="charges.freeDelivery" />
                                </Checkbox>
                              </RadioGroup>
                            </div>
                            <div class="ant-col ant-col-xs-24 ant-col-md-20">
                              <FormItem
                                label={
                                  <IntlMessages id="charges.perKmAmount" />
                                }
                                {...formItemLayout2}
                              >
                                {getFieldDecorator("PerKMAmount", {
                                  rules:
                                    this.state.fixedOrDistance == 2 ||
                                    this.state.fixedOrDistance == 3
                                      ? [inputRules.required]
                                      : [],
                                })(
                                  <InputNumber
                                    disabled={
                                      this.state.fixedOrDistance == 1 ||
                                      this.state.fixedOrDistance == 0 ||
                                      this.state.fixedOrDistance == 4
                                    }
                                    style={{ width: 200 }}
                                    min={0}
                                    defaultValue={0}
                                    placeholder={enterPerKmAmount}
                                  />
                                )}
                              </FormItem>
                            </div>
                            <div class="ant-col ant-col-xs-24 ant-col-sm-4 add-btn-form-box">
                              <FormItem
                                {...formItemLayout2}
                                className="add-btn-form"
                              >
                                <Button
                                  disabled={
                                    this.state.fixedOrDistance == 1 ||
                                    this.state.fixedOrDistance == 0 ||
                                    this.state.fixedOrDistance == 4
                                  }
                                  loading={this.state.loadingSaveCharges}
                                  htmlType="submit"
                                  type="primary"
                                >
                                  <IntlMessages id="charges.saveCharges" />
                                </Button>
                              </FormItem>
                            </div>
                          </Form>
                          <Form
                            className="ant-row charge-form"
                            style={{ marginLeft: "0px" }}
                            onSubmit={(e) =>
                              this.handleFreeDeliveryFromAmount(e)
                            }
                            layout={formItemLayout2}
                            // layout={null}
                          >
                            <div class="ant-col ant-col-xs-24 ant-col-md-20">
                              <FormItem
                                label={
                                  <IntlMessages
                                    id="charges.freeDeliveryFromAmount"
                                    defaultMessage="Free Delivery From Order Amount"
                                  />
                                }
                                {...formItemLayout2}
                              >
                                {getFieldDecorator("FreeDeliveryFromAmount", {
                                  rules: [],
                                })(
                                  <InputNumber
                                    disabled={this.state.fixedOrDistance == 4}
                                    style={{ width: 200 }}
                                    min={0}
                                    defaultValue={0}
                                    placeholder={"Amount"}
                                  />
                                )}
                              </FormItem>
                            </div>

                            <div class="ant-col ant-col-xs-24 ant-col-sm-4 add-btn-form-box">
                              <FormItem
                                {...formItemLayout2}
                                className="add-btn-form"
                              >
                                <Button
                                  disabled={this.state.fixedOrDistance == 4}
                                  loading={
                                    this.state.loadingFreeDeliveryFromAmount
                                  }
                                  htmlType="submit"
                                  type="primary"
                                >
                                  <IntlMessages id="charges.saveCharges" />
                                </Button>
                              </FormItem>
                            </div>
                          </Form>
                          <Form
                            className="ant-row charge-form"
                            style={{ marginLeft: "0px" }}
                            onSubmit={(e) => this.handleDeliveryTax(e)}
                            layout={formItemLayout2}
                            // layout={null}
                          >
                            <div class="ant-col ant-col-xs-24 ant-col-md-20">
                              <FormItem
                                label={
                                  <IntlMessages
                                    id="charges.deliveryTax"
                                    defaultMessage="Delivery Tax"
                                  />
                                }
                                {...formItemLayout2}
                              >
                                {getFieldDecorator("DeliveryTax", {
                                  rules: [],
                                })(
                                  <InputNumber
                                    style={{ width: 200 }}
                                    min={0}
                                    max={100}
                                    defaultValue={0}
                                    placeholder={"Tax in %"}
                                  />
                                )}
                              </FormItem>
                            </div>
                            <div class="ant-col ant-col-xs-24 ant-col-sm-4 add-btn-form-box">
                              <FormItem
                                {...formItemLayout2}
                                className="add-btn-form"
                              >
                                <Button
                                  loading={this.state.loadingDeliveryTax}
                                  htmlType="submit"
                                  type="primary"
                                >
                                  <IntlMessages
                                    id="charges.saveDeliveryTax"
                                    defaultMessage="Save Delivery Tax"
                                  />
                                </Button>
                              </FormItem>
                            </div>
                          </Form>
                          <Form
                            className="ant-row charge-form"
                            style={{ marginLeft: "0px" }}
                            onSubmit={(e) => this.handleFixedValueSubmit(e)}
                            layout={formItemLayout2}
                            // layout={null}
                          >
                            <div class="ant-col ant-col-xs-24 ant-col-sm-20 gx-mb-3">
                              <FormItem
                                label={
                                  <IntlMessages id="charges.applyFixedAmountOf" />
                                }
                                {...formItemLayout2}
                              >
                                {getFieldDecorator("Amount", {
                                  rules: [
                                    { required: true, message: "Required!" },
                                  ],
                                })(
                                  <InputNumber
                                    disabled={
                                      this.state.fixedOrDistance == 2 ||
                                      this.state.fixedOrDistance == 0 ||
                                      this.state.fixedOrDistance == 4
                                    }
                                    style={{ width: 200 }}
                                    min={0}
                                    defaultValue={0}
                                    placeholder={fixedAmount}
                                  />
                                )}
                              </FormItem>
                              <FormItem
                                label={
                                  <IntlMessages id="charges.uptoDistanceKm" />
                                }
                                {...formItemLayout2}
                              >
                                {getFieldDecorator("DistanceUpToKM", {
                                  rules: [
                                    { required: true, message: "Required!" },
                                  ],
                                })(
                                  <InputNumber
                                    disabled={
                                      this.state.fixedOrDistance == 2 ||
                                      this.state.fixedOrDistance == 0 ||
                                      this.state.fixedOrDistance == 4
                                    }
                                    style={{ width: 200 }}
                                    min={0}
                                    defaultValue={0}
                                    placeholder={distanceKm}
                                  />
                                )}
                              </FormItem>
                            </div>
                            <div class="ant-col ant-col-xs-24 ant-col-sm-4 gx-mb-3 add-btn-form-box">
                              <FormItem
                                {...formItemLayout2}
                                className="add-btn-form"
                              >
                                <Button
                                  disabled={
                                    this.state.fixedOrDistance == 2 ||
                                    this.state.fixedOrDistance == 0 ||
                                    this.state.fixedOrDistance == 4
                                  }
                                  htmlType="submit"
                                  type="primary"
                                  loading={this.state.loadingFixedValue}
                                >
                                  {this.state.fixAmountId ? (
                                    <IntlMessages id="charges.updateFixAmount" />
                                  ) : (
                                    <IntlMessages id="charges.addFixAmount" />
                                  )}
                                </Button>
                              </FormItem>
                            </div>
                          </Form>

                          <InfiniteScrollTable
                            columns={columns}
                            dataSource={this.state.dataDeliveryCharges}
                            loading={this.state.loadingDeliveryCharges}
                            hasMore={this.state.hasMoreDeliveryCharges}
                            height={"300px"}
                            initialLoad={true}
                            loadMore={this.fetchMoreDeliveryCharges}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane
                    disabled={this.state.id == null}
                    tab={<IntlMessages id="config.config" />}
                    key="3"
                  >
                    <RestaurantConfig rid={this.state.id} />
                  </TabPane>

                  <TabPane
                    disabled={this.state.id == null}
                    tab={
                      <IntlMessages
                        id="config.calendar"
                        defaultMessage="Calendar"
                      />
                    }
                    key="4"
                  >
                    <RestaurantCalendar rid={this.state.id} />
                  </TabPane>

                  {/* <TabPane disabled={this.state.id == null} tab={<IntlMessages id="orders.orders" />} key="5">
                    <RestaurantOrders rid={this.state.id} />
                  </TabPane> */}

                  <TabPane
                    disabled={this.state.id == null}
                    tab={<IntlMessages id="info.gallery" />}
                    key="6"
                  >
                    <RestaurantGallery rid={this.state.id} />
                  </TabPane>

                  <TabPane
                    disabled={this.state.id == null}
                    tab={<IntlMessages id="info.slider" />}
                    key="7"
                  >
                    <RestaurantSlider rid={this.state.id} />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </Card>
        <Modal
          title="Add User"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="item-detail-edit-box">
            {/* <p>Some contents...</p> */}
            <Form layout={formLayout}>
              <FormItem label="Name" {...formItemLayout}>
                <Input type="email" placeholder="" />
              </FormItem>
              <FormItem label="Email" {...formItemLayout}>
                <Input placeholder="" />
              </FormItem>
              <FormItem label="User Level" {...formItemLayout}>
                <Input placeholder="" />
              </FormItem>
              {/* <FormItem
                                label="Image"
                                {...formItemLayout}
                            >
                                <Upload {...props}>
                                <Button className="btn-upload-cust">
                                    <Icon type="upload"/> Upload
                                </Button>
                                </Upload>

                            </FormItem> */}
              {/* <FormItem
                                label="Alcohol %"
                                {...formItemLayout}
                            >
                                <Input placeholder=""/>
                            </FormItem>
                            <FormItem
                                label="POS ID"
                                {...formItemLayout}
                            >
                                <Input placeholder=""/>
                            </FormItem> */}
              {/* <FormItem
                                label="Field B"
                                {...formItemLayout}
                            >
                                <Input placeholder="input placeholder"/>
                            </FormItem> */}
              {/* <FormItem {...buttonItemLayout}>
                                <Button type="primary">Submit</Button>
                            </FormItem> */}
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  whitelisting: state.settings.whitelisting,
});
const WrappedCreateRestaurant = injectIntl(
  Form.create()(SaEditCreateRestaurant)
);
export default connect(mapStateToProps)(WrappedCreateRestaurant);
