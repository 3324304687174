import axios from "axios";
import BaseService from "./baseService";

class RiderServiceController extends BaseService {

    async fetch(body) {
        const response = await axios.post("/user/fetch-riders", body);
        return response.data;
    }

    async fetchNameList() {
        const response = await axios.post("/user/getallriderslist");
        return response.data;
    }
}

export const RiderService = new RiderServiceController();