import React from "react";
import AmCharts from "@amcharts/amcharts3-react";

const localDataProvider = [
  //   {
  //   "title": "New",
  //   "value": 4852
  // },
  {
    title: "Reapeat and New Customer",
    value: 9899,
  },
];

const DonutChart = (props) => {
  const config = {
    type: "pie",
    theme: "light",
    hideCredits: true,
    dataProvider: props.data ? props.data : localDataProvider,
    titleField: props.data ? props.title : "title",
    valueField: props.data ? props.value : "value",
    labelRadius: 0,
    labelsEnabled: false,
    radius: "50%",
    innerRadius: "45%",
    labelText: "[[title]]",
    export: {
      enabled: false,
    },
    legend: {
      type: "legend",
      position: "bottom",
    },
  };

  return (
    <div className="App">
      <AmCharts.React
        style={{ width: "100%", height: "250px" }}
        options={config}
      />
    </div>
  );
};

export default DonutChart;
