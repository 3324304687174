import React, { useEffect, useState } from "react";
import {
  Select,
  Card,
  Row,
  Col,
  Form,
  message,
  Input,
  Divider,
  Typography,
  Button,
} from "antd";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { SEOService } from "../../services";
import languageData from "../../containers/Topbar/languageData";

const { Title } = Typography;
const Option = Select.Option;

const RichTextToolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link", "code"],
  [{ align: "" }, { align: "center" }, { align: "right" }],
  [{ color: [] }, { background: [] }],
];
Quill.register(Quill.import("attributors/style/align"), true);

class SEODetails extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      cultureKey: "en-US",
    };
  }
  componentDidMount() {
    this.setFieldsValuesToForm(this.state.cultureKey);
  }

  setFieldsValuesToForm = (cultureKey) => {
    let { metaData } = this.props;
    let selectedCultureMetaData = metaData.find(
      (a) => a.CultureKey == cultureKey
    );
    if (selectedCultureMetaData) {
      this.props.form.setFieldsValue({
        Culturekey: cultureKey,
        MetaTitle: selectedCultureMetaData?.MetaTitle,
        FooterDescription: selectedCultureMetaData?.FooterDescription || "",
        MetaDescription: selectedCultureMetaData?.MetaDescription,
      });
      if (this.props.isDefaultSelected)
        this.props.form.setFieldsValue({
          PageHeading: selectedCultureMetaData?.PageHeading || "",
        });
    } else {
      this.props.form.setFieldsValue({
        Culturekey: cultureKey,
        MetaTitle: "",
        FooterDescription: "",
        MetaDescription: "",
      });
      if (this.props.isDefaultSelected)
        this.props.form.setFieldsValue({
          PageHeading: selectedCultureMetaData?.PageHeading || "",
        });
    }
  };

  handleCultureCodeChange = (value) => {
    this.setState({ cultureKey: value }, this.setFieldsValuesToForm(value));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
        let formData = new FormData();
        let id = this.props.metaTypeId;
        let metaType = this.props.metaType;
        if (id && metaType) {
          formData.append("Id", id);
          formData.append("CultureKey", this.state.cultureKey);
          formData.append("MetaType", metaType);
          formData.append("MetaTitle", values.MetaTitle);
          formData.append("MetaDescription", values.MetaDescription || "");
          formData.append("FooterDescription", values.FooterDescription || "");
          formData.append("PageHeading", values?.PageHeading || "");
          try {
            var response = await SEOService.PostData(formData);
            if (response && response.Success)
              message.success("Successfully updated!");
            else message.error(response.APIMessage);
          } catch (e) {
            message.error("Some thing went wrong");
          } finally {
            this.setState({ loading: false });
          }
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    return (
      <>
        <Divider />
        <Title level={4}>
          <IntlMessages
            id="settings.metaDetails"
            defaultMessage="Meta Details"
          />
        </Title>

        <Form layout="horizontal">
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-16 rest-info-box-lft">
            <Form.Item
              label={
                <IntlMessages
                  id="settings.language"
                  defaultMessage="Language"
                />
              }
              {...formItemLayout}
            >
              {getFieldDecorator(
                "Culturekey",
                {}
              )(
                <Select
                  onChange={(e) => this.handleCultureCodeChange(e)}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                >
                  {languageData.map((v, i) => {
                    return (
                      <Option key={i} value={v.cultureCode}>
                        {v.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </div>
        </Form>

        <Form layout="horizontal" onSubmit={this.handleSubmit}>
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-16 rest-info-box-lft">
            <Form.Item
              label={
                <IntlMessages
                  id="settings.metaTitle"
                  defaultMessage="Meta Title"
                />
              }
              {...formItemLayout}
            >
              {getFieldDecorator("MetaTitle", {
                rules: [{ required: true, message: "Meta title is Required!" }],
              })(<Input placeholder="Meta Title" />)}
            </Form.Item>
          </div>
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 rest-info-box-lft">
            <Form.Item
              label={
                <IntlMessages
                  id="settings.metaDescription"
                  defaultMessage="Meta Description"
                />
              }
              {...formItemLayout}
            >
              {getFieldDecorator(
                "MetaDescription",
                {}
              )(<Input.TextArea placeholder="Meta Description" />)}
            </Form.Item>
          </div>
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 rest-info-box-lft">
            <Form.Item
              // label is changed from Footer Description to Content Description as per requirement
              label={
                <IntlMessages
                  id="settings.contentDescription"
                  defaultMessage="Content Description"
                />
              }
              {...formItemLayout}
            >
              {getFieldDecorator(
                "FooterDescription",
                {}
              )(
                <ReactQuill
                  theme="snow"
                  modules={{ toolbar: RichTextToolbarOptions }}
                />
              )}
            </Form.Item>
          </div>
          {this.props.isDefaultSelected && (
            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 rest-info-box-lft">
              <Form.Item
                label={
                  <IntlMessages
                    id="settings.pageHeading"
                    defaultMessage="Page Heading"
                  />
                }
                {...formItemLayout}
              >
                {getFieldDecorator(
                  "PageHeading",
                  {}
                )(<Input.TextArea placeholder="Page heading" />)}
              </Form.Item>
            </div>
          )}
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 rest-info-box-lft">
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                loading={this.state.loading}
              >
                <IntlMessages id="info.save" />
              </Button>
            </Form.Item>
          </div>
        </Form>
      </>
    );
  }
}
const WrappedSEODetails = injectIntl(Form.create()(SEODetails));
export default WrappedSEODetails;
