import React from "react";
import IntlMessages from "../../util/IntlMessages";

export const getArticlePropertyName = (props) => {
  let id = articlePropertyForCountry[props.whitelisting.Country];
  return <IntlMessages id={id} defaultMessage="Article Number" />;
};
export const getArticlePropertyPlaceHolder = (props) => {
  return articlePlaceHolderForCountry[props.whitelisting.Country];
};

const articlePropertyForCountry = {
  //countryCode: Intl Resource ID for translation
  168: "menuList.articleNumber",
  215: "menuList.articleNumber",
  234: "menuList.sku",
};

const articlePlaceHolderForCountry = {
  //countryCode: Place holder
  168: "Article Number",
  215: "Article Number",
  234: "SKU",
};
