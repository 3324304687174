import React, { useEffect } from "react";
import { Button, Checkbox, Form, Icon, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { AuthSvc } from "../services/authService";
import { FormattedMessage, injectIntl } from "react-intl";
import appState from "../state/AppStateContainer";
import { NotificationManager } from 'react-notifications';
import _ from "lodash";
import IntlMessages from "util/IntlMessages";

import CircularProgress from "../../src/components/CircularProgress/index";
//import * as Yup from "yup";


import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSwitch,
  userTwitterSignIn
} from "appRedux/actions/Auth";



const SwitchUser = (props) => {

  const dispatch = useDispatch();
  const { showMessage, loader, authUser, tempUser } = useSelector(({ auth }) => auth);
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
  });


  const loginAs = async (id) => {
    const key = 'updatable';
    try {
      dispatch(showAuthLoader());
      message.loading({ content: 'Loading...', key });
      const response = await AuthSvc.loginAs(id);
      if (response && response.data.Success) {
        appState.persistString("rId", id)
        dispatch(userSwitch(response.data.Data));
        //history.push("/restaurant-categories")
        message.success({ content: 'Successfully logged in!', key, duration: 2 });
        window.location.href = '/';
      }
      else {
        message.error({ content: response.data.APIMessage, key, duration: 2 });
    
      }
    }
    catch (ex) {
      message.error({ content: ex.message, key, duration: 2 });
    }
  }

  return (
    <>
      <a onClick={() => loginAs(props.rid)}><IntlMessages id="restaurant.loginAs" /> <i className="icon icon-long-arrow-right"></i></a>
    </>
  );
};

export default SwitchUser;
