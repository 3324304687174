import axios from "axios";
import BaseService from "./baseService";

class PromoCodeServiceController extends BaseService {

    async fetch(body) {
        const response = await axios.post("/promocode/fetch", body);
        return response.data;
    }

    async details(body) {
        const response = await axios.post("/promocode/details", body);
        return response.data;
    }

    async save(optionData) {
        const response = await axios.post("/promocode/save", optionData);
        return response.data;
    }

    async updateStatus(templateInfo) {
        const response = await axios.post("/promocode/update-status", templateInfo);
        return response.data;
    }
    async remove(templateInfo) {
        const response = await axios.post("/promocode/remove", templateInfo);
        return response.data;
    }
}

export const PromoCodeService = new PromoCodeServiceController();