import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from "url-search-params";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { LocaleProvider, message } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignInSuperAdmin from "../SignInSuperAdmin";
import SignUp from "../SignUp";
import {
  SettingService,
  RestaurantService,
  DomainWhitelistService,
} from "../../services";
import { setInitUrl } from "appRedux/actions/Auth";
import appState from "../../state/AppStateContainer";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
  setWhitelisting,
  setRestaurant,
  setDomainWhitelist,
} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";

import { WHITELISTING, DOMAINWHITELIST } from "../../constants/ActionTypes";

import { SUPER_ADMIN_ID, RESTAURENT_ADMIN } from "../../constants/UserRoles";
import ForgotPassword from "../ForgotPassword";
import { AppSettings } from "../../constants/AppSettings";

const RestrictedRoute = ({
  component: Component,
  location,
  tempUser,
  authUser,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: location },
          }}
        />
      )
    }
  />
);

const App = (props) => {
  const dispatch = useDispatch();
  const { locale, navStyle, layoutType, whitelisting, restaurant } =
    useSelector(({ settings }) => settings);
  const { authUser, tempUser, initURL } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    const isDubai = whitelisting?.Country == AppSettings.Country.UAE;
    const rId = appState.loadRestaurantId();
    if (isDubai && rId && authUser != SUPER_ADMIN_ID) {
      document.title = restaurant.Name ?? "";
      let favIconElement = document.getElementById("favicon");
      if (favIconElement) {
        favIconElement.href = restaurant.Logo ?? favIconElement.href;
      }
    } else document.title = "Speedoy";
  }, [restaurant]);

  useEffect(() => {
    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "/css/style.css";

    link.className = "gx-style";
    document.body.appendChild(link);

    let link2 = document.createElement("link");
    link2.type = "text/css";
    link2.rel = "stylesheet";
    link2.href = "/css/style_2.css";

    link2.className = "gx-style";
    document.body.appendChild(link2);
  }, []);

  useEffect(() => {
    SettingService.fetchCultureConfiguration().then((settings) => {
      var data = settings.Data;
      if (data) {
        dispatch(setWhitelisting(data));
        appState.persistObj(WHITELISTING, data);
      }
    });
  }, []);

  useEffect(() => {
    if (authUser != SUPER_ADMIN_ID) {
      const id = appState.loadString("rId");
      if (id && id != "null" && id != null && id != undefined) {
        RestaurantService.getRestaurantDetailsForRa(id).then((response) => {
          var data = response.data.Data;
          if (data) {
            dispatch(setRestaurant(data));
          }
        });

        DomainWhitelistService.getRestaurantSpecificDomainWhitelist(id)
          .then((response) => {
            if (response.Success) {
              var data = response.Data;
              if (data) {
                dispatch(setDomainWhitelist(data));
                appState.persistObj(DOMAINWHITELIST, data);
              }
            } else {
              dispatch(setDomainWhitelist(null));
              appState.persistObj(DOMAINWHITELIST, {});
            }
          })
          .catch((ex) => {
            message.error("An error occured while fetching domain whitelist.");
            console.log(ex);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get("theme")));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get("nav-style")));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get("layout-type")));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  });

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  const setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      if (authUser === null && tempUser === null) {
        history.push("/signin");
      } else if (
        initURL === "" ||
        initURL === "/" ||
        initURL === "/signin" ||
        initURL == "/5461c537bbe8e8a6d71cbcb24eb9ad47/login"
      ) {
        if (authUser == SUPER_ADMIN_ID) {
          history.push("/dashboard");
        } else {
          history.push("/restaurant-dashboard");
        }
      } else {
        history.push(initURL);
      }
    }
  }, [authUser, initURL, location, history]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <LocaleProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <Route
            exact
            path="/5461c537bbe8e8a6d71cbcb24eb9ad47/login"
            component={SignInSuperAdmin}
          />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/ForgotPassword" component={ForgotPassword} />
          <RestrictedRoute
            path={`${match.url}`}
            tempUser={tempUser}
            authUser={authUser}
            location={location}
            component={MainApp}
          />
        </Switch>
      </IntlProvider>
    </LocaleProvider>
  );
};

export default memo(App);
