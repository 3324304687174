import React from "react";
import { Breadcrumb, Card, Collapse, Divider, Table, Modal } from "antd";
import { connect } from "react-redux";
import {
  InputNumber,
  Radio,
  Spin,
  Rate,
  Slider,
  Switch,
  message,
  Upload,
} from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Tooltip,
  formLayout,
  Anchor,
} from "antd";
import { MenuService } from "../../services";
import IntlMessages from "util/IntlMessages";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";

// ---------------drag sorting
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import DraggableBodyRow from "./menuInfo/DraggableBodyRow";
// ---------------drag sorting

// <------------------------------------------
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import MenuOutline from "@ant-design/icons/MenuOutlined";
import arrayMove from "array-move";
import { AppSettings, DomainType } from "../../constants/AppSettings";
import { getArticlePropertyName } from "./common";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const DragHandle = sortableHandle(() => (
  <MenuOutline style={{ cursor: "pointer", color: "#999" }} />
));
// -------------------------------------------------->

// Switch script start
function onChange(checked) {
  console.log(`switch to ${checked}`);
}
// Switch script start

// search-script
const Search = Input.Search;
// search-script-end

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const Panel = Collapse.Panel;
const columns = [
  {
    title: <IntlMessages id="menuList.sort" />,
    dataIndex: "sort",
    width: 30,
    className: "drag-visible",
    render: () => <DragHandle />,
  },
  {
    title: <IntlMessages id="menuList.itemName" />,
    dataIndex: "itemName",
    className: "drag-visible",
    key: "itemName",
  },
  {
    title: <IntlMessages id="menuList.price" />,
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: <IntlMessages id="menuList.action" />,
    dataIndex: "action",
    key: "action",
  },
];

let b2bColumns = [
  {
    title: <IntlMessages id="menuList.sort" />,
    dataIndex: "sort",
    width: 30,
    className: "drag-visible",
    render: () => <DragHandle />,
  },
  {
    title: <IntlMessages id="menuList.articleNumber" />,
    dataIndex: "articleNumber",
    className: "drag-visible",
    key: "articleNumber",
  },
  {
    title: <IntlMessages id="menuList.itemName" />,
    dataIndex: "itemName",
    className: "drag-visible",
    key: "itemName",
  },
  {
    title: <IntlMessages id="menuList.price" />,
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
  },
  {
    title: <IntlMessages id="menuList.department" />,
    dataIndex: "department",
    className: "drag-visible",
    key: "department",
  },
  {
    title: "Show Price Without Login",
    dataIndex: "showPriceWithoutLogin",
    key: "showPriceWithoutLogin",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: <IntlMessages id="menuList.action" />,
    dataIndex: "action",
    key: "action",
  },
];

let dubaiColumns = [
  {
    title: <IntlMessages id="menuList.sort" />,
    dataIndex: "sort",
    width: 30,
    className: "drag-visible",
    render: () => <DragHandle />,
  },
  {
    title: <IntlMessages id="menuList.sku" />,
    dataIndex: "articleNumber",
    className: "drag-visible",
    key: "articleNumber",
  },
  {
    title: <IntlMessages id="menuList.itemName" />,
    dataIndex: "itemName",
    className: "drag-visible",
    key: "itemName",
  },
  {
    title: <IntlMessages id="menuList.price" />,
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: <IntlMessages id="menuList.action" />,
    dataIndex: "action",
    key: "action",
  },
];

// <-----------------------

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const DragableBodyRow = ({ index, className, style, ...restProps }) => (
  <SortableItem index={restProps["data-row-key"]} {...restProps} />
);
//-------------------------->

//   upload-script start
const props = {
  name: "file",
  action: "//jsonplaceholder.typicode.com/posts/",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
//   upload-script end

class menuList extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      categoryWithMenu: [],
      data: [],
      listData: [],
      isAvailable: "",
      delivery: false,
      takeaway: false,
      spin: false,
      formLayout: "horizontal",
      visible: false,
      addItem: false,
      addOption: false,
      openImageModal: false,
      openExcelModal: false,
      imageUploading: false,
      excelUploading: false,
      selectedFileList: [],
      fileUrl: "",
      imageMenuId: "",
      imageMenuName: "",
      imageCategoryId: "",
      isB2B: false,
    };
  }
  // ---------drag sorting
  components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  onRow = (record, index) => ({
    index,
    moveRow: this.moveRow,
  });

  moveRow = (dragIndex, hoverIndex) => {
    const dataSource =
      this.state.categoryWithMenu[this.state.currentTable].menuItem;
    console.log("before", this.state.categoryWithMenu);

    if (dragIndex !== hoverIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        dragIndex,
        hoverIndex
      ).filter((el) => !!el);

      let newArray = [...this.state.categoryWithMenu];
      newArray[this.state.currentTable] = {
        ...newArray[this.state.currentTable],
        menuItem: newData,
      };

      this.setState({
        categoryWithMenu: newArray,
      });

      console.log("after", this.state.categoryWithMenu);
    }
  };
  // --------------drag sorting

  createTableAndMenuListing = (response) => {
    this.setState({
      categoryWithMenu: [],
    });
    var categories = response.map((c, ci) => {
      return {
        categoryName: c.Name,
        categoryId: c.Id,
        menuItem: c.MenuItems.map((m, mi) => {
          return {
            key: m.Id,
            articleNumber: m.ArticleNumber,
            itemName: m.Name,
            price: m.Price,
            showPriceWithoutLogin: (
              <Switch
                defaultChecked={m.ShowPriceWithoutLogin}
                onChange={(value) =>
                  this.updateShowPriceStatus(value, c.Id, m.Id)
                }
              />
            ),
            image: (
              <a
                href="#"
                onClick={() =>
                  this.handleOpenImageModal(m.Id, m.Image, m.Name, c.Id)
                }
              >
                <img src={m.Image} width={200} />
              </a>
            ),
            department: m.Department,
            status: (
              <Switch
                defaultChecked={m.IsAvailable}
                onChange={(value) => this.updateMenuStatus(value, c.Id, m.Id)}
              />
            ),
            action: (
              <a className="action-btn">
                <i
                  onClick={() =>
                    this.openMenuInfo(
                      c.Id,
                      m.Id,
                      m.Name,
                      m.Descriptions,
                      m.Allergens,
                      m.Warnings,
                      m.Price,
                      m.ServiceInfoType,
                      m.TaxId,
                      m.AlcoholPercentage,
                      m.IsAvailable,
                      m.OptionIds,
                      m.OptionTemplateId,
                      m.Image,
                      m.ShowPriceWithoutLogin,
                      m.ArticleNumber,
                      m.Department
                    )
                  }
                  className="fa fa-pencil"
                ></i>
                <i
                  onClick={() => this.deleteMenu(c.Id, m.Id, m.IsAvailable)}
                  style={{ marginLeft: "20px" }}
                  className="fa fa-trash"
                ></i>
              </a>
            ),
          };
        }),
      };
    });
    this.setState({
      categoryWithMenu: categories,
    });
  };

  saveSortedList = async () => {
    const categoryWithMenu = this.state.categoryWithMenu;
    let sortedData = [];

    for (let x = 0; x < categoryWithMenu.length; x++) {
      let menuItem = categoryWithMenu[x].menuItem;
      let sortedList = [];
      for (let y = 0; y < menuItem.length; y++) {
        sortedList.push({
          MenuId: menuItem[y].key,
          SortOrder: y,
        });
      }
      sortedData.push({
        MenuCategoryId: categoryWithMenu[x].categoryId,
        MenuSortOrderList: sortedList,
      });
    }
    sortedData = {
      MenuCategoryItems: sortedData,
    };

    const response = await MenuService.updateMenuListSorting(sortedData);
    console.log(response);

    if (response && response.Success) {
      message.success("Sorted List Successfully Updated");
    } else {
      message.error(response.APIMessage);
    }
  };

  async componentDidMount() {
    let isB2B = this.props.domainWhitelist?.Type == DomainType.B2B;
    this.setState({ isB2B });
    await this.fetchInitialData();
  }

  async fetchInitialData() {
    this.setState({ spin: true });
    const response = await MenuService.getMenuList("", 0, 0);
    this.createTableAndMenuListing(response);
    this.setState({ spin: false });
  }

  filterHandler = async () => {
    const isAvailable = this.state.isAvailable;
    const serviceInfoType = this.state.delivery
      ? 1
      : this.state.takeaway
      ? 2
      : this.state.delivery && this.state.takeaway
      ? 3
      : "";
    let response = [];

    if (serviceInfoType) {
      response = await MenuService.getMenuList(
        "",
        0,
        0,
        isAvailable,
        serviceInfoType
      );
    } else {
      response = await MenuService.getMenuList("", 0, 0, isAvailable);
    }

    this.createTableAndMenuListing(response);
  };

  searchHandler = async (searchInput) => {
    const response = await MenuService.getMenuList(searchInput, 0, 0);
    this.createTableAndMenuListing(response);
  };

  searchReset = async (searchInput) => {
    if (searchInput.target.value == "") {
      const response = await MenuService.getMenuList("", 0, 0);
      this.createTableAndMenuListing(response);
    }
  };

  deliveryOrTakeaway = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      () => {
        this.filterHandler();
      }
    );
  };

  handleIsAvailable = (value) => {
    this.setState(
      {
        isAvailable: value,
        data: [],
      },
      () => {
        this.filterHandler();
      }
    );
  };

  openMenuInfo = (
    categoryId,
    menuId,
    name,
    description,
    allergens,
    warnings,
    price,
    serviceType,
    taxId,
    alcohol,
    isAvailable,
    optionIds,
    optionTemplateId,
    image,
    showPriceWithoutLogin,
    articleNumber,
    department
  ) => {
    this.props.history.push({
      pathname: "/menuInfo",
      info: {
        categoryId: categoryId,
        menuId: menuId,
        name: name,
        description: description,
        allergens: allergens,
        warnings: warnings,
        price: price,
        serviceType: serviceType,
        taxId: taxId,
        alcohol: alcohol,
        isAvailable: isAvailable,
        optionIds: optionIds,
        optionTemplateId: optionTemplateId,
        image: image,
        showPriceWithoutLogin: showPriceWithoutLogin,
        articleNumber: articleNumber,
        department: department,
      },
    });
  };

  updateMenuStatus = async (value, menuCategoryId, menuItemId) => {
    const menuInfo = {
      MenuCategoryId: menuCategoryId,
      MenuItemId: menuItemId,
      IsAvailable: value,
    };

    const response = await MenuService.updateMenuStatus(menuInfo);
    if (response && response.Success) {
      message.success("Status Changed!");
      this.setState({ spin: true });
      const response = await MenuService.getMenuList("", 0, 0);
      this.createTableAndMenuListing(response);
      this.setState({ spin: false });
    } else {
      message.error("Failed!");
    }
  };

  updateShowPriceStatus = async (value, menuCategoryId, menuItemId) => {
    const menuInfo = {
      MenuCategoryId: menuCategoryId,
      MenuItemId: menuItemId,
      ShowPriceWithoutLogin: value,
    };

    const response = await MenuService.updateShowPriceStatus(menuInfo);
    if (response && response.Success) {
      this.setState({ spin: true });

      message.success("Status Changed!");
      const response = await MenuService.getMenuList("", 0, 0);
      this.createTableAndMenuListing(response);
      this.setState({ spin: false });
    } else {
      message.error("Failed!");
    }
  };

  deleteMenu = async (categoryId, menuId, isAvailable) => {
    if (isAvailable) {
      message.error("Please disable the menu before deleting it..!");
      return;
    }

    const body = {
      MenuCategoryId: categoryId,
      MenuId: menuId,
    };

    const deleteResponse = await MenuService.deleteMenu(body);
    console.log(deleteResponse);

    if (deleteResponse && deleteResponse.Success) {
      message.success("Deleted");

      this.setState({ spin: true });
      const response = await MenuService.getMenuList("", 0, 0);
      this.createTableAndMenuListing(response);
      this.setState({ spin: false });
    } else {
      message.error(deleteResponse.APIMessage);
    }
  };

  // <---------------------
  setCurrentMenuData = (index) => {
    if (this.state.currentTable != index) {
      this.setState({
        currentTable: index,
      });
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const dataSource =
      this.state.categoryWithMenu[this.state.currentTable].menuItem;
    console.log("before", this.state.categoryWithMenu);

    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);

      let newArray = [...this.state.categoryWithMenu];
      newArray[this.state.currentTable] = {
        ...newArray[this.state.currentTable],
        menuItem: newData,
      };

      this.setState({
        categoryWithMenu: newArray,
      });

      console.log("after", this.state.categoryWithMenu);
    }
  };

  handleOpenImageModal = (id, image, name, categoryId) => {
    console.log(image);
    this.setState({
      fileUrl: image,
      imageMenuId: id,
      imageMenuName: name,
      openImageModal: true,
      imageCategoryId: categoryId,
    });
  };
  handleImageSave = async () => {
    this.setState({ imageUploading: true });
    let image = null;
    if (this.state.selectedFile) {
      image = this.state.selectedFile.originFileObj;
    }
    if (this.state.selectedFile) {
      let formData = new FormData();
      formData.append("Image", image);
      formData.append("MenuId", this.state.imageMenuId);
      formData.append("CategoryId", this.state.imageCategoryId);

      try {
        const response = await MenuService.updateMenuImage(formData);
        console.log("image one: ", response);
        if (response && response.Success) {
          message.success("Menu Item successfully Saved");
        }
      } catch (e) {
        console.log(e);
        message.error("an error occured while processing your request");
      } finally {
        this.setState({
          selectedFile: null,
          imageUploading: false,
          openImageModal: false,
        });
        await this.fetchInitialData();
      }
    } else {
      message.error("Please provide valid image");
      this.setState({
        imageUploading: false,
      });
    }
  };

  handleImageCancel = () => {
    this.setState({ openImageModal: false });
  };

  onImageChange = (info) => {
    console.log("here change");
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        console.log("uploading");
        nextState.selectedFileList = [info.file];
        break;
      case "done":
        console.log("done");
        nextState.selectedFile = info.file;
        nextState.selectedFileList = [info.file];
        this.readURL(info);
        break;

      default:
        // error or removed
        console.log("error in uploading img");
        nextState.selectedFile = null;
        nextState.selectedFileList = [];
    }
    this.setState(() => nextState);
  };

  handleOpenExcelModal = () => {
    this.setState({
      openExcelModal: true,
    });
  };

  handleExcelSave = async () => {
    this.setState({ excelUploading: true });
    let excelFile = null;
    if (this.state.selectedFile) {
      excelFile = this.state.selectedFile.originFileObj;
    }
    if (this.state.selectedFile) {
      let formData = new FormData();
      formData.append("file", excelFile);

      try {
        const response = await MenuService.uploadMenuItems(formData);
        if (response && response.Success) {
          message.success("Menu Item successfully Saved");
        } else {
          message.error(response.APIMessage);
        }
      } catch (e) {
        console.log(e);
        message.error("an error occured while processing your request");
      } finally {
        this.setState({
          selectedFile: null,
          selectedFileList: [],
          excelUploading: false,
          openExcelModal: false,
        });
        await this.fetchInitialData();
      }
    } else {
      message.error("Please provide valid excel file");
      this.setState({
        excelUploading: false,
      });
    }
  };

  handleExcelCancel = () => {
    this.setState({ openExcelModal: false });
  };

  onExcelChange = (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        console.log("uploading");
        nextState.selectedFileList = [info.file];
        break;
      case "done":
        console.log("done");
        nextState.selectedFile = info.file;
        nextState.selectedFileList = [info.file];
        break;

      default:
        // error or removed
        console.log("error in uploading img");
        nextState.selectedFile = null;
        nextState.selectedFileList = [];
    }
    this.setState(() => nextState);
  };

  readURL = async (info) => {
    let src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(info.file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
    this.setState({
      fileUrl: [src],
    });
  };

  customRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  // ---------------------------->

  // form-scripts end
  // Modal-scripts start
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  addItem = (e) => {
    this.setState({
      addItem: !this.state.addItem,
    });
  };
  addOption = (e) => {
    this.setState({
      addOption: !this.state.addOption,
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  // Modal-scripts end

  render() {
    // Render Article number according to country requirments (e.g SKU (Article No) for Dubai)
    b2bColumns.find((a) => a.dataIndex == "articleNumber").title =
      getArticlePropertyName(this.props);
    const isDubai = this.props.whitelisting.Country == AppSettings.Country.UAE;

    const DraggableContainer = (props) => {
      return (
        <SortableContainer
          useDragHandle
          helperClass="row-dragging"
          onSortEnd={({ oldIndex, newIndex }) => {
            this.onSortEnd({ oldIndex, newIndex });
          }}
          {...props}
        />
      );
    };
    // ---------------------->
    // form-scripts start
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 16 },
          }
        : null;
    const buttonItemLayout =
      formLayout === "horizontal"
        ? {
            wrapperCol: { xs: 24, sm: { span: 14, offset: 6 } },
          }
        : null;
    // form-scripts end

    // MULTIPLE-scripts start
    const children = [];
    for (let i = 1; i < 10; i++) {
      children.push(<Option key={i.toString(36) + i}>{"test"}</Option>);
    }
    function handleChange(value) {
      console.log(`selected ${value}`);
    }
    // MULTIPLE-scripts End

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list menu-list-allingTable">
        <div className="payout-report-box">
          {/* Upload Menu Items through excel */}
          <Modal
            visible={this.state.openExcelModal}
            title={<IntlMessages id="menuInfo.uploadMenuItems" />}
            okText="Upload"
            onOk={this.handleExcelSave}
            okButtonProps={{ loading: this.state.excelUploading }}
            onCancel={this.handleExcelCancel}
          >
            <Form onSubmit={(e) => this.handleExcelCancel()}>
              <div className="ant-row">
                <div class="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                  <label>
                    <IntlMessages id="menuInfo.uploadMenuItems" /> :
                  </label>
                </div>
                <div className="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 uplaod-img-main">
                  <FormItem>
                    <Upload
                      fileList={this.state.selectedFileList}
                      onChange={this.onExcelChange}
                      customRequest={this.customRequest}
                      showUploadList={true}
                      multiple={false}
                      beforeUpload={(file) => {
                        const isValidImage =
                          file.type ===
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        if (!isValidImage) {
                          message.error("You can only upload Excel file!");
                          return false;
                        } else {
                          return true;
                        }
                      }}
                    >
                      <Button>
                        <IntlMessages id="menuInfo.choseFile" />
                      </Button>
                    </Upload>
                  </FormItem>
                </div>
              </div>
            </Form>
          </Modal>

          {/* Upload image */}
          <Modal
            visible={this.state.openImageModal}
            title={this.state.imageMenuName}
            okText="Upload"
            onOk={this.handleImageSave}
            okButtonProps={{ loading: this.state.imageUploading }}
            onCancel={this.handleImageCancel}
          >
            <Form onSubmit={(e) => this.handleImageSave()}>
              <div className="ant-row">
                <div class="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                  <label>
                    <IntlMessages id="menuInfo.uploadPicture" /> :
                  </label>
                </div>
                <div className="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 uplaod-img-main">
                  <FormItem>
                    <Upload
                      fileList={this.state.selectedFileList}
                      onChange={this.onImageChange}
                      customRequest={this.customRequest}
                      showUploadList={false}
                      multiple={false}
                      beforeUpload={(file) => {
                        const isValidImage =
                          file.type === "image/jpeg" ||
                          file.type === "image/png" ||
                          file.type === "image/jpg";
                        if (!isValidImage) {
                          message.error("You can only upload JPG or PNG file!");
                          return false;
                        } else {
                          return true;
                        }
                      }}
                    >
                      <Button>
                        <IntlMessages id="menuInfo.choseFile" />
                      </Button>
                    </Upload>
                  </FormItem>
                </div>
              </div>
              <div className="ant-row ant-row-flex-center">
                <div className="ant-col">
                  <div className="detail-res-rite upload-img-box">
                    <img src={this.state.fileUrl} />
                  </div>
                </div>
              </div>
            </Form>
          </Modal>
          <Card className="gx-card breadcrumb-box" title="">
            <div className="collaps-main">
              <div className="ant-row">
                <div className="search-box ant-col-md-12">
                  <IntlMessages id="categoryList.search">
                    {(text) => (
                      <Search
                        placeholder={text}
                        onSearch={(value) => this.searchHandler(value)}
                        onChange={(value) => this.searchReset(value)}
                        style={{ width: 200 }}
                      />
                    )}
                  </IntlMessages>
                </div>
                <div className="ant-col-md-12">
                  <div className="orders-item top-section filter-box flex-direction-reverse-col">
                    <div className="drop-filter-cover">
                      <i className="fa fa-filter filter-icon"></i>
                      <Select
                        className="select-box"
                        defaultValue={<IntlMessages id="menuList.select" />}
                        onChange={(value) => this.handleIsAvailable(value)}
                      >
                        <Option value={undefined}>
                          <IntlMessages id="menuList.all" />
                        </Option>
                        <Option value={true}>
                          <IntlMessages id="menuList.enabled" />
                        </Option>
                        <Option value={false}>
                          <IntlMessages id="menuList.disabled" />
                        </Option>
                      </Select>
                    </div>
                    {this.state.isB2B && (
                      <Button
                        type="primary"
                        className="color-white"
                        onClick={(e) => {
                          this.handleOpenExcelModal();
                        }}
                      >
                        <i className="icon icon-upload"></i>
                        <IntlMessages id="menuList.upload" />
                      </Button>
                    )}
                    <Button
                      type="primary"
                      className="color-white"
                      onClick={(e) => {
                        this.openMenuInfo(null, null);
                      }}
                    >
                      <i className="icon icon-add"></i>
                      <IntlMessages id="menuList.add" />
                    </Button>
                    <Button
                      type="primary"
                      className="color-white"
                      onClick={(e) => this.saveSortedList()}
                    >
                      <i className="fa fa-floppy-o"></i>
                      <IntlMessages id="menuList.saveSortedList" />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="ant-row">
                {this.state.categoryWithMenu.map((item, index) => {
                  return (
                    <div
                      onMouseOver={(event) => {
                        this.setCurrentMenuData(index);
                      }}
                      key={index}
                      className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24"
                    >
                      <h2 style={{ paddingTop: "30px", color: "#8bc53f" }}>
                        {item.categoryName}:
                      </h2>
                      <Table
                        pagination={false}
                        className="gx-table-responsive payout-report menu-cate-lisi-table"
                        columns={
                          this.state.isB2B
                            ? b2bColumns
                            : isDubai
                            ? dubaiColumns
                            : columns
                        }
                        dataSource={item.menuItem}
                        rowKey="index"
                        components={this.components}
                        onRow={this.onRow}
                      />
                    </div>
                  );
                })}
                {this.state.spin ? (
                  <Spin
                    style={{ marginLeft: "45%", marginRight: "45%" }}
                    size="large"
                    tip="Loading..."
                  />
                ) : null}
              </div>
            </div>
          </Card>
        </div>

        {/* modal-edit */}
        <Modal
          title="Add Category"
          visible={this.state.addItem}
          onOk={this.addItem}
          onCancel={this.addItem}
        >
          <div className="item-detail-edit-box">
            <Form layout={formLayout}>
              <FormItem label="Category Name" {...formItemLayout}>
                <Input placeholder="" />
              </FormItem>
              <FormItem label="Image" {...formItemLayout}>
                <Upload {...props}>
                  <Button className="btn-upload-cust">
                    <Icon type="upload" /> Upload
                  </Button>
                </Upload>
              </FormItem>
            </Form>
          </div>
        </Modal>

        {/* modal-edit */}
        <Modal
          title="Add Category"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="item-detail-edit-box">
            <Form layout={formLayout}>
              <FormItem label="Category name" {...formItemLayout}>
                <Input placeholder="" />
              </FormItem>
            </Form>
          </div>
        </Modal>

        <Modal
          title="Add a new option"
          visible={this.state.addOption}
          onOk={this.addOption}
          onCancel={this.addOption}
        >
          <div className="item-detail-edit-box">
            <p>Option Details</p>
            <Form layout={formLayout}>
              <FormItem label="Optin type" {...formItemLayout}></FormItem>
              <FormItem label="Name" {...formItemLayout}>
                <Input placeholder="" />
              </FormItem>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  domainWhitelist: state.settings.domainWhitelist,
  whitelisting: state.settings.whitelisting,
});
export default DragDropContext(HTML5Backend)(
  connect(mapStateToProps)(menuList)
);
