import axios from "axios";
import BaseService from "./baseService";

class DomainWhitelistServiceController extends BaseService {
  async fetch(body) {
    const response = await axios.post("/domain-whitelist/fetch", body);
    return response.data;
  }

  async details(body) {
    const response = await axios.post("/domain-whitelist/details", body);
    return response.data;
  }

  async getRestaurantSpecificDomainWhitelist(id) {
    const response = await axios.post(
      "/domain-whitelist/restaurant-domain-whitelist",
      {
        Id: id,
      }
    );
    return response.data;
  }

  async save(body) {
    const response = await axios.post("/domain-whitelist/save", body);
    return response.data;
  }

  async updateStatus(body) {
    const response = await axios.post("/domain-whitelist/update-status", body);
    return response.data;
  }
  async remove(body) {
    const response = await axios.post("/domain-whitelist/remove", body);
    return response.data;
  }
  async getAllDomainWhitelistNames() {
    const response = await axios.post(
      "/domain-whitelist/get-all-domainwhitelist"
    );
    return response.data;
  }
}

export const DomainWhitelistService = new DomainWhitelistServiceController();
