import axios from "axios";
import BaseService from "./baseService";

class ScheduleServiceController extends BaseService {

    async fetchRestaurantCloseSchedules(body) {
        const response = await axios.post("/schedule/fetch-restaurant-close", body);
        return response.data;
    }
    async saveRestaurantCloseSchedule(body) {
        const response = await axios.post("/schedule/save-restaurant-close", body);
        return response.data;
    }
    async removeRestaurantCloseSchedule(body){
        const response = await axios.post("/schedule/remove-restaurant-close", body);
        return response.data;
    }

}

export const ScheduleService = new ScheduleServiceController();