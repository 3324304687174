import axios from "axios";
import BaseService from "./baseService";

class DashboardControllerService extends BaseService {
  //for Super Admin
  async getTotalOrderSummary_SA() {
    const response = await axios.get("/dashboard/getTotalOrders");
    return response.data;
  }

  async getOrderAndCustomerByStatus_SA() {
    const response = await axios.get("/dashboard/getTotalByOrderStatus");
    return response.data;
  }

  async getBusinessOrders_SA() {
    const response = await axios.get("/dashboard/getBusinessCount");
    return response.data;
  }

  async getTopCustomers_SA(filter) {
    const response = await axios.post("/dashboard/getTopCustomers", filter);
    return response.data;
  }

  //for Restaurant Admin
  async getTotalOrderSummary_RA() {
    const response = await axios.get("/dashboard/getTotalOrders-ra");
    return response.data;
  }

  async getOrderAndCustomerByStatus_RA() {
    const response = await axios.get("/dashboard/getTotalByOrderStatus-ra");
    return response.data;
  }

  async getBusinessOrders_RA() {
    const response = await axios.get("/dashboard/getBusinessCount-ra");
    return response.data;
  }

  async getTopCustomers_RA(filter) {
    const response = await axios.post("/dashboard/getTopCustomers-ra", filter);
    return response.data;
  }
}

export const DashboardService = new DashboardControllerService();
