import React from "react";
import {
  Breadcrumb,
  Card,
  Collapse,
  Divider,
  Table,
  Modal,
  Popover,
} from "antd";
import {
  InputNumber,
  Radio,
  Spin,
  DatePicker,
  Badge,
  Rate,
  Slider,
  Switch,
  message,
  Upload,
} from "antd";
import {
  AutoComplete,
  Button,
  Cascader,
  Drawer,
  Checkbox,
  Col,
  Form,
  Icon,
  Tag,
  Input,
  Row,
  Select,
  Typography,
  Tooltip,
  formLayout,
  Timeline,
} from "antd";
import { ActivityService } from "../../services";
import moment from "moment";
import { connect } from "react-redux";
import IntlMessages from "util/IntlMessages";
import MapModal from "../sa_restaurantList/mapModal";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import { Link } from "react-router-dom";
import InfiniteScrollTable from "./../../components/table/InfiniteScrollTable";
const reactStringReplace = require("react-string-replace");
const { Text } = Typography;
const { RangePicker } = DatePicker;
const Search = Input.Search;
const { Option } = Select;
const { confirm } = Modal;

const columns = [
  {
    title: <IntlMessages id="activity.event" defaultMessage="Event" />,
    dataIndex: "event",
    key: "event",
  },
  {
    title: <IntlMessages id="activity.date" defaultMessage="Date" />,
    dataIndex: "date",
    key: "date",
  },
  {
    title: (
      <IntlMessages
        id="activity.sessionDetails"
        defaultMessage="Session Details"
      />
    ),
    dataIndex: "sessionDetails",
    key: "sessionDetails",
  },
  {
    title: (
      <IntlMessages id="activity.orderDetails" defaultMessage="Order Details" />
    ),
    dataIndex: "orderDetails",
    key: "orderDetails",
    width: 180,
  },
  {
    title: (
      <IntlMessages
        id="activity.requestDetails"
        defaultMessage="Request Details"
      />
    ),
    dataIndex: "requestDetails",
    key: "requestDetails",
  },
  {
    title: <IntlMessages id="activity.action" defaultMessage="Action" />,
    dataIndex: "action",
    key: "action",
  },
];

const subActivityType = {
  Email: "Email",
  Push: "Push",
};

class ActivityList extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      filter: {
        SearchQuery: "",
        Event: "",
        PageNo: 1,
        PageSize: 15,
        From: undefined,
        To: undefined,
      },
      activities: [],
      events: [],
      loading: true,
      hasMore: true,
      showAdvanceSearch: false,
      formLayout: "horizontal",
      showMapModal: false,
      mapModalDeviceLocation: { Latitude: 0, Longitude: 0 },
      showSubActivityModal: false,
      subActivityData: undefined,
    };
  }

  async componentDidMount() {
    let searchQuery = new URLSearchParams(this.props.location.search).get(
      "searchQuery"
    );
    if (searchQuery != null) {
      let filter = this.state.filter;
      filter.SearchQuery = searchQuery;
      this.setState({ filter: filter }, this.handleFilter);
    } else {
      this.handleFilter();
    }
    this.loadEvents();
  }

  showMapModal = (location) => {
    this.setState({
      mapModalDeviceLocation: location,
      showMapModal: true,
    });
  };

  loadEvents = async () => {
    try {
      const response = await ActivityService.fetchEvents();
      if (response && response.Success) {
        var events = [];
        for (var k in response.Data) events.push(k);
        this.setState({ events });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
  };

  handleFilter = async () => {
    try {
      const response = await ActivityService.fetch(this.state.filter);
      if (response && response.Success) {
        let hasMore = response.Data.length > 0;
        if (!hasMore) message.warning("End!");
        let mergeActivities = [...this.state.activities, ...response.Data];
        this.setState({ activities: mergeActivities, hasMore: hasMore });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
    this.setState({ loading: false });
  };

  delete = async (id) => {
    const _this = this;
    confirm({
      title: "Do you really want to delete this?",
      icon: <ExclamationCircleOutlined />,
      content: "Click!",
      onOk() {
        _this.deleteApi({ Id: id });
      },
      onCancel() {},
    });
  };

  deleteApi = async (deleteRequest) => {
    this.setState({ loading: true });
    const response = await ActivityService.remove(deleteRequest);
    if (response && response.Success) {
      message.success("Activity Deleted!");

      let filter = this.state.filter;
      filter.PageNo = 1;
      this.setState(
        {
          activities: [],
          loading: true,
          filter: filter,
        },
        this.handleFilter
      );
    } else {
      message.error("Failed!");
    }
  };

  fetchMoreData = async (e) => {
    if (!this.state.loading) {
      let filter = this.state.filter;
      filter.PageNo = filter.PageNo + 1;
      this.setState(
        {
          filter: filter,
          loading: true,
        },
        this.handleFilter
      );
    }
  };

  searchHandler = async (searchInput) => {
    this.props.history.push("/activitytracker?searchQuery=" + searchInput);
    let filter = this.state.filter;
    filter.PageNo = 1;
    filter.SearchQuery = searchInput;
    this.setState(
      {
        filter: filter,
        activities: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  subActivityHandler = async (id) => {
    this.setState({ showSubActivityModal: true });

    if (id === null || id === undefined || id === "") {
      this.setState({
        subActivityData: "Id not found!",
      });
      return false;
    }
    try {
      const response = await ActivityService.GetSubActivityDetails({ Id: id });
      if (response && response.Success) {
        let subActivityResponse;
        if (response.Data.length) {
          subActivityResponse = (
            <Timeline>{this.createSubActivity(response.Data)}</Timeline>
          );
        } else subActivityResponse = <p>No timeline found</p>;

        this.setState({ subActivityData: subActivityResponse });
      }
    } catch (e) {
      message.error(e);
    }
  };

  handleModalOk = () => {
    this.setState({ showSubActivityModal: false });
  };

  handleModalCancel = () => {
    this.setState({ showSubActivityModal: false });
  };

  createSubActivity(response) {
    let data = [];
    response.map((v, i) => {
      let activityDate = moment(v.CreateTime).format(
        this.props.whitelisting.AdminDateTimeFormat + ":ss"
      );
      let style = { maxHeight: "400px", maxWidth: "700px", overflow: "auto" };
      data.push(
        <span>
          <Timeline.Item color={v.Success ? "green" : "red"}>
            {v.Type} ({activityDate}) ({moment(v.CreateTime).fromNow()})
            <Popover
              trigger="click"
              content={
                <div style={style}>
                  <pre>{JSON.stringify(v.Receiver, null, 2)}</pre>
                </div>
              }
            >
              <Tag color="green" style={{ marginLeft: "5px" }}>
                Reciever
              </Tag>
            </Popover>
            <Popover
              trigger="click"
              content={
                <div style={style}>
                  <pre>{JSON.stringify(JSON.parse(v.Response), null, 2)}</pre>
                </div>
              }
            >
              <Tag color={v.Success ? "green" : "red"}>Response</Tag>
            </Popover>
          </Timeline.Item>
        </span>
      );
    });

    return data;
  }

  eventHandler = async (event) => {
    let filter = this.state.filter;
    filter.PageNo = 1;
    filter.Event = event;
    this.setState(
      {
        filter: filter,
        activities: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  rangeFilter = (value) => {
    let filter = this.state.filter;
    filter.PageNo = 1;
    filter.From = value.length && moment(value[0]._d);
    filter.To = value.length && moment(value[1]._d);
    filter.From = filter.From == 0 ? null : filter.From;
    filter.To = filter.To == 0 ? null : filter.To;
    this.setState(
      {
        filter: filter,
        activities: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  searchReset = (searchInput) => {
    if (searchInput.target.value == null || searchInput.target.value == "") {
      this.props.history.push("/activitytracker");
      let filter = this.state.filter;
      filter.PageNo = 1;
      filter.SearchQuery = "";
      this.setState(
        {
          filter: filter,
          activities: [],
          loading: true,
        },
        this.handleFilter
      );
    } else {
      let filter = this.state.filter;
      filter.SearchQuery = searchInput.target.value;
      this.setState({ filter });
    }
  };

  searchMark = (txt) => {
    return this.state.filter.SearchQuery == ""
      ? txt
      : reactStringReplace(txt, this.state.filter.SearchQuery, (match, i) => (
          <Text mark>{match}</Text>
        ));
  };

  isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  render() {
    const activitiesMaps = this.state.activities.map((v, i) => {
      let session = "";
      let request = "";
      let order = "";
      if (v.LoginSession != null) {
        let platform = (v.LoginSession.Platform + "").toLowerCase();
        switch ((v.LoginSession.Platform + "").toLowerCase()) {
          case "ios":
            platform = <i className="fa fa-apple"></i>;
            break;
          case "android":
            platform = <i className="fa fa-android"></i>;
            break;
          case "web":
            platform = <i className="fa fa-desktop"></i>;
            break;
        }

        let location = v.LoginSession?.DeviceLocation;
        let locationJhx = location && (
          <Tooltip title="Locate Device">
            <Link onClick={() => this.showMapModal(location)}>
              <i className="icon icon-geo-location"></i>
            </Link>
          </Tooltip>
        );

        session = (
          <span>
            <span>
              <Tooltip title={v.LoginSession.Platform}>{platform}</Tooltip>{" "}
              {v.LoginSession.Module}{" "}
              {v.LoginSession.AppVersion && (
                <small className="text-success">
                  v{v.LoginSession.AppVersion}
                </small>
              )}{" "}
              {locationJhx}
              <br></br>
            </span>
            <small>
              Token:
              <Link
                to={"activitytracker?searchQuery=token:" + v.LoginSession.Token}
                onClick={() =>
                  this.searchHandler("token:" + v.LoginSession.Token)
                }
              >
                {v.LoginSession.Token}
              </Link>
            </small>
            <br></br>
            {v.LoginSession.UserId && (
              <small>
                UserId:
                <Link
                  to={
                    "activitytracker?searchQuery=uid:" + v.LoginSession.UserId
                  }
                  onClick={() =>
                    this.searchHandler("uid:" + v.LoginSession.UserId)
                  }
                >
                  {v.LoginSession.UserId}
                </Link>
                <br></br>
              </small>
            )}
            {v.LoginSession.UserDeviceId && (
              <small>
                UDID:
                <Link
                  to={
                    "activitytracker?searchQuery=udid:" +
                    v.LoginSession.UserDeviceId
                  }
                  onClick={() =>
                    this.searchHandler("udid:" + v.LoginSession.UserDeviceId)
                  }
                >
                  {v.LoginSession.UserDeviceId}
                </Link>
                <br></br>
              </small>
            )}
            {v.LoginSession.DeviceId && (
              <small>
                DeviceId:
                <Link
                  to={
                    "activitytracker?searchQuery=did:" + v.LoginSession.DeviceId
                  }
                  onClick={() =>
                    this.searchHandler("did:" + v.LoginSession.DeviceId)
                  }
                >
                  {v.LoginSession.DeviceId}
                </Link>
                <br></br>
              </small>
            )}
            {v.LoginSession.UserName && (
              <small>
                UserName:{v.LoginSession.UserName}(
                <small>{v.LoginSession.UserRole}</small>)<br></br>
              </small>
            )}
            {v.LoginSession.UserEmail && (
              <small>
                Email:{v.LoginSession.UserEmail}
                <br></br>
              </small>
            )}
            {v.LoginSession.UserRestaurant && (
              <small>
                Restaurant:{v.LoginSession.UserRestaurant}
                <br></br>
              </small>
            )}
          </span>
        );
      }
      if (v.RequestDetails != null) {
        let rows = [];
        let headersCount = 0;
        if (v.RequestDetails.Headers != null) {
          for (let key in v.RequestDetails.Headers) {
            if (key != "Cookie" || true) {
              rows.push(
                <tr>
                  <td>{key}</td>
                  <td>
                    <small>
                      <Text>{v.RequestDetails.Headers[key]}</Text>
                    </small>
                  </td>
                </tr>
              );
              headersCount++;
            }
          }
        }
        let style = { maxHeight: "400px", maxWidth: "700px", overflow: "auto" };
        let headers = (
          <div style={style}>
            <table>{rows}</table>
          </div>
        );
        let requestBody = (
          <div style={style}>
            <pre>
              {JSON.stringify(JSON.parse(v.RequestDetails.Request), null, 2)}
            </pre>
          </div>
        );
        let responseBody = (
          <div style={style}>
            <pre>
              {JSON.stringify(
                JSON.parse(v.RequestDetails.ResponseData),
                null,
                2
              )}
            </pre>
          </div>
        );
        let stackTrace = (
          <div style={style}>
            <pre>{v.RequestDetails.ExceptionStackTrace}</pre>
          </div>
        );
        let stackTracePopover = (
          <Popover
            trigger="click"
            placement="left"
            content={stackTrace}
            title="Stack Trace"
          >
            {" "}
            <Button type="link" size="small">
              Stack Trace
            </Button>
          </Popover>
        );
        request = (
          <span>
            <Tag
              style={{ marginBottom: "0px" }}
              color={v.RequestDetails.Success ? "green" : "red"}
            >
              {v.RequestDetails.APICodeName} / Api Code :{" "}
              {v.RequestDetails.APICode}
            </Tag>
            <br></br>
            <small>
              {v.RequestDetails.APIMessage}
              {v.RequestDetails.ExceptionStackTrace && stackTracePopover}
            </small>
            <br></br>
            {this.isJsonString(v.RequestDetails.Request) == true && (
              <Popover
                trigger="click"
                placement="left"
                content={requestBody}
                title="Request Body"
              >
                {" "}
                <Button size="small">Request</Button>
              </Popover>
            )}
            {this.isJsonString(v.RequestDetails.ResponseData) == true && (
              <Popover
                trigger="click"
                placement="left"
                content={responseBody}
                title="Response Data"
              >
                {" "}
                <Button size="small">Response</Button>
              </Popover>
            )}
            <Popover
              style={{ maxHeight: "600px" }}
              trigger="click"
              placement="left"
              content={headers}
              title="Request Headers"
            >
              {" "}
              <Button type="dashed" size="small">
                {headersCount} Headers
              </Button>
            </Popover>{" "}
            <Button
              type="dashed"
              size="small"
              onClick={() => this.subActivityHandler(v.Id)}
            >
              Sub activity timeline
            </Button>
          </span>
        );
      }

      if (v.OrderDetails != null) {
        order = (
          <span>
            <small>
              <span>
                <i className="icon icon-burger"></i>{" "}
                <Link
                  to={
                    "activitytracker?searchQuery=oid:" + v.OrderDetails.OrderId
                  }
                  onClick={() =>
                    this.searchHandler("oid:" + v.OrderDetails.OrderId)
                  }
                >
                  {v.OrderDetails?.OrderId?.substr(18)}
                </Link>
              </span>
              <br></br>
              <span>
                <i className="icon icon-avatar"></i>{" "}
                <Link
                  to={
                    "activitytracker?searchQuery=cid:" +
                    v.OrderDetails.CustomerId
                  }
                  onClick={() =>
                    this.searchHandler("cid:" + v.OrderDetails.CustomerId)
                  }
                >
                  {v.OrderDetails.CustomerName}
                </Link>
              </span>
              <br></br>
              <span>
                <i className="icon icon-company"></i>{" "}
                <Link
                  to={
                    "activitytracker?searchQuery=rid:" +
                    v.OrderDetails.RestaurantId
                  }
                  onClick={() =>
                    this.searchHandler("rid:" + v.OrderDetails.RestaurantId)
                  }
                >
                  {v.OrderDetails.RestaurantName}
                </Link>
              </span>
              <br></br>
              <span>
                <i className="icon icon-pricing-table"> </i>
                {v.OrderDetails.PaymentMethod}
              </span>
            </small>
          </span>
        );
      }

      return {
        key: i,
        event: v.Event,
        date: (
          <>
            {moment(v.CreateTime).format(
              this.props.whitelisting.AdminDateTimeFormat
            )}
            <br />
            <small>({moment(v.CreateTime).fromNow()})</small>
          </>
        ),
        sessionDetails: session,
        requestDetails: request,
        orderDetails: order,
        action: (
          <a>
            <i
              onClick={() => this.delete(v.Id)}
              style={{ paddingLeft: "20px" }}
              class="fa fa-trash"
            ></i>
          </a>
        ),
      };
    });

    const eventMap = this.state.events.map((v, i) => {
      return <Select key={v}>{v}</Select>;
    });

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list">
        <MapModal
          showMarker={true}
          google={this.props.google}
          height="300px"
          zoom={15}
          center={this.state.mapModalDeviceLocation}
          onClose={() => this.setState({ showMapModal: false })}
          visible={this.state.showMapModal}
        />

        <Modal
          title="Sub-activity Timeline"
          visible={this.state.showSubActivityModal}
          width={1000}
          onOk={this.handleModalOk}
          onCancel={this.handleModalCancel}
          afterClose={() => this.setState({ subActivityData: null })}
        >
          <div
            style={{
              textAlign: "center",
            }}
          >
            {(this.state.subActivityData === null ||
              this.state.subActivityData === undefined) && (
              <Spin tip="Loading"></Spin>
            )}
            {this.state.subActivityData !== null && (
              <p>{this.state.subActivityData}</p>
            )}
          </div>
        </Modal>

        <div className="payout-report-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div>
              <div className="ant-row">
                <div className="search-box ant-col-md-9">
                  <IntlMessages id="option.search">
                    {(text) => (
                      <Search
                        allowClear
                        placeholder={text}
                        onSearch={(value) => this.searchHandler(value)}
                        onChange={(value) => this.searchReset(value)}
                        style={{ width: "100%" }}
                        value={this.state.filter.SearchQuery}
                      />
                    )}
                  </IntlMessages>
                </div>
                <div className="search-box ant-col-md-8">
                  <Select
                    onChange={(v, o) => this.eventHandler(v || "")}
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Filter events"
                  >
                    {eventMap}
                  </Select>
                </div>
                <div className="search-box ant-col-md-7">
                  <RangePicker
                    format={this.props.whitelisting.AdminDateFormat}
                    onChange={(value) => this.rangeFilter(value)}
                  />
                </div>
              </div>
              <div className="ant-row" style={{ marginTop: "10px" }}>
                <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
                  <InfiniteScrollTable
                    columns={columns}
                    dataSource={activitiesMaps}
                    loading={this.state.loading}
                    hasMore={this.state.hasMore}
                    height={"calc(100vh - 205px)"}
                    loadMore={this.fetchMoreData}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  whitelisting: state.settings.whitelisting,
});
export default connect(mapStateToProps)(ActivityList);
