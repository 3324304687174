import React from "react";
import {
  Card,
  Table,
  Spin,
  message,
  Button,
  Checkbox,
  Input,
  Select,
  Switch,
  Modal,
  Typography,
} from "antd";
import { connect } from "react-redux";
import { CustomerService, RestaurantService } from "../../services";
import { useHistory } from "react-router-dom";
import SwitchUser from "../../containers/SwitchUser";
import IntlMessages from "util/IntlMessages";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import appState from "../../state/AppStateContainer";
import InfiniteScrollTable from "./../../components/table/InfiniteScrollTable";
import { DomainType } from "../../constants/AppSettings";
import { B2BSTATUS } from "./b2bStatus";

const reactStringReplace = require("react-string-replace");
const { confirm } = Modal;
const { Text } = Typography;

const Search = Input.Search;
const Option = Select.Option;

const columns = [
  {
    title: <IntlMessages id="customer.firstName" defaultMessage="First Name" />,
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: <IntlMessages id="customer.lastName" defaultMessage="Last Name" />,
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: <IntlMessages id="customer.email" defaultMessage="Email" />,
    dataIndex: "email",
    key: "email",
  },
  {
    title: <IntlMessages id="customer.phone" defaultMessage="Phone Number" />,
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: <IntlMessages id="customer.fbId" defaultMessage="Facebook Id" />,
    dataIndex: "fbId",
    key: "fbId",
  },
  {
    title: <IntlMessages id="customer.orders" defaultMessage="Orders" />,
    dataIndex: "orders",
    key: "orders",
  },
  {
    title: <IntlMessages id="customer.status" defaultMessage="Status" />,
    dataIndex: "status",
    key: "status",
  },
  // {
  //   title: <IntlMessages id="customer.action" defaultMessage="Action" />,
  //   dataIndex: 'action',
  //   key: 'action'
  // }
];

const b2bColumns = [
  {
    title: <IntlMessages id="customer.firstName" defaultMessage="First Name" />,
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: <IntlMessages id="customer.lastName" defaultMessage="Last Name" />,
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: <IntlMessages id="customer.email" defaultMessage="Email" />,
    dataIndex: "email",
    key: "email",
  },
  {
    title: <IntlMessages id="customer.phone" defaultMessage="Phone Number" />,
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: (
      <IntlMessages
        id="customer.organizationNumber"
        defaultMessage="Organization Number"
      />
    ),
    dataIndex: "organizationNumber",
    key: "organizationNumber",
  },
  {
    title: (
      <IntlMessages id="customer.companyName" defaultMessage="Company Name" />
    ),
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: <IntlMessages id="customer.b2bStatus" defaultMessage="B2B Status" />,
    dataIndex: "b2bStatus",
    key: "b2bStatus",
  },
  {
    title: <IntlMessages id="customer.orders" defaultMessage="Orders" />,
    dataIndex: "orders",
    key: "orders",
  },
  {
    title: <IntlMessages id="customer.status" defaultMessage="Status" />,
    dataIndex: "status",
    key: "status",
  },
  {
    title: <IntlMessages id="customer.action" defaultMessage="Action" />,
    dataIndex: "action",
    key: "action",
  },
];

class RestaurantCustomerList extends React.Component {
  constructor() {
    super();
    this.state = {
      filter: {
        PageNo: 1,
        PageSize: 15,
        SearchQuery: "",
      },
      hasMore: true,
      customers: [],
      allowRedirectToOrders: false,
      formLayout: "horizontal",
      loading: true,
      isB2B: false,
    };
  }
  handleFilter = async () => {
    this.setState({ loading: true });
    try {
      let filter = this.state.filter;

      if (this.state.isB2B) {
        filter.domainWhitelistId = this.props.domainWhitelist?.Id;
      }
      const response = await CustomerService.fetchByRestaurant(filter);
      if (response && response.Success) {
        let hasMore = response.Data.length > 0;
        if (!hasMore) message.warning("End!");
        let mergeCustomers = [...this.state.customers, ...response.Data];
        this.setState({ customers: mergeCustomers, hasMore: hasMore });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
    this.setState({ loading: false });
  };

  updateStatus = async (value, id, i) => {
    let status = value == true ? 1 : 3;
    var customers = this.state.customers;
    customers[i].SwitchLoading = true;
    this.setState({ customers: customers });
    const request = {
      Id: id,
      Status: status,
    };
    const response = await CustomerService.updateStatusByRestaurant(request);
    if (response && response.Success) {
      customers[i].Status = status;
      message.success("Status Changed!");
    } else {
      message.error(response.APIMessage);
    }
    customers[i].SwitchLoading = false;
    this.setState({ customers: customers, spin: false });
  };
  getEnabledSidebarList = (number) => {
    const SIDEBAR = {
      Orders: 128,
      Customers: 256,
    };

    let selected = [];
    if ((number & SIDEBAR.Orders) === SIDEBAR.Orders) selected.push("Orders");
    if ((number & SIDEBAR.Customers) === SIDEBAR.Customers)
      selected.push("Customers");
    return selected;
  };

  async componentDidMount() {
    let isB2B = this.props.domainWhitelist?.Type == DomainType.B2B;
    this.setState({ isB2B });
    const id = appState.loadString("rId");
    if (id && id != "null" && id != null && id != undefined) {
      const restaurantDetail =
        await RestaurantService.getRestaurantDetailsForRa(id);
      var accessControls = this.getEnabledSidebarList(
        restaurantDetail.data.Data.AccessControl
      );
      const allowRedirectToOrders = accessControls.includes("Orders");
      this.setState({ allowRedirectToOrders });
    }

    let searchQuery = new URLSearchParams(this.props.location.search).get(
      "searchQuery"
    );
    if (searchQuery != null) {
      let filter = {
        PageNo: 1,
        PageSize: 15,
        SearchQuery: searchQuery,
      };
      this.setState({ filter: filter }, this.handleFilter);
    } else {
      this.handleFilter();
    }
  }

  delete = async (id, i) => {
    const _this = this;
    confirm({
      title: "Do you really want to delete this?",
      icon: <ExclamationCircleOutlined />,
      content: "Click!",
      onOk() {
        console.log("OK");
        _this.deleteApi({ Id: id }, i);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  deleteApi = async (deleteRequest, i) => {
    const response = await CustomerService.remove(deleteRequest);
    if (response && response.Success) {
      message.success("Customer Deleted!");
      let customers = this.state.customers;
      customers.splice(i, 1);
      this.setState({ customers: customers });
    } else if (response.APICode == 121) {
      //CantDeleteInProgressOrdersFound
      let modal = Modal.error({
        title: response.APIMessage,
        content: (
          <span>
            Here are order(s) :{" "}
            <ul>
              {response.Data.map((v) => (
                <li>
                  <a
                    onClick={() => {
                      this.props.history.push(
                        "/restaurant-orders?searchQuery=" + v.Id
                      );
                      modal.destroy();
                    }}
                  >
                    {v.Id}
                  </a>
                </li>
              ))}
            </ul>
          </span>
        ),
      });
    } else {
      message.error(response.APIMessage);
    }
  };

  fetchMoreData = async (e) => {
    if (!this.state.loading) {
      let filter = this.state.filter;
      filter.PageNo = filter.PageNo + 1;
      this.setState(
        {
          filter: filter,
          loading: true,
        },
        this.handleFilter
      );
    }
  };

  handleFilterByStatus = (value) => {
    let filter = {
      ...this.state.filter,
      PageNo: 1,
      Status: value,
    };

    this.setState({ filter, customers: [] }, this.handleFilter);
  };

  handleFilterByB2BStatus = (value) => {
    let filter = {
      ...this.state.filter,
      PageNo: 1,
      B2BStatus: value,
    };

    this.setState({ filter, customers: [] }, this.handleFilter);
  };

  searchHandler = async (searchInput) => {
    this.props.history.push("/restaurant-customers?searchQuery=" + searchInput);
    let filter = {
      PageNo: 1,
      PageSize: 15,
      SearchQuery: searchInput,
    };
    this.setState(
      {
        filter: filter,
        customers: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  searchReset = (searchInput) => {
    if (searchInput.target.value == null || searchInput.target.value == "") {
      this.props.history.push("/restaurant-customers");
      let filter = {
        PageNo: 1,
        PageSize: 15,
        SearchQuery: "",
        loading: true,
      };
      this.setState(
        {
          filter: filter,
          customers: [],
        },
        this.handleFilter
      );
    } else {
      let filter = this.state.filter;
      filter.SearchQuery = searchInput.target.value;
      this.setState({ filter });
    }
  };
  searchMark = (txt) => {
    return this.state.filter.SearchQuery == ""
      ? txt
      : reactStringReplace(txt, this.state.filter.SearchQuery, (match, i) => (
          <Text mark>{match}</Text>
        ));
  };

  render() {
    const customerList = this.state.customers.map((v, i) => {
      return this.state.isB2B
        ? {
            key: i,
            firstName: this.searchMark(v.FirstName || ""),
            lastName: this.searchMark(v.LastName || ""),
            email: this.searchMark(v.Email),
            phone: this.searchMark(v.PhoneNumber),
            fbId: this.searchMark(v.FbId),
            domainName: v.DomainName,
            organizationNumber: v.OrganizationNumber,
            companyName: v.CompanyName,
            b2bStatus: B2BSTATUS[v.B2BStatus],
            orders: this.state.allowRedirectToOrders ? (
              <Link to={"/restaurant-orders?searchQuery=cid:" + v.Id}>
                {v.OrdersCount}
              </Link>
            ) : (
              v.OrdersCount
            ),
            status: (
              <Switch
                loading={v.SwitchLoading == true}
                checked={v.Status != 3}
                disabled={v.Status == 2}
                onClick={(value) => this.updateStatus(value, v.Id, i)}
              />
            ),
            action: (
              <a>
                <i
                  onClick={() =>
                    this.props.history.push("customers/update/" + v.Id)
                  }
                  className="fa fa-pencil"
                ></i>
              </a>
            ),
          }
        : {
            key: i,
            firstName: this.searchMark(v.FirstName || ""),
            lastName: this.searchMark(v.LastName || ""),
            email: this.searchMark(v.Email),
            phone: this.searchMark(v.PhoneNumber),
            fbId: this.searchMark(v.FbId),
            orders: this.state.allowRedirectToOrders ? (
              <Link to={"/restaurant-orders?searchQuery=cid:" + v.Id}>
                {v.OrdersCount}
              </Link>
            ) : (
              v.OrdersCount
            ),
            status: (
              <Switch
                loading={v.SwitchLoading == true}
                checked={v.Status != 3}
                disabled={v.Status == 2}
                onClick={(value) => this.updateStatus(value, v.Id, i)}
              />
            ),
            action: (
              <a>
                <i
                  onClick={() =>
                    this.props.history.push(
                      "/restaurant-customers/update/" + v.Id + "?from=ra"
                    )
                  }
                  className="fa fa-pencil"
                ></i>
                <i
                  onClick={() => this.delete(v.Id, i)}
                  style={{ paddingLeft: "20px" }}
                  class="fa fa-trash"
                ></i>
              </a>
            ),
          };
    });

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list">
        <div className="payout-report-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div className="collaps-main">
              <div className="ant-row">
                <div className="search-box ant-col-md-12">
                  <IntlMessages id="categoryList.search">
                    {(text) => (
                      <Search
                        placeholder={text}
                        value={this.state.filter.SearchQuery}
                        onSearch={(value) => this.searchHandler(value)}
                        onChange={(value) => this.searchReset(value)}
                        style={{ width: 200 }}
                      />
                    )}
                  </IntlMessages>
                </div>
                <div className="ant-col-md-12">
                  <div className="orders-item top-section filter-box ">
                    {this.state.isB2B && (
                      <div className="drop-filter-cover">
                        <i class="fa fa-filter filter-icon"></i>
                        <Select
                          className="select-box"
                          defaultValue={0}
                          onChange={(value) =>
                            this.handleFilterByB2BStatus(value)
                          }
                          disabled={this.state.loading}
                        >
                          <Option value={0}>
                            <IntlMessages
                              id="customer.allB2BStatus"
                              defaultMessage="All B2B Status"
                            />
                          </Option>
                          <Option value={1}>
                            <IntlMessages
                              id="b2b.pending"
                              defaultMessage="Pending"
                            />
                          </Option>
                          <Option value={2}>
                            <IntlMessages
                              id="b2b.accepted"
                              defaultMessage="Accepted"
                            />
                          </Option>
                          <Option value={3}>
                            <IntlMessages
                              id="b2b.rejected"
                              defaultMessage="Rejected"
                            />
                          </Option>
                        </Select>
                      </div>
                    )}

                    <div className="drop-filter-cover">
                      <i class="fa fa-filter filter-icon"></i>
                      <Select
                        className="select-box"
                        defaultValue={0}
                        onChange={(value) => this.handleFilterByStatus(value)}
                        disabled={this.state.loading}
                      >
                        <Option value={0}>
                          <IntlMessages
                            id="customer.all"
                            defaultMessage="All"
                          />
                        </Option>
                        <Option value={1}>
                          <IntlMessages
                            id="customer.active"
                            defaultMessage="Active"
                          />
                        </Option>
                        <Option value={3}>
                          <IntlMessages
                            id="customer.in-active"
                            defaultMessage="In-Active"
                          />
                        </Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ant-row">
                <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
                  <InfiniteScrollTable
                    columns={this.state.isB2B ? b2bColumns : columns}
                    dataSource={customerList}
                    loading={this.state.loading}
                    hasMore={this.state.hasMore}
                    height={"calc(100vh - 205px)"}
                    loadMore={this.fetchMoreData}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  domainWhitelist: state.settings.domainWhitelist,
});
export default connect(mapStateToProps)(RestaurantCustomerList);
