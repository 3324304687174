export const AppSettings = {
  ApiUrls: {
    SwedenStaging: "https://spdevapi.azurewebsites.net/",
    SwedenProduction: "https://api-food.speedoy.se/",
    PakistanStaging: "https://beta.api-food.speedoy.pk/",
    PakistanProduction: "https://api-food.speedoy.pk/",
    DubaiStaging: "https://dubaispdevapi.azurewebsites.net/",
    Localhost: "http://localhost:24749/",
  },
  WebUrls: {
    SwedenStaging: "https://spdevweb.azurewebsites.net",
    SwedenProduction: "https://speedoyweb.azurewebsites.net",
    PakistanStaging: "https://betapkspdevweb.azurewebsites.net",
    PakistanProduction: "https://pkspdevweb.azurewebsites.net",
    DubaiStaging: "https://dubaispdevweb.azurewebsites.net",
    Localhost: "https://localhost:44363",
  },
  RestaurantOnlineOfflineMarginSeconds: 120,
  RefreshDeviceStatusAfterSeconds: 60,
  Country: {
    Pakistan: 168,
    Sweden: 215,
    UAE: 234,
  },
};

export const DomainType = {
  Restaurant: 0,
  B2B: 1,
};
