import React from "react";
import {
  Card,
  Table,
  Spin,
  message,
  Button,
  Checkbox,
  Input,
  Select,
  DatePicker,
} from "antd";
import { RestaurantService } from "../../../../services";
import IntlMessages from "util/IntlMessages";

import "../../../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import InfiniteScrollTable from "./../../../../components/table/InfiniteScrollTable";

const Search = Input.Search;
const Option = Select.Option;

const columns = [
  {
    title: <IntlMessages id="orders.dateTime" />,
    dataIndex: "dateTime",
    key: "dateTime",
  },
  {
    title: <IntlMessages id="orders.orderNumber" />,
    dataIndex: "orderNumber",
    key: "orderNumber",
  },
  /*{
    title: 'Email/Phone',
    dataIndex: 'emailPhone',
    key: 'emailPhone',
  },*/
  {
    title: <IntlMessages id="orders.status" />,
    dataIndex: "status",
    key: "status",
  },
  /*{
    title: 'Action',
    dataIndex: 'action',
    key: 'action'
  }*/
];

class RestaurantOrders extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      id: "",
      data: [],
      pageNo: 1,
      pageSize: 15,
      searchQuery: "",
      ratingSortOrder: "",
      loading: true,
      hasMore: true,
      delivery: false,
      takeaway: false,
      formLayout: "horizontal",
      status: 0,
      date: null,
    };
  }

  async getDataList(pageNo) {
    const filterParameters = {
      RestaurantId: this.state.id,
      PageNo: pageNo,
      PageSize: this.state.pageSize,
      SearchQuery: this.state.searchQuery,
      RatingSortOrder: this.state.ratingSortOrder,
      FilterServiceType:
        (this.state.delivery ? 1 : 0) + (this.state.takeaway ? 2 : 0),
      OrderStatus: this.state.status,
      FilterData:
        this.state.date == null
          ? null
          : new Date(this.state.date).toLocaleDateString(),
    };

    const response = await RestaurantService.getRestaurantsOrderList(
      filterParameters
    );
    let list = [];
    if (response != null) {
      for (let i = 0; i < response.length; i++) {
        list.push({
          dateTime: response[i].FormatedStringOrderDate,
          orderNumber: response[i].Id,
          //emailPhone:response[i].EmailPhone,
          status: response[i].OrderStatusString,
          //action:<div><a onClick={() =>{}}>Details</a></div>
        });
      }
    }
    return list;
  }

  async componentDidMount() {
    this.setState({ id: this.props.rid }, () => {
      this.performResearch();
    });
  }

  fetchMoreData = async (e) => {
    this.setState({
      loading: true,
    });

    const pageNo = this.state.pageNo + 1;
    let list = await this.getDataList(pageNo);

    if (list.length == 0) {
      message.warning("End!");
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }

    let mergeList = [...this.state.data, ...list];
    this.setState({
      data: mergeList,
      pageNo: pageNo,
      loading: false,
    });
  };

  searchHandler = async (searchInput) => {
    this.setState({ searchQuery: searchInput }, async () => {
      await this.performResearch();
    });
  };

  searchReset = (searchInput) => {
    if (searchInput == "" || searchInput == null) {
      this.setState(
        {
          searchQuery: "",
        },
        async () => await this.performResearch()
      );
    }
  };

  deliveryOrTakeaway = (key, value) => {
    this.setState({ [key]: value }, async () => {
      await this.performResearch();
    });
  };

  handleSorting = (value) => {
    this.setState({ ratingSortOrder: value }, async () => {
      await this.performResearch();
    });
  };

  handleDateFilter = (date) => {
    let _date = date == null ? null : date._d;
    this.setState({ date: _date }, async () => {
      await this.performResearch();
    });
  };

  handleFilterStatus = (value) => {
    this.setState({ status: value }, async () => {
      await this.performResearch();
    });
  };

  async performResearch() {
    this.setState(
      {
        pageNo: 1,
        hasMore: true,
        loading: true,
      },
      async () => {
        let list = await this.getDataList(1);
        this.setState({ data: list, loading: false });
      }
    );
  }

  handleSorting = (value) => {
    this.setState(
      {
        ratingSortOrder: value,
        pageNo: 1,
        hasMore: true,
        loading: true,
      },
      async () => {
        await this.performResearch();
      }
    );
  };

  render() {
    return (
      <div>
        <div className="ant-row">
          <div className="search-box ant-col-md-12">
            <IntlMessages id="categoryList.search">
              {(text) => (
                <Search
                  placeholder={text}
                  onSearch={(value) => this.searchHandler(value)}
                  onChange={(value) => this.searchReset(value.target.value)}
                  style={{ width: 200 }}
                />
              )}
            </IntlMessages>
            <div className="check-box-fill">
              <ul>
                <li>
                  <Checkbox
                    onChange={(e) =>
                      this.deliveryOrTakeaway("delivery", !this.state.delivery)
                    }
                  >
                    <IntlMessages id="orders.delivery" />
                  </Checkbox>
                </li>
                <li>
                  <Checkbox
                    onChange={(e) =>
                      this.deliveryOrTakeaway("takeaway", !this.state.takeaway)
                    }
                  >
                    <IntlMessages id="orders.takeaway" />
                  </Checkbox>
                </li>
              </ul>
            </div>
          </div>
          <div className="ant-col-md-12">
            <div className="orders-item top-section filter-box ">
              <div style={{ marginRight: "10px" }}>
                <DatePicker onChange={(date) => this.handleDateFilter(date)} />
              </div>
              <div className="drop-filter-cover">
                <i class="fa fa-filter filter-icon"></i>
                <Select
                  style={{ width: 200 }}
                  className="select-box"
                  defaultValue="Status"
                  onChange={(value) => this.handleFilterStatus(value)}
                >
                  <Option value={0}>
                    <IntlMessages id="orders.all" />
                  </Option>
                  <Option value={1}>
                    <IntlMessages id="orders.accepted" />
                  </Option>
                  <Option value={2}>
                    <IntlMessages id="orders.onTheWay" />
                  </Option>
                  <Option value={3}>
                    <IntlMessages id="orders.delivered" />
                  </Option>
                  <Option value={4}>
                    <IntlMessages id="orders.pending" />
                  </Option>
                  <Option value={5}>
                    <IntlMessages id="orders.rejected" />
                  </Option>
                  <Option value={6}>
                    <IntlMessages id="orders.readyForDelivery" />
                  </Option>
                  <Option value={7}>
                    <IntlMessages id="orders.acceptedByRider" />
                  </Option>
                  <Option value={8}>
                    <IntlMessages id="orders.completed" />
                  </Option>
                  <Option value={9}>
                    <IntlMessages id="orders.readyForTakeaway" />
                  </Option>
                </Select>
              </div>
              {/*<div className="drop-filter-cover">
                      <i class="fa fa-filter filter-icon"></i>
                      <Select disabled className="select-box" defaultValue="Rating" onChange={(value) => this.handleSorting(value)}>
                        <Option value={"ascending"}>low to high</Option>
                        <Option value={"descending"}>high to low</Option>
                      </Select>
    </div>*/}
            </div>
          </div>
        </div>

        <div className="ant-row">
          <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
            <InfiniteScrollTable
              columns={columns}
              dataSource={this.state.data}
              loading={this.state.loading}
              hasMore={this.state.hasMore}
              height={"300px"}
              className={"demo"}
              loadMore={this.fetchMoreData}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default RestaurantOrders;
