import axios from "axios";
import BaseService from "./baseService";

class RestaurantServiceController extends BaseService {
  async getRestaurantsList(body) {
    const response = await axios.post("/restaurant/getallrestaurants", body);
    return response.data;
  }

  async changeRestaurantStatus(status, id) {
    const response = await axios.post(
      "/restaurant/enableordisableresrautrant",
      {
        Status: status,
        RestaurantId: id,
      }
    );
    return response;
  }

  async getRestaurantsOrderList(filterParameters) {
    const response = await axios.post(
      "/order/getrestaurantorderslist",
      filterParameters
    );
    return response.data.Data;
  }

  async saveDeliverychargetype(deliveryChargeType) {
    return await axios.post(
      "/restaurant/updaterestaurantdeliverychargetype",
      deliveryChargeType
    );
  }

  async saveDeliveryCharges(deliveryCharges) {
    return await axios.post(
      "/restaurant/saverestaurantdeliverycharges",
      deliveryCharges
    );
  }

  async savePlatformCharges(platformCharges) {
    return await axios.post("/restaurant/saveplatformcharges", platformCharges);
  }
  async saveDeliveryTimings(timings) {
    return await axios.post("/restaurant/updatedeliverytimings", timings);
  }
  async saveTakeawayTimings(timings) {
    return await axios.post("/restaurant/updatetakeawaytimings", timings);
  }

  async addDeliveryFixAmount(amount) {
    return await axios.post("/restaurant/addrestaurantfixamount", amount);
  }

  async saveMinimumOrderAmount(amount) {
    return await axios.post(
      "/restaurant/saverestaurantminimumorderamount",
      amount
    );
  }

  async saveServiceInfoConfig(data) {
    return await axios.post("/restaurant/updateserviceinfo", data);
  }

  async saveSerialNumberOption(data) {
    return await axios.post("/restaurant/save-order-serial-option", data);
  }

  async saveEstimatedFoodPreparationTime(data) {
    return await axios.post(
      "/restaurant/save-estimated-food-preparation-time",
      data
    );
  }

  async updateAccessControl(data) {
    return await axios.post("/restaurant/updateaccesscontrol", data);
  }

  async updatePaymentMethods(data) {
    return await axios.post("/restaurant/updatepaymentmethod", data);
  }

  async getRestaurantCharges(id) {
    const response = await axios.post("/restaurant/getrestaurantcharges", {
      Id: id,
    });
    return response.data.Data;
  }
  async getRestaurantTimings(id) {
    return await axios.post("/restaurant/getrestauranttimings", { Id: id });
  }

  async getRestaurant(id) {
    const response = await axios.post("/restaurant/getRestaurantdetailSa", {
      Id: id,
    });
    return response;
  }

  async getRestaurantDetails(id) {
    const response = await axios.post("/restaurant/getRestaurantdetail", {
      Id: id,
    });
    return response;
  }
  async getRestaurantNameList() {
    const response = await axios.post("/restaurant/getallrestaurantslist");
    return response.data;
  }

  async getActiveRestaurantNameList() {
    const response = await axios.post(
      "/restaurant/active-restaurants-name-list"
    );
    return response.data;
  }

  async getActiveWhitelistRestaurantNameList() {
    const response = await axios.post(
      "/restaurant/active-whitelist-restaurants-name-list"
    );
    return response.data;
  }

  async getRestaurantsBranchesNames() {
    const response = await axios.get("/restaurant/restaurant-branches-names");
    return response;
  }

  async getRestaurantDetailsForRa(id) {
    const response = await axios.post("/restaurant/getrestaurantdetailforra", {
      Id: id,
    });
    return response;
  }

  async getUpdatedDevicesStatus(request) {
    const response = await axios.post(
      "/restaurant/updated-devices-status",
      request
    );
    return response.data;
  }

  async getCusinesList() {
    const response = await axios.get("/category/getcategories");
    return response;
  }

  async createRestaurant(restaurant) {
    const response = await axios.post(
      "/restaurant/addupdaterestaurant",
      restaurant
    );
    return response;
  }

  async updateRestaurantFixAmount(restaurantInfo) {
    const response = await axios.post(
      "/restaurant/updaterestaurantfixamount",
      restaurantInfo
    );
    return response;
  }

  async updateFreeDeliveryFromAmount(data) {
    const response = await axios.post(
      "/restaurant/update-free-delivery-from-amount",
      data
    );
    return response;
  }

  async updateDeliveryTax(data) {
    const response = await axios.post("/restaurant/update-delivery-tax", data);
    return response;
  }

  async deleteRestaurantFixAmount(restaurantInfo) {
    const response = await axios.post(
      "/restaurant/deleterestaurantfixamount",
      restaurantInfo
    );
    return response;
  }

  async getRestaurantGallery(id) {
    const response = await axios.post("/restaurant/gallerySa", {
      Id: id,
    });
    return response;
  }

  async getRestaurantSlider(id) {
    const response = await axios.post("/restaurant/restaurantSliderSa", {
      Id: id,
    });
    return response;
  }

  async saveGallery(request) {
    const response = await axios.post("/restaurant/addUpdateGallery", request);
    return response.data;
  }

  async saveSlider(request) {
    const response = await axios.post("/restaurant/addUpdateSlider", request);
    return response.data;
  }

  async getCreditCharges(request) {
    const response = await axios.post(
      "/restaurant/get-charging-payment-methods",
      request
    );
    return response.data;
  }

  async saveCreditCharges(request) {
    const response = await axios.post(
      "/restaurant/update-charging-payment-methods",
      request
    );
    return response.data;
  }

  async saveRestaurantSwishSettings(request) {
    const response = await axios.post(
      "/restaurant/update-restaurant-swish-settings",
      request
    );
    return response.data;
  }

  async updateRestaurantDeviceControls(request) {
    const response = await axios.post(
      "/restaurant/update-restaurant-device-controls",
      request
    );
    return response.data;
  }
}

export const RestaurantService = new RestaurantServiceController();
