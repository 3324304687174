import React from "react";
import AmCharts from "@amcharts/amcharts3-react";
import { property } from "lodash";

const localDataProvider = [
  {
    title: "Total<br> Order",
    value: 5000,
  },
  {
    title: "Accepted<br> Order",
    value: 1500,
  },
  {
    title: "Rejected<br> Order",
    value: 100,
  },
  {
    title: "Completed<br> Order",
    value: 3000,
  },
];

const DonutChart2 = (props) => {
  const config2 = {
    type: "pie",
    theme: "light",
    hideCredits: true,
    colorField: "Color",
    dataProvider: props.data ? props.data : localDataProvider,
    titleField: props.data ? props.title : "title",
    valueField: props.data ? props.value : "value",
    labelRadius: 5,
    labelsEnabled: false,
    radius: "50%",
    innerRadius: "45%",
    labelText: "[[title]]",
    export: {
      enabled: false,
    },
    legend: {
      type: "legend",
      position: "bottom",
    },
  };

  return (
    <div className="App">
      <AmCharts.React
        style={{ width: "100%", height: "250px" }}
        options={config2}
      />
    </div>
  );
};

export default DonutChart2;
