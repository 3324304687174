import React from "react";
import IntlMessages from "util/IntlMessages";
import {
  Input,
  Alert,
  Drawer,
  Button,
  message,
  Popconfirm,
  Spin,
  Table,
  Typography,
} from "antd";
import { RiderService, ReportService } from "../../services";
import InfiniteScrollTable from "./../../components/table/InfiniteScrollTable";
const { Search } = Input;
const { Text } = Typography;
const reactStringReplace = require("react-string-replace");

const columns = [
  {
    title: <IntlMessages id="report.riderName" />,
    dataIndex: "name",
    key: "name",
  },
  {
    title: <IntlMessages id="report.riderEmail" defaultMessage="Email" />,
    dataIndex: "email",
    key: "email",
  },
  {
    title: <IntlMessages id="report.action" />,
    dataIndex: "action",
    key: "action",
  },
];

export class AssignOrderToRiderDrawer extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      assigningLoading: false,
      ridersCity: null,
      riders: [],
      filter: {
        SearchQuery: "",
        PageNo: 1,
        PageSize: 15,
      },
    };
  }

  componentDidMount = () => {
    //this.handleFilter();
  };
  componentDidUpdate = (previoutProp) => {
    if (
      this.props.orderId != null &&
      previoutProp.orderId != this.props.orderId
    ) {
      this.setState({ ridersCity: null, assigningLoading: false }, () =>
        this.searchHandler("")
      );
    }
  };

  handleFilter = async () => {
    this.setState({ loading: true });
    try {
      this.state.filter.OrderId = this.props.orderId;
      const response = await RiderService.fetch(this.state.filter);
      if (response && response.Success) {
        let hasMore = response.Data.Riders.length > 0;
        if (!hasMore) message.warning("End!");
        let mergedRiders = [...this.state.riders, ...response.Data.Riders];
        this.setState({
          riders: mergedRiders,
          hasMore: hasMore,
          ridersCity: response.Data.RidersCity,
        });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
    this.setState({ loading: false });
  };

  fetchMoreData = async (e) => {
    if (!this.state.loading) {
      let filter = this.state.filter;
      filter.PageNo = filter.PageNo + 1;
      this.setState(
        {
          filter: filter,
          loading: true,
        },
        this.handleFilter
      );
    }
  };
  searchHandler = async (searchInput) => {
    let filter = this.state.filter;
    filter.PageNo = 1;
    filter.SearchQuery = searchInput;
    this.setState(
      {
        filter: filter,
        riders: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  searchReset = (searchInput) => {
    let filter = this.state.filter;
    filter.SearchQuery = searchInput.target.value;
    this.setState({ filter });
  };

  searchMark = (txt) => {
    return this.state.filter.SearchQuery == ""
      ? txt
      : reactStringReplace(txt, this.state.filter.SearchQuery, (match, i) => (
          <Text mark>{match}</Text>
        ));
  };

  onAssignRider = async (riderId) => {
    this.setState({ assigningLoading: true });
    try {
      const response = await ReportService.assignRider({
        RiderId: riderId,
        OrderId: this.props.orderId,
      });
      if (response && response.Success) {
        message.success("Successfully Assigned!");
        this.props.onAssign(response.Data);
      } else {
        message.error(response.APIMessage);
      }
      this.props.onClose();
    } catch (ex) {
      message.error(ex.message);
    } finally {
      this.setState({ assigningLoading: false });
    }
  };

  render() {
    const ridersMap = this.state.riders.map((v, i) => {
      return {
        key: i,
        name: this.searchMark(v.Name),
        email: this.searchMark(v.Email),
        action: (
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => this.onAssignRider(v.Id)}
            okText="Yes"
            disabled={this.state.assigningLoading}
            cancelText="No"
          >
            <Button disabled={this.state.assigningLoading} type="link">
              Assign
            </Button>
          </Popconfirm>
        ),
      };
    });

    return (
      <>
        <Drawer
          title={<IntlMessages id="orders.assignOrder" />}
          placement="right"
          closable={true}
          width="500px !important"
          onClose={this.props.onClose}
          visible={this.props.visible}
        >
          <Search
            allowClear={!this.state.loading}
            style={{ marginTop: 10 }}
            placeholder="Search rider by name or email"
            onSearch={this.searchHandler}
            onChange={this.searchReset}
            loading={this.state.loading}
          />
          {this.state.ridersCity != null && (
            <Alert
              message={`City "${this.state.ridersCity.Name}" riders.`}
              type="info"
              showIcon
            />
          )}

          <InfiniteScrollTable
            columns={columns}
            dataSource={ridersMap}
            loading={this.state.loading}
            hasMore={this.state.hasMore}
            height={"400px"}
            loadMore={this.fetchMoreData}
          />

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button onClick={this.props.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
          </div>
        </Drawer>
      </>
    );
  }
}
AssignOrderToRiderDrawer.defaultProps = {
  visible: false,
};
