import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
} from "react-google-maps";
import { Modal } from "antd";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import moment from 'moment';
import { GoogleMapsAPI } from "../../constants/MapConfig";
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

class MapModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: this.props.center
    };
  }

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.props.center.Latitude,
            lng: this.props.center.Longitude,
          }}
          defaultOptions={{
            streetViewControl: false,
            scaleControl: false,
            mapTypeControl: false,
            panControl: false,
            rotateControl: false,
            fullscreenControl: false,
          }}
        >
          {this.props.showMarker && (
            <Marker
              google={this.props.google}
              name={"Dolores park"}
              position={{
                lat: this.props.center.Latitude,
                lng: this.props.center.Longitude,
              }}
            >
            </Marker>
          )}
        </GoogleMap>
      ))
    );
    let map;
    if (
      this.props.center.Latitude !== undefined &&
      this.props.center.Longitude !== undefined
    ) {
      map = (
        <div>
          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return (
      <Modal
        title={`Device Location : ${moment(this.props.center.DateTime).fromNow()}`}
        centered
        width={"500px"}
        visible={this.props.visible}
        onCancel={() => this.props.onClose()}
        onOk={() => this.props.onClose()}
      >
        {map}
      </Modal>
    );
  }
}
export default MapModal;
