import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import { AuthSvc } from "../services";
import { FormattedMessage, injectIntl } from "react-intl";
import appState from "../state/AppStateContainer";
import { NotificationManager } from "react-notifications";
import _ from "lodash";

import IntlMessages from "util/IntlMessages";

const FormItem = Form.Item;

const ForgotPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (props.location.state === undefined) {
      history.push("/");
      return;
    }
    props.form.setFieldsValue({ Email: props.location.state.email });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoading(true);
          const form = {
            OtpCode: values.Code,
            Email: values.Email,
            NewPassword: values.Password,
          };

          var response = await AuthSvc.verifyOtpPasswordRestaurant(form);

          if (response && response.Success) {
            message.success("Password changed!");
            props.form.resetFields();
            history.push("/");
          } else {
            message.error(response.APIMessage);
          }
        } catch (ex) {
          message.error(ex.toString());
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const validateForm = (rule, value, callback) => {
    const newPassword = props.form.getFieldValue("Password");

    if (value != newPassword) callback("retype password not same");

    callback();
  };

  const { getFieldDecorator } = props.form;
  return (
    <div
      className="gx-app-login-wrap "
      style={{
        backgroundImage:
          "url(" + require("assets/images/home_section_1.jpg") + ")",
      }}
    >
      <div className="gx-app-login-container">
        <div className="hero_single">
          <img alt="example" src={require("assets/images/logo.png")} />
        </div>
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content">
            <Form
              onSubmit={handleSubmit}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem hidden>
                {getFieldDecorator("Email", {
                  label: "Email",
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ],
                })(<Input placeholder="Email" hidden />)}
              </FormItem>
              <FormItem label="Verification Code">
                {getFieldDecorator("Code", {
                  rules: [
                    {
                      required: true,
                      message: "Please input verification code!",
                    },
                  ],
                })(<Input type="text" placeholder="Verification code" />)}
              </FormItem>
              <FormItem label="New Password">
                {getFieldDecorator("Password", {
                  rules: [
                    { required: true, message: "Please input your Password!" },
                    { min: 6, message: "Enter atleast 6 characters!" },
                  ],
                })(<Input.Password type="password" placeholder="Password" />)}
              </FormItem>
              <FormItem label="Confirm Password">
                {getFieldDecorator("ConfirmPassword", {
                  rules: [
                    {
                      required: true,
                      message: "Please input confirm password!",
                    },
                    { validator: validateForm },
                  ],
                })(<Input.Password type="password" placeholder="Password" />)}
              </FormItem>
              <Button
                type="primary"
                className="gx-mb-0"
                htmlType="submit"
                loading={loading}
              >
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const WrappedForgotPassword = Form.create()(ForgotPassword);
export default WrappedForgotPassword;
