import React from "react";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Breadcrumb,
  Card,
  Collapse,
  Divider,
  Table,
  Modal,
  getFieldDecorator,
  Menu,
  Dropdown,
  InputNumber,
  Radio,
  Rate,
  Slider,
  Switch,
  message,
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  TimePicker,
  Col,
  Icon,
  Row,
  Select,
  Spin,
  Tooltip,
  formLayout,
  Tabs,
} from "antd";

import DynamicFieldSet from "./dynamicInput";
import moment from "moment";

import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";
import {
  ExclamationCircleOutlined,
  SmallDashOutlined,
} from "@ant-design/icons";

import _, { forEach, times } from "lodash";

// import "./basic.less";
import "../../../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../../../src/routes/components/dataEntry/Form/otherFormControls.less";

import { RestaurantService, AuthSvc, CityService } from "../../../../services";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import { GoogleMapsAPI } from "../../../../constants/MapConfig";
import { Label } from "recharts";
import { paymentOptions } from "./paymentOptions";
import AdministrativeAccess from "../../../settings/administrativeAccess";
import {
  DeliveryServiceModel,
  DeliveryServiceProviders,
  SpeedoyDeliveryModels,
} from "./common";
import DeliveryServiceProviderInput from "./deliveryServiceProvider";

Geocode.setApiKey(GoogleMapsAPI);

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;
function onChange(checkedValues) {
  console.log("checked = ", checkedValues);
}

const Panel = Collapse.Panel;
const { TextArea } = Input;
const { confirm } = Modal;
const defaultTimeFormat = "HH:mm";

let id = 0;

class RestaurantConfig extends React.Component {
  async componentDidMount() {
    const { rid } = this.props;
    this.setState({ id: rid });

    const res = await AuthSvc.restaurantDevice(rid);
    if (res && res.Success) {
      this.setState({ restaurantDevice: res.Data });
      if (res?.Data?.PairingCode) {
        this.props.form.setFieldsValue({ code: res?.Data?.PairingCode });
        this.setState({ isDevicePaired: true });
      }
    }

    this.loadCities();

    const response = await RestaurantService.getRestaurantTimings(rid);
    if (response && response.data.Success) {
      console.log(response.data.Data);

      //Add time slots
      let data = response.data.Data;
      this.props.form.setFieldsValue({
        km: data.ServiceDistanceKm || 0,
        totalScheduleDays: data.TotalScheduleDays,
      });

      let serviceInfoType = data.ServiceInfoType;
      let takeAwayOptionType = data.TakeAwayOption;
      // if(serviceInfoType.length > 2){
      //   serviceInfoType.pop();
      // }

      var orderColumns = data.OrdersReportColumns || [0];
      var strOrderOptionColumns = this.orderOptionColumns
        .filter((x) => orderColumns.includes(x.value))
        .map((x) => x.label);
      this.setOrderOptionColumnsSelected(strOrderOptionColumns);
      var selectedDeliveryServiceProviders = data.DeliveryServices?.map(
        (x) => x.ServiceProvider
      );
      this.setState({
        checkedPaymentOptions: data.PaymentMethod || [0],
        checkedAccessControlOptions: data.AccessControl || [0],
        showOrderReportColumnOptions:
          (data.AccessControl || [0]).includes(128) || false,
        checkedServiceOptions: serviceInfoType || [0],
        showAdditionalTakeAwayOptions: takeAwayOptionType || 0,
        serviceInfo: {
          deliveryModel: data.DeliveryModel || 0,
          serviceCity: data.ServiceCity,
          serviceProvidersDetails: data.DeliveryServices,
          serviceProviders: selectedDeliveryServiceProviders,
        },
        totalScheduleDays: data.TotalScheduleDays,
      });
      var resetNumbers = data?.OrderSerialOption?.ResetNumber || 1;
      var resetsIn = data?.OrderSerialOption?.NumberResetsIn || 0;
      var dineInOption = data?.DineInOption || 0;
      this.props.form.setFieldsValue({
        deliveryModel: data.DeliveryModel || 0,
        ResetNumbers: resetNumbers,
        ResetsIn: resetsIn,
        DineInOption: dineInOption,
        TakeAwayOption: takeAwayOptionType,
        foodPreparationMinutes: data.EFPT,
        serviceCity: data.ServiceCity,
        DeliveryModelServiceProvider: selectedDeliveryServiceProviders || [],
      });

      let resDeliveryTimings = response.data.Data.DeliveryTimings;

      for (let i = 0; i < resDeliveryTimings.length; i++) {
        if (resDeliveryTimings[i].Day == 0) {
          resDeliveryTimings[i].Day = 7;
        }
      }

      resDeliveryTimings.forEach((v) => {
        try {
          let day = v.Day;
          let fromTime = v.StartTimeString;
          let endTime = v.EndTimeString;

          const keys = this.props.form.getFieldValue("keys_" + day + "_d");
          const nextKeys = keys.concat(id++);
          this.props.form.setFieldsValue({
            ["keys_" + day + "_d"]: nextKeys,
          });

          this.props.form.setFieldsValue({
            ["d_from[" + (id - 1) + "]"]: moment(
              fromTime,
              this.props.whitelisting.AdminShortTimeFormat
            ),
            ["d_to[" + (id - 1) + "]"]: moment(
              endTime,
              this.props.whitelisting.AdminShortTimeFormat
            ),
          });

          let times = this.state.deliveryTimes;
          times[id - 1 + "_from"] = fromTime;
          times[id - 1 + "_to"] = endTime;

          this.setState({ deliveryTimes: times });
        } catch (er) {
          console.log(er);
        }
      });

      let resTakeawayTimings = response.data.Data.TakeawayTimings;

      for (let i = 0; i < resTakeawayTimings.length; i++) {
        if (resTakeawayTimings[i].Day == 0) {
          resTakeawayTimings[i].Day = 7;
        }
      }

      resTakeawayTimings.forEach((v) => {
        try {
          let day = v.Day;
          let fromTime = v.StartTimeString;
          let endTime = v.EndTimeString;

          const keys = this.props.form.getFieldValue("keys_" + day + "_t");
          const nextKeys = keys.concat(id++);
          this.props.form.setFieldsValue({
            ["keys_" + day + "_t"]: nextKeys,
          });

          this.props.form.setFieldsValue({
            ["t_from[" + (id - 1) + "]"]: moment(
              fromTime,
              this.props.whitelisting.AdminShortTimeFormat
            ),
            ["t_to[" + (id - 1) + "]"]: moment(
              endTime,
              this.props.whitelisting.AdminShortTimeFormat
            ),
          });

          let times = this.state.takeawayTimes;
          times[id - 1 + "_from"] = fromTime;
          times[id - 1 + "_to"] = endTime;
          this.setState({ takeawayTimes: times });
        } catch (er) {
          console.log(er);
        }
      });
    } else {
      message.error(response.data.APIMessage);
    }
  }

  loadCities = async () => {
    try {
      var response = await CityService.cityNameList();
      if (response && response.Success) {
        this.setState({ cities: response.Data });
      } else {
        message.error(response.APIMessage);
      }
    } catch {
      message.error("Something going wrong!");
    }
    this.setState({ loadingCity: false });
    console.log(this.state);
  };

  timestampToStringTime(timestamp) {
    var timeOffset = new Date().getTimezoneOffset() * 60;

    var date = new Date(timestamp + timeOffset);
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var seconds = "0" + date.getSeconds();

    return hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
  }

  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      isDevicePaired: false,
      restaurantDevice: null,
      showOrderReportColumnOptions: false,
      orderOptionColumnsSelected: this.orderOptionColumnsSelected,
      orderOptionColumnsSelectIndeterminate: false,
      orderOptionColumnsSelectAll: true,

      formLayout: "horizontal",
      loading: false,
      id: "",
      delivertTimingLoading: false,
      takeawayTimingLoading: false,
      deliveryTimes: {},
      takeawayTimes: {},
      serviceInfo: {
        deliveryModel: 0,
        serviceCity: "",
        serviceProviders: [],
        serviceProvidersDetails: [],
      },
      cities: [],
      checkedPaymentOptions: [0],
      checkedServiceOptions: [0],
      showAdditionalTakeAwayOptions: 0,
      checkedAccessControlOptions: [0],
      loadingServiceInfo: false,
      loadingPaymentMethods: false,
      loadingAccountControl: false,
      loadingSerialNumberOption: false,
      autocompleteValue: "",
      isAutoCompleteDirty: false,
      loadingEstimatedFoodPreparationTime: false,
      loadingCity: true,
      totalScheduleDays: 1,
      accessDialogVisible: false,
      pairingAccessGranted: false,
    };

    this.accessPairingCodeCallBack = this.accessPairingCodeCallBack.bind(this);
  }

  orderOptionColumns = [
    { label: "#", value: 1 << 0 },
    { label: "Custom Order ID", value: 1 << 20 },
    { label: "Order Number", value: 1 << 1 },
    { label: "Date", value: 1 << 2 },
    { label: "Customer Name", value: 1 << 4 },
    { label: "Rider Name", value: 1 << 5 },
    { label: "Delivery Address", value: 1 << 6 },
    { label: "Status", value: 1 << 7 },
    { label: "Sub Total", value: 1 << 8 },
    { label: "Commission", value: 1 << 9 },
    { label: "Minimum Order Adjustment", value: 1 << 10 },
    { label: "Delivery Fee", value: 1 << 11 },
    { label: "Total Without Commission", value: 1 << 12 },
    { label: "Discount", value: 1 << 13 },
    { label: "Total Tax", value: 1 << 18 },
    { label: "Total", value: 1 << 14 },
    { label: "Order Type", value: 1 << 15 },
    { label: "Payment Type", value: 1 << 16 },
    { label: "Action", value: 1 << 17 },
    { label: "Commission With Tax", value: 1 << 19 },
    { label: "Tax On Commission", value: 1 << 22 },
    { label: "Restaurant Address", value: 1 << 23 },
    { label: "Delivery Service Provider", value: 1 << 24 },
    { label: "Delivery Service Provider Charges", value: 1 << 25 },
    { label: "Platform Charges", value: 1 << 26 },
  ];

  orderOptionColumnsSelected = [
    "#",
    "Custom Order ID",
    "Order Number",
    "Date",
    "Customer Name",
    "Rider Name",
    "Delivery Address",
    "Status",
    "Sub Total",
    "Commission",
    "Minimum Order Adjustment",
    "Delivery Fee",
    "Total Without Commission",
    "Discount",
    "Total Tax",
    "Total",
    "Order Type",
    "Payment Type",
    "Action",
    "Commission With Tax",
    "Tax On Commission",
    "Restaurant Address",
    "Delivery Service Provider",
    "Delivery Service Provider Charges",
    "Platform Charges",
  ];

  onOrderOptionColumnsChange = (value, label) => {
    var checked = value.target.checked;
    var orderOptionColumnsSelected =
      this.state.orderOptionColumnsSelected || [];
    if (checked) {
      orderOptionColumnsSelected.push(label);
    } else {
      orderOptionColumnsSelected = orderOptionColumnsSelected.filter(
        (x) => x != label
      );
    }
    this.setOrderOptionColumnsSelected(orderOptionColumnsSelected);
  };

  setOrderOptionColumnsSelected = (orderOptionColumnsSelected) => {
    this.setState({
      orderOptionColumnsSelected,
      orderOptionColumnsSelectIndeterminate:
        !!orderOptionColumnsSelected.length &&
        orderOptionColumnsSelected.length < this.orderOptionColumns.length,
      orderOptionColumnsSelectAll:
        orderOptionColumnsSelected.length === this.orderOptionColumns.length,
    });
  };

  onOrderOptionColumnsCheckAllChange = (e) => {
    this.setState({
      orderOptionColumnsSelected: e.target.checked
        ? this.orderOptionColumns.map((x) => x.label)
        : [],
      orderOptionColumnsSelectIndeterminate: false,
      orderOptionColumnsSelectAll: e.target.checked,
    });
  };

  handleTakeawayTimingsSubmit = async (event, v) => {
    event.preventDefault();

    let fieldValues = [];
    for (let di = 1; di <= 7; di++) {
      let values = this.props.form.getFieldValue(`keys_${di}_t`);
      if (values != undefined) {
        values.forEach((v) => fieldValues.push(v));
      }
    }
    let validationSchemaFields = [];
    fieldValues.forEach((v) => {
      validationSchemaFields.push(`t_from[${v}]`);
      validationSchemaFields.push(`t_to[${v}]`);
    });

    this.props.form.validateFields(
      validationSchemaFields,
      async (err, values) => {
        if (!err) {
          console.log("values:", values);
          //Parsing Formate
          let timings = [];
          var times = this.state.takeawayTimes;
          for (let di = 1; di <= 7; di++) {
            let dayIds = this.props.form.getFieldValue(`keys_${di}_t`);

            console.log("dayIds_di:" + di, dayIds);

            forEach(dayIds, (v, i) => {
              // Calling parse function on input date string.
              let fromDecimal = Date.parse(
                "February 18, 2020 " + times[v + "_from"]
              );
              let toDecimal = Date.parse(
                "February 18, 2020 " + times[v + "_to"]
              );

              timings.push({
                StartTime: times[v + "_from"],
                EndTime: times[v + "_to"],
                Day: di,
              });
            });
          }

          console.log(timings);
          for (let i = 0; i < timings.length; i++) {
            if (timings[i].Day == 7) {
              timings[i].Day = 0;
            }
          }

          let requestData = {
            Id: this.state.id,
            Timings: timings,
          };

          const overlap = this.timeValidation(timings);
          if (overlap == "overlap") return;

          this.setState({ takeawayTimingLoading: true });
          try {
            let response = await RestaurantService.saveTakeawayTimings(
              requestData
            );
            if (response && response.data.Success) {
              message.success(`Takeaway timings successfully updated!`);
            } else {
              message.error(response.data.APIMessage);
            }
          } catch {
          } finally {
            this.setState({ takeawayTimingLoading: false });
          }
        } else {
          message.error("Some fields are required or contains invalid value!");
        }
      }
    );
  };

  timeValidation = (timings) => {
    var format = this.props.whitelisting.AdminShortTimeFormat;
    let daysTiming = {
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    };
    for (let j = 0; j < timings.length; j++) {
      if (timings[j].Day == 1) {
        daysTiming.Monday.push(
          moment(timings[j].StartTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
        daysTiming.Monday.push(
          moment(timings[j].EndTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
      } else if (timings[j].Day == 2) {
        daysTiming.Tuesday.push(
          moment(timings[j].StartTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
        daysTiming.Tuesday.push(
          moment(timings[j].EndTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
      } else if (timings[j].Day == 3) {
        daysTiming.Wednesday.push(
          moment(timings[j].StartTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
        daysTiming.Wednesday.push(
          moment(timings[j].EndTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
      } else if (timings[j].Day == 4) {
        daysTiming.Thursday.push(
          moment(timings[j].StartTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
        daysTiming.Thursday.push(
          moment(timings[j].EndTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
      } else if (timings[j].Day == 5) {
        daysTiming.Friday.push(
          moment(timings[j].StartTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
        daysTiming.Friday.push(
          moment(timings[j].EndTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
      } else if (timings[j].Day == 6) {
        daysTiming.Saturday.push(
          moment(timings[j].StartTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
        daysTiming.Saturday.push(
          moment(timings[j].EndTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
      } else {
        daysTiming.Sunday.push(
          moment(timings[j].StartTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
        daysTiming.Sunday.push(
          moment(timings[j].EndTime, format).diff(
            moment().startOf("day"),
            "seconds"
          )
        );
      }
    }

    for (const property in daysTiming) {
      console.log(daysTiming[property]);
      let daysArr = daysTiming[property];

      for (let x = 0; x < daysArr.length - 1; x++) {
        if (daysArr[x] > daysArr[x + 1]) {
          const duration = moment.duration(daysArr[x + 1], "seconds");
          const overlapTime =
            duration._data.hours + ":" + duration._data.minutes;
          message.error(
            `${property}: ${overlapTime} Time Overlapp. Please correct..!`
          );
          return "overlap";
        }
      }
    }
  };

  handleDeliveryTimingsSubmit = async (event, v) => {
    event.preventDefault();

    let fieldValues = [];
    for (let di = 1; di <= 7; di++) {
      let values = this.props.form.getFieldValue(`keys_${di}_d`);
      if (values != undefined) {
        values.forEach((v) => fieldValues.push(v));
      }
    }
    let validationSchemaFields = [];
    fieldValues.forEach((v) => {
      validationSchemaFields.push(`d_from[${v}]`);
      validationSchemaFields.push(`d_to[${v}]`);
    });

    this.props.form.validateFields(
      validationSchemaFields,
      async (err, values) => {
        if (!err) {
          //Parsing Formate
          let timings = [];
          var times = this.state.deliveryTimes;
          for (let di = 1; di <= 7; di++) {
            let dayIds = this.props.form.getFieldValue(`keys_${di}_d`);
            forEach(dayIds, (v, i) => {
              // Calling parse function on input date string.
              let fromDecimal = Date.parse(
                "February 18, 2020 " + times[v + "_from"]
              );
              let toDecimal = Date.parse(
                "February 18, 2020 " + times[v + "_to"]
              );
              timings.push({
                StartTime: times[v + "_from"],
                EndTime: times[v + "_to"],
                Day: di,
              });
            });
          }

          for (let i = 0; i < timings.length; i++) {
            if (timings[i].Day == 7) {
              timings[i].Day = 0;
            }
          }

          let requestData = {
            Id: this.state.id || "0",
            Timings: timings,
          };

          const overlap = this.timeValidation(timings);
          if (overlap == "overlap") return;

          this.setState({ delivertTimingLoading: true });

          try {
            let response = await RestaurantService.saveDeliveryTimings(
              requestData
            );
            if (response && response.data.Success) {
              message.success(`Delivery timings successfully updated!`);
            } else {
              message.error(response.data.APIMessage);
            }
          } catch {
          } finally {
            this.setState({ delivertTimingLoading: false });
          }
        } else {
          message.error("Some fields are required or contains invalid value!");
        }
      }
    );
  };

  handleAccessControlSubmit = async (event, v) => {
    event.preventDefault();
    try {
      let validateFields = ["accessControl"];
      this.props.form.validateFields(validateFields, async (err, values) => {
        if (!err) {
          var columns = this.orderOptionColumns
            .filter((x) =>
              this.state.orderOptionColumnsSelected.includes(x.label)
            )
            .map((x) => x.value);
          var valuesSumFlag = columns.reduce((a, b) => a + b, 0);

          let paymentMethodFlagValue = 0;
          this.state.checkedAccessControlOptions.forEach((v) => {
            paymentMethodFlagValue += v;
          });
          let requestData = {
            Id: this.state.id,
            AccessControl: paymentMethodFlagValue,
            OrdersReportColumns: valuesSumFlag,
          };

          this.setState({ loadingAccountControl: true });

          const response = await RestaurantService.updateAccessControl(
            requestData
          );

          console.log(response);
          if (response && response.data.Success) {
            message.success(`Access control successfully saved!`);
          } else {
            message.error(response.data.APIMessage);
          }
          this.setState({ loadingAccountControl: false });
        } else {
          message.error("Some fields are required or contains invalid value!");
        }
      });
    } catch {
      message.error("Unexpected error occured while saving access control.");

      this.setState({ loadingAccountControl: false });
    }
  };

  handlePaymentMethodsSubmit = async (event, v) => {
    event.preventDefault();
    try {
      let validateFields = ["paymentMethods"];
      this.props.form.validateFields(validateFields, async (err, values) => {
        if (!err) {
          let paymentMethodFlagValue = 0;
          values.paymentMethods.forEach((v) => {
            paymentMethodFlagValue += v;
          });
          let requestData = {
            Id: this.state.id,
            PaymentMethodSupported: paymentMethodFlagValue,
          };

          this.setState({ loadingPaymentMethods: true });

          const response = await RestaurantService.updatePaymentMethods(
            requestData
          );

          console.log(response);
          if (response && response.data.Success) {
            message.success(`Payment methods successfully saved!`);
          } else {
            message.error(response.data.APIMessage);
          }
          this.setState({ loadingPaymentMethods: false });
        } else {
          message.error("Some fields are required or contains invalid value!");
        }
      });
    } catch {
      message.error("Unexpected error occured while saving payment methods.");

      this.setState({ loadingPaymentMethods: false });
    }
  };

  devicePairing = async (
    code,
    id,
    confirmPair = false,
    confirmCreateDevice = false
  ) => {
    const response = await AuthSvc.devicePairing(
      code,
      id,
      confirmPair,
      confirmCreateDevice
    );
    const _this = this;

    if (response && response.data.Success) {
      message.success("Paired Successfully");
      _this.setState({ isDevicePaired: true });
    } else if (response.data.APICode == 106) {
      //NotFound / Device not found
      confirm({
        title: "Do you really want to pair?",
        icon: <ExclamationCircleOutlined />,
        content: "Device has not been found. Do you still need to pair device?",
        onOk() {
          _this.devicePairing(code, id, true, true);
          _this.setState({ isDevicePaired: true });
        },
        onCancel() { },
      });
    } else if (response.data.APICode == 15) {
      //DevicePairedWithAnotherRestaurant
      confirm({
        title: "Do you really want to pair?",
        icon: <ExclamationCircleOutlined />,
        content: "Device already connected with another restaurant!",
        onOk() {
          _this.devicePairing(code, id, true);
          _this.setState({ isDevicePaired: true });
        },
        onCancel() { },
      });
    } else {
      message.error(response.data.APIMessage);
    }
  };

  deviceUnPairing = async (code, id) => {
    const response = await AuthSvc.deviceUnpairing(code, id);
    if (response && response.data.Success) {
      message.success("Unpaired Successfully");
      this.setState({ isDevicePaired: false });
    } else {
      message.error(response.data.APIMessage);
    }
  };

  accessPairingCodeCallBack(ev) {
    this.setState({ accessDialogVisible: false });
    this.pairCode();
  }
  pairCode = async () => {
    const _this = this;
    _this.props.form.validateFields(["code"], async (err, values) => {
      if (!err) {
        const code = values.code;
        const id = _this.state.id;
        const isPaired = _this.state.isDevicePaired;
        if (isPaired) {
          confirm({
            title: "Do you really want to unpair?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
              _this.deviceUnPairing(code, id);
            },
            onCancel() { },
          });
        } else {
          if (_this.state.restaurantDevice) {
            confirm({
              title: "Do you really want to pair?",
              icon: <ExclamationCircleOutlined />,
              content: "A device already connected!",
              onOk() {
                _this.devicePairing(code, id);
              },
              onCancel() { },
            });
          } else {
            _this.devicePairing(code, id);
          }
        }
      }
    });
  };

  handlePairCode = async (event) => {
    event.preventDefault();
    if (!this.state.pairingAccessGranted) {
      this.setState({ accessDialogVisible: true });
      return;
    }
    await this.pairCode();
  };

  handleServiceInfoSubmit = async (event, v) => {
    event.preventDefault();
    // In order to submit dynamic Service provider details,
    // we need to add any additional inputs here in validateFields

    try {
      let validateFields = [
        "serviceInfo",
        "km",
        "deliveryModel",
        "serviceCity",
        "totalScheduleDays",
        "DeliveryModelServiceProvider",
        "Speedoy_PhoneNumber",
        "Bykea_Username",
        "Bykea_Password",
        "DineInOption",
        "TakeAwayOption"
      ];
      this.props.form.validateFields(validateFields, async (err, values) => {
        if (!err) {
          let serviceInfoFlagValue = 0;
          let serviceInfoValue = this.state.checkedServiceOptions;
          serviceInfoValue.forEach((v) => {
            serviceInfoFlagValue += v;
          });

          let takeAwayOptionFlagValue = 0;
          let takeAwayInfoValue = this.state.showAdditionalTakeAwayOptions || 0;
          if (!Array.isArray(takeAwayInfoValue)) {
            takeAwayInfoValue = [takeAwayInfoValue];
          }
          takeAwayInfoValue.forEach((v) => {
            takeAwayOptionFlagValue += v;
          });

          let requestData = {
            Id: this.state.id,
            ServiceInfoType: serviceInfoFlagValue,
            ServiceDistanceKm: values.km,
            ServiceCity: values.serviceCity,
            DeliveryModel: values.deliveryModel,
            TotalScheduleDays: values.totalScheduleDays,
            DineInOption: values.DineInOption,
            TakeAwayOption: takeAwayOptionFlagValue,
          };

          this.setState({ loadingServiceInfo: true });

          requestData = this.getDeliveryServiceRequestModel(
            requestData,
            values
          );

          const response = await RestaurantService.saveServiceInfoConfig(
            requestData
          );

          if (response && response.data.Success) {
            message.success(`Service Info successfully saved!`);
            this.setState({ totalScheduleDays: values.totalScheduleDays });
          } else if (response.data.APICode == 124) {
            //InProcessOrdersFound
            this.props.form.setFieldsValue({
              deliveryModel: this.state.serviceInfo.deliveryModel,
              serviceCity: this.state.serviceInfo.serviceCity,
            });
            message.error(response.data.APIMessage);
          } else {
            message.error(response.data.APIMessage);
          }
          this.setState({ loadingServiceInfo: false });
        }
      });
    } catch {
      message.error("Unexpected error occured while saving amount.");

      this.setState({ loadingServiceInfo: false });
    }
  };

  handleSerialNumberOptionSubmit = async (event, v) => {
    event.preventDefault();
    try {
      let validateFields = ["ResetNumbers", "ResetsIn"];
      this.props.form.validateFields(validateFields, async (err, values) => {
        if (!err) {
          let requestData = {
            RestaurantId: this.state.id,
            ResetsIn: values.ResetsIn,
            ResetNumber: values.ResetNumbers,
          };
          this.setState({ loadingSerialNumberOption: true });
          const response = await RestaurantService.saveSerialNumberOption(
            requestData
          );
          if (response && response.data.Success) {
            message.success(`Successfully Saved!`);
          } else {
            message.error(response.data.APIMessage);
          }
          this.setState({ loadingSerialNumberOption: false });
        }
      });
    } catch {
      message.error("Unexpected error occured while saving.");
      this.setState({ loadingSerialNumberOption: false });
    }
  };

  handleEstimatedFoodPreparationTimeSubmit = async (event, v) => {
    event.preventDefault();
    try {
      let validateFields = ["foodPreparationMinutes"];
      this.props.form.validateFields(validateFields, async (err, values) => {
        if (!err) {
          let requestData = {
            RestaurantId: this.state.id,
            FoodPreparationMinutes: values.foodPreparationMinutes,
          };
          this.setState({ loadingEstimatedFoodPreparationTime: true });
          const response =
            await RestaurantService.saveEstimatedFoodPreparationTime(
              requestData
            );
          if (response && response.data.Success) {
            message.success(`Successfully Saved!`);
          } else {
            message.error(response.data.APIMessage);
          }
          this.setState({ loadingEstimatedFoodPreparationTime: false });
        }
      });
    } catch {
      message.error("Unexpected error occured while saving.");
      this.setState({ loadingEstimatedFoodPreparationTime: false });
    }
  };

  handleTakeAwayTypeChange = (event) => {
    const value = event.target.value;
    this.setState({
      showAdditionalTakeAwayOptions: value,
    });
  }

  handleDeliveryModelChange = (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      serviceInfo: {
        ...prevState.serviceInfo,
        deliveryModel: value,
      },
    }));

    if (value == SpeedoyDeliveryModels.WithoutRider) {
      this.setState((prevState) => ({
        serviceInfo: {
          ...prevState.serviceInfo,
          serviceProviders: [],
        },
      }));
    }
  };

  handleDeliveryServiceProviderChange = (values) => {
    this.setState((prevState) => ({
      serviceInfo: {
        ...prevState.serviceInfo,
        serviceProviders: values,
      },
    }));
  };

  getDeliveryServiceRequestModel = (request, values) => {
    let serviceProvidersList = values.DeliveryModelServiceProvider;
    if (!serviceProvidersList) return request;
    serviceProvidersList.forEach((a) => {
      switch (a) {
        case DeliveryServiceProviders.Speedoy:
          request = this.getSpeedoyDeliveryRequest(a, request, values);
          break;
        case DeliveryServiceProviders.Bykea:
          request = this.getBykeaDeliveryRequest(a, request, values);
          break;
      }
    });
    return request;
  };

  getSpeedoyDeliveryRequest = (provider, request, values) => {
    const phoneNumber = values.Speedoy_PhoneNumber;
    request.DeliveryServices = {
      ...request.DeliveryServices,
      SpeedoyService: {
        ServiceProvider: provider,
        PhoneNumber: phoneNumber,
      },
    };

    return request;
  };

  getBykeaDeliveryRequest = (provider, request, values) => {
    const userName = values.Bykea_Username;
    const password = values.Bykea_Password;

    request.DeliveryServices = {
      ...request.DeliveryServices,
      BykeaService: {
        ServiceProvider: provider,
        Username: userName,
        Password: password,
      },
    };

    return request;
  };

  // Modal-scripts end
  render() {
    // form-scripts start
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
          labelCol: { xs: 24, sm: 24 },
          wrapperCol: { xs: 24, sm: 24 },
        }
        : null;
    const formItemLayout2 =
      formLayout === "horizontal"
        ? {
          labelCol: { xs: 12, sm: 8 },
          wrapperCol: { xs: 12, sm: 8 },
        }
        : null;
    const buttonItemLayout = null;

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };

    const paymentsCheckboxOptions = paymentOptions;

    const serviceInfoCheckboxOptions = [
      { label: <IntlMessages id="config.delivery" />, value: 1 },
      { label: <IntlMessages id="config.takeaway" />, value: 2 },
      { label: <IntlMessages id="config.customersCanSchedule" />, value: 4 },
      { label: <IntlMessages id="config.dineIn" />, value: 8 },
    ];
    const dineInInfoCheckBoxOptions = [
      { label: "SelfPick", value: 1 },
      { label: "Table", value: 2 },
      { label: "Both", value: 4 },
      { label: "None", value: 0 },
    ];
    const takeAwayInfoCheckBoxOptions = [
      { label: "SelfPick", value: 1 },
      { label: "Drive Thru", value: 2 },
      { label: "Both", value: 4 },
      { label: "None", value: 0 },
    ];
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
    };
    const deliveryModelOptions = [
      { label: <IntlMessages id="config.withRider" />, value: 1 },
      { label: <IntlMessages id="config.withoutRider" />, value: 2 },
    ];

    const deliveryServiceProvidersCheckbox = DeliveryServiceModel.filter(
      (a) => a.Country == this.props.whitelisting.Country
    ).map((a) => {
      return { value: a.Value, label: a.ServiceProvider };
    });

    const accessControlCheckboxOptions = [
      // { label: 'Dashboard', value: 1 },
      { label: "Categories", value: 1 },
      { label: "Menu", value: 2 },
      { label: "Option", value: 4 },
      { label: "Option Templates", value: 8 },
      // { label: 'Restaurant Details', value: 16 },
      // { label: 'Reports', value: 32 },
      // { label: 'User Management', value: 64 },
      {
        label: "Orders",
        value: 128,
        onChange: (e) => {
          this.setState({ showOrderReportColumnOptions: e.target.checked });
        },
      },
      { label: "Customers", value: 256 },
      { label: "Push Messages", value: 512 },
    ];

    const SerialNumberResetsIn = [
      {
        label: "Days",
        value: 0,
      },
      { label: "Months", value: 1 },
    ];

    const { getFieldDecorator, setFieldsValue, getFieldValue } =
      this.props.form;
    const { intl } = this.props;
    const distanceInKm = intl.formatMessage({ id: "config.distanceInKm" });
    const deliveryModelLabel = intl.formatMessage({
      id: "config.deliveryModel",
    });
    const deliveryServiceProvidersLabel = intl.formatMessage({
      id: "config.deliveryServiceProvider",
    });
    const pairCode = intl.formatMessage({ id: "config.pairCode" });
    const totalScheduleDays = intl.formatMessage({
      id: "config.totalScheduleDays",
    });

    const remove = (k, keysName) => {
      const keys = getFieldValue(keysName);
      if (keys.length === 1) {
        return;
      }
      setFieldsValue({
        [keysName]: keys.filter((key) => key !== k),
      });
    };

    const add = (keysName) => {
      const keys = getFieldValue(keysName);
      const nextKeys = keys.concat(id++);
      setFieldsValue({
        [keysName]: nextKeys,
      });
    };

    const setTimes = (keysName, value, isDeliveryTimings) => {
      if (isDeliveryTimings) {
        let times = this.state.deliveryTimes;
        times[keysName] = value;
        this.setState({ deliveryTimes: times });
      } else {
        let times = this.state.takeawayTimes;
        times[keysName] = value;
        this.setState({ takeawayTimes: times });
      }
    };

    const config = {
      rules: [
        {
          type: "object",
          // required: true,
          // message: 'Required!'
        },
      ],
    };

    const getDayTimingsComponent = (keysName, isDeliveryTimings) => {
      getFieldDecorator(keysName, { initialValue: [] });
      const keys = getFieldValue(keysName);

      const formItems = keys.map((k, index) => (
        <Input.Group
          style={{ margin: "0px", padding: "opx" }}
          key={k}
          className="ant-row time-select-box"
        >
          <Row gutter={[24, 8]} className="time-select-row">
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator(
                  `${isDeliveryTimings ? "d" : "t"}_from[${k}]`,
                  config
                )(
                  <TimePicker
                    placeholder="Start"
                    format={this.props.whitelisting.AdminShortTimeFormat}
                    onChange={(time, timeString) => {
                      setTimes(k + "_from", timeString, isDeliveryTimings);
                    }}
                    size={"small"}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator(
                  `${isDeliveryTimings ? "d" : "t"}_to[${k}]`,
                  config
                )(
                  <TimePicker
                    placeholder="End"
                    format={this.props.whitelisting.AdminShortTimeFormat}
                    onChange={(time, timeString) => {
                      setTimes(k + "_to", timeString, isDeliveryTimings);
                    }}
                    size={"small"}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={4} className="delet-icon-box">
              {keys.length > 1 ? (
                <Icon
                  className="dynamic-delete-button"
                  type="minus-circle-o"
                  onClick={() => remove(k, keysName)}
                />
              ) : null}
            </Col>
          </Row>
        </Input.Group>
      ));

      return (
        <>
          {formItems}
          <Form.Item {...formItemLayoutWithOutLabel} className="addTimeBtn">
            <Button
              size="small"
              type="dashed"
              onClick={(e) => {
                add(keysName);
              }}
              style={{ width: "100%" }}
            >
              <Icon type="plus" /> <IntlMessages id="config.addTimings" />
            </Button>
          </Form.Item>
        </>
      );
    };

    // MULTIPLE-scripts End
    return (
      <>
        <div className="title-sub">
          <h2>
            <span style={{ color: "red" }}>*</span>
            <IntlMessages id="config.timings" />
          </h2>
          <hr className="hr-line"></hr>
        </div>

        <div class="ant-row Platform-Charges Config-tab">
          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 Config-tab-lft">
            <div className="detail-res-lft">
              <div className="title-sub">
                <h4>
                  <IntlMessages id="config.deliveryTimings" />
                </h4>
                <hr className="hr-line"></hr>
              </div>
              <div className="detail-time">
                <Form
                  onSubmit={(e) => this.handleDeliveryTimingsSubmit(e)}
                  layout={formLayout}
                >
                  <table>
                    <tbody>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.monday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_1_d", true)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.tuesday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_2_d", true)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.wednesday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_3_d", true)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.thursday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_4_d", true)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.friday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_5_d", true)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.saturday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_6_d", true)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.sunday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_7_d", true)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span className="add-catgroey-btn">
                    {/*<a href="#">
                    <i className="icon icon-edit" />
                  </a>*/}
                    <FormItem {...formItemLayout}>
                      <Button
                        htmlType="submit"
                        type="primary"
                        loading={this.state.delivertTimingLoading}
                      >
                        <IntlMessages id="config.saveDeliveryTimings" />
                      </Button>
                    </FormItem>
                  </span>
                </Form>
              </div>
            </div>
          </div>
          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 Config-tab-right">
            <div className="detail-res-lft">
              <div className="title-sub">
                <h4>
                  <IntlMessages id="config.takeawayTiming" /> /{" "}
                  <IntlMessages id="config.dineInTiming" />
                </h4>
                <hr className="hr-line"></hr>
              </div>
              <div className="detail-time">
                <Form
                  onSubmit={(e) => this.handleTakeawayTimingsSubmit(e)}
                  layout={formLayout}
                >
                  <table>
                    <tbody>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.monday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_1_t", false)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.tuesday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_2_t", false)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.wednesday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_3_t", false)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.thursday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_4_t", false)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.friday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_5_t", false)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.saturday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_6_t", false)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ verticalAlign: "top" }}
                          className="days-lable"
                        >
                          <IntlMessages id="config.sunday" />
                        </td>
                        <td className="date-picker-box">
                          {getDayTimingsComponent("keys_7_t", false)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span className="add-catgroey-btn">
                    <FormItem {...formItemLayout}>
                      <Button
                        htmlType="submit"
                        type="primary"
                        loading={this.state.takeawayTimingLoading}
                      >
                        <IntlMessages id="config.saveTakeawayTimings" />
                      </Button>
                    </FormItem>
                  </span>
                </Form>
              </div>
            </div>
          </div>
          <div className="title-sub">
            <h2>
              <span style={{ color: "red" }}>*</span>
              <IntlMessages id="config.paymentMethod" />
            </h2>
            <hr className="hr-line"></hr>
          </div>
          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 payment-methods">
            <Form
              onSubmit={this.handlePaymentMethodsSubmit}
              className="ant-row"
            >
              <div class="ant-col ant-col-xs-24 ant-col-sm-6 ant-col-md-6 ant-col-lg-6">
                <Form.Item>
                  {getFieldDecorator("paymentMethods", {
                    valuePropName: "checked",
                    initialValue: this.state.checkedPaymentOptions || [0],
                  })(
                    <CheckboxGroup
                      options={paymentsCheckboxOptions}
                      value={this.state.checkedPaymentOptions}
                      onChange={(e) => {
                        this.setState({ checkedPaymentOptions: e });
                      }}
                    />
                  )}
                </Form.Item>
              </div>
              <div class="ant-col ant-col-xs-6 ant-col-sm-6 gx-mb-3">
                <Button
                  type="primary"
                  loading={this.state.loadingPaymentMethods}
                  htmlType="submit"
                  className="login-form-button"
                >
                  <IntlMessages id="config.savePaymentMethod" />
                </Button>
              </div>
            </Form>
          </div>
          <div className="title-sub gx-mt-4">
            <h2>
              <span style={{ color: "red" }}>*</span>
              <IntlMessages id="config.serviceInfo" />
            </h2>
            <hr className="hr-line"></hr>
          </div>
          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 service-info">
            <Form onSubmit={this.handleServiceInfoSubmit}>
              <Form.Item>
                {getFieldDecorator("serviceInfo", {
                  valuePropName: "checked",
                  initialValue: [0],
                })(
                  <CheckboxGroup
                    options={serviceInfoCheckboxOptions}
                    value={this.state.checkedServiceOptions}
                    onChange={(e) => {
                      this.setState({ checkedServiceOptions: e });
                    }}
                  />
                )}
              </Form.Item>
              {/* Radio Button for Additional Dine In Options */}
              <Form.Item label="Dine In Options">
                {getFieldDecorator("DineInOption", {
                  rules: [{ required: true, message: "Required!" }],
                  initialValue: 0,
                })(
                  <Radio.Group disabled={!this.state.checkedServiceOptions.includes(8)}>
                    {dineInInfoCheckBoxOptions.map((x) => (
                      <Radio key={x.value} value={x.value}>{x.label}</Radio>
                    ))}
                  </Radio.Group>
                )}
              </Form.Item>
              {/*  Additional Take Away Options */}
              <Form.Item label="Take Away Options">
                {getFieldDecorator("TakeAwayOption", {
                  rules: [{ required: true, message: "Required!" }],
                  valuePropName: "checked",
                })(
                  <RadioGroup
                    options={takeAwayInfoCheckBoxOptions}
                    value={this.state.showAdditionalTakeAwayOptions}
                    onChange={this.handleTakeAwayTypeChange}
                    disabled={!this.state.checkedServiceOptions.includes(2)}
                  />
                )}
              </Form.Item>
              {/* if customerCanSchedule is selected i.e 4 */}
              {this.state.checkedServiceOptions.includes(4) && (
                <Form.Item label={totalScheduleDays}>
                  {getFieldDecorator("totalScheduleDays", {
                    rules: [{ required: true, message: "Required!" }],
                    initialValue: this.state.totalScheduleDays,
                  })(
                    <InputNumber
                      style={{ width: 200 }}
                      min={0}
                      placeholder={totalScheduleDays}
                      max={7}
                    />
                  )}
                </Form.Item>
              )}
              <Form.Item label={deliveryModelLabel}>
                {getFieldDecorator("deliveryModel", {
                  rules: [{ required: true, message: "Required!" }],
                })(
                  <RadioGroup
                    disabled={
                      this.state.checkedServiceOptions.includes(1) == false
                    }
                    options={deliveryModelOptions}
                    defaultValue="1"
                    onChange={this.handleDeliveryModelChange}
                  />
                )}
              </Form.Item>

              {/* With Rider = 1 */}
              {this.state.serviceInfo?.deliveryModel ==
                SpeedoyDeliveryModels.WithRider && (
                  <>
                    <Form.Item label={deliveryServiceProvidersLabel}>
                      {getFieldDecorator("DeliveryModelServiceProvider", {
                        rules: [{ required: true, message: "Required!" }],
                      })(
                        <CheckboxGroup
                          options={deliveryServiceProvidersCheckbox}
                          defaultValue={this.state.serviceInfo?.serviceProviders}
                          onChange={this.handleDeliveryServiceProviderChange}
                        />
                      )}
                    </Form.Item>
                    <DeliveryServiceProviderInput
                      serviceInfo={this.state.serviceInfo}
                      form={this.props.form}
                      providerNameList={deliveryServiceProvidersCheckbox}
                    />
                  </>
                )}

              <Form.Item label={distanceInKm}>
                {getFieldDecorator("km", {
                  rules: [{ required: true, message: "Required!" }],
                })(
                  <InputNumber
                    style={{ width: 200 }}
                    min={0}
                    defaultValue={0}
                    placeholder={distanceInKm}
                  />
                )}
              </Form.Item>
              <Form.Item style={{ minWidth: "300px" }} label="City">
                {getFieldDecorator("serviceCity", {
                  rules: [{ required: true, message: "Required!" }],
                })(
                  <Select style={{ minWidth: "300px" }}>
                    {this.state.cities.map((item, index) => (
                      <Option key={index + 1} value={item.Id}>
                        {item.Name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Button
                type="primary"
                loading={this.state.loadingServiceInfo}
                htmlType="submit"
                className="login-form-button"
              >
                <IntlMessages id="config.saveServiceInfo" />
              </Button>
            </Form>
          </div>

          <div className="title-sub gx-mt-4">
            <h2>
              <span style={{ color: "red" }}>*</span>
              <IntlMessages
                id="config.serialNumberOption"
                defaultMessage="Order Serial Number Reset Option"
              />
            </h2>
            <hr className="hr-line"></hr>
          </div>
          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 service-info">
            <Form onSubmit={this.handleSerialNumberOptionSubmit}>
              <div className="ant-row Service-Info">
                <div class="ant-col ant-col-xs-24 ant-col-sm-5 ant-col-md-5 ant-col-lg-5">
                  <Form.Item
                    label={
                      <IntlMessages
                        id="config.resetIn"
                        defaultMessage="Reset In"
                      />
                    }
                  >
                    {getFieldDecorator("ResetsIn", {
                      rules: [{ required: true, message: "Required!" }],
                      initialValue: 0,
                    })(
                      <Radio.Group>
                        {SerialNumberResetsIn.map((x) => {
                          return <Radio value={x.value}>{x.label}</Radio>;
                        })}
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="ant-row Service-Info">
                <div class="ant-col ant-col-xs-24 ant-col-sm-5 ant-col-md-5 ant-col-lg-5">
                  <Form.Item>
                    {getFieldDecorator("ResetNumbers", {
                      rules: [{ required: true, message: "Required!" }],
                    })(
                      <InputNumber
                        style={{ width: 200 }}
                        min={1}
                        defaultValue={1}
                        addonBefore="Reset Numbers"
                        placeholder={"Reset Numbers"}
                      />
                    )}
                  </Form.Item>
                </div>
                <div class="ant-col ant-col-xs-4 ant-col-sm-4 ant-col-md-4 ant-col-lg-4">
                  <Button
                    type="primary"
                    loading={this.state.loadingSerialNumberOption}
                    htmlType="submit"
                    className="login-form-button"
                  >
                    <IntlMessages
                      id="config.saveRestDays"
                      defaultMessage="Save Reset Days"
                    />
                  </Button>
                </div>
              </div>
            </Form>
          </div>

          <div className="title-sub gx-mt-4">
            <h2>
              <span style={{ color: "red" }}>*</span>
              <IntlMessages
                id="config.estimatedFoodPreparationTime"
                defaultMessage="Estimated Food Preparation Time (Minutes)"
              />
            </h2>
            <hr className="hr-line"></hr>
          </div>
          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 service-info">
            <Form onSubmit={this.handleEstimatedFoodPreparationTimeSubmit}>
              <div className="ant-row Service-Info">
                <div class="ant-col ant-col-xs-24 ant-col-sm-5 ant-col-md-5 ant-col-lg-5">
                  <Form.Item>
                    {getFieldDecorator("foodPreparationMinutes", {
                      rules: [{ required: true, message: "Required!" }],
                    })(
                      <InputNumber
                        style={{ width: 200 }}
                        min={0}
                        defaultValue={0}
                        placeholder={"Minutes"}
                      />
                    )}
                  </Form.Item>
                </div>
                <div class="ant-col ant-col-xs-4 ant-col-sm-4 ant-col-md-4 ant-col-lg-4">
                  <Button
                    type="primary"
                    loading={this.state.loadingEstimatedFoodPreparationTime}
                    htmlType="submit"
                    className="login-form-button"
                  >
                    <IntlMessages
                      id="config.saveEstimatedFoodPreparationTimeMinutes"
                      defaultMessage="Save Minutes"
                    />
                  </Button>
                </div>
              </div>
            </Form>
          </div>

          <div className="title-sub gx-mt-4">
            <h2>
              <IntlMessages id="config.minPizzaMerchantDevice" />
              {this.state.restaurantDevice ? (
                <h5 style={{ color: "#8bc53f" }}>
                  <IntlMessages id="config.deviceConnected" />
                </h5>
              ) : null}
            </h2>
            <hr className="hr-line"></hr>
          </div>
          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 service-info">
            <Form onSubmit={(e) => this.handlePairCode(e)}>
              <div className="ant-row">
                <div class="ant-col ant-col-xs-24 ant-col-sm-5 ant-col-md-5 ant-col-lg-5">
                  <Form.Item>
                    {getFieldDecorator("code", {
                      rules: [{ required: true, message: "Please enter code" }],
                    })(
                      <Input
                        style={{ width: 200 }}
                        placeholder={pairCode}
                        disabled={this.state.isDevicePaired}
                      />
                    )}
                  </Form.Item>
                </div>
                <div class="ant-col ant-col-xs-4 ant-col-sm-4 ant-col-md-4 ant-col-lg-4">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    {this.state.isDevicePaired ? (
                      <IntlMessages id="config.unPair" />
                    ) : (
                      <IntlMessages id="config.pair" />
                    )}
                  </Button>
                </div>
              </div>
            </Form>
            <AdministrativeAccess
              title="Pairing Code Access"
              visible={this.state.accessDialogVisible}
              callBack={this.accessPairingCodeCallBack}
              cancelUrl="/restaurants"
              preserveAccess={false}
            />
          </div>

          <div className="title-sub gx-mt-4">
            <h2>Access Control</h2>
            <hr className="hr-line"></hr>
          </div>
          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 service-info">
            <Form onSubmit={this.handleAccessControlSubmit}>
              <Form.Item>
                {getFieldDecorator("accessControl", {
                  valuePropName: "checked",
                  initialValue: [0],
                })(
                  <CheckboxGroup
                    options={accessControlCheckboxOptions}
                    value={this.state.checkedAccessControlOptions}
                    onChange={(e) => {
                      this.setState({ checkedAccessControlOptions: e });
                    }}
                  />
                )}
              </Form.Item>

              {this.state.showOrderReportColumnOptions && (
                <div>
                  <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                    <Checkbox
                      indeterminate={
                        this.state.orderOptionColumnsSelectIndeterminate
                      }
                      onChange={this.onOrderOptionColumnsCheckAllChange}
                      checked={this.state.orderOptionColumnsSelectAll}
                    >
                      Check all orders columns
                    </Checkbox>
                  </div>
                  <br />
                  <div>
                    <Row>
                      {this.orderOptionColumns.map((column, index) => (
                        <Col span={12}>
                          <Checkbox
                            key={column.label}
                            onChange={(x) =>
                              this.onOrderOptionColumnsChange(x, column.label)
                            }
                            checked={this.state.orderOptionColumnsSelected.includes(
                              column.label
                            )}
                          >
                            {column.label}
                          </Checkbox>
                          <br></br>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              )}
              <br></br>
              <Button
                type="primary"
                loading={this.state.loadingAccountControl}
                htmlType="submit"
                className="login-form-button"
              >
                Save Access Control
              </Button>
            </Form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  whitelisting: state.settings.whitelisting,
});
const WrappedRestaurantConfig = injectIntl(Form.create()(RestaurantConfig));
export default connect(mapStateToProps)(WrappedRestaurantConfig);
