import React from "react";
import {
  Card,
  Table,
  Spin,
  message,
  Button,
  Checkbox,
  Input,
  Select,
  Switch,
  Modal,
  Typography,
} from "antd";
import { CustomerService, DomainWhitelistService } from "../../services";
import { useHistory } from "react-router-dom";
import SwitchUser from "../../containers/SwitchUser";
import IntlMessages from "util/IntlMessages";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { filter } from "lodash";
import InfiniteScrollTable from "./../../components/table/InfiniteScrollTable";
const reactStringReplace = require("react-string-replace");
const { confirm } = Modal;
const { Text } = Typography;

const Search = Input.Search;
const Option = Select.Option;

const columns = [
  {
    title: <IntlMessages id="customer.firstName" defaultMessage="First Name" />,
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: <IntlMessages id="customer.lastName" defaultMessage="Last Name" />,
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: <IntlMessages id="customer.email" defaultMessage="Email" />,
    dataIndex: "email",
    key: "email",
  },
  {
    title: <IntlMessages id="customer.phone" defaultMessage="Phone Number" />,
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: <IntlMessages id="customer.fbId" defaultMessage="Facebook Id" />,
    dataIndex: "fbId",
    key: "fbId",
  },
  {
    title: (
      <IntlMessages id="customer.domainName" defaultMessage="Domain Name" />
    ),
    dataIndex: "domainName",
    key: "domainName",
  },
  {
    title: (
      <IntlMessages
        id="customer.organizationNumber"
        defaultMessage="Organization Number"
      />
    ),
    dataIndex: "organizationNumber",
    key: "organizationNumber",
  },
  {
    title: (
      <IntlMessages id="customer.companyName" defaultMessage="Company Name" />
    ),
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: <IntlMessages id="customer.orders" defaultMessage="Orders" />,
    dataIndex: "orders",
    key: "orders",
  },
  {
    title: <IntlMessages id="customer.status" defaultMessage="Status" />,
    dataIndex: "status",
    key: "status",
  },
  {
    title: <IntlMessages id="customer.action" defaultMessage="Action" />,
    dataIndex: "action",
    key: "action",
  },
];

class CustomerList extends React.Component {
  constructor() {
    super();
    this.state = {
      filter: {
        PageNo: 1,
        PageSize: 15,
        SearchQuery: "",
        Status: 0,
        DomainWhitelistId: "",
      },
      hasMore: true,
      customers: [],
      formLayout: "horizontal",
      loading: true,
      domainNameList: [],
    };
  }
  handleFilter = async () => {
    this.setState({ loading: true });
    try {
      const response = await CustomerService.fetch(this.state.filter);
      if (response && response.Success) {
        let hasMore = response.Data.length > 0;
        if (!hasMore) message.warning("End!");
        let mergeCustomers = [...this.state.customers, ...response.Data];
        this.setState({ customers: mergeCustomers, hasMore: hasMore });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
    this.setState({ loading: false });
  };

  handleFilterByStatus = (value) => {
    let filter = {
      ...this.state.filter,
      PageNo: 1,
      Status: value,
    };

    this.setState({ filter: filter, customers: [] }, this.handleFilter);
  };

  handleFilterByDomain = (value) => {
    let filter = {
      ...this.state.filter,
      PageNo: 1,
      DomainWhitelistId: value,
    };

    this.setState({ filter: filter, customers: [] }, this.handleFilter);
  };
  updateStatus = async (value, id, i) => {
    let status = value == true ? 1 : 3;
    var customers = this.state.customers;
    customers[i].SwitchLoading = true;
    this.setState({ customers: customers });
    const request = {
      Id: id,
      Status: status,
    };
    const response = await CustomerService.updateStatus(request);
    if (response && response.Success) {
      customers[i].Status = status;
      message.success("Status Changed!");
    } else {
      message.error(response.APIMessage);
    }
    customers[i].SwitchLoading = false;
    this.setState({ customers: customers, spin: false });
  };

  async componentDidMount() {
    let searchQuery = new URLSearchParams(this.props.location.search).get(
      "searchQuery"
    );
    if (searchQuery != null) {
      let filter = {
        ...this.state.filter,
        PageNo: 1,
        PageSize: 15,
        SearchQuery: searchQuery,
      };
      this.setState({ filter: filter }, this.handleFilter);
    } else {
      this.handleFilter();
    }

    try {
      const response =
        await DomainWhitelistService.getAllDomainWhitelistNames();

      if (response.Success) {
        let list = [];
        for (let i = 0; i < response.Data.length; i++) {
          list.push({
            id: response.Data[i].Id,
            name: response.Data[i].Name,
          });
        }
        this.setState({ domainNameList: list });
      }
    } catch (e) {
      console.log(e);
      message.error("an error occured while fetching domain names");
    }
  }

  delete = async (id, i) => {
    const _this = this;
    confirm({
      title: "Do you really want to delete this?",
      icon: <ExclamationCircleOutlined />,
      content: "Click!",
      onOk() {
        console.log("OK");
        _this.deleteApi({ Id: id }, i);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  deleteApi = async (deleteRequest, i) => {
    const response = await CustomerService.remove(deleteRequest);
    if (response && response.Success) {
      message.success("Customer Deleted!");
      let customers = this.state.customers;
      customers.splice(i, 1);
      this.setState({ customers: customers });
    } else if (response.APICode == 121) {
      //CantDeleteInProgressOrdersFound
      let modal = Modal.error({
        title: response.APIMessage,
        content: (
          <span>
            Here are order(s) :{" "}
            <ul>
              {response.Data.map((v) => (
                <li>
                  <a
                    onClick={() => {
                      this.props.history.push("/orders?searchQuery=" + v.Id);
                      modal.destroy();
                    }}
                  >
                    {v.Id}
                  </a>
                </li>
              ))}
            </ul>
          </span>
        ),
      });
    } else {
      message.error(response.APIMessage);
    }
  };

  fetchMoreData = async (e) => {
    if (!this.state.loading) {
      let filter = this.state.filter;
      filter.PageNo = filter.PageNo + 1;
      this.setState(
        {
          filter: filter,
          loading: true,
        },
        this.handleFilter
      );
    }
  };

  searchHandler = async (searchInput) => {
    this.props.history.push("/customers?searchQuery=" + searchInput);
    let filter = {
      ...this.state.filter,
      PageNo: 1,
      PageSize: 15,
      SearchQuery: searchInput,
    };
    this.setState(
      {
        filter: filter,
        customers: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  searchReset = (searchInput) => {
    if (searchInput.target.value == null || searchInput.target.value == "") {
      this.props.history.push("/customers");
      let filter = {
        ...this.state.filter,
        PageNo: 1,
        PageSize: 15,
        SearchQuery: "",
        loading: true,
      };
      this.setState(
        {
          filter: filter,
          customers: [],
        },
        this.handleFilter
      );
    } else {
      let filter = this.state.filter;
      filter.SearchQuery = searchInput.target.value;
      this.setState({ filter });
    }
  };
  searchMark = (txt) => {
    return this.state.filter.SearchQuery == ""
      ? txt
      : reactStringReplace(txt, this.state.filter.SearchQuery, (match, i) => (
          <Text mark>{match}</Text>
        ));
  };

  render() {
    const customerList = this.state.customers.map((v, i) => {
      return {
        key: i,
        firstName: this.searchMark(v.FirstName || ""),
        lastName: this.searchMark(v.LastName || ""),
        email: this.searchMark(v.Email),
        phone: this.searchMark(v.PhoneNumber),
        fbId: this.searchMark(v.FbId),
        domainName: v.DomainName,
        organizationNumber: v.OrganizationNumber,
        companyName: v.CompanyName,
        orders: (
          <Link to={"/orders?searchQuery=cid:" + v.Id}>{v.OrdersCount}</Link>
        ),
        status: (
          <Switch
            loading={v.SwitchLoading == true}
            checked={v.Status != 3}
            disabled={v.Status == 2}
            onClick={(value) => this.updateStatus(value, v.Id, i)}
          />
        ),
        action: (
          <a>
            <i
              onClick={() =>
                this.props.history.push("customers/update/" + v.Id)
              }
              className="fa fa-pencil"
            ></i>
            <i
              onClick={() => this.delete(v.Id, i)}
              style={{ marginLeft: "20px" }}
              class="fa fa-trash"
            ></i>
          </a>
        ),
      };
    });

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list">
        <div className="payout-report-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div className="collaps-main">
              <div className="ant-row">
                <div className="search-box ant-col-md-12">
                  <IntlMessages id="categoryList.search">
                    {(text) => (
                      <Search
                        placeholder={text}
                        value={this.state.filter.SearchQuery}
                        onSearch={(value) => this.searchHandler(value)}
                        onChange={(value) => this.searchReset(value)}
                        style={{ width: 200 }}
                      />
                    )}
                  </IntlMessages>
                </div>
                <div className="ant-col-md-12">
                  <div className="orders-item top-section filter-box ">
                    <div className="drop-filter-cover">
                      <i class="fa fa-home filter-icon"></i>
                      <Select
                        style={{ minWidth: "200px" }}
                        className="select-box"
                        defaultValue="All Domains"
                        onChange={(value) => this.handleFilterByDomain(value)}
                      >
                        <Option key={0} value="">
                          <IntlMessages
                            id="customer.allDomains"
                            defaultMessage="All Domains"
                          />
                        </Option>
                        {this.state.domainNameList.map((item, index) => {
                          return (
                            <Option key={index + 1} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>

                    <div className="drop-filter-cover">
                      <i class="fa fa-filter filter-icon"></i>
                      <Select
                        className="select-box"
                        defaultValue={0}
                        onChange={(value) => this.handleFilterByStatus(value)}
                      >
                        <Option value={0}>
                          <IntlMessages
                            id="customer.all"
                            defaultMessage="All"
                          />
                        </Option>
                        <Option value={1}>
                          <IntlMessages
                            id="customer.active"
                            defaultMessage="Active"
                          />
                        </Option>
                        <Option value={3}>
                          <IntlMessages
                            id="customer.in-active"
                            defaultMessage="In-Active"
                          />
                        </Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ant-row">
                <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
                  <InfiniteScrollTable
                    columns={columns}
                    dataSource={customerList}
                    loading={this.state.loading}
                    hasMore={this.state.hasMore}
                    height={"calc(100vh - 205px)"}
                    loadMore={this.fetchMoreData}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
export default CustomerList;
