import axios from "axios";
import { AppSettings } from "../constants/AppSettings";
import BaseService from "./baseService";

class ReportServiceController extends BaseService {
  async getAllOrders(body) {
    const response = await axios.post("/order/getordersreportdata", body);
    return response.data.Data;
  }

  async getAllOrdersRa(body) {
    const response = await axios.post("/order/getordersreportdata-ra", body);
    return response.data;
  }

  async downloadOrdersExcelReport(q) {
    const response = await axios.post("/order/download-orders-excel-report", q);
    return response.data;
  }

  async downloadOrdersExcelReportRa(q) {
    const response = await axios.post(
      "/order/download-orders-excel-report-ra",
      q
    );
    return response.data;
  }

  async getOrderDetails(body) {
    const response = await axios.post("/order/details", body);
    return response.data;
  }

  async getOrderDetailsRa(body) {
    const response = await axios.post("/order/details-ra", body);
    return response.data;
  }

  async getUpdatedOrdersStatuses(body) {
    const response = await axios.post("/order/updated-order-statuses", body);
    return response.data;
  }

  async getUpdatedOrdersStatusesRa(body) {
    const response = await axios.post("/order/updated-order-statuses-ra", body);
    return response.data;
  }

  async changeStatus(body) {
    const response = await axios.post("/order/updatestatusbyadmin", body);
    return response.data;
  }

  async assignRider(body) {
    const response = await axios.post("/order/assign-rider", body);
    return response.data;
  }

  async getRestaurantNameList() {
    const response = await axios.post("/restaurant/getallrestaurantslist");
    return response.data.Data;
  }

  async printOrder(orderId) {
    let success = false;
    await axios
      .get(`/order/generate-admin-pdf?orderId=${orderId}`, {
        responseType: "blob",
      })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${orderId}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        success = true;
      })
      .catch((e) => {
        success = false;
      });

    return success;
  }

  async printSalesReportInvoice(body, filename) {
    let success = false;
    await axios
      .post(`/order/generate-report-invoice`, body, {
        responseType: "blob",
      })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${filename}_Sales_Report.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        success = true;
      })
      .catch((e) => {
        success = false;
      });

    return success;
  }
}

export const ReportService = new ReportServiceController();
