import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AuthSvc } from "../services";
import appState from "../state/AppStateContainer";
import _ from "lodash";
import { hideMessage, userSignIn } from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import { AppSettings } from "../constants/AppSettings";

const FormItem = Form.Item;

const SignInSuperAdmin = (props) => {
  const dispatch = useDispatch();
  const { alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();
  const [spin, setSpin] = useState(false);

  useEffect(() => {
    if (authUser !== null) {
      history.push("/");
    }
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    props.form.validateFields(async (err, values) => {
      try {
        if (!err) {
          setSpin(true);
          const responseToken = await AuthSvc.createToken();
          if (responseToken && responseToken.data.APICode == 0) {
            await appState.setToken(responseToken.data.Data.Token);
          }

          const response = await AuthSvc.loginSuperAdmin(
            values.email,
            values.password,
            responseToken.data.Data.Token
          );

          if (response && response.data.Success) {
            appState.persistString("rId", response.data.Data.RestaurantId);
            dispatch(userSignIn(response.data.Data));
            setSpin(false);
          } else {
            message.error(response.data.APIMessage);
            setSpin(false);
          }
        }
      } catch (e) {
        setSpin(false);
        message.error(
          _.get(
            e,
            "response.data.APIMessage",
            "Unexpected error occured while processing your request."
          )
        );
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <div
      className="gx-app-login-wrap "
      style={{
        backgroundImage:
          "url(" + require("assets/images/home_section_1.jpg") + ")",
      }}
    >
      <div className="gx-app-login-container">
        <div className="hero_single">
          <img alt="example" src={require("assets/images/logo.png")} />
        </div>
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content">
            <Form
              onSubmit={handleSubmit}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ],
                })(<Input placeholder="Email" />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Please input your Password!" },
                  ],
                })(<Input type="password" placeholder="Password" />)}
              </FormItem>
              <FormItem>
                <Button
                  type="primary"
                  className="gx-mb-0"
                  htmlType="submit"
                  loading={spin}
                >
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </FormItem>
            </Form>
          </div>
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(SignInSuperAdmin);

export default WrappedNormalLoginForm;
