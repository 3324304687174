import React from "react";
import { Card, Collapse, Table, Modal, Menu, Spin } from "antd";
import { InputNumber, Radio, Switch, message, Upload } from "antd";
import { Button, Checkbox, Form, Input, Select } from "antd";
import { Tabs } from "antd";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import "../../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import { CategoryService, MenuService, TaxService } from "../../../services";
import { AppSettings, DomainType } from "../../../constants/AppSettings";
import {
  getArticlePropertyName,
  getArticlePropertyPlaceHolder,
} from "../common";
import ItemAvailability from "./itemAvailability/itemAvailability";

const FormItem = Form.Item;
function onChange(checkedValues) {
  console.log("checked = ", checkedValues);
}
const { TextArea } = Input;
const { confirm } = Modal;

// menuOpt-table-data
const columnsMenuOpt = [
  {
    title: <IntlMessages id="menuOption.option" />,
    dataIndex: "option",
    key: "option",
  },
  {
    title: <IntlMessages id="menuOption.min" />,
    dataIndex: "min",
    key: "min",
  },
  {
    title: <IntlMessages id="menuOption.max" />,
    dataIndex: "max",
    key: "max",
  },
  {
    title: <IntlMessages id="menuOption.priceExcelOfTax" />,
    dataIndex: "priceExcl",
    key: "priceExcl",
  },
];

// PrimarySize-table-data
const columnsPrimarySize = [
  {
    title: <IntlMessages id="menuOptionTemplate.optionItems" />,
    dataIndex: "option",
    key: "option",
  },
  {
    title: <IntlMessages id="menuOptionTemplate.price" />,
    dataIndex: "price",
    key: "price",
  },
];

// Toppings-table-data
const columnsToppings = [
  {
    title: <IntlMessages id="menuOptionTemplate.optionItems" />,
    dataIndex: "option",
    key: "option",
  },
  {
    title: <IntlMessages id="menuOptionTemplate.large" />,
    dataIndex: "large",
    key: "large",
  },
  {
    title: <IntlMessages id="menuOptionTemplate.medium" />,
    dataIndex: "medium",
    key: "medium",
  },
  {
    title: <IntlMessages id="menuOptionTemplate.small" />,
    dataIndex: "small",
    key: "small",
  },
];

const dataToppings = [
  {
    key: "1",
    option: "Olives",
    large: "25",
    medium: "10",
    small: "5",
  },
  {
    key: "2",
    option: "Olives",
    large: "25",
    medium: "10",
    small: "5",
  },
  {
    key: "3",
    option: "Tomatos",
    large: "25",
    medium: "10",
    small: "5",
  },
];

const customRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

//   upload-script end
class menuInfo extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      spin: false,

      allOptionTempleteInGroup: [],

      categoryId: "",
      menuId: "",
      isAvailable: false,

      fileUrl: "",
      selectedFile: null,
      selectedFileList: [],
      categoryList: [],
      optionList: [],
      optionTemplateList: [],
      allOptionTemplete: [],
      selectedTemplate: "",

      updateItemImage: false,

      primaryOptionList: [],
      secondaryOptionList: [],
      optionItemList: [],

      hideOptionTemplate: false,
      hideOption: false,

      defaultOptionIds: [],
      defaultOptionTemplateId: "",

      selectedPrimaryOption: "",
      selectedSecondaryOption: "",
      formLayout: "horizontal",

      primaryCoulumn: [],

      taxes: [],
      price: [],
      itemName: [],
      columnName: [],
      isB2B: false,
      templateLoading: false,
    };
  }

  async componentDidMount() {
    let isB2B = this.props.domainWhitelist?.Type == DomainType.B2B;
    this.setState({ isB2B });
    const optionIds =
      (this.props.location.info && this.props.location.info.optionIds) || [];
    const optionTemplateId =
      (this.props.location.info && this.props.location.info.optionTemplateId) ||
      "";
    if (optionIds.length) {
      this.setState({
        defaultOptionIds: optionIds,
        hideOptionTemplate: true,
      });
    } else if (optionTemplateId) {
      this.setState({
        defaultOptionTemplateId: optionTemplateId,
        hideOption: true,
      });
    }

    this.setState({
      menuId: this.props.location.info && this.props.location.info.menuId,
      categoryId:
        (this.props.location.info && this.props.location.info.categoryId) || "",
      isAvailable:
        this.props.location.info && this.props.location.info.isAvailable,
      showPriceWithoutLogin:
        this.props.location.info &&
        this.props.location.info.showPriceWithoutLogin,
      fileUrl:
        (this.props.location.info && this.props.location.info.image) || "", //[src]
    });

    let delivery = false;
    let takeaway = false;
    const serviceType =
      (this.props.location.info && this.props.location.info.serviceType) || "";

    if (serviceType == 1) {
      delivery = true;
    } else if (serviceType == 2) {
      takeaway = true;
    } else {
      delivery = true;
      takeaway = true;
    }

    this.props.form.setFieldsValue({
      name: (this.props.location.info && this.props.location.info.name) || "",
      description:
        (this.props.location.info && this.props.location.info.description) ||
        "",
      allergens:
        (this.props.location.info && this.props.location.info.allergens) || "",
      warnings:
        (this.props.location.info && this.props.location.info.warnings) || "",
      categoryId:
        (this.props.location.info && this.props.location.info.categoryId) || "",
      price: (this.props.location.info && this.props.location.info.price) || "",
      taxId:
        (this.props.location.info && this.props.location.info.taxId) ||
        "5eafd4abd0c8d425c0aafc4d", //default 12%
      isDelivery: delivery,
      isTakeaway: takeaway,
      alcohol:
        (this.props.location.info && this.props.location.info.alcohol) || "",
      isAvailable:
        (this.props.location.info && this.props.location.info.isAvailable) ||
        false,
      showPriceWithoutLogin:
        (this.props.location.info &&
          this.props.location.info.showPriceWithoutLogin) ||
        false,
      articleNumber:
        (this.props.location.info && this.props.location.info.articleNumber) ||
        "",
      department:
        (this.props.location.info && this.props.location.info.department) || "",
    });

    CategoryService.getCategoriesList("", 0, 0).then((categoryList) => {
      let categoryDropDownList = [];
      for (let x = 0; x < categoryList.length; x++) {
        if (categoryList[x].IsAvailable) {
          categoryDropDownList.push(categoryList[x]);
        }
      }
      this.setState({
        categoryList: categoryDropDownList,
      });
    });

    TaxService.getAllTaxes().then((taxes) => {
      this.setState({
        taxes: taxes.Data,
      });
    });

    MenuService.getAllOption().then((getAllOption) => {
      let optionList = [];
      for (let j = 0; j < getAllOption.length; j++) {
        if (getAllOption[j].IsAvailable) {
          optionList.push({
            name: getAllOption[j].Name,
            id: getAllOption[j].Id,
          });
        }
      }

      this.setState(
        {
          allOption: getAllOption,
          optionList: optionList,
        },
        () => {
          if (optionIds.length) this.selectOption(optionIds, true);
        }
      );
    });

    const getAllOptionTemplete = await MenuService.getAllOptionTemplatesNames();
    let optionTemplateList = [];
    for (let i = 0; i < getAllOptionTemplete.length; i++) {
      if (getAllOptionTemplete[i].IsAvailable) {
        optionTemplateList.push({
          name: getAllOptionTemplete[i].Name,
          id: getAllOptionTemplete[i].Id,
        });
      }
    }
    console.log(optionTemplateList);

    this.setState(
      {
        optionTemplateList: optionTemplateList,
        allOptionTemplete: getAllOptionTemplete,
      },
      () => {
        if (optionTemplateId) this.selectOptionTemplate(optionTemplateId, true);
      }
    );
  }

  readURL = async (info) => {
    let src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(info.file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
    this.setState({
      fileUrl: [src],
    });
  };

  onChange = (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        console.log("uploading");
        nextState.selectedFileList = [info.file];
        break;
      case "done":
        console.log("done");
        nextState.selectedFile = info.file;
        nextState.selectedFileList = [info.file];
        nextState.updateItemImage = true;
        this.readURL(info);
        break;

      default:
        // error or removed
        nextState.selectedFile = null;
        nextState.selectedFileList = [];
    }
    this.setState(() => nextState);
    console.log(this.state);
  };

  removeImage = () => {
    this.setState({
      fileUrl: "",
      selectedFile: null,
      selectedFileList: [],
      updateItemImage: true,
    });
  };

  deleteMenu = async () => {
    if (this.state.isAvailable) {
      message.error("Please disable the menu before deleting it..!");
      return;
    }

    const body = {
      MenuCategoryId: this.state.categoryId, //this.props.location.info && this.props.location.info.categoryId ,
      MenuId: this.state.menuId, //this.props.location.info && this.props.location.info.menuId
    };

    const deleteResponse = await MenuService.deleteMenu(body);
    console.log(deleteResponse);

    if (deleteResponse && deleteResponse.Success) {
      message.success("Deleted");
      // this.props.form.resetFields();
      this.props.history.push("menuList");
    } else {
      message.error(deleteResponse.APIMessage);
    }
  };

  DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const _this = this;
    _this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        _this.setState({ spin: true });

        //for checking duplicate name
        let notDuplicateName = false;
        const responseList = await MenuService.getMenuList("", 0, 0);
        for (let x = 0; x < responseList.length; x++) {
          let menuItem = responseList[x].MenuItems;
          for (let y = 0; y < menuItem.length; y++) {
            if (
              responseList[x].Id == values.categoryId &&
              menuItem[y].Name == values.name &&
              !_this.state.menuId
            ) {
              notDuplicateName = true;
            }
          }
        }

        if (notDuplicateName) {
          confirm({
            title: "Do you really want to continue with duplaicate name?",
            icon: <ExclamationCircleOutlined />,
            content: "Same name already exists!",
            onOk() {
              console.log("OK");
              _this.saveMenuItem(values);
            },
            onCancel() {
              console.log("Cancel");
              _this.setState({ spin: false });
            },
          });
        } else {
          _this.saveMenuItem(values);
        }
      }
    });
  };

  saveMenuItem = async (values) => {
    let image = null;
    if (this.state.selectedFile) {
      image = this.state.selectedFile.originFileObj;
    }

    this.setState({ spin: true });

    let formData = new FormData();
    formData.append("MenuCategoryId", values.categoryId);
    formData.append("MenuId", this.state.menuId || "");
    if (this.state.selectedFile) {
      formData.append("Image", image);
    }
    formData.append("UpdateItemImage", this.state.updateItemImage);
    formData.append("Name", values.name);
    formData.append("Description", values.description);
    formData.append("Allergens", values.allergens);
    formData.append("Warnings", values.warnings);
    formData.append("TaxId", values.taxId);
    formData.append("Price", values.price);
    formData.append("IsAvailable", values.isAvailable);
    formData.append("AlcoholPercentage", values.alcohol || 0);
    formData.append("ShowPriceWithoutLogin", values.showPriceWithoutLogin);
    formData.append("ArticleNumber", values.articleNumber);
    formData.append("Department", values.department);

    const response = await MenuService.addUpdateMenuList(formData);
    console.log("image one: ", response);
    if (response && response.Success) {
      message.success("Menu Item successfully Saved");
      this.setState({
        spin: false,
        menuId: response.Data.Id,
        categoryId: values.categoryId,
        isAvailable: values.isAvailable,
      });
    } else {
      this.setState({ spin: false });
      message.error(response.APIMessage);
    }
  };

  selectOption = async (value, previousData) => {
    if (value.length == 0) {
      this.setState({
        hideOptionTemplate: false,
      });
    }
    const data = this.state.allOption;
    let optionItemList = [];
    let obj = {};
    for (let k = 0; k < value.length; k++) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].Id == value[k]) {
          obj = {
            name: data[i].Name,
            type: data[i].Type,
            maxSelectable: data[i].MaxSelectable,
          };
          const allItem = data[i].OptionItems;
          let items = [];
          for (let j = 0; j < allItem.length; j++) {
            items.push({
              key: j,
              option: allItem[j].Name,
              min: allItem[j].MinCount,
              max: allItem[j].MaxCount,
              priceExcl: allItem[j].Price,
            });
          }

          optionItemList.push({
            name: obj.name,
            maxSelectable: obj.maxSelectable,
            type:
              obj.type == 1 ? (
                <IntlMessages id="menuOption.singleSelect" />
              ) : (
                <IntlMessages id="menuOption.multiSelect" />
              ),
            list: items,
          });
        }
      }
    }
    console.log(optionItemList);

    this.setState({
      optionItemList: optionItemList,
    });

    // |---------------
    if (!previousData) {
      let associateOptions = {
        MenuCategoryId:
          (this.props.location.info && this.props.location.info.categoryId) ||
          this.state.categoryId,
        MenuId:
          (this.props.location.info && this.props.location.info.menuId) ||
          this.state.menuId,
        OptionIds: value,
      };
      console.log(associateOptions);
      const response = await MenuService.addAssociateOptions(associateOptions);
      console.log(response);
      if (response && response.Success && value.length == 0) {
        message.success("Removed");
      } else if (response && response.Success && value.length != 0) {
        message.success("Added");
        this.setState({
          hideOptionTemplate: true,
        });
      } else {
        message.error(response.APIMessage);
      }
    }
    //--------------|
  };

  selectOptionTemplateWork = async (value, previousData) => {
    let templateNotSelected = false;
    this.setState({ templateLoading: true, defaultOptionTemplateId: value });
    if ((value && value.length == 0) || value == undefined || value == "") {
      templateNotSelected = true;
      this.setState({
        hideOptionTemplate: false,
        hideOption: false,
      });
    }
    const data = templateNotSelected
      ? []
      : await MenuService.getOptionTemplateInGroup(value);

    let primaryCoulumn = [
      {
        title: <IntlMessages id="menuOptionTemplate.optionItems" />,
        dataIndex: "option",
        key: "option",
      },
    ];

    let primaryOptionList = [];
    let selectedPrimaryOption = "";

    let secondaryCombination = [];
    let primaryOption = [];

    for (let j = 0; j < data.length; j++) {
      if (data[j].Id == value) {
        selectedPrimaryOption = data[j].PrimaryOption.Name;
        secondaryCombination = data[j].SecondaryCombinationOptions;

        console.log(selectedPrimaryOption);
        console.log("selectedsecondaryCombination: ", secondaryCombination);

        primaryOption = data[j].PrimaryOption.OptionItems;
        console.log(primaryOption);

        for (let k = 0; k < primaryOption.length; k++) {
          primaryOptionList.push({
            key: primaryOption[k].Id,
            option: primaryOption[k].Name,
            price: primaryOption[k].Price,
          });

          primaryCoulumn.push({
            title: primaryOption[k].Name,
            dataIndex: `option${k}`,
            key: `option${k}`,
          });
        }
      }
    }

    this.setState({
      primaryCoulumn: primaryCoulumn,
    });

    let secondaryOptionList = [];

    for (let a = 0; a < secondaryCombination.length; a++) {
      let secondaryOptions = secondaryCombination[a].SecondryOptions;
      let temp = [];

      for (let c = 0; c < secondaryOptions.length; c++) {
        temp.push(secondaryOptions[c].DefaultSecondaryOptionItem.Name);
      }

      let unique = [...new Set(temp)];
      secondaryOptionList.push(unique);
    }

    console.log("secondaryOptionList:", secondaryOptionList);

    let secondaryOptionEach = [];
    let row = {};
    let selectedSecondaryOption = [];

    for (let a = 0; a < secondaryCombination.length; a++) {
      let secondaryRow = [];
      let secondaryOptionsNames = secondaryOptionList[a];

      for (let d = 0; d < secondaryOptionsNames.length; d++) {
        for (let b = 0; b < primaryCoulumn.length; b++) {
          row["option"] = secondaryOptionsNames[d];

          let secondaryOptions = secondaryCombination[a].SecondryOptions;
          selectedSecondaryOption.push(
            secondaryCombination[a].SecondaryOption.Name
          );

          for (let c = 0; c < secondaryOptions.length; c++) {
            if (
              secondaryOptionsNames[d] ==
                secondaryOptions[c].DefaultSecondaryOptionItem.Name &&
              primaryCoulumn[b].title ==
                secondaryOptions[c].DefaultPrimaryOptionItem.Name
            ) {
              if (secondaryOptions[c].UseDefault) {
                row[primaryCoulumn[b].key] =
                  secondaryOptions[c].DefaultSecondaryOptionItem.Price;
              } else {
                row[primaryCoulumn[b].key] = secondaryOptions[c].Price;
              }
            }
          }
        }

        secondaryRow.push(row);
        row = {};
      }
      secondaryOptionEach.push(secondaryRow);
      console.log("secondaryOptionEach: ", secondaryOptionEach);
    }

    selectedSecondaryOption = [...new Set(selectedSecondaryOption)];

    this.setState({
      selectedPrimaryOption: selectedPrimaryOption,
      selectedSecondaryOption: selectedSecondaryOption,
      primaryOptionList: primaryOptionList,
      secondaryOptionList: secondaryOptionEach,
    });

    // |---------------
    if (!previousData) {
      let associateOptionTemplate = {
        MenuCategoryId:
          (this.props.location.info && this.props.location.info.categoryId) ||
          this.state.categoryId,
        MenuId:
          (this.props.location.info && this.props.location.info.menuId) ||
          this.state.menuId,
        OptionTemplateId: value,
      };
      console.log(associateOptionTemplate);
      const response = await MenuService.addAssociateOptionTemplate(
        associateOptionTemplate
      );
      console.log(response);

      if (
        (response && response.Success && value && value.length == 0) ||
        value == undefined
      ) {
        message.success("Removed");
      } else if (
        (response && response.Success && value && value.length != 0) ||
        value != undefined
      ) {
        message.success("Added");
        this.setState({
          hideOption: true,
        });
      } else {
        message.error(response.APIMessage);
      }
    }

    this.setState({ templateLoading: false });
    //--------------|
  };

  selectOptionTemplate = async (value, previousData) => {
    const _this = this;
    if (value == undefined && this.state.defaultOptionTemplateId) {
      confirm({
        title: "Do you really want to remove?",
        icon: <ExclamationCircleOutlined />,
        content: "Associate Option Template!",
        onOk() {
          console.log("OK");
          _this.selectOptionTemplateWork(value, previousData);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } else {
      _this.selectOptionTemplateWork(value, previousData);
    }
  };

  // Modal-scripts end
  render() {
    // form-scripts start
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { xs: 24, sm: 12 },
            wrapperCol: { xs: 24, sm: 24 },
          }
        : null;

    // tab-script-start
    const TabPane = Tabs.TabPane;
    const Option = Select.Option;
    // tab-script-end

    // MULTIPLE-scripts start
    const children = [];
    for (let i = 1; i < 10; i++) {
      children.push(<Option key={i.toString(36) + i}>{"test"}</Option>);
    }
    function handleChange(value) {
      console.log(`selected ${value}`);
    }
    // MULTIPLE-scripts End

    // Select-box-search-Script
    function handleChange(value) {
      console.log(`selected ${value}`);
    }
    // Select-box-search-Script-end
    const { getFieldDecorator } = this.props.form;

    const { intl } = this.props;
    const itemName = intl.formatMessage({ id: "menuInfo.itemName" });
    const itemDescription = intl.formatMessage({
      id: "menuInfo.itemDescription",
    });
    const price = intl.formatMessage({ id: "menuInfo.price" });
    const tax = intl.formatMessage({ id: "menuInfo.tax" });
    const alcohol = intl.formatMessage({ id: "menuInfo.alcohol" });
    const pleaseSelect = intl.formatMessage({
      id: "menuOptionTemplate.pleaseSelect",
    });

    return (
      <div className="resturantDetail-main">
        <Card className="gx-card breadcrumb-box" title="">
          <div className="menu-info">
            <section>
              <div className="ant-row">
                <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
                  <Tabs tabPosition="top">
                    <TabPane
                      tab={<IntlMessages id="menuInfo.menuInfo" />}
                      key="1"
                    >
                      <Form
                        onSubmit={(e) => this.handleSubmit(e)}
                        layout={formLayout}
                      >
                        <div className="rest-info-box">
                          <div className="title-sub">
                            <h2>
                              <IntlMessages id="menuInfo.itemDetail" />
                            </h2>
                            <hr className="hr-line"></hr>
                          </div>

                          <div class="ant-row">
                            <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 menu-info-box-lft">
                              <div className="detail-res-lft">
                                <div className="ant-row">
                                  <div class="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                                    <label>
                                      <IntlMessages id="menuInfo.itemName" /> :
                                    </label>
                                  </div>
                                  <div className="gx-mb-3 ant-col-md-16">
                                    <FormItem {...formItemLayout}>
                                      {getFieldDecorator("name", {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Required!",
                                          },
                                        ],
                                      })(<Input placeholder={itemName} />)}
                                    </FormItem>
                                  </div>
                                </div>
                                <div className="ant-row">
                                  <div class="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                                    <label>
                                      <IntlMessages id="menuInfo.itemDescription" />{" "}
                                      :
                                    </label>
                                  </div>
                                  <div className="gx-mb-3 ant-col-md-16">
                                    <FormItem {...formItemLayout}>
                                      {getFieldDecorator(
                                        "description",
                                        {}
                                      )(
                                        <TextArea
                                          rows={5}
                                          placeholder={itemDescription}
                                        />
                                      )}
                                    </FormItem>
                                  </div>
                                </div>
                                <div className="ant-row">
                                  <div class="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                                    <label>
                                      <IntlMessages
                                        id="menuInfo.itemAllergens"
                                        defaultMessage="Item Allergens"
                                      />{" "}
                                      :
                                    </label>
                                  </div>
                                  <div className="gx-mb-3 ant-col-md-16">
                                    <FormItem {...formItemLayout}>
                                      {getFieldDecorator(
                                        "allergens",
                                        {}
                                      )(
                                        <TextArea
                                          rows={3}
                                          placeholder={"Item Allergens"}
                                        />
                                      )}
                                    </FormItem>
                                  </div>
                                </div>
                                <div className="ant-row">
                                  <div class="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                                    <label>
                                      <IntlMessages
                                        id="menuInfo.itemWarnings"
                                        defaultMessage="Item Warnings"
                                      />{" "}
                                      :
                                    </label>
                                  </div>
                                  <div className="gx-mb-3 ant-col-md-16">
                                    <FormItem {...formItemLayout}>
                                      {getFieldDecorator(
                                        "warnings",
                                        {}
                                      )(
                                        <TextArea
                                          rows={3}
                                          placeholder={"Item Warnings"}
                                        />
                                      )}
                                    </FormItem>
                                  </div>
                                </div>

                                <div className="ant-row">
                                  <div class="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                                    <label>
                                      <IntlMessages id="menuInfo.category" /> :
                                    </label>
                                  </div>
                                  <div className="gx-mb-3 ant-col-md-16 ant-col-xs-16">
                                    <FormItem {...formItemLayout}>
                                      {getFieldDecorator("categoryId", {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Required!",
                                          },
                                        ],
                                      })(
                                        <Select
                                          placeholder="Category"
                                          onChange={handleChange}
                                        >
                                          {this.state.categoryList.map(
                                            (item, index) => {
                                              return (
                                                <Option
                                                  key={index}
                                                  value={item.Id}
                                                >
                                                  {item.Name}
                                                </Option>
                                              );
                                            }
                                          )}
                                        </Select>
                                      )}
                                    </FormItem>
                                  </div>
                                </div>
                                <div
                                  className="ant-row"
                                  style={
                                    this.state.isB2B == false &&
                                    this.props.whitelisting.Country !==
                                      AppSettings.Country.UAE
                                      ? { display: "none" }
                                      : {}
                                  }
                                >
                                  <div class="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                                    <label>
                                      {getArticlePropertyName(this.props)}:
                                    </label>
                                  </div>
                                  <div className="gx-mb-3 ant-col-md-16">
                                    <FormItem {...formItemLayout}>
                                      {getFieldDecorator(
                                        "articleNumber",
                                        {}
                                      )(
                                        <Input
                                          placeholder={getArticlePropertyPlaceHolder(
                                            this.props
                                          )}
                                        />
                                      )}
                                    </FormItem>
                                  </div>
                                </div>
                                <div
                                  className="ant-row"
                                  style={
                                    this.state.isB2B == false
                                      ? { display: "none" }
                                      : {}
                                  }
                                >
                                  <div class="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                                    <label>
                                      <IntlMessages id="menuInfo.department" />{" "}
                                      :
                                    </label>
                                  </div>
                                  <div className="gx-mb-3 ant-col-md-16">
                                    <FormItem {...formItemLayout}>
                                      {getFieldDecorator(
                                        "department",
                                        {}
                                      )(<Input placeholder="Department" />)}
                                    </FormItem>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 mnenu-info-box-rite">
                              <div className="ant-row">
                                <div class="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                                  <label>
                                    <IntlMessages id="menuInfo.uploadPicture" />{" "}
                                    :
                                  </label>
                                </div>
                                <div className="gx-mb-3 uplaod-img-main">
                                  <FormItem {...formItemLayout}>
                                    <Upload
                                      fileList={this.state.selectedFileList}
                                      customRequest={customRequest}
                                      onChange={this.onChange}
                                      showUploadList={false}
                                      multiple={false}
                                      beforeUpload={(file) => {
                                        const isValidImage =
                                          file.type === "image/jpeg" ||
                                          file.type === "image/png" ||
                                          file.type === "image/jpg";
                                        if (!isValidImage) {
                                          message.error(
                                            "You can only upload JPG or PNG file!"
                                          );
                                          return false;
                                        } else {
                                          return true;
                                        }
                                      }}
                                    >
                                      <Button>
                                        <IntlMessages id="menuInfo.choseFile" />
                                      </Button>
                                    </Upload>
                                  </FormItem>
                                  <div className="detail-res-rite upload-img-box">
                                    <img src={this.state.fileUrl} />
                                  </div>
                                  {this.state.fileUrl != "" && (
                                    <div style={{ marginTop: "5px" }}>
                                      <Button
                                        type="danger"
                                        onClick={this.removeImage}
                                      >
                                        Remove Image
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24">
                              <div class="ant-row charge-form op-cnter text-lable-alling">
                                <div class="ant-col ant-col-xs-24 ant-col-md-4 gx-mb-3 charge-lable">
                                  <label>
                                    <IntlMessages id="menuInfo.price" /> :
                                  </label>
                                </div>
                                <div class="ant-col ant-col-xs-24 ant-col-md-3 gx-mb-3">
                                  <FormItem {...formItemLayout}>
                                    {getFieldDecorator("price", {
                                      rules: [
                                        {
                                          required: true,
                                          message: "Required!",
                                        },
                                      ],
                                    })(<Input placeholder={price} />)}
                                  </FormItem>
                                </div>
                                <div class="ant-col ant-col-xs-24 ant-col-md-2 gx-mb-3 charge-lable wid-auto">
                                  <label>
                                    <IntlMessages id="menuInfo.tax" /> :
                                  </label>
                                </div>
                                <div class="ant-col ant-col-xs-24 ant-col-md-3 gx-mb-3">
                                  <FormItem {...formItemLayout}>
                                    {getFieldDecorator("taxId", {
                                      rules: [
                                        {
                                          required: true,
                                          message: "Required!",
                                        },
                                      ],
                                    })(
                                      <Select
                                        placeholder="Tax"
                                        onChange={handleChange}
                                      >
                                        {this.state.taxes &&
                                          this.state.taxes.map((tax) => (
                                            <Option value={tax.Id}>
                                              {tax.Percentage}%
                                            </Option>
                                          ))}
                                      </Select>
                                    )}
                                  </FormItem>
                                </div>
                                <div class="ant-col ant-col-xs-24 ant-col-md-2 gx-mb-3 charge-lable wid-auto">
                                  <label>
                                    <IntlMessages id="menuInfo.alcohol" /> :
                                  </label>
                                </div>
                                <div class="ant-col ant-col-xs-24 ant-col-md-3 gx-mb-3">
                                  <FormItem {...formItemLayout}>
                                    {getFieldDecorator("alcohol", {
                                      rules: [
                                        {
                                          type: "number",
                                          min: 0,
                                          message: "Should be positive number!",
                                        },
                                      ],
                                    })(<InputNumber placeholder={alcohol} />)}
                                  </FormItem>
                                </div>

                                <div class="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 add-btn-rite"></div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="ant-row">
                          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24">
                            <div className="title-sub">
                              <h2>
                                <IntlMessages id="menuInfo.action" />
                              </h2>
                              <hr className="hr-line"></hr>
                            </div>
                          </div>
                        </div>
                        <div class="ant-row">
                          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-4 ant-col-lg-4">
                            <div class="gx-mb-1 center">
                              <FormItem {...formItemLayout}>
                                {getFieldDecorator("isAvailable", {
                                  valuePropName: "checked",
                                  initialValue: true,
                                })(<Switch />)}
                              </FormItem>
                            </div>
                            <div class="center">
                              <label>
                                <IntlMessages id="menuInfo.disabled" />/
                                <IntlMessages id="menuInfo.enabled" />
                              </label>
                            </div>
                          </div>
                          <div
                            class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-4 ant-col-lg-4"
                            style={
                              this.state.isB2B == false
                                ? { visibility: "hidden" }
                                : {}
                            }
                          >
                            <div class="gx-mb-1 center">
                              <FormItem {...formItemLayout}>
                                {getFieldDecorator("showPriceWithoutLogin", {
                                  valuePropName: "checked",
                                  initialValue: true,
                                })(<Switch />)}
                              </FormItem>
                            </div>
                            <div class="center">
                              <label>
                                <IntlMessages id="menuInfo.showPriceWithoutLogin" />
                              </label>
                            </div>
                          </div>
                          <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-16 ant-col-lg-16 add-btn-rite">
                            <Button
                              type="button"
                              onClick={() => this.deleteMenu()}
                              class="ant-btn color-white ant-btn-primary"
                            >
                              <i class="icon icon-trash"></i>{" "}
                              <span>
                                <IntlMessages id="menuInfo.delete" />
                              </span>
                            </Button>
                            {this.state.spin ? (
                              <Spin />
                            ) : (
                              <Button
                                htmlType="submit"
                                type="primary"
                                class="ant-btn color-white ant-btn-primary"
                              >
                                <i class="icon icon-add"></i>
                                <span>
                                  {this.state.categoryId ? (
                                    "Update"
                                  ) : (
                                    <IntlMessages id="menuInfo.add" />
                                  )}
                                </span>
                              </Button>
                            )}
                          </div>
                        </div>
                      </Form>
                    </TabPane>
                    <TabPane
                      tab={
                        <IntlMessages
                          id="menuInfo.ItemAvailability"
                          defaultMessage="Item Availability"
                        />
                      }
                      disabled={!this.state.menuId && !this.state.categoryId}
                      key="2"
                    >
                      <div className="title-sub">
                        <h2>
                          <IntlMessages
                            id="menuInfo.ItemAvailability"
                            defaultMessage="Item Availability"
                          />
                        </h2>
                        <hr className="hr-line"></hr>
                      </div>
                      <div>
                        <ItemAvailability
                          MenuId={this.state.menuId}
                          CategoryId={this.state.categoryId}
                        />
                      </div>
                    </TabPane>
                    <TabPane
                      disabled={this.state.hideOption}
                      tab={<IntlMessages id="menuOption.options" />}
                      key="3"
                    >
                      <div className="title-sub">
                        <h2>
                          <IntlMessages id="menuOption.availableOptions" />
                        </h2>
                        <hr className="hr-line"></hr>
                      </div>
                      <div class="ant-row Platform-Charges menu-option-tab">
                        <div class="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 platform-charges-lft">
                          <div className="detail-res-lft">
                            <div className="multi-dropdown-box">
                              <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder={pleaseSelect}
                                defaultValue={this.state.defaultOptionIds}
                                onChange={(e) => this.selectOption(e)}
                              >
                                {this.state.optionList.map((item, index) => {
                                  return (
                                    <Option key={index} value={item.id}>
                                      {item.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="ant-row gx-ml-1 gx-mr-1">
                        <div className="title-sub">
                          <h2>
                            <IntlMessages id="menuOption.optionDetail" />
                          </h2>
                          <hr className="hr-line"></hr>
                        </div>
                      </div>

                      {this.state.optionItemList.map((item, key) => {
                        return (
                          <div key={key}>
                            <div class="ant-row gx-ml-1 gx-mr-1">
                              <div className="title-sub">
                                <h2
                                  style={{
                                    paddingTop: "30px",
                                    color: "#8bc53f",
                                  }}
                                >
                                  {item.name}
                                </h2>
                              </div>
                              <div class="ant-col ant-col-xs-24  ant-col-md-12 ant-col-lg-12 ">
                                <p>
                                  <IntlMessages id="menuOption.type" />:{" "}
                                  {item.type}
                                </p>
                              </div>
                              <div class="ant-col ant-col-xs-24  ant-col-md-12 ant-col-lg-12 right">
                                <p>
                                  <IntlMessages id="menuOption.maxSelecttableRows" />
                                  : {item.maxSelectable}
                                </p>
                              </div>
                            </div>
                            <Table
                              pagination={false}
                              className="gx-table-responsive MenuOpt-tabile"
                              columns={columnsMenuOpt}
                              dataSource={item.list}
                            />
                          </div>
                        );
                      })}
                    </TabPane>

                    <TabPane
                      disabled={this.state.hideOptionTemplate}
                      tab={
                        <IntlMessages id="menuOptionTemplate.optionTemplate" />
                      }
                      key="4"
                    >
                      <div className="title-sub">
                        <h2>
                          <IntlMessages id="menuOptionTemplate.availableTemplates" />
                        </h2>
                        <hr className="hr-line"></hr>
                      </div>
                      <div
                        class="ant-row Platform-Charges menu-option-tab"
                        style={{ alignItems: "center" }}
                      >
                        <div class="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 platform-charges-lft">
                          <div className="detail-res-lft">
                            <div className="multi-dropdown-box">
                              <Select
                                loading={this.state.templateLoading}
                                disabled={this.state.templateLoading}
                                mode="single"
                                // allowClear
                                style={{ width: "100%" }}
                                placeholder={pleaseSelect}
                                value={this.state.defaultOptionTemplateId}
                                onChange={(e) => this.selectOptionTemplate(e)}
                              >
                                {this.state.optionTemplateList.map(
                                  (item, index) => {
                                    return (
                                      <Option key={index} value={item.id}>
                                        {item.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </div>
                          </div>
                        </div>
                        <div
                          class="ant-col ant-col-xs-24 ant-col-sm-1 ant-col-md-1 opt-close-template"
                          style={{ marginTop: "-11px", color: "#8bc53f" }}
                        >
                          <Spin
                            spinning={this.state.templateLoading}
                            size="small"
                          >
                            <CloseCircleOutlined
                              onClick={() =>
                                this.selectOptionTemplate(undefined)
                              }
                            />
                          </Spin>
                        </div>
                      </div>
                      <div class="ant-row gx-ml-1 gx-mr-1">
                        <div className="title-sub">
                          <h2>
                            <IntlMessages id="menuOptionTemplate.tempalteDetails" />
                          </h2>
                          <hr className="hr-line"></hr>
                        </div>
                        <section className="gx-w-100">
                          <div class="ant-col ant-col-xs-24  ant-col-md-24 ant-col-lg-24">
                            <div className="title-sub">
                              <h2>
                                <IntlMessages id="menuOptionTemplate.primaryOption" />
                              </h2>
                              <hr className="hr-line"></hr>
                            </div>
                          </div>
                          <Table
                            title={() => (
                              <h2 style={{ color: "#8bc53f" }}>
                                {this.state.selectedPrimaryOption}
                              </h2>
                            )}
                            bordered
                            className="gx-table-responsive MenuOpt-tabile"
                            columns={columnsPrimarySize}
                            dataSource={this.state.primaryOptionList}
                          />
                        </section>
                        <section className="gx-w-100">
                          <div class="ant-col ant-col-xs-24  ant-col-md-24 ant-col-lg-24">
                            <div className="title-sub">
                              <h2>
                                <IntlMessages id="menuOptionTemplate.secondaryOption" />
                              </h2>
                              <hr className="hr-line"></hr>
                            </div>
                          </div>
                          <ul></ul>
                          {this.state.secondaryOptionList.map((item, index) => {
                            return (
                              <Table
                                title={() => (
                                  <h2 style={{ color: "#8bc53f" }}>
                                    {this.state.selectedSecondaryOption[index]}
                                  </h2>
                                )}
                                bordered
                                key={index}
                                className="gx-table-responsive MenuOpt-tabile"
                                columns={this.state.primaryCoulumn}
                                dataSource={item}
                              />
                            );
                          })}
                        </section>
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </section>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  domainWhitelist: state.settings.domainWhitelist,
  whitelisting: state.settings.whitelisting,
});
const WrappedMenuInfo = injectIntl(
  Form.create()(connect(mapStateToProps)(menuInfo))
);
export default WrappedMenuInfo;
