import React, { Component } from "react";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import {
  Form,
  Select,
  Checkbox,
  Radio,
  Button,
  Spin,
  message,
  Divider,
  InputNumber,
  Icon,
  Popconfirm,
  Tooltip,
} from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { MenuService } from "../../../../services/menuService";
import createRecurringTextHelper, {
  DaysOptionList,
  SelectedHoursList,
  MenuAvailability,
  SelectedHours,
  RecurrenceBy,
  DatePickerProps,
} from "./common";
import ScheduleTimings from "./scheduleTimings";

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const SeviceInfoCheckBoxOptions = [
  { label: <IntlMessages id="config.delivery" />, value: 1 },
  { label: <IntlMessages id="config.takeaway" />, value: 2 },
  { label: <IntlMessages id="config.dineIn" />, value: 8 },
];
const ItemAvailableForOptions = [
  {
    label: (
      <IntlMessages
        id="menuInfo.alwaysAvailable"
        defaultMessage="Always Available"
      />
    ),
    value: 1,
  },
  {
    label: (
      <IntlMessages
        id="menuInfo.forSelectedHours"
        defaultMessage="For Selected Hours"
      />
    ),
    value: 2,
  },
];
const RecurrenceDropDown = [
  {
    label: <IntlMessages id="menuInfo.weekly" defaultMessage="Weekly" />,
    value: 2,
  },
];
const SelectAllDays = 7;

class ItemAvailability extends Component {
  constructor() {
    super();
    this.state = {
      menuId: "",
      categoryId: "",
      timeFormat: "HH:mm",
      formLayout: "horizontal",
      formItemLayout: {
        labelCol: { xs: 24, sm: 12 },
        wrapperCol: { xs: 24, sm: 24 },
      },
      itemVisibleFor: [1, 2, 8], // Initial values
      availableFor: MenuAvailability.AlwaysAvailable,
      schedule: [
        {
          days: [1],
          isFullDay: true,
          timingKeys: [0],
          timings: [
            {
              StartTime: moment().format(this.timeFormat),
              EndTime: moment().format(this.timeFormat),
            },
          ],
        },
      ],
      recurrenceBy: RecurrenceBy.None,
      endDateChecked: false,
      startDate: "",
      endDate: "",
      recurringText: "",
      spinLoading: true,
      loading: false,
      errorDuration: 5,
      timingsOverlappingError: [new Error("Timings overlapping")],
      timingsOverlappingForSameDayError: [
        new Error("Timings overlapping for same days"),
      ],
      sameDayDifferentHourTypeError: [
        new Error("Same days schedules but one of them is 24 hours selected."),
      ],
      validationFieldsList: [],
    };
  }

  componentDidMount() {
    this.setState({
      menuId: this.props.MenuId,
      categoryId: this.props.CategoryId,
    });

    MenuService.getMenuItemAvailability(
      this.props.MenuId,
      this.props.CategoryId
    ).then((response) => {
      if (response.Data) {
        if (response.Data.Availability != MenuAvailability.None) {
          // startDate and endDate for Recurring text
          let dateFormat = this.props.whitelisting.AdminDateFormat;
          let startDate = response.Data.Recurrence
            ? moment(response.Data.StartDate).format(dateFormat)
            : null;
          let endDate = response.Data.EndDate
            ? moment(response.Data.EndDate).format(dateFormat)
            : null;

          this.setState({
            schedule: this.getScheduleModelFromResponse(
              response.Data.ItemSchedules
            ),
            availableFor: response.Data.Availability,
            endDateChecked: response.Data.EndDate ? true : false,
            recurrenceBy:
              response.Data.Recurrence?.RecurrenceBy ?? RecurrenceBy.None,
            startDate: startDate,
            endDate: endDate,
          });

          this.props.form.setFieldsValue({
            ItemVisibleFor: response.Data.ServiceInfoTypeList,
            StartDate: response.Data.Recurrence ? startDate : null,
            EndDate: response.Data.EndDate ? endDate : null,
            RecurrenceBy: response.Data.Recurrence?.RecurrenceBy,
            RecurrenceRepetition: response.Data.Recurrence?.Repetition,
          });

          //Recurring text
          this.createRecurringText(startDate, endDate);
        }
      }
      this.setState({ spinLoading: false });
    });
  }
  //#region HandleChangeMethods
  handleScheduleNormalizeAll = (values, prevValue = []) => {
    let daysList = DaysOptionList.map(({ value }) => value);
    if (values?.includes(SelectAllDays)) {
      return daysList;
    }
    return values;
  };

  handleScheduleChange = (values, index) => {
    let { schedule } = this.state;
    if (schedule) {
      let child = schedule[index];
      child.days = values;
      schedule[index] = child;
      this.setState({ schedule });
    }
  };

  handleItemAvailableChange = (ev) => {
    this.setState({ availableFor: ev.target.value });
  };

  handleSelectedHourChange = (ev, index) => {
    let { schedule } = this.state;
    if (schedule) {
      let child = schedule[index];
      child.isFullDay = ev.target.value == 2 ? true : false;
      schedule[index] = child;
      this.setState({ schedule });
    }

    this.removeValidationErrors(index);
  };

  handleReccurenceChange = (ev) => {
    const startDate = ev.target.checked ? this.state.startDate : null;
    this.setState({
      recurrenceBy: ev.target.checked ? RecurrenceBy.Weekly : RecurrenceBy.None,
      startDate: startDate,
    });

    this.createRecurringText(startDate, this.state.endDate);
  };

  handleStartDate = (value) => {
    let valueInString = value.format();
    const { endDate } = this.state;
    this.setState({ startDate: valueInString });
    this.createRecurringText(valueInString, endDate);
  };

  handleEndDate = (value) => {
    let valueInString = value.format();
    const { startDate } = this.state;
    this.setState({ endDate: valueInString });
    this.createRecurringText(startDate, valueInString);
  };

  handleEndDateChange = (ev) => {
    let endDate = ev.target.checked ? this.state.endDate : null;
    this.setState({
      endDateChecked: ev.target.checked,
      endDate: endDate,
    });

    this.createRecurringText(this.state.startDate, endDate);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
        var data = this.getJsonDataToSubmit(values);

        if (!this.isValidItemScheduleData(data.ItemSchedules)) {
          message.error("Failed! Please validate all fields");
        } else {
          var response = await MenuService.addUpdateItemAvailability(data);
          if (response && response.Success) {
            message.success(response.APIMessage);
          } else {
            message.error(response.APIMessage, this.state.errorDuration);
          }
        }

        this.setState({ loading: false });
      }
    });
  };

  //Callback schedule is called from child component (scheduleTiming) when there is a change in time values
  handleScheduleTimingsCallBack = (callBackSchedule, index) => {
    let { schedule } = this.state;
    schedule[index] = callBackSchedule;
    this.removeValidationErrors(index);
    this.setState({ schedule });
  };
  //#endregion
  //#region HelpingMethods
  removeValidationErrors(index) {
    let { validationFieldsList } = this.state;

    if (validationFieldsList.includes(index)) {
      let scheduleFields = [];
      validationFieldsList.map((index) => {
        scheduleFields.push(`Schedule_${index}`);
      });
      this.props.form.validateFields(scheduleFields, (values, err) => {});
      validationFieldsList = [];
    }
    this.setState({ validationFieldsList });
  }

  getScheduleModelFromResponse(itemSchedule) {
    if (itemSchedule?.length > 0) {
      var scheduleList = [];
      itemSchedule.map((v, i) => {
        let schedule = {
          days: v.Days,
          isFullDay: v.IsFullDay,
          timings: v.ItemScheduleTimings,
          timingKeys: [],
        };

        if (!v.IsFullDay) {
          v.ItemScheduleTimings.map((cv, j) => {
            schedule.timingKeys.push(j);
          });
        } else {
          schedule.timingKeys.push(0);

          schedule.timings = [
            {
              StartTime: moment().format(this.timeFormat),
              EndTime: moment().format(this.timeFormat),
            },
          ];
        }

        scheduleList.push(schedule);
      });
      return scheduleList;
    }
    return this.state.schedule;
  }

  getJsonDataToSubmit(values) {
    const { timeFormat } = this.state;
    const dateFormat = this.props.whitelisting.AdminDateFormat;
    let serviceInfoTypes = values.ItemVisibleFor;
    let serviceInfoType = 0;
    //Creating enum values
    serviceInfoTypes.forEach((v) => {
      serviceInfoType += v;
    });

    let startDate = values.StartDate
      ? moment(this.state.startDate, dateFormat).set({
          hour: 0,
          minute: 0,
          second: 0,
        })
      : null;

    let endDate = values.EndDate
      ? moment(this.state.endDate, dateFormat).set({
          hour: 23,
          minute: 59,
          second: 0,
        })
      : null;

    var data = {
      MenuId: this.state.menuId,
      CategoryId: this.state.categoryId,
      ServiceInfoType: serviceInfoType,
      Availability: values.ItemAvailable,
      ItemSchedules: [],
      StartDate: startDate,
      EndDate: endDate,
    };
    if (data.Availability == MenuAvailability.ForSelectedHours) {
      if (values.RecurrenceBy && values.RecurrenceBy == RecurrenceBy.Weekly)
        data.Recurrence = {
          RecurrenceBy: values.RecurrenceBy,
          Repetition: values.RecurrenceRepetition,
        };

      let scheduleList = this.state.schedule;

      for (var i = 0; i < scheduleList.length; i++) {
        let scheduleDays = this.props.form.getFieldValue(`Schedule_${i}`);
        scheduleDays = scheduleDays.filter((o) => o != SelectAllDays);
        let scheduleSelectedHours = this.props.form.getFieldValue(
          `Schedule_${i}_SelectedHours`
        );
        let timingKeys = scheduleList[i].timingKeys;
        let timings = [];

        if (scheduleSelectedHours == SelectedHours.ManualHours) {
          timingKeys.map((j, index) => {
            let startTime = moment(
              this.props.form.getFieldValue(`${i}_StartTime_${j}`)
            ).format(timeFormat);
            let endTime = moment(
              this.props.form.getFieldValue(`${i}_EndTime_${j}`)
            ).format(timeFormat);

            timings.push({
              StartTime: startTime,
              EndTime: endTime,
            });
          });
        }
        data.ItemSchedules.push({
          Days: scheduleDays,
          IsFullDay:
            scheduleSelectedHours == SelectedHours.FullDay ? true : false,
          ItemScheduleTimings: timings,
        });
      }
    }
    return data;
  }

  isValidItemScheduleData = (itemScheduleList) => {
    const { schedule, timeFormat, validationFieldsList } = this.state;
    for (let i = 0; i < itemScheduleList.length; i++) {
      if (!itemScheduleList[i].IsFullDay) {
        const keysLength = schedule[i].timingKeys.length;
        if (keysLength > 1) {
          for (let j = 0; j < schedule[i].timingKeys.length - 1; j++) {
            const key = schedule[i].timingKeys[j];

            if (
              !this.checkTimingValidationError([
                `${i}_StartTime_${key}`,
                `${i}_EndTime_${key}`,
                `${i}_StartTime_${key + 1}`,
                `${i}_EndTime_${key + 1}`,
              ])
            )
              return false;
          }
        } else {
          if (
            !this.checkTimingValidationError([
              `${i}_StartTime_${0}`,
              `${i}_EndTime_${0}`,
            ])
          )
            return false;
        }
      }
    }
    let isValid = true;
    DaysOptionList.map(({ value }) => {
      let defaultScheduleIndex = [];
      const scheduleList = itemScheduleList.filter((a, index) => {
        if (a.Days.includes(value)) {
          defaultScheduleIndex.push(index);
          return true;
        }
      });

      if (scheduleList.length > 1) {
        for (let i = 0; i < scheduleList.length - 1; i++) {
          for (let j = i + 1; j < scheduleList.length; j++) {
            const currentSchedule = scheduleList[i];
            const nextSchedule = scheduleList[j];

            if (currentSchedule.IsFullDay != nextSchedule.IsFullDay) {
              isValid = false;
              validationFieldsList.push(i, j);
              this.setState({ validationFieldsList });
              this.setValidationError(
                [
                  `Schedule_${defaultScheduleIndex[i]}`,
                  `Schedule_${defaultScheduleIndex[j]}`,
                ],
                this.state.sameDayDifferentHourTypeError
              );
            }

            for (
              let k = 0;
              k < currentSchedule.ItemScheduleTimings.length;
              k++
            ) {
              const xStartTime =
                currentSchedule.ItemScheduleTimings[k].StartTime;
              const xEndTime = currentSchedule.ItemScheduleTimings[k].EndTime;
              const timeIsOverlapping = nextSchedule.ItemScheduleTimings.some(
                (a) => {
                  // Logic reference: https://stackoverflow.com/a/13513973
                  if (
                    moment(xStartTime, timeFormat).format(timeFormat) <
                      moment(a.EndTime, timeFormat).format(timeFormat) &&
                    moment(a.StartTime, timeFormat).format(timeFormat) <
                      moment(xEndTime, timeFormat).format(timeFormat)
                  ) {
                    return true;
                  }
                }
              );

              if (timeIsOverlapping) {
                this.setValidationError(
                  [
                    `Schedule_${defaultScheduleIndex[i]}`,
                    `Schedule_${defaultScheduleIndex[j]}`,
                  ],
                  this.state.timingsOverlappingForSameDayError
                );
                validationFieldsList.push(i, j);
                this.setState({ validationFieldsList });
                isValid = false;
                return isValid;
              }
            }
          }
        }
      }
    });

    return isValid;
  };

  checkTimingValidationError(timings) {
    const { timingsOverlappingError, timeFormat } = this.state;
    if (timings && timings.length > 0) {
      for (let i = 0; i < timings.length - 1; i++) {
        const startTime = this.props.form.getFieldValue(timings[i]);
        const endTime = this.props.form.getFieldValue(timings[i + 1]);

        if (
          moment(startTime).format(timeFormat) >
          moment(endTime).format(timeFormat)
        ) {
          this.props.form.setFields({
            [timings[i]]: {
              value: startTime,
              errors: timingsOverlappingError,
            },
            [timings[i + 1]]: {
              value: endTime,
              errors: timingsOverlappingError,
            },
          });
          return false;
        }
      }
    }
    return true;
  }

  setValidationError(fieldsList, error) {
    fieldsList.map((field) => {
      this.props.form.setFields({
        [field]: { errors: error },
      });
    });
  }

  createRecurringText = (startDate, endDate) => {
    const { intl } = this.props;
    let text = createRecurringTextHelper(startDate, endDate, intl);
    this.setState({ recurringText: text });
  };

  addSchedule = () => {
    let { schedule } = this.state;
    schedule.push({
      days: [],
      isFullDay: true,
      timingKeys: [0],
      timings: [
        {
          StartTime: moment().format(this.state.timeFormat),
          EndTime: moment().format(this.state.timeFormat),
        },
      ],
    });
    this.setState({ schedule });
  };

  renderSchedule(schedule, index) {
    const { getFieldDecorator } = this.props.form;
    const { formItemLayout } = this.state;
    const { intl } = this.props;
    const deleteScheduleMsg = intl.formatMessage({
      id: "menuInfo.deleteSchedule",
      defaultMessage: "Delete this schedule",
    });
    return (
      <div key={index}>
        <FormItem {...formItemLayout}>
          {getFieldDecorator(`Schedule_${index}`, {
            initialValue: schedule.days,
            rules: [{ required: true, message: "Required" }],
            normalize: this.handleScheduleNormalizeAll,
            onChange: (values) => this.handleScheduleChange(values, index),
          })(
            <Select mode="multiple" style={{ width: "90%" }}>
              <Option value={SelectAllDays} key={SelectAllDays} label={"All"}>
                All
              </Option>
              {DaysOptionList.map((v, key) => {
                return (
                  <Option value={v.value} key={v.value} label={v.name}>
                    {v.name}
                  </Option>
                );
              })}
            </Select>
          )}
          {this.state.schedule.length > 1 && (
            <>
              <span style={{ padding: "0px 5px" }}></span>
              <Popconfirm
                title="Are you sure to delete this schedule?"
                onConfirm={() => this.removeSchedule(index)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip placement="bottom" title={deleteScheduleMsg}>
                  <Icon
                    className="dynamic-delete-button"
                    type="delete-o"
                    style={{ fontSize: "16px", color: "#e02026" }}
                  />
                </Tooltip>
              </Popconfirm>
            </>
          )}
        </FormItem>
        <FormItem {...formItemLayout}>
          {getFieldDecorator(`Schedule_${index}_SelectedHours`, {
            initialValue: schedule.isFullDay ? 2 : 1,
            rules: [{ required: true, message: "Required" }],
          })(
            <RadioGroup
              options={SelectedHoursList}
              onChange={(value) => this.handleSelectedHourChange(value, index)}
            />
          )}
        </FormItem>
        {!schedule.isFullDay && (
          <ScheduleTimings
            key={index + this.state.schedule.length}
            index={index}
            schedule={schedule}
            form={this.props.form}
            callBack={this.handleScheduleTimingsCallBack}
          />
        )}
        <Divider dashed />
      </div>
    );
  }

  removeSchedule = (index) => {
    let { schedule } = this.state;
    schedule.splice(index, 1);
    this.setState({ schedule });
    this.props.form.resetFields();
  };
  //#endregion
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { xs: 24, sm: 12 },
            wrapperCol: { xs: 24, sm: 24 },
          }
        : null;

    const datePickerProps = {
      ...DatePickerProps,
      render: <InputIcon />,
    };
    return (
      <>
        <Spin spinning={this.state.spinLoading}>
          <Form onSubmit={this.handleSubmit}>
            <div className="ant-row">
              <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-16 ant-col-lg-16 menu-info-box-lft">
                {/* Item Visible For (Delivery, Takeaway) */}
                <div className="ant-row">
                  <div className="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                    <label>
                      <IntlMessages
                        id="menuInfo.deliveryMethod"
                        defaultMessage="Delivery Method"
                      />
                      :
                    </label>
                  </div>
                  <div class="ant-col ant-col-xs-16 ant-col-md-16 gx-mb-3">
                    <FormItem {...formItemLayout}>
                      {getFieldDecorator("ItemVisibleFor", {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: this.state.itemVisibleFor,
                      })(<CheckboxGroup options={SeviceInfoCheckBoxOptions} />)}
                    </FormItem>
                  </div>
                </div>
                {/* Item Available For Always / Selected Hour */}
                <div className="ant-row">
                  <div className="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                    <label>
                      <IntlMessages
                        id="menuInfo.itemAvailableFor"
                        defaultMessage="Item Available For"
                      />
                      :
                    </label>
                  </div>
                  <div class="ant-col ant-col-xs-16 ant-col-md-16 gx-mb-3">
                    <FormItem {...formItemLayout}>
                      {getFieldDecorator("ItemAvailable", {
                        initialValue: this.state.availableFor,
                        rules: [{ required: true, message: "Required" }],
                      })(
                        <RadioGroup
                          options={ItemAvailableForOptions}
                          onChange={this.handleItemAvailableChange}
                        />
                      )}
                    </FormItem>
                  </div>
                </div>
                {/* Schedule and it's child components */}
                {this.state.availableFor ==
                  MenuAvailability.ForSelectedHours && (
                  <>
                    <div className="ant-row">
                      <div className="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                        <label>
                          <IntlMessages
                            id="menuInfo.schedule"
                            defaultMessage="Schedule"
                          />
                          :
                        </label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-md-16 gx-mb-3">
                        {this.state.schedule.map((v, k) => {
                          return this.renderSchedule(v, k);
                        })}
                        <Button
                          type="primary"
                          onClick={() => this.addSchedule()}
                        >
                          <IntlMessages
                            id="menuInfo.addEntry"
                            defaultMessage="Add Entry"
                          />
                        </Button>
                      </div>
                    </div>
                    {/* Recurring Item */}
                    <div className="ant-row">
                      <div className="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                        <label></label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-md-16 gx-mb-3">
                        <FormItem {...formItemLayout}>
                          <Checkbox
                            checked={
                              this.state.recurrenceBy == RecurrenceBy.Weekly
                            }
                            onChange={this.handleReccurenceChange}
                          >
                            <IntlMessages
                              id="menuInfo.recurringItems"
                              defaultMessage="Recurring Item"
                            />
                          </Checkbox>
                          {(this.state.startDate || this.state.endDate) && (
                            <span className="rec-txt">
                              {this.state.recurringText}
                            </span>
                          )}
                          {this.state.recurrenceBy == RecurrenceBy.None &&
                            !this.state.endDate && (
                              <Tooltip
                                title={
                                  <IntlMessages id="menuInfo.recurringInfo" />
                                }
                                placement="right"
                              >
                                <InfoCircleFilled />
                              </Tooltip>
                            )}
                        </FormItem>
                        {/* Start Date */}
                        {this.state.recurrenceBy == RecurrenceBy.Weekly && (
                          <>
                            <div className="ant-row">
                              <div className="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                                <label>
                                  <IntlMessages
                                    id="menuInfo.startDate"
                                    defaultMessage="Start Date"
                                  />
                                  :
                                </label>
                              </div>
                              <div class="ant-col ant-col-xs-24 ant-col-md-16 gx-mb-3">
                                <FormItem {...formItemLayout}>
                                  {getFieldDecorator("StartDate", {
                                    rules: [
                                      { required: true, message: "Required" },
                                    ],
                                  })(
                                    <DatePicker
                                      onChange={this.handleStartDate}
                                      format={
                                        this.props.whitelisting.AdminDateFormat
                                      }
                                      {...datePickerProps}
                                    />
                                  )}
                                </FormItem>
                              </div>
                            </div>
                            {/* Recurrence */}
                            <div
                              className="ant-row"
                              style={{ display: "none" }} // As per requirement from client
                            >
                              <div className="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                                <label>
                                  <IntlMessages
                                    id="menuInfo.recurrence"
                                    defaultMessage="Recurrence"
                                  />
                                  :
                                </label>
                              </div>
                              <div class="ant-col ant-col-xs-24 ant-col-md-16 gx-mb-3">
                                <FormItem {...formItemLayout}>
                                  {getFieldDecorator("RecurrenceBy", {
                                    rules: [
                                      { required: true, message: "Required" },
                                    ],
                                    initialValue: RecurrenceBy.Weekly,
                                  })(
                                    <Select style={{ width: "100%" }}>
                                      {RecurrenceDropDown.map((v, key) => {
                                        return (
                                          <Option
                                            value={v.value}
                                            key={v.value}
                                            label={v.label}
                                          >
                                            {v.label}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  )}
                                </FormItem>
                              </div>
                            </div>
                            {/* Recurrence Repetition */}
                            <div className="ant-row">
                              <div className="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                                <label>
                                  <IntlMessages
                                    id="menuInfo.repeatEvery"
                                    defaultMessage="Repeat Every"
                                  />
                                  :
                                </label>
                              </div>
                              <div class="ant-col ant-col-xs-24 ant-col-md-16 gx-mb-3">
                                <FormItem {...formItemLayout}>
                                  {getFieldDecorator("RecurrenceRepetition", {
                                    rules: [
                                      { required: true, message: "Required" },
                                    ],
                                  })(
                                    <InputNumber
                                      step={1}
                                      min={1}
                                      precision={0}
                                    />
                                  )}
                                  <IntlMessages
                                    id="menuInfo.weeks"
                                    defaultMessage="Week(s)"
                                  />
                                </FormItem>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {/* End Date */}
                    <div className="ant-row">
                      <div className="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                        <label></label>
                      </div>
                      <div class="ant-col ant-col-xs-24 ant-col-md-16 gx-mb-3">
                        <FormItem {...formItemLayout}>
                          <Checkbox
                            checked={this.state.endDateChecked}
                            onChange={this.handleEndDateChange}
                          >
                            <IntlMessages
                              id="menuInfo.enterEndDate"
                              defaultMessage="Enter End Date"
                            />
                          </Checkbox>
                          {!this.state.endDate && (
                            <Tooltip
                              title={
                                <IntlMessages id="menuInfo.endDateDescription" />
                              }
                              placement="right"
                            >
                              <InfoCircleFilled />
                            </Tooltip>
                          )}
                        </FormItem>
                        {this.state.endDateChecked && (
                          <div className="ant-row">
                            <div className="ant-col ant-col-xs-24 ant-col-md-8 gx-mb-3 charge-lable">
                              <label>
                                <IntlMessages
                                  id="menuInfo.endDate"
                                  defaultMessage="End Date"
                                />
                                :
                              </label>
                            </div>
                            <div class="ant-col ant-col-xs-24 ant-col-md-16 gx-mb-3">
                              <FormItem {...formItemLayout}>
                                {getFieldDecorator("EndDate", {
                                  rules: [
                                    { required: true, message: "Required" },
                                  ],
                                })(
                                  <DatePicker
                                    onChange={this.handleEndDate}
                                    format={
                                      this.props.whitelisting.AdminDateFormat
                                    }
                                    {...datePickerProps}
                                  />
                                )}
                              </FormItem>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="ant-row">
              <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 add-btn-rite">
                <Button
                  htmlType="submit"
                  type="primary"
                  class="ant-btn color-white ant-btn-primary"
                  loading={this.state.loading}
                >
                  <i class="icon icon-add"></i>
                  <span>
                    {this.state.menuId ? (
                      "Update"
                    ) : (
                      <IntlMessages id="menuInfo.add" />
                    )}
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  domainWhitelist: state.settings.domainWhitelist,
  whitelisting: state.settings.whitelisting,
});
const WrappedMenuAvailable = injectIntl(
  Form.create()(connect(mapStateToProps)(ItemAvailability))
);
export default WrappedMenuAvailable;
