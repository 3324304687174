import React from "react";
import AmCharts from "@amcharts/amcharts3-react";

const localDataProvider = [
  {
    Date: "No Data",
    Count: 0,
  },
];

const ColumnChartWithImagesOnTop = (props) => {
  const config = {
    type: "serial",
    addClassNames: true,
    theme: "light",
    autoMargins: false,
    marginLeft: 30,
    marginRight: 8,
    marginTop: 10,
    marginBottom: 26,
    hideCredits: true,
    balloon: {
      adjustBorderColor: false,
      horizontalPadding: 10,
      verticalPadding: 8,
      color: "#ffffff",
    },

    dataProvider: props.data.length > 0 ? props.data : localDataProvider,
    valueAxes: [
      {
        axisAlpha: 0,
        position: "left",
      },
    ],
    startDuration: 1,
    graphs: [
      {
        alphaField: "alpha",
        balloonText:
          "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
        fillAlphas: 1,
        title: "Count",
        type: "column",
        valueField: "Count",
        dashLengthField: "dashLengthColumn",
      },
      // {
      //   id: "graph2",
      //   balloonText:
      //     "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
      //   bullet: "round",
      //   lineThickness: 3,
      //   bulletSize: 7,
      //   bulletBorderAlpha: 1,
      //   bulletColor: "#FFFFFF",
      //   useLineColorForBulletBorder: true,
      //   bulletBorderThickness: 3,
      //   fillAlphas: 0,
      //   lineAlpha: 1,
      //   title: "Expenses",
      //   valueField: "expenses",
      //   dashLengthField: "dashLengthLine",
      // },
    ],
    categoryField: "Date",
    categoryAxis: {
      gridPosition: "start",
      axisAlpha: 0,
      tickLength: 0,
      //parseDates: true,
      //autoRotateAngle: -45,
      //autoRotateCount: 5,
    },
    export: {
      enabled: false,
    },
  };
  return (
    <div className="App">
      <AmCharts.React
        style={{ width: "100%", height: "250px" }}
        options={config}
      />
    </div>
  );
};

export default ColumnChartWithImagesOnTop;
