import React from "react";
import { connect } from "react-redux";
import { Card } from "antd";
import { Switch, message } from "antd";
import { Button, Form, Input, Table, Select } from "antd";
import IntlMessages from "util/IntlMessages";
import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import { CustomerService } from "../../services";
import moment from "moment";
const FormItem = Form.Item;
const Option = { Select };

const columns = [
  {
    title: (
      <IntlMessages id="customer.domainName" defaultMessage="Domain Name" />
    ),
    dataIndex: "domainName",
    key: "domainName",
    width: "30%",
  },
  {
    title: (
      <IntlMessages
        id="customer.customerNumber"
        defaultMessage="Customer Number"
      />
    ),
    dataIndex: "customerNumber",
    key: "customerNumber",
    width: "30%",
  },
  {
    title: (
      <IntlMessages id="customer.createdTime" defaultMessage="Created Time" />
    ),
    dataIndex: "createTime",
    key: "createTime",
    width: "30%",
  },
  {
    title: <IntlMessages id="customer.status" defaultMessage="Status" />,
    dataIndex: "status",
    key: "status",
    width: "30%",
  },
];

const DomainAccountStatus = [
  {
    label: <IntlMessages id="customer.pending" defaultMessage="Pending" />,
    value: 1,
  },
  {
    label: <IntlMessages id="customer.accepted" defaultMessage="Accepted" />,
    value: 2,
  },
  {
    label: <IntlMessages id="customer.rejected" defaultMessage="Rejected" />,
    value: 3,
  },
];

class SaveCustomer extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  constructor() {
    super();
    this.state = {
      customerId: "",
      customer: {},
      spin: false,
      formLayout: "horizontal",
    };
  }

  componentDidMount() {
    let customerId = this.props.match.params.id;
    this.loadCustomer(customerId);
  }
  loadCustomer = async (id) => {
    try {
      this.setState({ customerId: id });
      var response = await CustomerService.details({ Id: id });
      if (response && response.Success) {
        let pc = response.Data;
        this.setState({
          customer: response.Data,
        });
        this.props.form.setFieldsValue({
          FirstName: pc.FirstName,
          LastName: pc.LastName,
          PhoneNumber: pc.PhoneNumber,
          CompanyName: pc.CompanyName,
          OrganizationNumber: pc.OrganizationNumber,
          Status: pc.Status != 3 ? true : false,
        });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
  };

  validatePhoneNumber = (e) => {
    let phoneNumber = e && e.target.value;
    console.log(phoneNumber);
    if (phoneNumber == "" || phoneNumber == null) {
      this.props.form.setFieldsValue({ PhoneNumber: "34535340" });
    }
  };

  saveCustomer = async () => {
    this.props.form.validateFields(
      [
        "FirstName",
        "LastName",
        "PhoneNumber",
        "Status",
        "OrganizationNumber",
        "CompanyName",
        "DomainList",
      ],
      async (err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          const form = this.state.customer;
          form.Status = values.Status == true ? 1 : 3;
          form.FirstName = values.FirstName;
          form.LastName = values.LastName;
          form.PhoneNumber = values.PhoneNumber;
          form.DomainList = values.DomainList;
          form.OrganizationNumber = values.OrganizationNumber;
          form.CompanyName = values.CompanyName;
          this.setState({ spin: true });
          try {
            const response = await CustomerService.save(form);
            if (response && response.Success) {
              message.success("Customer Saved!");
              this.props.history.goBack();
            } else {
              message.error(response.APIMessage);
            }
          } catch (ex) {
            message.error(ex.message);
          }
        }
        this.setState({ spin: false });
      }
    );
  };

  render() {
    const { formLayout } = this.state;
    const { getFieldDecorator } = this.props.form;

    let domainListSource = this.state.customer.DomainWithNameList?.map(
      (val, i) => {
        return {
          key: i,
          domainName: (
            <>
              {getFieldDecorator(`DomainList[${i}].Id`, {
                initialValue: val.Id,
              })(<Input type="hidden" value={val.Id} />)}
              {getFieldDecorator(`DomainList[${i}].DomainWhitelistId`, {
                initialValue: val.DomainWhitelistId,
              })(<Input type="hidden" value={val.DomainWhitelistId} />)}
              {val.DomainName}
            </>
          ),
          customerNumber: (
            <>
              {getFieldDecorator(`DomainList[${i}].CustomerNumber`, {
                initialValue: val.CustomerNumber,
              })(<Input value={val.CustomerNumber} />)}
            </>
          ),
          createTime: (
            <>
              {getFieldDecorator(`DomainList[${i}].CreateTime`, {
                initialValue: val.CreateTime,
              })(<Input type="hidden" value={val.CreateTime} />)}
              {moment(val.CreateTime).format(
                this.props.whitelisting.AdminDateTimeFormat
              )}
            </>
          ),
          status: (
            <>
              {getFieldDecorator(`DomainList[${i}].AccountStatus`, {
                initialValue: val.AccountStatus,
              })(
                <Select>
                  {DomainAccountStatus.map((item, i) => {
                    return (
                      <Option key={i} value={item.value}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </>
          ),
        };
      }
    );

    return (
      <div className="menu-editor-box card-pad-new menu-cate-list option-list">
        <div className="payout-report-box option-add-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div>
              <div className="title-sub">
                <h2>
                  <IntlMessages
                    id="customer.update"
                    defaultMessage="Update Customer"
                  />
                </h2>
                <hr className="hr-line"></hr>
              </div>
              <div class="ant-row Platform-Charges">
                <Form onSubmit={(e) => e.preventDefault()} layout={formLayout}>
                  <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 platform-charges-lft">
                    <div className="detail-res-lft">
                      <div class="ant-row charge-form op-cnter new-opp-sa">
                        <div class="ant-col" style={{ marginLeft: "50px" }}>
                          <FormItem
                            label={
                              <IntlMessages
                                id="customer.firstName"
                                defaultMessage="First Name"
                              />
                            }
                          >
                            {getFieldDecorator("FirstName", {
                              rules: [
                                { required: true, message: "Required" },
                                {
                                  max: 40,
                                  message:
                                    "First name is larger then 40 characters!",
                                },
                              ],
                            })(<Input placeholder="Customer First Name" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col" style={{ marginLeft: "50px" }}>
                          <FormItem
                            label={
                              <IntlMessages
                                id="customer.lastName"
                                defaultMessage="Last Name"
                              />
                            }
                          >
                            {getFieldDecorator("LastName", {
                              rules: [
                                { required: true, message: "Required" },
                                {
                                  max: 40,
                                  message:
                                    "Last name is larger then 40 characters!",
                                },
                              ],
                            })(<Input placeholder="Customer Last Name" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col" style={{ marginLeft: "50px" }}>
                          <FormItem
                            label={
                              <IntlMessages
                                id="customer.phone"
                                defaultMessage="Phone Number"
                              />
                            }
                          >
                            {getFieldDecorator("PhoneNumber", {
                              rules: [
                                { required: true, message: "Required" },
                                { max: 15, message: "Invalid Phone Number!" },
                              ],
                            })(<Input placeholder="Phone Number" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col" style={{ marginLeft: "50px" }}>
                          <FormItem
                            label={
                              <IntlMessages
                                id="customer.organizationNumber"
                                defaultMessage="Organization Number"
                              />
                            }
                          >
                            {getFieldDecorator(
                              "OrganizationNumber",
                              {}
                            )(<Input placeholder="Organization Number" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col" style={{ marginLeft: "50px" }}>
                          <FormItem
                            label={
                              <IntlMessages
                                id="customer.companyName"
                                defaultMessage="Company Name"
                              />
                            }
                          >
                            {getFieldDecorator(
                              "CompanyName",
                              {}
                            )(<Input placeholder="Company Name" />)}
                          </FormItem>
                        </div>
                        <div class="ant-col" style={{ marginLeft: "50px" }}>
                          <FormItem
                            label={
                              <IntlMessages
                                id="customer.status"
                                defaultMessage="Status"
                              />
                            }
                          >
                            {getFieldDecorator("Status", {
                              valuePropName: "checked",
                              initialValue: true,
                            })(<Switch />)}
                          </FormItem>
                        </div>
                      </div>
                      <div className="ant-row Platform-Charges op-cnter new-opp-sa">
                        <div className="ant-col-md-24 ant-col-sm-12 ant-col-xs-8">
                          <Table
                            columns={columns}
                            pagination={true}
                            dataSource={domainListSource}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div style={{ float: "right", marginTop: "10px" }}>
              <Button
                type="default"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <IntlMessages id="customer.cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                loading={this.state.spin}
                onClick={this.saveCustomer}
                htmlType="submit"
                type="primary"
                class="ant-btn color-white ant-btn-primary"
              >
                <i class="icon icon-add"></i>
                <IntlMessages
                  id="customer.update"
                  defaultMessage="Update Customer"
                />
              </Button>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  whitelisting: state.settings.whitelisting,
});
const WrappedSaveCustomer = Form.create()(SaveCustomer);
export default connect(mapStateToProps)(WrappedSaveCustomer);
