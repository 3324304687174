import React from "react";
import {
  Modal,
  Upload,
  Popconfirm,
  message,
  Button,
  Icon,
  Input,
  Select,
} from "antd";
import { RestaurantService } from "../../../../services";
import _ from "lodash";
import IntlMessages from "util/IntlMessages";

import "../../../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import Item from "antd/lib/list/Item";

const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">Upload</div>
  </div>
);

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/gif";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/GIF file!");
  }
  const isLt2M = 5 < file.size / 1024 / 1024 < 15;
  if (!isLt2M) {
    message.error("Image size must be in between 5MB to 15MB!");
  }

  let resolutionVerified = false;

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.addEventListener("load", (event) => {
    const _loadedImageUrl = event.target.result;
    const image = document.createElement("img");
    image.src = _loadedImageUrl;
    image.addEventListener("load", () => {
      const { width, height } = image;
      // set image width and height to your state here
      if (width < 1280 || height < 400) {
        message.error("Image Width and Height must be atleast 1280 x 400");
        resolutionVerified = false;
      } else resolutionVerified = true;
    });
  });

  return isJpgOrPng && isLt2M && resolutionVerified;
}

class RestaurantSlider extends React.Component {
  // form-scripts start
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };

  state = {
    sliderGallery: [],
    imageFiles: [],
    previewVisible: false,
    previewImage: "",
  };

  async componentDidMount() {
    const response = await RestaurantService.getRestaurantSlider(
      this.props.rid
    );
    if (response && response.data.Success) {
      const r = response.data.Data;
      console.log(r);
      if (r.SliderImages && r.SliderImages.length > 0) {
        const imageFiles = [];
        const formattedBannerData = r.SliderImages?.map((i) => {
          imageFiles.push([
            {
              uid: i?.Image ? i.Image : i?.ImageName,
              Title: i?.Title,
              Image: i?.Image ? i.Image : i?.ImageName,
              url: i?.Image ? i.Image : i?.url,
            },
          ]);
        });
        this.setState({
          sliderGallery: r.SliderImages,
          imageFiles: imageFiles,
        });
      }
    }
  }

  submit = async () => {
    try {
      this.setState({ loading: true });

      this.state.sliderGallery.forEach((item, index) => {
        item.Title = item.Title;
        console.log({ item });
        if (Array.isArray(item.Image)) {
          item.Image = item.Image[0];
        } else {
          item.Image = item.ImageName;
        }
      });

      let data = {
        Id: this.props.rid,
        SliderImages: this.state.sliderGallery.filter(
          (i) => i.Image !== undefined
        ),
      };

      let formData = new FormData();
      for (let key in data) {
        if (key === "SliderImages" && data.SliderImages.length > 0) {
          data[key].map((item, index) => {
            if (Array.isArray(item.Image)) {
              formData.append(
                `SliderImages[${index}].Image`,
                item.Image[0].originFileObj
              );
            } else if (item.Image.name) {
              formData.append(
                `SliderImages[${index}].Image`,
                item.Image.originFileObj
              );
            } else {
              formData.append(`SliderImages[${index}].ImageName`, item.Image);
            }
            formData.append(
              `SliderImages[${index}].Title`,
              item.Title ? item.Title : ""
            );
          });
        } else {
          formData.append(key, data[key]);
        }
      }

      const response = await RestaurantService.saveSlider(formData);
      if (response && response.Success) {
        message.success("Successfully Saved!");
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };
  customRequest = ({ file, onSuccess }) => {
    if (file.status !== "uploading") {
    }
    if (file.status === "done") {
      message.success(`${file.name} file uploaded successfully`);
    } else if (file.status === "error") {
      message.error(`${file.name} file upload failed.`);
    }
    setTimeout(() => onSuccess("ok"), 0);
  };
  handleChange = (e, index) => {
    const isLt2M = e.file ? 5 < e.file.size / 1024 / 1024 < 15 : true;

    let resolutionVerified = false;

    if (e.file.status == "removed") {
      resolutionVerified = true;
      if (
        (e.file.Image ||
          e.file.type === "image/png" ||
          e.file.type === "image/jpeg" ||
          e.file.type === "image/gif") &&
        isLt2M &&
        resolutionVerified
      ) {
        this.setState((prev) => {
          prev.imageFiles[index] = e.fileList;
          prev.sliderGallery[index].Image = e.fileList;
          prev.sliderGallery[index].ImageName = e.fileList;
          return {
            sliderGallery: prev.sliderGallery,
            imageFiles: prev.imageFiles,
          };
        });
      }
    } else {
      this.CheckResolution(e, isLt2M, index);
    }
  };
  _renderGallery = (i) => {
    const addTitle = (e) => {
      e.persist();
      this.setState((prev) => {
        prev.sliderGallery[i].Title = e.target.value;
        return {
          sliderGallery: prev.sliderGallery,
        };
      });
    };

    return (
      <div className="ant-row" style={{ marginBottom: "15px" }} key={i}>
        <div className="ant-col-md-8">
          <Input
            required
            type="text"
            placeholder="Add Title"
            onChange={addTitle.bind(this)}
            value={this.state.sliderGallery[i].Title}
          />
        </div>

        <div className="ant-col-md-8">
          <Upload
            listType="picture-card"
            fileList={this.state.imageFiles[i]}
            onPreview={this.handlePreview}
            customRequest={this.customRequest}
            onChange={(e) => this.handleChange(e, i)}
            beforeUpload={beforeUpload}
            // onRemove={this.handleRemove}
            accept="image/jpeg, image/png,image/gif"
          >
            {this.state.imageFiles[i]?.length ? null : uploadButton}
          </Upload>
          <Modal
            visible={this.state.previewVisible}
            footer={null}
            onCancel={this.handleCancel}
          >
            <img
              alt=""
              style={{ width: "100%" }}
              src={this.state.previewImage}
            />
          </Modal>
        </div>
        <div className="ant-col-md-4 rite-btn">
          <div className="action-btn">
            <a href="" title="Delete">
              <Popconfirm
                title="Are you sure delete this ?"
                onConfirm={() => {
                  this.setState((prev) => {
                    delete prev.sliderGallery[i];
                    delete prev.imageFiles[i];
                    return {
                      sliderGallery: prev.sliderGallery,
                      imageFiles: prev.imageFiles,
                    };
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <span className="gx-link" title="Delete">
                  <i class="icon icon-trash"></i>
                </span>
              </Popconfirm>
            </a>
          </div>
        </div>
      </div>
    );
  };

  CheckResolution(e, isLt2M, index) {
    const reader = new FileReader();
    reader.readAsDataURL(e.file);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        const { width, height } = image;
        // set image width and height to your state here

        let resolutionVerified = false;

        if (width < 1280 || height < 400) {
          resolutionVerified = false;
        } else {
          resolutionVerified = true;
        }

        if (
          (e.file.Image ||
            e.file.type === "image/png" ||
            e.file.type === "image/jpeg" ||
            e.file.type === "image/gif") &&
          isLt2M &&
          resolutionVerified
        ) {
          this.setState((prev) => {
            prev.imageFiles[index] = e.fileList;
            prev.sliderGallery[index].Image = e.fileList;
            prev.sliderGallery[index].ImageName = e.fileList;
            return {
              sliderGallery: prev.sliderGallery,
              imageFiles: prev.imageFiles,
            };
          });
        }
      });
    });
  }

  render() {
    return (
      <div>
        <div className="ant-row hd-title gx-m-0">
          <div className="title-sub gx-mt-3">
            <h2>
              <IntlMessages id="info.slider" />
            </h2>
            <hr className="hr-line"></hr>
          </div>
          <div className="ant-col-md-12 rite-btn">
            <Button
              type="primary"
              className="color-white"
              onClick={() => {
                let sliderGallery = this.state.sliderGallery;
                this.setState((prev) => {
                  prev.sliderGallery.push({
                    Title: "",
                  });
                  return { sliderGallery: prev.sliderGallery };
                });
              }}
            >
              <i class="icon icon-add"></i>
              <IntlMessages id="menuInfo.add" defaultMessage="Add" />
            </Button>
          </div>
          <div style={{ float: "right", marginTop: "10px" }}>
            <Button
              loading={this.state.loading}
              onClick={this.submit}
              htmlType="submit"
              type="primary"
              class="ant-btn color-white ant-btn-primary"
            >
              <i class="icon icon-add"></i>
              <IntlMessages id="info.save" defaultMessage="Save" />
            </Button>
          </div>
        </div>
        <div className="ant-row gx-mt-4">
          <div className="ant-col-md-24">
            <div className="item-detail-edit-box">
              {this.state.sliderGallery &&
                this.state.sliderGallery?.map((item, index) => {
                  return this._renderGallery(index);
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RestaurantSlider;
