import React from "react";
import {
  Card,
  Table,
  Spin,
  message,
  Button,
  Checkbox,
  Typography,
  Tooltip,
  Popover,
  Input,
  Select,
  Badge,
  Modal,
  Form,
  Switch,
  Popconfirm,
} from "antd";
import { RestaurantService, DeviceService } from "../../services";
import { AppSettings } from "../../constants/AppSettings";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";
import MapModal from "../sa_restaurantList/mapModal";
import moment from "moment";
import { connect } from "react-redux";
import { Icon } from "antd";

import "../../../src/routes/components/dataDisplay/Collapse/basic.less";
import "../../../src/routes/components/dataEntry/Form/otherFormControls.less";
import InfiniteScrollTable from "./../../components/table/InfiniteScrollTable";

const { Text } = Typography;
const Search = Input.Search;
const Option = Select.Option;

const columns = [
  {
    title: <IntlMessages id="restaurant.restaurantName" />,
    dataIndex: "restaurantName",
    key: "restaurantName",
    width: 200,
  },
  {
    title: <IntlMessages id="restaurant.device" defaultMessage="Device" />,
    dataIndex: "device",
    key: "device",
    width: 300,
  },
  {
    title: (
      <IntlMessages id="restaurant.autoUpdate" defaultMessage="Auto Update" />
    ),
    dataIndex: "deviceAutoUpdate",
    key: "deviceAutoUpdate",
  },
  {
    title: <IntlMessages id="restaurant.action" />,
    dataIndex: "action",
    key: "action",
  },
];

class devices extends React.Component {
  constructor() {
    super();
    this.state = {
      filter: {
        SearchQuery: "",
        PageNo: 1,
        PageSize: 15,
        RatingSortOrder: "",
        FilterServiceType: 0,
      },
      restaurants: [],
      loading: false,
      hasMore: true,
      loadingAutoUpdate: false,
      formLayout: "horizontal",
      showMapModal: false,
      mapModalDeviceLocation: { Latitude: 0, Longitude: 0 },
      restaurantLogId: "",
      deviceId: "",
      showLogModal: false,
      logLoading: false,
      isDownloadLogsModal: false,
      deviceLogs: [],
    };
  }

  async componentDidMount() {
    let searchQuery = new URLSearchParams(this.props.location.search).get(
      "searchQuery"
    );
    if (searchQuery != null) {
      this.setState(
        { filter: { ...this.state.filter, SearchQuery: searchQuery } },
        this.handleFilter
      );
    } else {
      this.handleFilter();
    }

    this.startStatusUpdatePolling();
  }

  startStatusUpdatePolling = async () => {
    setInterval(
      this.updateDevicesStatus,
      AppSettings.RefreshDeviceStatusAfterSeconds * 1000
    );
  };

  updateDevicesStatus = async () => {
    if (this.state.loadingAutoUpdate == false) {
      this.setState({ loadingAutoUpdate: true });
      try {
        var request = {
          RestaurantsIds: this.state.restaurants.map((x) => x.Id),
        };

        var response = await RestaurantService.getUpdatedDevicesStatus(request);
        if (response && response.Success) {
          if (!this.state.loading) {
            this.setDevicesStatus(response.Data);
          }
        } else {
          message.error(response.APIMessage);
        }
      } catch (ex) {
        message.error(ex.message);
      } finally {
        this.setState({ loadingAutoUpdate: false });
      }
    }
  };
  setDevicesStatus = (statuses) => {
    var restaurants = this.state.restaurants.map((r) => {
      var updatedStatuses = statuses.filter((x) => x.Id == r.Id);
      if (updatedStatuses.length > 0) {
        var updatedStatuse = updatedStatuses[0];
        r.Device = updatedStatuse.Device;
        r.CloseTill = updatedStatuse.CloseTill;
        r.LastOnlineHeartbeat = updatedStatuse.LastOnlineHeartbeat;
        r.CloseTillDateTime = updatedStatuse.CloseTillDateTime;
        r.LastOnlineHeartbeatDateTime =
          updatedStatuse.LastOnlineHeartbeatDateTime;
        return r;
      } else {
        return r;
      }
    });
    this.setState({ restaurants });
  };

  refreshList = async () => {
    this.setState({
      filter: { ...this.state.filter, PageNo: 1 },
      restaurants: [],
    });
    await this.handleFilter(1);
  };

  handleFilter = async (pageNo) => {
    this.setState({ loading: true });
    try {
      let filterServiceType =
        (this.state.delivery ? 1 : 0) +
        (this.state.takeaway ? 2 : 0) +
        (this.state.dineIn ? 8 : 0);
      let request = {
        ...this.state.filter,
        FilterServiceType: filterServiceType,
      };
      if (pageNo) request.PageNo = pageNo;
      const response = await RestaurantService.getRestaurantsList(request);
      if (response && response.Success) {
        let hasMore = response.Data.length > 0;
        if (!hasMore) message.warning("End!");
        response.Data = response.Data.map((r) => {
          r.FormattedTimings = r.FormattedTimings.map((t) => {
            try {
              t.From = moment(t.UtcFrom);
              t.To = moment(t.UtcTo);
            } catch (ex) {}
            return t;
          });
          return r;
        });

        let merge = [...this.state.restaurants, ...response.Data];
        this.setState({ restaurants: merge, hasMore: hasMore });
      } else {
        message.error(response.APIMessage);
      }
    } catch (ex) {
      message.error(ex.message);
    }
    this.setState({ loading: false });
  };

  fetchMoreData = async (e) => {
    if (!this.state.loading) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            PageNo: this.state.filter.PageNo + 1,
          },
          loading: true,
        },
        this.handleFilter
      );
    }
  };

  searchHandler = async (searchInput) => {
    this.props.history.push("/devices?searchQuery=" + searchInput);
    this.setState(
      {
        filter: { ...this.state.filter, PageNo: 1, SearchQuery: searchInput },
        restaurants: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  searchReset = (searchInput) => {
    if (searchInput.target.value == null || searchInput.target.value == "") {
      this.props.history.push("/devices");
      this.setState(
        {
          filter: { ...this.state.filter, PageNo: 1, SearchQuery: "" },
          restaurants: [],
          loading: true,
        },
        this.handleFilter
      );
    } else {
      this.setState({
        filter: { ...this.state.filter, SearchQuery: searchInput.target.value },
      });
    }
  };

  showMapModal = (location) => {
    this.setState({
      mapModalDeviceLocation: location,
      showMapModal: true,
    });
  };

  deviceOptions = (r) => {
    var localNowTime = moment().toISOString(true).split("T")[1];
    var unixLocalDate = moment(`1970-01-01T${localNowTime}`);
    var weekday = moment().utc().day();
    var operationalTiming = r.FormattedTimings.filter(
      (x) =>
        x.From <= unixLocalDate &&
        x.To >= unixLocalDate &&
        x.DayOfWeek == weekday
    );
    var closeTill = moment.unix(r.CloseTill - moment().utcOffset() * 60);

    var lastHearbeat = moment(r.LastOnlineHeartbeatDateTime);
    var isNoOperationalTimings = operationalTiming.length == 0;
    var isCloseTill = closeTill > moment();
    var isNoOnline =
      lastHearbeat <
      moment().subtract(
        AppSettings.RestaurantOnlineOfflineMarginSeconds,
        "seconds"
      );
    let version = r.Device?.AppVersion;
    let pairingCode = r.Device?.PairingCode ?? "Device Not Connected";
    let deviceId = r.Device?.Id || "";
    let status = r.Device?.IsPaired == true;
    let location = r.Device?.DeviceLocation;
    let versionJhx = version && <small>v{version}</small>;
    var lastHeartbeatTime = `${lastHearbeat.toLocaleString()} (${lastHearbeat.fromNow()})`;
    var restaurantOpenStatus = "Open Now";

    if (isNoOperationalTimings) {
      restaurantOpenStatus = "Closed Now (no timings)";
      status = false;
    } else if (isCloseTill) {
      restaurantOpenStatus = `Close till : ${closeTill.toLocaleString()} (${closeTill.fromNow()})`;
      status = false;
    } else if (isNoOnline) {
      restaurantOpenStatus = `Offline`;
      status = false;
    }

    let locationJhx = location && (
      <Link onClick={() => this.showMapModal(location)}>
        <i className="icon icon-geo-location"></i> Locate Device
      </Link>
    );
    var deviceDetailsContent = (
      <>
        <table>
          <tbody>
            <tr>
              <td>Device ID</td>
              <td>
                <small style={{ padding: 10 }}>
                  <b>
                    <Text copyable>{deviceId}</Text>
                  </b>
                </small>
              </td>
            </tr>
            <tr>
              <td>Pairing Code</td>
              <td>
                <small style={{ padding: 10 }}>
                  <b>
                    <Text copyable>{pairingCode}</Text>
                  </b>
                </small>
              </td>
            </tr>
            <tr>
              <td>OA App Version</td>
              <td>
                <small style={{ padding: 10 }}>
                  <b>{version}</b>
                </small>
              </td>
            </tr>
            <tr>
              <td>Last Online Heartbeat</td>
              <td>
                <small style={{ padding: 10 }}>
                  <b>{lastHeartbeatTime}</b>
                </small>
              </td>
            </tr>
            <tr>
              <td>Restaurant Open Status</td>
              <td>
                <small style={{ padding: 10 }}>
                  <b>{restaurantOpenStatus}</b>
                </small>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
    var devicePopover = (
      <>
        <Popover title="Device Details" content={deviceDetailsContent}>
          {" "}
          {pairingCode}
        </Popover>{" "}
        {versionJhx}
      </>
    );

    return (
      <>
        <Badge
          status={status ? "success" : "default"}
          text={r.Device?.IsPaired ? devicePopover : pairingCode}
        />
        <br />
        {locationJhx}
      </>
    );
  };

  handleLogSubmit = async () => {
    this.setState({
      logLoading: true,
    });

    if (this.state.isDownloadLogsModal) {
      this.props.form.validateFields(["LogsFile"], async (err, values) => {
        if (!err) {
          try {
            let requestData = {
              FileName: values.LogsFile,
              DeviceId: this.state.deviceId,
            };

            if (
              !this.checkIfNullOrEmpty(requestData.FileName) &&
              !this.checkIfNullOrEmpty(requestData.DeviceId)
            ) {
              const response = await DeviceService.DownloadLog(requestData);
              if (response && response.Success) {
                this.downloadLink(response.Data);
                this.setState({
                  showLogModal: false,
                  restaurantLogId: "",
                  deviceId: "",
                  deviceLogs: null,
                });
              } else {
                message.error(response.APIMessage);
              }
            }
          } catch {
            message.error("Unexpected error occured while saving.");
          }
        }

        this.setState({
          logLoading: false,
        });
      });
    } else {
      this.props.form.validateFields(["Email"], async (err, values) => {
        if (!err) {
          try {
            let requestData = {
              Email: values.Email,
              RestaurantId: this.state.restaurantLogId,
            };

            if (
              !this.checkIfNullOrEmpty(requestData.Email) &&
              !this.checkIfNullOrEmpty(requestData.RestaurantId)
            ) {
              const response = await DeviceService.RequestLog(requestData);
              if (response && response.Success) {
                message.success(response.APIMessage);
                this.setState({ showLogModal: false, restaurantLogId: "" });
              } else {
                message.error(response.APIMessage);
              }
            }
          } catch {
            message.error("Unexpected error occured while saving.");
          }
        }

        this.setState({
          logLoading: false,
        });
      });
    }
  };

  checkIfNullOrEmpty(value) {
    if (value === undefined || value === null || value === "") return true;

    return false;
  }

  handleLogModalOpen = (rId, deviceId, isDownloadModal = false) => {
    this.props.form.setFieldsValue({ LogsFile: undefined });
    let restaurant = this.state.restaurants.find((a) => a.Id == rId);
    let deviceLogs = [];
    if (restaurant) deviceLogs = restaurant?.Device?.DeviceLogs;
    this.setState({
      showLogModal: true,
      restaurantLogId: rId,
      deviceId: deviceId,
      isDownloadLogsModal: isDownloadModal,
      deviceLogs: deviceLogs,
    });
  };

  handleClearData = async (rId, deviceId) => {
    const request = {
      RestaurantId: rId,
    };

    try {
      var response = await DeviceService.ClearData(request);
      if (response && response.Success) {
        message.success("Updated!");
      }
    } catch {
      message.error("Something went wrong");
    }
  };

  handleAutoUpdateChange = async (value, rId, index) => {
    var restaurantsList = this.state.restaurants;
    restaurantsList[index].SwitchLoading = true;
    this.setState({ restaurants: restaurantsList });

    const restaurantIds = [rId];

    const request = {
      RestaurantIds: restaurantIds,
      AutoUpdate: value,
    };

    this.updateDeviceControls(request)
      .then((isSuccess) => {
        if (isSuccess) restaurantsList[index].DeviceAutoUpdate = value;
        restaurantsList[index].SwitchLoading = false;
      })
      .finally(() => {
        this.setState({ restaurants: restaurantsList });
      });
  };

  handleAutoUpdateAllDevices = async (value) => {
    this.setState({ loading: true });
    const restaurantIds = [];

    const request = {
      RestaurantIds: restaurantIds,
      AutoUpdate: value,
    };

    await this.updateDeviceControls(request);
    this.props.history.push("/devices");
    this.setState(
      {
        filter: { ...this.state.filter, PageNo: 1, SearchQuery: "" },
        restaurants: [],
        loading: true,
      },
      this.handleFilter
    );
  };

  updateDeviceControls = async (deviceRequest) => {
    try {
      const response = await RestaurantService.updateRestaurantDeviceControls(
        deviceRequest
      );
      if (response && response.Success) {
        message.success("Status updated!");
        return true;
      } else {
        message.error(response.APIMessage);
        return false;
      }
    } catch {
      message.error("Something went wrong.");
      return false;
    }
  };

  handleLogCancel = () => {
    this.setState({ showLogModal: false });
  };

  downloadLink = (uri, name) => {
    try {
      var link = document.createElement("a");
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch {
      message.error("something went wrong while downloading the file!");
    }
  };

  render() {
    const rows = this.state.restaurants.map((r, i) => {
      return {
        restaurantName:
          r.Status == 1 ? (
            r.Name
          ) : (
            <span>
              {r.Name} <Text type="danger">(Disabled)</Text>
            </span>
          ),
        device: this.deviceOptions(r),
        deviceId: <Text style={{ fontSize: "11px" }}>{r.Device?.Id}</Text>,
        deviceAutoUpdate: (
          <Switch
            disabled={r.Status != 1}
            checked={r.DeviceAutoUpdate}
            loading={r.SwitchLoading == true}
            onClick={async (value) =>
              await this.handleAutoUpdateChange(value, r.Id, i)
            }
          />
        ),
        email: r.Email,
        phone: r.PhoneNumber,
        status:
          r.Status == 1 ? (
            <span style={{ color: "green" }}>
              <IntlMessages id="restaurant.enabled" />
            </span>
          ) : (
            <IntlMessages id="restaurant.disabled" />
          ),
        action: (
          <div>
            <a
              className="ant-btn ant-btn-sm ant-btn-dashed btn-color-green"
              onClick={() => this.handleLogModalOpen(r.Id)}
            >
              <Icon type="mail" />
              Request Logs
            </a>
            <a
              className="ant-btn ant-btn-sm ant-btn-dashed btn-color-blue"
              onClick={() => this.handleLogModalOpen(r.Id, r.Device?.Id, true)}
            >
              <Icon type="cloud-download" />
              Download Logs
            </a>
            <Popconfirm
              title="Are you sure you want to clear data?"
              // for now we are disabling this functionality, in future we can use below code
              // onConfirm={() => this.handleClearData(r.Id, r.Device?.Id)}
            >
              <a
                disabled
                className="ant-btn ant-btn-sm ant-btn-dashed btn-color-red"
              >
                <Icon type="delete" />
                Clear Data
              </a>
            </Popconfirm>
          </div>
        ),
      };
    });

    const { getFieldDecorator } = this.props.form;
    return (
      <div className="menu-editor-box card-pad-new menu-cate-list">
        <MapModal
          showMarker={true}
          google={this.props.google}
          height="300px"
          zoom={15}
          center={this.state.mapModalDeviceLocation}
          onClose={() => this.setState({ showMapModal: false })}
          visible={this.state.showMapModal}
        />

        <Modal
          visible={this.state.showLogModal}
          title={
            this.state.isDownloadLogsModal ? "Download Logs" : "Log Request"
          }
          onOk={this.handleLogSubmit}
          onCancel={this.handleLogCancel}
          footer={[
            <Button
              key="back"
              onClick={this.handleLogCancel}
              disabled={this.state.logLoading}
            >
              Back
            </Button>,
            <Button
              key="submit"
              loading={this.state.logLoading}
              onClick={this.handleLogSubmit}
            >
              Submit
            </Button>,
          ]}
        >
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 14 }}>
            {this.state.isDownloadLogsModal ? (
              <Form.Item label="Files">
                {getFieldDecorator("LogsFile", {
                  rules: [
                    {
                      required: true,
                      message: "Please select log file!",
                    },
                  ],
                })(
                  <Select
                    filterOption={false}
                    disabled={
                      this.state.deviceLogs == null ||
                      this.state.deviceLogs?.length == 0
                    }
                    placeholder={
                      this.state.deviceLogs == null ||
                      this.state.deviceLogs == undefined ||
                      this.state.deviceLogs?.length == 0
                        ? "No files available"
                        : "Select file"
                    }
                  >
                    {this.state.deviceLogs?.map((log, index) => {
                      return (
                        <Option key={index} value={log.FileName}>
                          {moment(log.LogsDate).format(
                            this.props.whitelisting.AdminDateFormat
                          )}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            ) : (
              <Form.Item label="Receiving E-mail">
                {getFieldDecorator("Email", {
                  rules: [
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            )}
          </Form>
        </Modal>
        <div className="payout-report-box">
          <Card className="gx-card breadcrumb-box" title="">
            <div>
              <div className="ant-row">
                <div className="search-box ant-col-md-12">
                  <IntlMessages id="categoryList.search">
                    {(text) => (
                      <Search
                        placeholder={text}
                        onSearch={(value) => this.searchHandler(value)}
                        onChange={(value) => this.searchReset(value)}
                        style={{ width: 200 }}
                      />
                    )}
                  </IntlMessages>
                </div>
                <div className="ant-col-md-12">
                  <div className="ant-row" style={{ float: "right" }}>
                    <div className="ant-col-md-12 gx-m-0 gx-p-0">
                      <Popconfirm
                        title="Are you sure you want to auto update all?"
                        onConfirm={() => this.handleAutoUpdateAllDevices(true)}
                      >
                        <a className="ant-btn ant-btn-sm ant-btn-dashed btn-color-red">
                          Enable Auto Update All Devices
                        </a>
                      </Popconfirm>
                    </div>
                    <div className="ant-col-md-12 gx-m-0 gx-p-0">
                      <Popconfirm
                        title="Are you sure you want to auto update all?"
                        onConfirm={() => this.handleAutoUpdateAllDevices(false)}
                      >
                        <a className="ant-btn ant-btn-sm ant-btn-dashed">
                          Disable Auto Update All Devices
                        </a>
                      </Popconfirm>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ant-row">
                <div className="ant-col gx-order-sm-1 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
                  <InfiniteScrollTable
                    columns={columns}
                    dataSource={rows}
                    loading={this.state.loading}
                    hasMore={this.state.hasMore}
                    height={"calc(100vh - 205px)"}
                    loadMore={this.fetchMoreData}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  whitelisting: state.settings.whitelisting,
});
const WrappedDevicesList = injectIntl(Form.create()(devices));
export default connect(mapStateToProps)(WrappedDevicesList);
